import React from 'react';
import { TextButton } from '../../Buttons';
import { EditorView } from './ProjectEditorNavigationView';

interface Props {
    name: string;
    view: EditorView;
    changeView: (view: EditorView) => unknown;
    active?: boolean;
}

export const ProjectEditorNavigationItem: React.FC<Props> = ({ name, view, changeView, active }) => {
    const handleClick = () => changeView(view);

    return (
        <TextButton
            className={`mr-3 mb-3 ${active ? 'active' : ''}`}
            name={name}
            onClick={handleClick}
            active={active}
        />
    );
};
