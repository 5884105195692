export const RoutesEnum = {
    REGISTER_REDIRECT: '/api/auth/register-redirect',
    LOGIN_REDIRECT: '/api/auth/login-redirect',
    AUTH_REDIRECT: '/api/auth/authentication-redirect',
    CHANGE_PASSWORD: '/api/auth/change-password-redirect',
    BASE: '/',
    REGISTER: '/register',
    WAITING_FOR: '/waitingfor',
    FEEDBACK: '/feedback',
    CUSTOMER_SERVICE: '/customer-service',
    ERROR: '/error/:whatFor?',
    THANK_YOU: '/thankyou/:whatFor?',
    GLOSSARY: '/glossary',
    DISCLAIMER: '/disclaimer',
    EDITOR: '/editor',
    MAIN: '/main',
    WORKBOOK_VIEW: '/tableau-workbooks/:workbookId?/:viewId?',
    AWS_BUCKET_SRC: `${process.env.REACT_APP_S3_PATH}/:page/:dataName`,
    PROJECTS: '/editor/projects',
    PROJECT_PAGE: '/editor/project/:projectId',
    EDITOR_CREATE: `/editor/create/:type(project|estimation)`,
} as const;
