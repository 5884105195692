import { faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useMemo, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { DialogFormProps, useFormDialog } from '../Dialog';
import { TextInput } from '../FormComponents';
import { TagEditorReplacementForm } from './TagEditorReplacementForm';
import { TFunction } from 'i18next';
import { noop } from 'lodash';

interface Props {
    tag: Tag;
    tags: Tag[];
    index: number;
    language: EditorLanguage;
    onChange: (tag: Tag) => unknown;
    onDelete: (tag: Tag) => unknown;
    onReplace: (tag: Tag, replacement: Tag) => unknown;
    t: TFunction;
}

export const TagEditorRow: React.FC<Props> = ({ tag, tags, index, language, onChange, onDelete, onReplace, t }) => {
    const [changes, setChanges] = useState(tag);

    const hasChanges = Object.entries(changes.name).some(([key, value]) => tag.name[key] !== value);

    const form = useMemo(
        () =>
            function TagReplacementForm(props: DialogFormProps<Tag[], Tag>) {
                return <TagEditorReplacementForm {...props} text={t('editor:tagEditor:replace:text')} />;
            },
        [t]
    );

    const openReplaceDialog = useFormDialog({
        form,
        confirm: t('editor:tagEditor:replace:confirm'),
        cancel: t('editor:tagEditor:replace:cancel'),
        onConfirm: noop,
        onCancel: noop,
    });

    const otherTags = useMemo(
        () => tags.filter(({ id }, i) => (tag.id ? id !== tag.id : i !== index)),
        [tags, tag, index]
    );

    const handleTextChangeDE = useCallback(
        (name) =>
            setChanges((t) => ({
                ...t,
                name: { ...t.name, de_DE: name },
            })),
        []
    );

    const handleTextChangeFR = useCallback(
        (name) =>
            setChanges((t) => ({
                ...t,
                name: { ...t.name, fr_FR: name },
            })),
        []
    );

    const handleTextChangeIT = useCallback(
        (name) =>
            setChanges((t) => ({
                ...t,
                name: { ...t.name, it_IT: name },
            })),
        []
    );

    const handleTextChangeEN = useCallback(
        (name) =>
            setChanges((t) => ({
                ...t,
                name: { ...t.name, en_GB: name },
            })),
        []
    );

    const handleSave = useCallback(() => {
        onChange({ ...tag, name: changes.name });
    }, [onChange, tag, changes]);

    const handleDelete = useCallback(async () => {
        if (!tag.usages) return onDelete(tag);

        const replacement = await openReplaceDialog(otherTags);

        if (!replacement) return;

        onReplace(tag, replacement);
    }, [tag, otherTags, onDelete, openReplaceDialog, onReplace]);

    tag = { ...tag, name: { ...changes.name } };

    return (
        <React.Fragment>
            <Row className="data-view__project-tag py-1 mt-2">
                <Col>{tag.name[language.codes.editor]}</Col>
                {hasChanges && (
                    <Col xs="auto" role="button" onClick={handleSave}>
                        <FontAwesomeIcon icon={faSave} />
                    </Col>
                )}
                <Col xs="auto" role="button" onClick={handleDelete}>
                    <FontAwesomeIcon icon={faTrash} />
                </Col>
            </Row>
            <Row className="data-view__project-tag border-bottom py-1">
                <Col className="px-1">
                    <TextInput label={t('languages:de')} value={tag.name.de_DE} onChange={handleTextChangeDE} />
                </Col>
                <Col className="px-1">
                    <TextInput label={t('languages:fr')} value={tag.name.fr_FR} onChange={handleTextChangeFR} />
                </Col>
                <Col className="px-1">
                    <TextInput label={t('languages:it')} value={tag.name.it_IT} onChange={handleTextChangeIT} />
                </Col>
                <Col className="px-1">
                    <TextInput label={t('languages:en')} value={tag.name.en_GB} onChange={handleTextChangeEN} />
                </Col>
            </Row>
        </React.Fragment>
    );
};
