import { TFunction } from 'i18next';
import React, { useCallback } from 'react';
import { Col, Row } from 'reactstrap';
import { NumberInput } from '../../../../FormComponents';

interface Props {
    measurement: ProjectMeasurement;
    changeMeasurement: (id: ID, update: (measurement: ProjectMeasurement) => ProjectMeasurement) => void;
    language: EditorLanguage;
    t: TFunction;
}

export const Measurement: React.FC<Props> = ({ measurement, language, changeMeasurement, t }) => {
    const regulationId = measurement.regulation.id;
    const changeValue = useCallback(
        (value = '') =>
            changeMeasurement(regulationId, (measurement) => ({
                ...measurement,
                value: parseFloat(`${value}`),
            })),
        [changeMeasurement, regulationId]
    );

    return (
        <Row className="measurement border-bottom" data-testid="measurement-row">
            <Col xs="1" className="d-flex align-items-center" />
            <Col xs={2} className="d-flex align-items-center">
                {measurement.regulation.code}
            </Col>
            <Col xs={4} className="px-0 d-flex align-items-center">
                {measurement.regulation.shortName?.[language.codes.editor]}&nbsp;
                {`${
                    measurement.regulation.description?.[language.codes.editor] ||
                    Object.values(measurement.regulation.description ?? {})[0]
                } ${measurement.regulation.measureUnit}`}
            </Col>
            <Col xs={5} className="px-0 mt-1">
                <NumberInput
                    label={t('editor:projects:creator:measurement-sheet:label')}
                    decimals={2}
                    value={`${measurement.value || ''}`}
                    onChange={changeValue}
                />
            </Col>
        </Row>
    );
};
