import {
    Building as BuildingPlanBuilding,
    Floor as BuildingPlanFloor,
    FloorCalculation,
} from '@crb-oa-viewer/data-assistant-building-plan';
import { v4 as uuidv4 } from 'uuid';

export function extractOverview(staticData: StaticData, project: Project): ProjectOverview {
    const parType = staticData.parameters.find((par) => par.key === 'PROJECT_PHASE');
    const projParValueId = project.parameters?.find((par) => par.typeId === parType?.id)?.valueId;
    const projParTrans = parType?.values.find((v) => v.id === projParValueId)?.name;
    const emptyTranslationMap: TranslationMap = {};

    return {
        id: project.id,
        name: project.name,
        creator: project.creator,
        owner: project.owner,
        buildingClassCrb: project.buildingClassCrb,
        buildingClassPartners: project.buildingClassPartners,
        linkedProjects: project.linkedProjects,
        cantonCode: project.projectAddress?.location?.zipCode
            ? project.projectAddress.location.canton?.cantonCode || ''
            : '',
        moveIn: project.constructionTime?.moveIn || '',
        phase: projParTrans || emptyTranslationMap,
        userPublished: !!project.userPublished,
        thumbnail: project.thumbnail,
        arithmeticalFlags: project.arithmeticalFlags,
    };
}

export function valueFromPath<T>(obj: unknown, [key, ...path]: string[]): T | undefined {
    if (!key) {
        return obj as T;
    }

    let part;
    try {
        part = ((obj || {}) as Record<string, unknown>)[key];
    } catch (e) {
        return undefined;
    }

    return valueFromPath(part, path);
}

export const override = <O extends Record<string, unknown>>(obj: O, path: string, value: unknown): O => {
    const [key, ...restPath] = path.split('.');

    if (restPath.length === 0) {
        return {
            ...obj,
            [key]: value,
        };
    } else {
        return {
            ...(obj ?? ({} as O)),
            [key]: override((obj[key] ?? {}) as O, restPath.join('.'), value),
        };
    }
};

export function mapProjectFloorToBuildingPlanFloor(floor: Floor): BuildingPlanFloor {
    return {
        ...floor,
        uses: [],
        area: undefined,
        floorFunctions: undefined,
        id: floor.id,
        contours: floor.contours ?? [],
    } as BuildingPlanFloor;
}

export function mapProjectBuildingToBuildingPlanBuilding(
    building: Building,
    languageCode: string
): BuildingPlanBuilding {
    return {
        ...building,
        referenceZeroHeight: building.referenceHeight,
        id: building.id,
        name: building.name[languageCode],
        floors:
            building.floors
                .map((f) => mapProjectFloorToBuildingPlanFloor(f))
                .sort((a, b) => getSignedFloorNumber(b) - getSignedFloorNumber(a)) ?? [],
    } as BuildingPlanBuilding;
}

export function mapBuildingPlanFloorToProjectFloor(floor: BuildingPlanFloor): Partial<Floor> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { uses, ...rest } = floor;
    return rest;
}

export function mapBuildingPlanBuildingToProjectBuilding(
    building: BuildingPlanBuilding,
    languageCode: string
): Partial<Building> {
    return {
        referenceHeight: building.referenceZeroHeight,
        id: building.id,
        name: { [languageCode]: building.name },
        floors: building.floors.map((f) => mapBuildingPlanFloorToProjectFloor(f)),
    } as Building;
}

/**
 * Adds a ids  if the project has a buildings or floors that
 * do not have ids.
 */
export function addIdToBuildingsAndFloors(project: Project): Project {
    return {
        ...project,
        buildings: project.buildings.map((b) => ({
            ...b,
            id: b.id ?? uuidv4(),
            floors: b.floors.map((f) => ({
                ...f,
                id: f.id ?? uuidv4(),
            })),
        })),
    };
}

export function getSignedFloorNumber(floor: Floor | BuildingPlanFloor | FloorCalculation): number {
    return floor.floorNumber * (floor.floorType === 'UNDER_GROUND' ? -1 : 1);
}
