import { TFunction } from 'i18next';
import { SelectOption } from '../types';
import { useMemo } from 'react';

export function useTagSelectorOptions(
    tags: ProjectTag[],
    value: ProjectTag | null,
    hiddenTags: number[] = [],
    text: string,
    language: string,
    t: TFunction
): SelectOption<ProjectTag>[] {
    // Map tag to option
    let options = useMemo<SelectOption<ProjectTag>[]>(() => {
        return tags.map<SelectOption<ProjectTag>>((value, i) => ({
            title: value.isPublic
                ? t('editor:project:parameters:projectTags:isPublic')
                : t('editor:project:parameters:projectTags:isPrivate'),
            label: value.name[language],
            value,
            disabled: false,
            focused: false,
            hidden: false,
            key: value.id ?? -i,
        }));
    }, [tags, language, t]);

    const newOption = useMemo<SelectOption<ProjectTag> | null>(() => {
        if (!value || value.id || !value.name[language]) return null;

        return {
            hidden: false,
            title: t('editor:project:parameters:projectTags:isNew'),
            label: value.name[language],
            value: value,
            key: 'new',
        };
    }, [value, language, t]);

    options = useMemo(() => [...(newOption ? [newOption] : []), ...options], [options, newOption]);

    options = useMemo(() => {
        const valueId = value?.id;

        return options.map((opt) => ({
            ...opt,
            selected: opt.value.id === valueId,
        }));
    }, [options, value]);

    options = useMemo(() => {
        return options.map((option) => {
            const hiddenByTags = option.value.id && hiddenTags.includes(option.value.id);
            if (hiddenByTags) return { ...option, hidden: true };

            const hasValueId = options[0]?.value.id;
            const hiddenByText = !option.value.name[language].toLowerCase().startsWith(text.toLowerCase());
            if (!hasValueId && hiddenByText) return { ...option, hidden: true };

            return { ...option, hidden: false };
        });
    }, [options, hiddenTags, text, language]);

    return options;
}
