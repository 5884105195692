import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'i18next';
import React, { FC, useCallback, useState } from 'react';
import { Col, Row } from 'reactstrap';
import {
    useFloorUsageTagCreate,
    useFloorUsageTagDelete,
    useFloorUsageTagReplace,
    useFloorUsageTagUpdate,
    useFloorUsageTags,
} from '../../../../../../effects';
import { TextButton } from '../../../../../Buttons';
import { TagEditor } from '../../../../../TagEditor';
import { FloorUsageRow } from './FloorUsageRow';
import { useConfirmDialog } from '../../../../../Dialog';

interface Props {
    project: Project;
    language: EditorLanguage;
    changeProject: (update: (oldProject: Project) => Project) => unknown;
    t: TFunction;
}

export const FloorUsages: FC<Props> = ({ project, language, changeProject, t }) => {
    const [state, setState] = useState({
        editorOpen: false,
    });

    const { mutateAsync: createTag } = useFloorUsageTagCreate();
    const { mutateAsync: updateTag } = useFloorUsageTagUpdate();
    const { mutateAsync: deleteTag } = useFloorUsageTagDelete();
    const { mutateAsync: replaceTag } = useFloorUsageTagReplace();

    const openDeletionConfirmDialog = useConfirmDialog({
        title: t('editor:project:parameters:floorUsages:tags:confirmDelete.title'),
        description: t('editor:project:parameters:floorUsages:tags:confirmDelete.description'),
        confirm: t('editor:project:parameters:floorUsages:tags:confirmDelete.confirm'),
        cancel: t('editor:project:parameters:floorUsages:tags:confirmDelete.cancel'),
    });

    const openReplacementConfirmDialog = useConfirmDialog({
        title: t('editor:project:parameters:floorUsages:tags:replaceDelete.title'),
        description: t('editor:project:parameters:floorUsages:tags:replaceDelete.description'),
        confirm: t('editor:project:parameters:floorUsages:tags:replaceDelete.confirm'),
        cancel: t('editor:project:parameters:floorUsages:tags:replaceDelete.cancel'),
    });

    const { usages = [] } = project;

    const { data: tags } = useFloorUsageTags();

    const changeFloorUsage = useCallback(
        (usage, index) => {
            changeProject(({ usages = [], ...p }) => ({
                ...p,
                usages: usages.map((t, i) => {
                    if (i === index) return usage;

                    return t;
                }),
            }));
        },
        [changeProject]
    );

    const deleteFloorUsage = useCallback(
        (index) => {
            changeProject(({ usages = [], ...p }) => ({
                ...p,
                usages: usages.filter((_, i) => i !== index),
            }));
        },
        [changeProject]
    );

    const addFloorUsage = useCallback(() => {
        changeProject((project) => ({
            ...project,
            usages: [
                ...(project.usages ?? []),
                {
                    regulation: { id: -1 },
                    tag: { id: null, name: {} },
                    area: 0,
                } as FloorUsage,
            ],
        }));
    }, [changeProject]);

    const openTagEditor = useCallback(() => setState((s) => ({ ...s, editorOpen: true })), []);
    const closeTagEditor = useCallback(() => setState((s) => ({ ...s, editorOpen: false })), []);

    const handleTagCreate = useCallback(
        async (tag: TagCreate) => {
            await createTag(tag);
        },
        [createTag]
    );

    const handleTagEdit = useCallback(
        async (tag: Tag) => {
            if (tag.id && tag.id > 0) await updateTag([tag.id, tag]);
        },
        [updateTag]
    );

    const handleTagDelete = useCallback(
        async (tag: Tag) => {
            if (await openDeletionConfirmDialog()) {
                if (tag.id && tag.id > 0) await deleteTag(tag.id);
            }
        },
        [deleteTag, openDeletionConfirmDialog]
    );

    const handleTagReplace = useCallback(
        async (tag: Tag, replacement: Tag) => {
            if (await openReplacementConfirmDialog()) {
                if (tag.id && tag.id > 0 && replacement.id && replacement.id > 0)
                    await replaceTag([tag.id, replacement.id]);
            }
        },
        [replaceTag, openReplacementConfirmDialog]
    );

    return (
        <Row xs={12} className="small-push-bottom">
            <Col>
                <div className="d-flex justify-content-between mb-3">
                    <h3>{t('editor:project:parameters:floorUsages:title')}</h3>
                    <TextButton
                        name={t('editor:project:parameters:floorUsages:tags:showTags')}
                        onClick={openTagEditor}
                    />
                </div>
                {tags &&
                    usages.map((usage, i) => (
                        <FloorUsageRow
                            usage={usage}
                            language={language}
                            tags={tags}
                            onChange={changeFloorUsage}
                            onDelete={deleteFloorUsage}
                            index={i}
                            t={t}
                            key={i}
                        />
                    ))}
                <div className="mt-3 project-editor__action" onClick={addFloorUsage} data-testid="add-floor-usage">
                    {`${t('editor:project:parameters:floorUsages:addFloorUsage')} `}
                    <FontAwesomeIcon icon={faPlus} />
                </div>
            </Col>

            {state.editorOpen && tags && (
                <TagEditor
                    title={t('editor:project:parameters:floorUsages:tags:tagEditor:title')}
                    tags={tags}
                    language={language}
                    onCreate={handleTagCreate}
                    onEdit={handleTagEdit}
                    onDelete={handleTagDelete}
                    onReplace={handleTagReplace}
                    onClose={closeTagEditor}
                    t={t}
                />
            )}
        </Row>
    );
};
