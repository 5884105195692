import React from 'react';
import { useEvent } from '../../effects';

function use<Args extends unknown[]>(
    usage: (...args: Args) => unknown,
    deps: Args extends Array<infer I> ? Array<I | null | undefined> : never
): void {
    const allAvailable = !deps.some((dep) => !dep);

    if (allAvailable) usage(...(deps as unknown as Args));
}

function getParent<Ref extends React.RefObject<HTMLElement>>(ref: Ref) {
    return ref.current ? ref.current.parentElement : undefined;
}

function getDimensions<Element extends HTMLElement>(element: Element) {
    return {
        width: element.clientWidth,
        height: element.clientHeight,
    };
}

// function getRatio(dimensions: { width: number, height: number }) {
//     return dimensions.width / dimensions.height;
// }

function calculateStyle(ratio: number, dimensions: { width: number; height: number }) {
    const width = dimensions.width;
    const height = dimensions.width / ratio;

    return { width, height };
}

export function useAspectRatio<Element extends HTMLElement>(
    ratio: number,
    deps: unknown[] = []
): [React.RefObject<Element>, React.CSSProperties] {
    const [style, setStyle] = React.useState<React.CSSProperties>({});
    const ref = React.useRef<Element>(null);

    React.useEffect(
        () => {
            use<[HTMLElement, HTMLElement]>(
                (_, parent) => {
                    const dimensions = getDimensions(parent);

                    setStyle(calculateStyle(ratio, dimensions));
                },
                [ref.current, getParent(ref)]
            );
        },
        [ratio, ...deps] // eslint-disable-line react-hooks/exhaustive-deps
    );

    useEvent(
        window,
        'resize',
        () => {
            use<[HTMLElement, HTMLElement]>(
                (_, parent) => {
                    const dimensions = getDimensions(parent);
                    setStyle(calculateStyle(ratio, dimensions));
                },
                [ref.current, getParent(ref)]
            );
        },
        [ratio, ...deps] // eslint-disable-line react-hooks/exhaustive-deps
    );

    useEvent(
        window,
        'orientationchange',
        () => {
            use<[HTMLElement, HTMLElement]>(
                (_, parent) => {
                    const dimensions = getDimensions(parent);
                    setStyle(calculateStyle(ratio, dimensions));
                },
                [ref.current, getParent(ref)]
            );
        },
        [ratio, ...deps] // eslint-disable-line react-hooks/exhaustive-deps
    );

    return [ref, style];
}

interface Props {
    ratio: number;
}

export const AspectRatio: React.FC<Props> = ({ ratio, children }) => {
    const [ref, style] = useAspectRatio<HTMLDivElement>(ratio);

    return (
        <div ref={ref} className="aspect-ratio" style={style}>
            {children}
        </div>
    );
};
