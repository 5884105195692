import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFloorUsageTags, useFunctionalUnitTags, useLanguage, useStaticData } from '../../effects';
import { editorService } from '../../services';
import { exportElektrosmog, exportProject } from '../../utils';
import { Overlay } from '../Overlay';
import { TextButton } from '../Buttons';
import axios, { CancelToken } from 'axios';
import { Spinner } from '../Spinner';
import classnames from 'classnames';

interface ProgressDetails {
    success: number[];
    failed: number[];
}

interface Props {
    projects: number[];
    onCancel?: () => unknown;
    onComplete?: (completedExports: number) => unknown;
    onProgress?: (completedExports: number, totalExports: number, details: ProgressDetails) => unknown;
    onClose?: () => unknown;
}

const fetchProject = (id: number, cancelToken?: CancelToken) => editorService.getProject(id, cancelToken);

const NO_TAGS: Tag[] = [];

export const ProjectExcelExporter: React.FC<Props> = ({ projects, onCancel, onComplete, onProgress, onClose }) => {
    const { data: staticData } = useStaticData();

    const { data: functionalUnitTags = NO_TAGS } = useFunctionalUnitTags();

    const [t, i18n] = useTranslation();
    const [progress, setProgress] = useState<ProgressDetails>({ success: [], failed: [] });

    const projectCount = projects.length;
    const lang = i18n.language;

    useEffect(() => {
        const token = axios.CancelToken.source();
        const fetchWithToken = (id: number) => fetchProject(id, token.token);

        if (!staticData) return;

        (async () => {
            for (let i = 0; i < projects.length && !token.token.reason; i++) {
                const projectId = projects[i];
                try {
                    const project = await fetchWithToken(projectId);
                    await exportProject(project, staticData, functionalUnitTags, fetchWithToken, t, lang, token.token);

                    if (!token.token.reason) setProgress((p) => ({ ...p, success: [...p.success, projectId] }));
                } catch (error) {
                    if (!token.token.reason) setProgress((p) => ({ ...p, failed: [...p.failed, projectId] }));
                    console.error(error);
                }
            }
        })();

        return () => token.cancel('Export cancelled');
    }, [projects, staticData, functionalUnitTags, t, lang]);

    const progressCount = progress.success.length + progress.failed.length;
    useEffect(
        () => void onProgress?.(progressCount, projectCount, progress),
        [progress, progressCount, projectCount, onProgress]
    );
    useEffect(() => () => setProgress({ success: [], failed: [] }), [projects]);

    const isComplete = progressCount === projectCount;
    useEffect(() => {
        if (isComplete) onComplete?.(projectCount);
    }, [isComplete, projectCount, onComplete]);

    return (
        <Overlay>
            <div className={classnames('project-exporter', 'project-exporter--completed')}>
                <div className="project-exporter__spinner">
                    {!isComplete && <Spinner color="#000" size={128} />}
                    <p className="project-exporter__spinner-text">
                        {progressCount} / {projects.length}
                        {isComplete && (
                            <>
                                <br />
                                {t('export:success', { count: progress.success.length })}
                                <br />
                                {!!progress.failed.length && t('export:failed', { count: progress.failed.length })}
                            </>
                        )}
                    </p>
                </div>

                {!isComplete && <TextButton onClick={onCancel} name={t('common:cancel')} />}
                {isComplete && <TextButton onClick={onClose} name={t('common:ok')} />}
            </div>
        </Overlay>
    );
};

export const ProjectElektrosmogExporter: React.FC<Props> = ({
    projects,
    onCancel,
    onComplete,
    onProgress,
    onClose,
}) => {
    const { data: staticData } = useStaticData();
    const [t] = useTranslation();
    const [progress, setProgress] = useState<ProgressDetails>({ success: [], failed: [] });

    const projectCount = projects.length;
    const [lang] = useLanguage();

    const { data: usageTags } = useFloorUsageTags();

    useEffect(() => {
        const token = axios.CancelToken.source();
        const fetchWithToken = (id: number) => fetchProject(id, token.token);

        if (!staticData) return;
        if (!usageTags) return;

        (async () => {
            for (let i = 0; i < projects.length && !token.token.reason; i++) {
                const projectId = projects[i];
                try {
                    const project = await fetchWithToken(projectId);
                    await exportElektrosmog(project, staticData, usageTags, lang, t, token.token);

                    if (!token.token.reason) setProgress((p) => ({ ...p, success: [...p.success, projectId] }));
                } catch (error) {
                    if (!token.token.reason) setProgress((p) => ({ ...p, failed: [...p.failed, projectId] }));
                    console.error(error);
                }
            }
        })();

        return () => token.cancel('Export cancelled');
    }, [projects, staticData, usageTags, t, lang]);

    const progressCount = progress.success.length + progress.failed.length;
    useEffect(
        () => void onProgress?.(progressCount, projectCount, progress),
        [progress, progressCount, projectCount, onProgress]
    );
    useEffect(() => () => setProgress({ success: [], failed: [] }), [projects]);

    const isComplete = progressCount === projectCount;
    useEffect(() => {
        if (isComplete) onComplete?.(projectCount);
    }, [isComplete, projectCount, onComplete]);

    return (
        <Overlay>
            <div className={classnames('project-exporter', 'project-exporter--completed')}>
                <div className="project-exporter__spinner">
                    {!isComplete && <Spinner color="#000" size={128} />}
                    <p className="project-exporter__spinner-text">
                        {progressCount} / {projects.length}
                        {isComplete && (
                            <>
                                <br />
                                {t('export:success', { count: progress.success.length })}
                                <br />
                                {!!progress.failed.length && t('export:failed', { count: progress.failed.length })}
                            </>
                        )}
                    </p>
                </div>

                {!isComplete && <TextButton onClick={onCancel} name={t('common:cancel')} />}
                {isComplete && <TextButton onClick={onClose} name={t('common:ok')} />}
            </div>
        </Overlay>
    );
};
