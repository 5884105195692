import classnames from 'classnames';
import { noop, uniqueId } from 'lodash';
import React, { ChangeEvent, FocusEvent, useCallback, useEffect, useState } from 'react';

export type InputType = 'number' | 'text' | 'email' | 'tel' | 'password' | 'date' | 'time';

export interface DefaultTextInputProps {
    id?: string;
    name?: string;
    className?: string;
    labelClassName?: string;
    noDefaultClassName?: boolean;
    wrapperClassName?: string;

    label?: string;

    autoComplete?: string;
    autoFocus?: boolean;
    disabled?: boolean;
    required?: boolean;

    gutterTop?: boolean;

    lazy?: boolean;

    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => unknown;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => unknown;
    onPaste?: (event: React.ClipboardEvent<HTMLElement>) => unknown;
    testId?: string;

    placeholder?: string | undefined;
    max?: string | number | undefined;
    handleChange?: ({ currentTarget }: ChangeEvent<HTMLInputElement>) => void;
    type?: InputType;
}

interface BaseTextInputProps extends DefaultTextInputProps {
    value: string;
    onTouched?: () => unknown;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => unknown;
}

export const BaseTextInput: React.FC<BaseTextInputProps> = ({
    value = '',
    autoComplete,
    autoFocus,
    className,
    labelClassName,
    disabled,
    gutterTop,
    id: givenId,
    name,
    label,
    noDefaultClassName,
    onChange = noop,
    onFocus = noop,
    onTouched = noop,
    onPaste = noop,
    onBlur = noop,
    required,
    type = 'text',
    wrapperClassName,
    testId,
    placeholder = undefined,
    max = undefined,
}) => {
    const generatedId = React.useMemo(() => uniqueId(), []);
    const id = givenId ?? generatedId;

    const filled = !!`${value}`.trim().length;

    return (
        <div
            className={classnames(wrapperClassName, {
                form__input: !noDefaultClassName,
                'gutter-top': gutterTop,
            })}
            onClick={onTouched}
            onTouchStart={onTouched}
            onFocus={onFocus}
            onBlur={onBlur}
            onPaste={onPaste}
        >
            <input
                id={id}
                name={name}
                type={type}
                value={value}
                className={classnames(className, 'form__input-field', {
                    'form__input-field--filled': filled,
                })}
                onChange={onChange}
                required={required}
                autoComplete={autoComplete}
                autoFocus={autoFocus}
                disabled={disabled}
                placeholder={placeholder}
                max={max}
                data-testid={testId}
            />

            {label && (
                <label className={labelClassName ?? 'form__input-label'} htmlFor={id} data-testid="text-input-label">
                    <span className={labelClassName ? '' : 'form__input-label-content'}>
                        {label} {required ? '*' : ''}
                    </span>
                </label>
            )}
        </div>
    );
};

export const useDefaultChangeAndBlur = (
    value: string,
    type: InputType,
    lazy: boolean | undefined,
    onChange: OnChange<string>,
    onBlur: (e: FocusEvent<HTMLInputElement>) => void,
    initialValue: string = value
): {
    currentValue: string;
    setCurrentValue: React.Dispatch<React.SetStateAction<string>>;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => unknown;
    handleBlur: (e: FocusEvent<HTMLInputElement>) => unknown;
} => {
    const [text, setText] = useState(initialValue);
    useEffect(() => {
        setText(value);
    }, [value]);

    const handleChange = useCallback(
        ({ target }: React.ChangeEvent<HTMLInputElement>) => {
            setText(target.value);

            if (!lazy) {
                onChange(target.value);
            }
        },
        [setText, onChange, lazy]
    );

    const handleBlur = useCallback(
        (e: FocusEvent<HTMLInputElement>) => {
            e.stopPropagation();

            if (lazy) {
                onChange(text);
            }

            onBlur(e);
        },
        [lazy, onBlur, onChange, text]
    );

    return { currentValue: text, setCurrentValue: setText, handleChange, handleBlur };
};

export function defaultTransform(type: InputType, value: string): string {
    return value;
}
