import classnames from 'classnames';
import React from 'react';
import { MouseEvent, useCallback } from 'react';

interface Props {
    name?: string;
    className?: string;
    onClick: (e: MouseEvent<HTMLAnchorElement>) => void;
    disabled?: boolean;
    noUnderline?: boolean;
    testId?: string;
}

export const LinkButton: React.FC<Props> = ({ noUnderline, name, children, className, onClick, disabled, testId }) => {
    const handleClick = useCallback(
        (e: MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            if (!disabled) {
                onClick(e);
            }
        },
        [disabled, onClick]
    );

    return (
        <a
            className={classnames('link-button', className, {
                'link-button--no-underline': !!noUnderline,
            })}
            href="/"
            data-disabled={disabled}
            onClick={handleClick}
            data-testid={testId}
        >
            {name || children}
        </a>
    );
};
