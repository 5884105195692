import React from 'react';
import { SliderStatePosition } from './SliderPosition';

interface SliderStateProps {
    slides: number;
    active: number;
}

export const SliderState: React.FC<SliderStateProps> = ({ slides, active }) => {
    const slideArray = React.useMemo(() => Array.from({ length: slides }), [slides]);

    return (
        <div className="slider-state d-flex justify-content-center">
            {slideArray.map((_, i) => (
                <SliderStatePosition active={i === active} key={i} />
            ))}
        </div>
    );
};
