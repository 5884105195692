import { maxBy, minBy, sumBy, round } from 'lodash';
import type { Tree } from '../effects';
import type { AugmentedRegulation, BKPDistributionTree } from '../types';

/**
 * Rounds all nodes of the tree to 2 digits and returns a new tree.
 * If necessary the child with the smallest difference of rounding error to total difference will be adjusted to match the parents value.
 * @param tree
 */
export function roundTree(tree: BKPDistributionTree): BKPDistributionTree {
    function roundNode(node: Tree<AugmentedRegulation>): Tree<AugmentedRegulation> {
        if (node.children.length === 0) return { ...node };

        const children = node.children.map<Tree<AugmentedRegulation> & { roundingError: number }>((child) => {
            const roundedValue = round(child.cost.value ?? 0, 2);
            const roundingError = (child.cost.value ?? 0) - roundedValue;

            return {
                ...child,
                cost: { ...child.cost, value: roundedValue },
                roundingError,
            };
        });

        const total = node.cost.value ?? 0;
        const childSum = sumBy(children, ({ cost }) => cost.value ?? 0);
        const diff = total - childSum;

        if (diff !== 0 && Math.abs(diff) >= 0.005) {
            const find = diff > 0 ? minBy : maxBy;

            const roundingErrorChildren = children.filter(({ roundingError }) => !!roundingError);
            const child = find(roundingErrorChildren, ({ roundingError }) => roundingError);

            if (child) {
                const otherChildrenSum = childSum - (child.cost.value ?? 0);
                child.cost.value = round(total - otherChildrenSum, 2);
            }
        }

        // Delete temporary property
        children.forEach((child) => delete child.roundingError);

        return {
            ...node,
            children: children.map((child) => roundNode(child)),
        };
    }

    return {
        ...tree,
        ...roundNode(tree),
    };
}
