import React from 'react';

export interface FormContextType {
    state: FormState;
    error?: string;

    path: string;
    valid: boolean;
    fields: Fields;

    registerField: (path: string, value: any, info: { name: string; valid: boolean }) => () => void;
    unregisterField: (path: string) => void;

    changeField: (path: string, value: any, valid: boolean, meta: FieldMeta) => void;
    touchField: (path: string) => void;
    getField: (path: string) => Field | null;
}

export const FormContext = React.createContext<FormContextType>(null as any);
