import axios, { AxiosInstance } from 'axios';

export interface HomeScreenImagesResult {
    Placeholder_1: string[];
    Placeholder_2: string[];
    Placeholder_3: string[];
}

export class ResourcesService {
    protected http: AxiosInstance;

    constructor() {
        this.http = axios.create({
            baseURL: '/api',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });
    }

    public async fetchHomeScreenResources(): Promise<HomeScreenImagesResult> {
        return this.http.get('/aws/images').then((res) => res.data);
    }
}
