import type { AxiosError } from 'axios';
import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { editorService } from '../../services';

export function useProjectLicenceCreate(projectId: number): UseMutationResult<unknown, AxiosError, ProjectLicence> {
    const queryClient = useQueryClient();
    return useMutation((licence: ProjectLicence) => editorService.createProjectLicence(projectId, licence), {
        onSuccess: () => queryClient.invalidateQueries(['project-licences', projectId]),
    });
}
