import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useLanguage } from '../../effects';
import classnames from 'classnames';
import { TextInput } from '../FormComponents';
import { TextButton } from '../Buttons';
import { keyBy, orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';

interface ProjectShareFormProps {
    project: Project;
    licences: ProjectLicence[];
    userId: string;
    onSave: (info: {
        newLicences: ProjectLicence[];
        changedLicences: ProjectLicence[];
        deletedLicences: ProjectLicence[];
    }) => unknown;
}

function* createIdSequence(): Generator<number, number, unknown> {
    let i = -1;

    for (;;) {
        yield i--;
    }
}

export const ProjectShareForm: React.FC<ProjectShareFormProps> = ({
    project,
    licences: originalLicences,
    userId,
    onSave,
}) => {
    const [idSequence] = useState(createIdSequence());
    const [t] = useTranslation();
    const [language] = useLanguage();
    const [licences, setLicenses] = useState(orderBy(originalLicences, 'userId'));

    useEffect(() => setLicenses(originalLicences), [originalLicences]);

    const projectName = project.name[language.codes.editor] || project.name.de_DE || Object.values(project.name)[0];

    const changeLicenceUserId = useCallback(
        (id, userId) =>
            setLicenses((l) =>
                l.map((l) => {
                    if (l.id !== id) return l;
                    return { ...l, userId };
                })
            ),
        []
    );

    const changeLicenceView = useCallback(
        (id, value?: boolean) =>
            setLicenses((l) =>
                l.map((l) => {
                    if (l.id !== id) return l;
                    const view = value ?? !l.view;
                    return { ...l, view };
                })
            ),
        []
    );

    const changeLicenceEdit = useCallback(
        (id, value?: boolean) =>
            setLicenses((l) =>
                l.map((l) => {
                    if (l.id !== id) return l;
                    const edit = value ?? !l.edit;
                    return { ...l, edit };
                })
            ),
        []
    );

    const onNewLicenceAdd = useCallback(
        () =>
            setLicenses((l) => [
                {
                    id: idSequence.next().value,
                    userId: '',
                    view: true,
                    edit: false,
                },
                ...l,
            ]),
        [idSequence]
    );

    const [hasChanges, info] = useMemo(() => {
        const _licences = licences.filter(({ edit, view }) => edit || view);
        const projectLicenceMap = keyBy(originalLicences, 'userId');
        const licenceMap = keyBy(_licences, 'userId');

        const newLicences: ProjectLicence[] = [];
        const changedLicences: ProjectLicence[] = [];
        const deletedLicences: ProjectLicence[] = originalLicences.filter(
            (l) => !(licenceMap[l.userId] as ProjectLicence | undefined)
        );

        _licences.forEach((licence) => {
            const original = projectLicenceMap[licence.userId] as ProjectLicence | undefined;
            if (!original) return newLicences.push(licence);
            if (original.view !== licence.view) return changedLicences.push(licence);
            if (original.edit !== licence.edit) return changedLicences.push(licence);
        });

        const hasChanges = newLicences.length > 0 || changedLicences.length > 0 || deletedLicences.length > 0;

        return [hasChanges, { newLicences, changedLicences, deletedLicences }];
    }, [originalLicences, licences]);

    const valid = useMemo(() => licences.every(({ userId }) => !!userId), [licences]);

    const saveChanges = useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            setLicenses((l) => l.filter(({ edit, view }) => edit || view));

            onSave(info);
        },
        [info, onSave]
    );

    return (
        <form onSubmit={saveChanges} className="project-share-form p-3 pr-5">
            <Container>
                <Row className="mb-4">
                    <Col>
                        <h2>{t('editor:share:form:title', { name: projectName })}</h2>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <TextButton name="Neue Lizenz hinzufügen" onClick={onNewLicenceAdd} />
                    </Col>
                </Row>

                <Row className="border-bottom align-items-center">
                    <Col xs={6}>{t('editor:share:form:userId')}</Col>
                    <Col xs={3}>{t('editor:share:form:canView')}</Col>
                    <Col xs={3}>{t('editor:share:form:canEdit')}</Col>
                </Row>
            </Container>

            <div className="project-share-form__licences">
                <Container className="w-100">
                    {licences.map((licence) => (
                        <ProjectShareFormEntry
                            licence={licence}
                            licences={licences}
                            userId={userId}
                            creatorId={project.creator}
                            onUserIdChange={changeLicenceUserId}
                            onViewChange={changeLicenceView}
                            onEditChange={changeLicenceEdit}
                            key={licence.id}
                        />
                    ))}
                </Container>
            </div>

            <div className="project-share__button-group">
                <TextButton
                    className="project-share__button"
                    type="submit"
                    name={<FontAwesomeIcon icon={faCheck} />}
                    disabled={!hasChanges || !valid}
                />
            </div>
        </form>
    );
};

interface ProjectShareFormEntryProps {
    licence: ProjectLicence;
    userId: string;
    creatorId: string;
    onViewChange: (licenceId: number, value: boolean) => unknown;
    onEditChange: (licenceId: number, value: boolean) => unknown;
}

interface ProjectShareFormEntryProps {
    licence: ProjectLicence;
    userId: string;
    creatorId: string;
    licences: ProjectLicence[];
    onViewChange: (id: number, value: boolean) => unknown;
    onEditChange: (id: number, value: boolean) => unknown;
    onUserIdChange: (id: number, value: string) => unknown;
}

const ProjectShareFormEntry: React.FC<ProjectShareFormEntryProps> = ({
    licence,
    userId,
    creatorId,
    onUserIdChange,
    onViewChange,
    onEditChange,
}) => {
    const isLocked = (licence.userId === userId || licence.userId === creatorId) && licence.id >= 0;

    const changeUserId = useCallback(
        (userId: string) => !isLocked && onUserIdChange(licence.id, userId),
        [licence.id, isLocked, onUserIdChange]
    );
    const toggleView = useCallback(() => {
        if (!isLocked) onViewChange(licence.id, !licence.view);
        if (!licence.view) onEditChange(licence.id, false);
    }, [licence.id, isLocked, onViewChange, onEditChange, licence.view]);
    const toggleEdit = useCallback(
        () => !isLocked && onEditChange(licence.id, !licence.edit),
        [licence.id, isLocked, onEditChange, licence.edit]
    );

    return (
        <Row className="project-share-form__entry project-share-form__entry--create py-2">
            <Col xs={6}>
                {isLocked && licence.userId}
                {!isLocked && (
                    <TextInput name="Benutzer ID" label="Benutzer ID" value={licence.userId} onChange={changeUserId} />
                )}
            </Col>
            <Col
                className={classnames('text-center', {
                    'project-share-form__entry__locked': isLocked,
                    'project-share-form__entry__check': licence.view,
                    'project-share-form__entry__cross': !licence.view,
                })}
                xs={3}
            >
                {licence.view && <FontAwesomeIcon icon={faCheck} onClick={toggleView} />}
                {!licence.view && <FontAwesomeIcon icon={faTimes} onClick={toggleView} />}
            </Col>
            <Col
                className={classnames('text-center', {
                    'project-share-form__entry__locked': isLocked,
                    'project-share-form__entry__check': licence.edit,
                    'project-share-form__entry__cross': !licence.edit,
                })}
                xs={3}
            >
                {licence.edit && <FontAwesomeIcon icon={faCheck} onClick={toggleEdit} />}
                {!licence.edit && <FontAwesomeIcon icon={faTimes} onClick={toggleEdit} />}
            </Col>
        </Row>
    );
};
