export function percentageOf(value: number, ofWhat: number): string {
    return percentage(value / ofWhat);
}

export function percentage(value: number): string {
    return (value * 100).toFixed(2);
}

export function toCHF(value = 0): string {
    return `${value.toLocaleString('de-CH', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    })}.-`;
}

export function formatValueToLocale(value = 0, decimalPlaces: number | { min?: number; max?: number } = 0): string {
    return value.toLocaleString('de-CH', {
        minimumFractionDigits: typeof decimalPlaces === 'number' ? decimalPlaces : decimalPlaces.min ?? 0,
        maximumFractionDigits: typeof decimalPlaces === 'number' ? decimalPlaces : decimalPlaces.max ?? 0,
    });
}
