import { AxiosError } from 'axios';
import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { editorService } from '../../services';

interface ProjectTransfer {
    projectIds: number[];
    receiverId: string;
}

export function useProjectTransfer(): UseMutationResult<unknown, AxiosError | Error, ProjectTransfer> {
    const queryClient = useQueryClient();

    return useMutation(
        ({ projectIds, receiverId }: ProjectTransfer) =>
            Promise.all(
                projectIds.map((projectId) => {
                    return editorService.transferProject(projectId, receiverId);
                })
            ),
        {
            onSettled: (_, _err, { projectIds }) => {
                queryClient.invalidateQueries(['projects']);
                projectIds.forEach((projectId) => {
                    queryClient.invalidateQueries(['project', projectId]);
                    queryClient.invalidateQueries(['project-licences', projectId]);
                });
            },
        }
    );
}
