import type { InputFile } from '@crb-oa-viewer/data-assistant-building-plan';
import type { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import type { UseQueryOptions } from 'react-query';
import { editorService } from '../../services';

export function useProjectInputFile(
    projectId: ID,
    options?: Omit<UseQueryOptions<unknown, AxiosError, InputFile>, 'queryKey' | 'queryFn'>
): UseQueryResult<InputFile, AxiosError> {
    return useQuery<unknown, AxiosError, InputFile>(
        ['project', projectId, 'inputfile'],
        () => editorService.getProjectInputFile(projectId),
        {
            retry: false,
            ...options,
        }
    );
}
