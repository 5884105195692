export enum ActionsEnum {
    CREATE_PROJECTS = 'CREATE_PROJECTS', // Creation of Projects, but not loading of stored projects.
    VIEW_PROJECTS = 'VIEW_PROJECTS', // Can see the list of projects
    EDIT_PROJECTS = 'EDIT_PROJECTS', // Can edit / update projects
    DELETE_PROJECTS = 'DELETE_PROJECTS', // Can delete projects
    ACCESS_TABLEAU = 'ACCESS_TABLEAU', // refers to role "Objektarten", allows access to Tableau
    VIEW_APPROVED_PROJECTS = 'VIEW_APPROVED_PROJECTS', // refers to role "Objektarten", allows access to projects that have been approved by the admin
    START_BKP_TO_EBKPH_CONVERSION = 'START_BKP_TO_EBKPH_CONVERSION', // Conversion from BKP to eBKP-H. Role "Umschlüsselungswizard"
    START_CALCULATOR = 'START_CALCULATOR', // Allows the usage of the new Object calculator. Role "Objektkalkulator"
    CALCULATE_STATISTICS = 'CALCULATE_STATISTICS', // Allows calculated statistics from projects instead of only pre-calculated reference data
    VIEW_ALL_PROJECTS = 'VIEW_ALL_PROJECTS', // for Admin
    APPROVE_PROJECT = 'APPROVE_PROJECT', // for Admin to approve publishing projects
    CLEAR_CACHE = 'CLEAR_CACHE', // for Admin to clear cached data
}
