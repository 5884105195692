import React from 'react';

interface ImageBoxProps {
    isOpen: boolean;
    src: string | string[];
    visibleIndex?: number;
    onClose(): void;
    onNext?(): void;
    onPrevious?(): void;
}

export const ImageBox: React.FC<ImageBoxProps> = ({ isOpen, src, onClose, visibleIndex, onNext, onPrevious }) => {
    if (!isOpen) {
        return null;
    }

    return (
        <div className="image-box">
            <button className="image-box__close-button" onClick={onClose}>
                &times;
            </button>
            {typeof src === 'string' && (
                <div className="image-box__container">
                    <img className="image-box__image" src={src} alt="" />
                </div>
            )}
            {typeof src === 'object' && visibleIndex !== undefined && (
                <div className="image-box__container">
                    <img className="image-box__image image-box__image--carousel" src={src[visibleIndex]} alt="" />
                    <div className="image-box__previous" onClick={onPrevious} />
                    <div className="image-box__next" onClick={onNext} />
                </div>
            )}
        </div>
    );
};
