import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

interface FloorCalculationResultsProps {
    className?: string;
    floor: Floor;
    highlighted: boolean;
    onHover: (floorId: string) => void;
}

export const FloorCalculationResults: React.FC<FloorCalculationResultsProps> = ({
    className,
    floor,
    highlighted,
    onHover,
}) => {
    const handleHover = useCallback(() => onHover(floor.id), [floor.id, onHover]);

    const [t] = useTranslation();
    const tt = (key: string) => t(`editor:project:floor:floorTypes:${key}`);

    return (
        <tr
            className={classNames('hoverable', className)}
            onMouseEnter={handleHover}
            data-hover={highlighted}
            data-testid="floor-entry-wrapper"
        >
            <td />
            <td>
                {floor.floorNumber ? `${floor.floorNumber} ` : ''}
                {tt(floor.floorType)}
            </td>
            <td className="py-1 text-center">{floor.height == null || floor.height <= 0 ? '' : floor.height}</td>
            <td className="py-1 text-right" data-testid="results-floor-area-field">
                {floor.area == null || floor.area < 0 ? '' : floor.area.toFixed(2)}
            </td>
        </tr>
    );
};
