import { Dictionary, groupBy, keyBy, negate, sortBy, sumBy, uniqBy } from 'lodash';
import { disconnectTree } from '.';
import { isChildOf, isDescendantOf } from '../components/ProjectEditor/views/CostView/utils';
import { DistributionStatus, MappingNames } from '../enums';
import type { AugmentedRegulation, BKPDistributionTree, WizardState } from '../types';
import { getLevels } from '../utils';
import { buildForest, connectTree, getAllDescendants, getSumOfDescendants, Tree } from './tree';

export function hasMapping(
    regulation: StaticRegulation
): regulation is StaticRegulation & { ebkphMappings: StaticRegulation[] } {
    return !!regulation.ebkphMappings && regulation.ebkphMappings.length > 0;
}

export function createArtificialID(parentId: ID, index: number): ID {
    return parseFloat(`${parentId}.${index + 1}`);
}

const augmentRegulations = (regulations: StaticRegulation[]): AugmentedRegulation[] => {
    return regulations.flatMap((reg) => {
        const levels = getLevels(reg);

        if (!hasMapping(reg)) {
            return {
                ...reg,
                locked: false,
                status: DistributionStatus.UNKNOWN,
                ebkphLetter: undefined,
                levels,
            } as AugmentedRegulation;
        }

        const mappingsLength = reg.ebkphMappings.length;
        if (mappingsLength === 1) {
            return {
                ...reg,
                locked: false,
                status: DistributionStatus.UNKNOWN,
                ebkphLetter: reg.ebkphMappings[0].code,
                levels,
            } as AugmentedRegulation;
        }

        const newReg = {
            ...reg,
            locked: false,
            status: DistributionStatus.UNKNOWN,
            ebkphLetter: undefined,
            levels,
        } as AugmentedRegulation;

        const newChildren = sortBy(reg.ebkphMappings, ({ code }) => code).map((mapping, i) => {
            return {
                ...reg,
                locked: false,
                id: createArtificialID(reg.id, i),
                status: DistributionStatus.UNKNOWN,
                code: `${reg.code}.${mapping.code}`,
                percentageFromParent: 1 / mappingsLength,
                ebkphMappings: [mapping],
                ebkphLetter: mapping.code,
                parent: newReg,
                cost: { ...newReg.cost, value: (newReg.cost.value ?? 0) / (mappingsLength || 1) },
                generated: true,
                levels: [...levels, mapping.code],
            } as AugmentedRegulation;
        });

        return [newReg].concat(newChildren);
    });
};

export function disconnectBkpTree(wizardState: WizardState): WizardState {
    const bkpTree = wizardState.bkp;

    if (!wizardState || !bkpTree) return wizardState;

    return {
        ...wizardState,
        bkp: disconnectTree(bkpTree),
    };
}

export function reconnectBkpTree(wizardState: WizardState): WizardState {
    const bkpTree = wizardState.bkp;

    if (!bkpTree) return wizardState;

    return {
        ...wizardState,
        bkp: connectTree(bkpTree),
    };
}

export function createBkpTree(
    project: Project,
    staticData: StaticData,
    treeType: MappingNames,
    stats: ProjectStats
): BKPDistributionTree {
    const { regulations, regulationMappings } = staticData;

    const mapping = regulationMappings.find(({ name }) => name === treeType);
    if (!mapping) throw new Error('Invalid mapping name');

    const costMap = keyBy(project.costs, ({ regulation }) => regulation.id);
    const eBKPHMap = keyBy(regulations['eBKP-H2020(A)'], ({ id }) => id);

    const mappingMap: Dictionary<RegulationMappingEntry[] | undefined> = groupBy(
        mapping.entries,
        ({ source }) => source.id
    );
    const { defaultRegulationPartitions = [] } = regulationMappings.find(({ name }) => name === treeType) ?? {};

    const regulationsWithCosts = regulations.BKP.map(
        (regulation) =>
            ({
                ...regulation,
                cost: costMap[regulation.id] || {
                    regulation,
                    value: 0,
                },
                ebkphMappings: mappingMap[regulation.id]?.map(({ target }) => eBKPHMap[target.id]),
            } as StaticRegulation)
    );

    const augmentedRegs = augmentRegulations(regulationsWithCosts);
    const forest = buildForest(augmentedRegs || [], isChildOf, isDescendantOf);

    const total = sumBy(forest, ({ cost }) => cost.value ?? 0);

    const connectedTree = connectTree({
        children: forest,
        cost: { value: total },
        percentageFromParent: 1,
        treeType,
    } as BKPDistributionTree);

    return preprocessBkpTree<AugmentedRegulation, BKPDistributionTree>(
        connectedTree,
        stats,
        defaultRegulationPartitions
    );
}

/**
 * Preprocess the tree.
 *
 * <ol>
 *  <li>Propagates the mappings of the children to the parents</li>
 *  <li>Removes unmappable forests</li>
 *  <li>Redistributes unmappable nodes and removes them</li>
 *  <li>Sets the distribution of each node</li>
 *  <li>Updates the value according to the parent's value and the distribution of the node</li>
 *  <li>Updates the status of each node</li>
 * </ol>
 *
 * @param tree The treee to preprocess
 * @param staticData Static data for preprocessing
 * @param withH The type of tree
 */
export function preprocessBkpTree<I extends AugmentedRegulation, T extends Tree<I>>(
    tree: T,
    stats: ProjectStats,
    defaultPartitions: RegulationPartition[]
): T {
    if (tree.children.length === 0) {
        return tree;
    }

    tree = propagateMappings(tree);
    tree = redistributeUnmappableNodes(tree);
    tree = removeUnmappedForests(tree);
    tree = setDistribution(tree, stats.regulationStats, defaultPartitions);
    tree = updateValues(tree, tree.cost.value ?? 0);
    tree = updateStatus(tree, tree.cost.value ?? 0);

    return tree;
}

/**
 * Propagates the mappings of the children to the parents.
 * This function will also update it's descendants.
 *
 * @param tree The tree where the propagation should take place.
 */
export function propagateMappings<I extends AugmentedRegulation, T extends Tree<I>>(tree: T): T {
    const children = tree.children.map(propagateMappings);

    const childrenMappings = children.flatMap((child) => child.ebkphMappings ?? []);
    const mappings = [...childrenMappings, ...(tree.ebkphMappings ?? [])];
    const uniqueMappings = uniqBy(mappings, ({ id }) => id);
    const sortedMappings = sortBy(uniqueMappings, ({ code }) => code);

    return {
        ...tree,
        ebkphMappings: sortedMappings,
        children,
    };
}

/**
 * Removes all childrens if none of the children has any mapping.
 * This function will also update it's descendants.
 *
 * @param tree The tree where the unmapped forests should be removed from
 */
export function removeUnmappedForests<I extends AugmentedRegulation, T extends Tree<I>>(tree: T): T {
    if (tree.ebkphMappings?.length === 1) {
        return {
            ...tree,
            children: [],
        };
    }

    return {
        ...tree,
        children: tree.children.map(removeUnmappedForests),
    };
}

/**
 * Nodes that are unmappable to EbkpH are removed and redistributed among their siblings, proportionally to the sibling's percentage of the parent.
 * Reference ticket: https://crbcloud.atlassian.net/browse/WMO3-97
 * @param tree
 */
export function redistributeUnmappableNodes<I extends AugmentedRegulation, T extends Tree<I>>(tree: T): T {
    function distribute(
        regulation: Tree<AugmentedRegulation>,
        unmappables: Tree<AugmentedRegulation>[]
    ): Tree<AugmentedRegulation> {
        const total = regulation.cost.value ?? 0;

        if (total === 0)
            return {
                ...regulation,
                children: regulation.children.filter(({ id }) =>
                    unmappables.every((unmappable) => unmappable.id !== id)
                ),
            };

        const unmappableTotal = sumBy(unmappables, ({ cost }) => cost.value ?? 0);

        if (unmappableTotal === 0)
            return {
                ...regulation,
                children: regulation.children.filter(
                    ({ id }) => !unmappables.some((unmappable) => unmappable.id === id)
                ),
            };

        const mappableTotal = total - unmappableTotal;

        const distribution =
            mappableTotal > 0
                ? distributeProportionally(1 + unmappableTotal / mappableTotal)
                : distributeEvenly(unmappableTotal);

        return {
            ...regulation,
            children: regulation.children
                .filter(({ id }) => unmappables.every((unmappable) => unmappable.id !== id))
                .map(distribution),
        };
    }

    function traverse<R extends Tree<AugmentedRegulation>>(regulation: R): R {
        const unmappables = regulation.children.filter((item) => negate(hasMapping)(item));

        if (unmappables.length === 0) {
            return {
                ...regulation,
                children: regulation.children.map((child) => traverse(child)),
            };
        }

        const newRegulation = distribute(regulation, unmappables);

        return {
            ...newRegulation,
            children: newRegulation.children.map((child) => traverse(child)),
        } as R;
    }

    return traverse(tree);
}

type DistributionFunction = (
    tree: Tree<AugmentedRegulation>,
    index: number,
    siblings: Tree<AugmentedRegulation>[]
) => Tree<AugmentedRegulation>;

/**
 * Returns a function that distributes a given total evenly on all regulations in the collection
 * @param valueToDistribute The value to distribute.
 */
export function distributeEvenly(valueToDistribute: number): DistributionFunction {
    const compute = (value = 0, denominator: number) => value + valueToDistribute / denominator;

    return (tree, _, siblings) => ({
        ...tree,
        cost: {
            ...tree.cost,
            value: compute(tree.cost.value, siblings.length),
        },
    });
}

/**
 * Returns a function that distributes the values proportionally to there existing value using a ratio.
 * @param ratio The ratio which is used for distribution
 */
export function distributeProportionally(ratio: number): DistributionFunction {
    const compute = (value = 0) => value * ratio;

    return (tree) => ({
        ...tree,
        cost: {
            ...tree.cost,
            value: compute(tree.cost.value),
        },
    });
}

export function calculateDistributionByStatistic(
    statistic: RegulationStats | undefined,
    siblings: Tree<AugmentedRegulation>[],
    siblingStatistics: RegulationStats[]
): number {
    const statisticalDistributionTotal = sumBy(siblingStatistics, (stats) => stats.median);

    if (statisticalDistributionTotal === 0) {
        if (siblings.length === siblingStatistics.length) {
            return 1 / siblings.length;
        }

        if (statistic) return 0;
        else return 1 / (siblings.length - siblingStatistics.length);
    }

    if (statistic) {
        const shouldBeScaled = statisticalDistributionTotal > 1 || siblings.length === siblingStatistics.length;
        const scaler = shouldBeScaled ? statisticalDistributionTotal : 1;

        return statistic.median / scaler;
    } else if (statisticalDistributionTotal < 1) {
        const countSiblingsWithoutStatistics = siblings.length - siblingStatistics.length;
        const restPercentage = Math.max(0, 1 - statisticalDistributionTotal);

        return restPercentage / countSiblingsWithoutStatistics;
    }

    return 0;
}

export function calculateDistributionByPartition(
    defaultPartition: RegulationPartition | undefined,
    siblings: Tree<AugmentedRegulation>[],
    siblingPartitions: RegulationPartition[]
): number {
    const defaultDistributionTotal = sumBy(siblingPartitions, (partition) => partition.size);

    if (defaultDistributionTotal === 0) {
        if (siblings.length === siblingPartitions.length) {
            return 1 / siblings.length;
        }

        if (defaultPartition) return 0;
        else return 1 / (siblings.length - siblingPartitions.length);
    }

    if (defaultPartition) {
        const percentageFromParent = defaultPartition.size;

        const shouldScaleDown = defaultDistributionTotal > 1;
        const shouldScaleUp = defaultDistributionTotal < 1 && siblings.length === siblingPartitions.length;

        if (shouldScaleDown || shouldScaleUp) {
            return percentageFromParent * (1 / defaultDistributionTotal);
        }

        return percentageFromParent;
    } else if (defaultDistributionTotal < 1) {
        const countSiblingsWithoutDefaults = siblings.length - siblingPartitions.length;
        const restPercentage = Math.max(0, 1 - defaultDistributionTotal);

        return restPercentage / countSiblingsWithoutDefaults;
    }

    return 0;
}

export function setDistribution<I extends AugmentedRegulation, T extends Tree<I>>(
    tree: T,
    stats: RegulationStats[],
    defaultPartitions: RegulationPartition[]
): T {
    const statsByRegulation = keyBy(stats, ({ regulationId }) => regulationId);
    const defaultPartitionsByRegulation = keyBy(defaultPartitions, ({ regulation }) => regulation.id);

    function traverseChildren(regulation: Tree<I>): Tree<I>[] {
        return regulation.children
            .map<[Tree<I>, RegulationStats | undefined, RegulationPartition | undefined]>((child) => [
                child,
                statsByRegulation[child.id],
                defaultPartitionsByRegulation[child.id],
            ])
            .map(([child, statistic, defaultPartition], _, children) => {
                const siblings = children.map(([sibling]) => sibling);
                const siblingStatistics = children
                    .map(([, siblingStatistic]) => siblingStatistic)
                    .filter((stat): stat is RegulationStats => !!stat);
                const siblingPartitions = children
                    .map(([, , siblingPartition]) => siblingPartition)
                    .filter((partition): partition is RegulationPartition => !!partition);

                return traverse(
                    child,
                    statistic,
                    defaultPartition,
                    sumBy(siblings, ({ cost }) => cost.value ?? 0),
                    siblings,
                    siblingStatistics,
                    siblingPartitions
                );
            });
    }

    function traverse(
        sibling: Tree<I>,
        statistic: RegulationStats | undefined,
        defaultPartition: RegulationPartition | undefined,
        siblingTotal: number,
        siblings: Tree<I>[],
        siblingStatistics: RegulationStats[],
        siblingPartitions: RegulationPartition[]
    ): Tree<I> {
        // TODO: WMO3-152 - Add distribution type "Initial" here
        let percentageFromParent = 1 / siblings.length;
        const { value = 0 } = sibling.cost;

        if (siblingTotal > 0) {
            // TODO: WMO3-152 - Add distribution type "Initial" here
            percentageFromParent = value / siblingTotal;
        } else if (siblingStatistics.length > 0) {
            // TODO: WMO3-152 - Add distribution type "Statistics" here
            percentageFromParent = calculateDistributionByStatistic(statistic, siblings, siblingStatistics);
        } else if (siblingPartitions.length > 0) {
            // TODO: WMO3-152 - Add distribution type "Default" here
            percentageFromParent = calculateDistributionByPartition(defaultPartition, siblings, siblingPartitions);
        }

        return {
            ...sibling,
            percentageFromParent,
            children: traverseChildren(sibling),
        };
    }

    return {
        ...tree,
        percentageFromParent: 1,
        children: traverseChildren(tree),
    };
}

/**
 * Redistributes the children of a given regulation (Immutable).
 *
 * <b>Note:</b> This function only changes the <b>percentageFromParent</b>, not the actual cost.
 *
 * Following rules apply:
 * <ul>
 * <li>The changed regulation has maximum percentage of the available percentages *</li>
 * <li>The changed regulation has minimum percentage of the required percentages **</li>
 * <li>The rest percentages are distributes proportionally if any unlocked sibling has a cost value greater then 0</li>
 * <li>The rest percentages are distributes evenly if all unlocked siblings have a cost value of 0</li>
 * </ul>
 *
 * 	<p>*  The available percentage is calculated by subtracting the sum of percentages of all locked siblings from 1.</p>
 * 	<p>** The required percentage is the available percentage if all siblings are locked.</p>
 *
 * @param regulation The regulation where the children should be distributed
 * @param regulationId The id of the regulation that is treated as locked for the distribution
 */
export function redistribute<I extends AugmentedRegulation, T extends Tree<I>>(regulation: T, regulationId: ID): T {
    const changedRegulation = regulation.children.find((childReg) => childReg.id === regulationId);
    if (!changedRegulation)
        throw new Error(
            `Can only redistribute regulation that has a child with the given regulation id: ${regulationId}!`
        );

    const lockedChildren = regulation.children.filter((child) => child.locked);
    if (lockedChildren.some((child) => child.id === regulationId))
        throw new Error('Cannot change value of regulation that is locked!');

    const unlockedChildren = regulation.children
        .filter((child) => !child.locked)
        .filter((child) => child.id !== regulationId);

    const lockedPercentage = sumBy(lockedChildren, ({ percentageFromParent }) => percentageFromParent ?? 0);
    const unlockedPercentage = sumBy(unlockedChildren, ({ percentageFromParent }) => percentageFromParent ?? 0);

    const maxPercentage = 1 - lockedPercentage;
    const minPercentage = unlockedChildren.length === 0 ? maxPercentage : 0;

    const percentage = Math.max(minPercentage, Math.min(maxPercentage, changedRegulation.percentageFromParent ?? 0));

    const percentageToDistribute = maxPercentage - percentage;
    const distributionRatio = percentageToDistribute / (unlockedPercentage || 1);

    const children = regulation.children.map((child) => {
        if (child.locked) {
            return child;
        }

        // Set the changed regulations value in case the original value was greater or lesser then what is allowed
        if (child.id === regulationId) {
            return {
                ...child,
                percentageFromParent: percentage,
            };
        }

        // If the value of all unlocked siblings is 0 but there is some percentage that need to be distributed they need to be distributed evenly on those nodes
        if (unlockedPercentage === 0 && percentageToDistribute > 0) {
            return {
                ...child,
                percentageFromParent: percentageToDistribute / unlockedChildren.length,
            };
        }

        const percentageFromParent = (child.percentageFromParent ?? 0) * distributionRatio;

        return { ...child, percentageFromParent };
    });

    return { ...regulation, children };
}

/**
 * Updates the values of the cost with the parentValue and it's percentageFromParent (Immutable).
 * This function will also update it's descendants.
 *
 * Usually this would be the root of the tree and it's value.
 *
 * @param regulation The tree to update the values on
 * @param parentValue The value of the parent
 */
export function updateValues<I extends AugmentedRegulation, T extends Tree<I>>(regulation: T, parentValue: number): T {
    const value = parentValue * (regulation.percentageFromParent ?? 0);

    return {
        ...regulation,
        cost: { ...regulation.cost, value },
        children: regulation.children.map((childReg) => updateValues(childReg, value)),
    };
}

/**
 * Updates the status of each distribution (Immutable).
 * This function will also update its descendants.
 *
 * Usually this would be the root of the tree and its value.
 *
 * @param tree The tree to update the values on
 * @param parentValue The value of the parent
 */
export function updateStatus<I extends AugmentedRegulation, T extends Tree<I>>(tree: T, totalValue: number): T {
    const newRegulation = {
        ...tree,
        children: tree.children.map((child) => updateStatus(child, totalValue)),
    };

    const status = newRegulation.children.length
        ? getNewStatusForNode(newRegulation, totalValue)
        : getNewStatusForLeaf(newRegulation, totalValue);

    return {
        ...newRegulation,
        status,
    };
}

function getNewStatusForLeaf(regulation: Tree<AugmentedRegulation>, totalValue: number) {
    if (regulation.locked) {
        return DistributionStatus.CHECKED;
    }
    const percentageOfTotal = (regulation.cost.value || 0) / totalValue;
    if (percentageOfTotal < 0.05) {
        return DistributionStatus.CAN_BE_CHECKED;
    } else if (percentageOfTotal > 0.05) {
        return DistributionStatus.TO_BE_CHECKED;
    }
    return DistributionStatus.UNKNOWN; // Should probabebly be Status.CAN_BE_CHECKED or Status.TO_BE_CHECKED.
}

function getNewStatusForNode(regulation: Tree<AugmentedRegulation>, totalValue: number) {
    if (getSumOfDescendants(regulation) === 0) {
        return getNewStatusForLeaf(regulation, totalValue);
    }
    const descendants = getAllDescendants(regulation);
    const groups: Dictionary<Tree<AugmentedRegulation>[] | undefined> = groupBy(descendants, ({ status }) => status);

    if ((groups[DistributionStatus.TO_BE_CHECKED]?.length ?? 0) > 0) {
        return DistributionStatus.TO_BE_CHECKED;
    }
    if ((groups[DistributionStatus.CAN_BE_CHECKED]?.length ?? 0) > 0) {
        return DistributionStatus.CAN_BE_CHECKED;
    }
    if ((groups[DistributionStatus.CHECKED]?.length ?? 0) > 0) {
        return DistributionStatus.CHECKED;
    }
    return DistributionStatus.UNKNOWN;
}

export function lockTree(tree: Tree<AugmentedRegulation>, locked: boolean): Tree<AugmentedRegulation> {
    const children = tree.children.map((child) => lockTree(child, locked));

    return {
        ...tree,
        children,
        locked,
    };
}
