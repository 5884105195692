import { TFunction } from 'i18next';
import React, { useCallback } from 'react';
import { Col, Row } from 'reactstrap';
import { override, valueFromPath } from '../../../../../../utils/project-utils';
import { Checkbox } from '../../../../../FormComponents';
import { Common } from '../Common/Common';
import { FieldOption } from '../Common/types';

interface Props {
    project: Project;
    language: EditorLanguage;
    staticData: StaticData;
    changeProject: (update: (project: Project) => Project) => unknown;
    t: TFunction;
}

const fields: Array<[string, FieldOption]> = [
    [
        'projectSpecificReadings.plotRatio',
        { input: 'text', type: 'number', options: { decimals: 2 }, testId: 'plot-ratio' },
    ],
    ['projectSpecificReadings.zoneAllocation', { input: 'text', type: 'text' }],
];

export const StatutoryPlanningFramework: React.FC<Props> = ({ project, language, changeProject, t }) => {
    const handleChange = useCallback(
        (path: string, value: string) => {
            changeProject((p) => override(p, path, value));
        },
        [changeProject]
    );

    const handleChangeMasterplan = useCallback(
        (master: boolean) => {
            changeProject((p) => ({
                ...p,
                projectSpecificReadings: {
                    ...p.projectSpecificReadings,
                    siteMasterplan: master,
                },
            }));
        },
        [changeProject]
    );

    const handleChangePlotRatioBonus = useCallback(
        (bonus: boolean) => {
            changeProject((p) => ({
                ...p,
                projectSpecificReadings: {
                    ...p.projectSpecificReadings,
                    plotRatioBonus: bonus,
                },
            }));
        },
        [changeProject]
    );

    const checkBoxes: Array<[string, boolean, (x: boolean) => void]> = [
        [
            t('editor:project:parameters:projectSpecificReadings:siteMasterplan'),
            !!project.projectSpecificReadings?.siteMasterplan,
            handleChangeMasterplan,
        ],
        [
            t('editor:project:parameters:projectSpecificReadings:plotRatioBonus'),
            !!project.projectSpecificReadings?.plotRatioBonus,
            handleChangePlotRatioBonus,
        ],
    ];

    return (
        <Row className="small-push-bottom">
            <Col xs={12}>
                <h3 className="mb-3">
                    {t('editor:project:parameters:projectSpecificReadings:statutoryPlanningFramework')}
                </h3>
                <Row className="align-items-start">
                    <Col xs={12}>
                        {fields.map(([field, options]) => (
                            <Common
                                name={t(`editor:project:parameters:${field.replace(/\./g, ':')}`)}
                                value={
                                    options.getValue
                                        ? options.getValue(project)
                                        : valueFromPath(project, field.split('.'))
                                }
                                changeValue={handleChange}
                                option={options}
                                language={language}
                                key={field}
                                passThrough={field}
                                testId={options.testId}
                            />
                        ))}
                        {checkBoxes.map(([label, value, callback]) => (
                            <Row className="data-view__common border-bottom py-0" key={label}>
                                <Col className="text-bold" xs={6} sm={5} md={4}>
                                    {label}
                                </Col>
                                <Col className="pt-3" xs={6} sm={7} md={8}>
                                    <Checkbox label={label} value={value} onChange={callback} />
                                </Col>
                            </Row>
                        ))}
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};
