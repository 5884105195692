import React from 'react';

interface Props {
    label: string;
    isActive: boolean;
    onClick: () => void;
}

export const LanguageSelectorButton: React.FC<Props> = ({ label, isActive, onClick }) => (
    <button
        className={`lang-changer__lang ${isActive ? 'lang-changer__lang--active' : ''}`}
        type="button"
        onClick={onClick}
    >
        {label}
    </button>
);
