import type { View, Workbook } from 'tableau-api';

/**
 * If the hidden tag exists on a tableau object, then the item
 * should never be displayed to the user.
 */
type HiddenTag = 'hidden';

type EstimationTag =
    /**
     * Indicates that the item should be used in the integration
     * of the tableau iframe in the cost estimation
     */
    | 'estimation_cost'
    /**
     * Indicates from which item the data should be retrieved, when
     * retrieving reference data from the tableau iframe.
     */
    | 'estimation_cost_data'
    /**
     * Indicates the first page that should be displyaed in the
     * tableau iframe in the cost estimation.
     */
    | 'estimation_cost_first_page';

type LanguageTag = 'en' | 'de' | 'fr' | 'it';
type TagType = HiddenTag | LanguageTag | EstimationTag;

/**
 * Check if a tableau object has a tag with a given label.
 */
function hasTag(tableauObject: View | Workbook, tag: TagType, fallback = false): boolean {
    return tableauObject.tags.tag?.some(({ label }) => label === tag) ?? fallback;
}

/**
 * Returns true if the tableau object has a tag with the label 'hidden'.
 * If a tableau has the tag 'hidden' it should not be displayed.
 */
const isHidden = (tableauObject: View | Workbook): boolean => {
    const HIDDEN_TAG: TagType = 'hidden';
    return hasTag(tableauObject, HIDDEN_TAG);
};

/**
 * Returns true if the tableau object has a tag with the given language.
 */
const hasLanguage = (tableauObject: View | Workbook, language: string): boolean => {
    return hasTag(tableauObject, language as LanguageTag);
};

/**
 * Returns if the tableau object should be displayed in the cost estimation.
 */
const isEstimationItem = (tableauObject: View | Workbook): boolean => {
    const ESTIMATION_TAG: EstimationTag = 'estimation_cost';
    return hasTag(tableauObject, ESTIMATION_TAG);
};

// Project Reference prid_
// prid_
const isEstimationDataItem = (tableauObject: View | Workbook): boolean => {
    const ESTIMATION_DATA_TAG: EstimationTag = 'estimation_cost_data';
    return hasTag(tableauObject, ESTIMATION_DATA_TAG);
};

const isFirstEstimationPage = (tableauObject: View | Workbook): boolean => {
    const FIRST_ESTIMATION_PAGE: EstimationTag = 'estimation_cost_first_page';
    return hasTag(tableauObject, FIRST_ESTIMATION_PAGE);
};

export const TableauFilter = {
    hasTag,
    isHidden,
    hasLanguage,
    estimation: {
        isEstimationItem,
        isEstimationDataItem,
        isFirstEstimationPage,
    },
};
