import React from 'react';

interface Props {
    size?: number;
    color?: string;
}

export const Spinner: React.FC<Props> = ({ size = 16, color = '#fff' }) => (
    <svg viewBox="0 0 16 16" width={size} height={size} className="spinner">
        <defs>
            <linearGradient id="a" x1="-22.819%" x2="50%" y1="78.575%" y2="97.513%">
                <stop stopColor={color} stopOpacity="0" offset="0" />
                <stop stopColor={color} stopOpacity=".51567" offset=".65932" />
                <stop stopColor={color} offset="1" />
            </linearGradient>
        </defs>
        <g fill="none" fillRule="evenodd">
            <g transform="translate(-393 -1130)" fill="url(#a)">
                <path d="m401 1146c4.4183 0 8-3.5817 8-8s-3.5817-8-8-8-8 3.5817-8 8c0 0.99637 1 0.99637 1 0 0-3.8514 3.1355-7.003 7-7.003s7 3.128 7 7.003-3.1093 7.0001-7 7.0001c-0.84765 0-0.84765 0.99991 0 0.99991z" />
            </g>
        </g>
    </svg>
);
