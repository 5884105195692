import classnames from 'classnames';
import React, { FC, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';

interface Props {
    selectedIndex?: number;
}

export const OutOfViewLanguageSelector: FC<PropsWithChildren<Props>> = ({ selectedIndex = 0, children }) => {
    const [collapsed, setCollapsed] = useState(true);

    const child = useMemo(() => {
        const childrenArray = React.Children.toArray(children);

        // Only render the selected language when collapsed
        if (collapsed) return childrenArray[selectedIndex];

        return children;
    }, [children, selectedIndex, collapsed]);

    // Collapse when clicking outside
    useEffect(() => {
        if (collapsed) return;

        const listener = () => setTimeout(() => setCollapsed(true), 100);
        document.body.addEventListener('click', listener);

        return () => document.body.removeEventListener('click', listener);
    }, [collapsed]);

    const expand = useCallback(() => setCollapsed(false), []);

    return (
        <div
            className={classnames('lang-changer', 'lang-changer__out-of-view', {
                'lang-changer__out-of-view--collapsed': collapsed,
            })}
            onClick={expand}
        >
            {child}
        </div>
    );
};
