export default {
    actions: {
        'everything-up-to-date': 'All data is saved.',
        save: 'Save',
    },
    errors: {
        saving: 'Saving failed',
    },
    'bkp-distribution': {
        title: 'BKP Distribution Settings',
        level: 'Level {{level}}',
        regulation: {
            type: {
                node: 'Node',
                leaf: 'Leaf',
            },
        },
        columns: {
            code: 'Code',
            level: 'Level',
            description: 'Description',
            type: 'Type',
            percentage: 'Distribution Percentage',
        },
    },
};
