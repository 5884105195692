import React from 'react';

export function useEvent<
    E extends Event,
    EventName extends string = E['type'],
    EE extends EventEmitter<EventName, E> = EventEmitter<EventName, E>
>(target: EE, event: EventName, handler: (event: E) => unknown, deps: unknown[], condition = true): void {
    React.useEffect(() => {
        if (condition) {
            target.addEventListener(event, handler);
            return () => {
                target.removeEventListener(event, handler);
            };
        }
    }, deps); // eslint-disable-line react-hooks/exhaustive-deps
}

interface EventEmitter<Name extends string, E extends Event> {
    addEventListener: (event: Name | string, handler: (event: E) => void) => void;
    removeEventListener: (event: Name | string, handler: (event: E) => void) => void;
}
