import React from 'react';
import { WizardState } from '../types';

export type WizardStateContextType = [WizardState, React.Dispatch<React.SetStateAction<WizardState>>];

export const WizardContext = React.createContext<null | WizardStateContextType>(null);

export function useWizardStateContext(): WizardStateContextType {
    const state = React.useContext(WizardContext);

    if (!state) {
        throw new Error(`${useWizardStateContext.name} can only be used inside of a ${WizardContext.Provider.name}`);
    }

    return state;
}
