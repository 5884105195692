import React from 'react';

interface Props {
    className?: string;
    type?: 'button' | 'submit' | 'reset';
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    testId?: string;
}

export const IconButton: React.FC<Props> = ({ className = '', type = 'button', onClick, children, testId }) => (
    <button className={`icon-button ${className}`} type={type} onClick={onClick} data-testid={testId}>
        {children}
    </button>
);
