import classnames from 'classnames';
import React, { ChangeEvent, useCallback } from 'react';

interface CheckboxProps {
    id?: string;
    className?: string;
    name?: string;
    label?: React.ReactNode;

    value?: boolean;
    onChange: (value: boolean) => unknown;

    gutterTop?: boolean;
    required?: boolean;
    disabled?: boolean;
}

type Props = FormFieldComponentProps<boolean, Omit<CheckboxProps, 'value' | 'onChange'>>;

export const FormCheckbox: React.FC<Props> = ({
    id,
    className,
    label,
    value,
    required,
    disabled,
    onChange,
    gutterTop,
}) => {
    const handleChange = useCallback(
        ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
            onChange(currentTarget.checked);
        },
        [onChange]
    );

    return (
        <div className={classnames('form__input', className)}>
            <label className={classnames('form__checkbox', { 'gutter-top': gutterTop })} data-disabled={disabled}>
                <input
                    id={id}
                    type="checkbox"
                    checked={value || false}
                    disabled={disabled}
                    required={required}
                    onChange={handleChange}
                />
                <span className="form__checkbox-checkmark" />
                {label}
            </label>
        </div>
    );
};

export const Checkbox = FormCheckbox;
