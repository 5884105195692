import { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

export function useSearchParam<P extends string | undefined = string>(param: string): [P | null, (value: P) => void];
export function useSearchParam<P extends string | undefined = string>(
    param: string,
    defaultValue: P
): [P, (value: P) => void];
export function useSearchParam<P extends string | undefined = string>(
    param: string,
    defaultValue?: P
): [P | null, (value: P) => void] {
    const history = useHistory();

    const value = new URLSearchParams(history.location.search).get(param) || defaultValue || null;

    useEffect(
        () => () => {
            const params = new URLSearchParams(history.location.search);

            params.delete(param);

            history.push({
                ...history.location,
                search: params.toString(),
            });
        },
        [history, param]
    );

    const changeValue = useCallback(
        (newValue: P) => {
            const params = new URLSearchParams(history.location.search);

            if (newValue) {
                params.set(param, `${newValue ?? ''}`);
            }

            history.push({
                ...history.location,
                search: params.toString(),
            });
        },
        [history, param]
    );

    return useMemo(() => [value as P, changeValue], [value, changeValue]);
}
