import React, { useCallback, useMemo } from 'react';
import { Sidebar, SidebarProps } from './Sidebar';
import { SidebarOverlay } from './SidebarOverlay';

interface Sidebars<
    Component extends React.ComponentType<SidebarProps & any> = React.ComponentType<SidebarProps & any>
> {
    name: string;
    header?: React.ComponentType;
    component: Component;
    props: Component extends React.ComponentType<infer P> ? Omit<P, keyof SidebarProps> : never;
}

interface Props {
    names: string[];
    sidebars: Sidebars[];
    closeSidebar: (name: string) => unknown;
}

export const Sidebars: React.FC<Props> = ({ names, sidebars, closeSidebar }) => {
    const [count, setCount] = React.useState<number>(names.length);

    React.useEffect(() => {
        setCount(names.length);

        if (names.length > 0) {
            document.documentElement.classList.add('no-scroll');
        }

        return () => document.documentElement.classList.remove('no-scroll');
    }, [names]);

    const willClose = useCallback(() => {
        setCount((oldCount) => {
            if (oldCount - 1 === 0) {
                document.documentElement.classList.remove('no-scroll');
            }

            return oldCount - 1;
        });
    }, []);

    return (
        <>
            <SidebarOverlay show={count > 0} />

            {sidebars.map((sidebar) => (
                <MemoSidebar
                    names={names}
                    sidebar={sidebar}
                    onWillClose={willClose}
                    onClose={closeSidebar}
                    key={sidebar.name}
                />
            ))}
        </>
    );
};

interface MemoSidebarProps {
    sidebar: Sidebars;
    names: string[];
    onWillClose: () => unknown;
    onClose: (name: string) => unknown;
}

const MemoSidebar: React.FC<MemoSidebarProps> = ({ sidebar, names, onWillClose, onClose }) => {
    const { name, header, component: Component, props } = sidebar;

    const open = useMemo(() => names.includes(name), [name, names]);
    const active = useMemo(() => names[names.length - 1] === name, [name, names]);

    const close = useCallback(() => onClose(name), [onClose, name]);

    return (
        <Sidebar open={open} active={active} willClose={onWillClose} close={close} header={header} key={name}>
            <React.Suspense fallback={<div />}>
                <Component {...props} close={close} />
            </React.Suspense>
        </Sidebar>
    );
};
