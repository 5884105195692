import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { codeOf } from '../utils';

export function useLanguage(): [EditorLanguage, (lang: string) => void] {
    const [, i18next] = useTranslation();
    const locale = codeOf(i18next.language);

    return useMemo<[EditorLanguage, (lang: string) => void]>(
        () => [
            {
                editor: i18next.language,
                documentation: i18next.language,
                codes: {
                    editor: locale,
                    documentation: locale,
                },
            },
            i18next.changeLanguage,
        ],
        [i18next.language, i18next.changeLanguage, locale]
    );
}

export function useLocale(): string {
    const [, i18n] = useTranslation();

    return useMemo(() => codeOf(i18n.language), [i18n.language]);
}
