export default [
    ['Generelle Anregungen', 'Suggestions générales', 'Suggerimenti generali', 'General suggestions'],
    ['Funktionalität', 'Fonctionnalité', 'Funzionalità', 'Functionality'],
    ['Analysenmöglichkeit', "Possibilité d'analyse", 'Possibilità di analisi', 'Analysis possibility'],
    [
        'Einreichung eigene Projekte',
        'Présentation de vos propres projets',
        'Presentazione di progetti propri',
        'Submission of own projects',
    ],
];
