import React, { useMemo } from 'react';
import { SliderState } from '../SliderState';
import type { NavigationState } from './types';

export const BuildingPlanNavState: React.FC<NavigationState> = ({ step, steps }) => {
    const index = useMemo(() => steps.indexOf(step), [steps, step]);

    if (steps.length <= 1) return null;

    return (
        <div className="building-plan-importer__nav-state">
            <SliderState slides={steps.length} active={index} />
        </div>
    );
};
