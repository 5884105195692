import { TFunction } from 'i18next';
import React, { useCallback, useState } from 'react';
import { TextInput } from '../../TextInput';
import { SelectBase } from '../SelectBase';
import { useTagSelectorOptions } from './use-tag-selector-options';
import { useTagSelectorValue } from './use-tag-selector-value';

interface Props {
    label: string;
    tag?: ProjectTag;
    tags: ProjectTag[];
    onChange: (projectTag: ProjectTag) => void;
    language: string;
    hiddenTags?: number[];
    autoFocus?: boolean;
    t: TFunction;
    testId?: string;
}

export const TagSelector: React.FC<Props> = ({
    label,
    tag,
    tags,
    hiddenTags,
    language,
    onChange,
    t,
    autoFocus,
    ...props
}) => {
    const [value, , valueName] = useTagSelectorValue(tag, language);
    const [text, setText] = useState(valueName);
    const optionValue = valueName !== text ? null : value;
    const options = useTagSelectorOptions(tags, optionValue, hiddenTags, text, language, t);

    const [focused, setFocused] = useState(false);
    const handleFocus = useCallback(() => setFocused(true), []);
    const handleBlur = useCallback(() => setFocused(false), []);

    const changeText = useCallback(
        (value: string) => {
            setText(value);
            const tag = tags.find(({ name }) => name[language] === value);

            if (tag) {
                onChange(tag);
            } else {
                onChange({
                    id: null,
                    name: { [language]: value },
                });
            }
        },
        [tags, language, onChange]
    );

    const changeTag = useCallback(
        (value?: ProjectTag | null) => {
            if (!value) return;

            const name = value.name[language] ?? Object.values(value.name)[0];
            setText(name);
            onChange(value);
        },
        [language, onChange]
    );

    return (
        <SelectBase
            {...props}
            className="form__tag-select"
            label={label}
            value={value}
            onChange={changeTag}
            options={options}
            focused={focused}
            multiple={false}
            filled={focused || !!text || !!tag?.id}
            onFocus={handleFocus}
            onBlur={handleBlur}
        >
            <TextInput
                value={valueName}
                onChange={changeText}
                noDefaultClassName={true}
                autoFocus={autoFocus && !value}
                onFocus={handleFocus}
            />
        </SelectBase>
    );
};
