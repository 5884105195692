import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { TextButton } from '../Buttons';
import { ProjectShareForm } from './ProjectShareForm';

interface ProjectShareOverlayProps {
    project: Project;
    licences: ProjectLicence[];
    userId: string;
    onSave: (info: {
        newLicences: ProjectLicence[];
        changedLicences: ProjectLicence[];
        deletedLicences: ProjectLicence[];
    }) => unknown;
    onClose: () => unknown;
}

export const ProjectShareOverlay: React.FC<ProjectShareOverlayProps> = ({
    project,
    licences,
    userId,
    onClose,
    onSave,
}) => {
    return (
        <div className="project-share-overlay">
            <div className="position-relative">
                <ProjectShareForm project={project} licences={licences} userId={userId} onSave={onSave} />
                <TextButton
                    className="project-share__button"
                    name={<FontAwesomeIcon icon={faTimes} />}
                    onClick={onClose}
                />
            </div>
        </div>
    );
};
