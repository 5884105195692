import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../effects';

export type LanguageContextType = {
    languages: string[];
    language: EditorLanguage;
    changeDocumentationLanguage: (lang: string) => void;
};

export const LanguageContext = React.createContext<null | LanguageContextType>(null);

export const LanguageProvider: React.FC = ({ children }) => {
    const [, i18next] = useTranslation();
    const [language] = useLanguage();

    const value = useMemo(
        () => ({
            languages: i18next.languages,
            language,
            changeLanguage: i18next.changeLanguage,
            changeDocumentationLanguage: i18next.changeLanguage,
        }),
        [i18next.languages, i18next.changeLanguage, language]
    );

    return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>;
};

export function useLanguageContext(): LanguageContextType {
    const state = React.useContext(LanguageContext);

    if (!state) {
        throw new Error(`${useLanguageContext.name} can only be used inside of a ${LanguageProvider.name}`);
    }

    return state;
}
