import type { TFunction } from 'i18next';
import { Dictionary, eq, groupBy, identity, keyBy, noop, orderBy, round, sortBy, sumBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Forest, IsChildOfFunction, IsDescendantOfFunction, Tree, buildForest, map } from '../../../../effects';
import { TotalCostsCode } from '../../../../enums';
import { formatValueToLocale, getLevels } from '../../../../utils';
import { CalculatedRegulationID, Calculator, CostCalculator } from './calculators';
import { calculateQuantities } from './quantity-calculations';
import type { CostForest, CostNode, CostTree } from './types';

/**
 * Checks if the id of the parent and the id of the parent of the child are the same.
 * Can also be used to identify roots of trees (parent is null & parent ID of child is also null).
 *
 * @param parent
 * @param child
 * @returns If the child is a direct child of the parent.
 */
export const isChildOf: IsChildOfFunction<Regulation> = (parent, child) => {
    if (!parent && !child.parent) {
        return true;
    }
    return parent?.id === child.parent?.id;
};

/**
 * Checks if a specific regulation is a descendant of another specific regulation.
 *
 * @param ancestor The regulation that should be used to check if it is an ancestor
 * @param descendant The regulation that should be checked if its a descendant
 * @returns If the descendant is actually a descendant of the ancestor.
 */
export const isDescendantOf: IsDescendantOfFunction<Regulation> = (ancestor, descendant) => {
    // Return true if descendant is a direct child of the ancestor
    if (isChildOf(ancestor, descendant)) {
        return true;
    }

    if (!ancestor) {
        return false;
    }

    if (!isStaticRegulation(descendant) || !isStaticRegulation(ancestor)) {
        return false;
    }

    const ancestorLevels = ancestor.levels || getLevels(ancestor);
    const childLevels = descendant.levels || getLevels(descendant);

    if (ancestorLevels.length >= childLevels.length) {
        return false;
    }

    return childLevels.every((childLevel, index) => {
        const ancestorLevel = ancestorLevels[index];
        return !ancestorLevel || childLevel === ancestorLevel;
    });
};

/**
 * For each node this function calculates the checksum and the sum of the children's values.
 *
 * @param tree
 */
export function calculateValues(tree: Tree<Regulation>[]): Tree<RegulationWithCost>[] {
    return tree.map((branch): Tree<RegulationWithCost> => {
        const children = calculateValues(branch.children || []);
        const checksum = sumBy(
            children,
            (child) => child.cost.value ?? child.cost.checksum ?? child.cost.childSum ?? 0
        );

        const roundedChecksum = round(checksum, 2);
        return {
            ...branch,
            children,
            cost: {
                regulation: branch,
                ...(isStaticRegulation(branch) && branch.cost != null ? branch.cost : {}),
                checksum: roundedChecksum,
            },
        };
    });
}

const createCalculatedRegulation = (
    id: CalculatedRegulationID,
    code: string,
    name: string,
    value: number,
    checksum: number,
    t: TFunction
): CalculatedRegulationWithCost => ({
    id,
    code,
    description: {
        de_DE: t(`editor:projects:creator:cost-sheet:calculated-regulation:${code.toLowerCase()}`, { lng: 'de' }),
        fr_FR: t(`editor:projects:creator:cost-sheet:calculated-regulation:${code.toLowerCase()}`, { lng: 'fr' }),
        it_IT: t(`editor:projects:creator:cost-sheet:calculated-regulation:${code.toLowerCase()}`, { lng: 'it' }),
        en_GB: t(`editor:projects:creator:cost-sheet:calculated-regulation:${code.toLowerCase()}`, { lng: 'en' }),
    },
    shortName: { de_DE: code, fr_FR: code, it_IT: code, en_GB: code },
    name,
    cost: {
        regulation: { id },
        value,
        checksum,
        referenceQuantity: 0,
        description: {},
    },
    measureUnit: null,
});

export function createTotalCostForest(
    project: Project,
    staticData: StaticData,
    regulationName: string,
    calculator: Calculator,
    t: TFunction
): CostForest {
    const regulations = staticData.regulations[regulationName] ?? [];
    const totalCosts = project.totalCosts ?? [];

    const totalCost = totalCosts.find((t) => t.regulationName === regulationName);

    const costMap: Partial<Dictionary<ProjectCost>> = keyBy(project.costs, (cost) => cost.regulation.id);
    const regulationsWithCosts = regulations.map((regulation) => ({
        ...regulation,
        cost: costMap[regulation.id] ?? { regulation },
    }));

    const investmentCost = calculator.getInvestmentCosts(regulationsWithCosts);
    const productionCost = calculator.getCreationCosts(regulationsWithCosts);
    const constructionFacilityCost = calculator.getConstructionCosts(regulationsWithCosts);
    const buildingCost = calculator.getBuildingCosts(regulationsWithCosts);

    const totals = [
        createCalculatedRegulation(
            CalculatedRegulationID.KoA,
            TotalCostsCode.KoA_INVESTMENT_COSTS,
            regulationName,
            totalCost ? totalCost.investmentCost : investmentCost,
            investmentCost,
            t
        ),
        createCalculatedRegulation(
            CalculatedRegulationID.KoE,
            TotalCostsCode.KoE_PRODUCTION_COSTS,
            regulationName,
            totalCost ? totalCost.productionCost : productionCost,
            productionCost,
            t
        ),
        createCalculatedRegulation(
            CalculatedRegulationID.KoB,
            TotalCostsCode.KoB_CONSTRUCTION_FACILITY_COSTS,
            regulationName,
            totalCost ? totalCost.constructionFacilityCost : constructionFacilityCost,
            constructionFacilityCost,
            t
        ),
        createCalculatedRegulation(
            CalculatedRegulationID.KoG,
            TotalCostsCode.KoG_BUILDING_COSTS,
            regulationName,
            totalCost ? totalCost.buildingCost : buildingCost,
            buildingCost,
            t
        ),
    ];

    const isChildOf = (p: CalculatedRegulation | null) => p === null;
    const isDescendantOf = (a: CalculatedRegulation | null) => a === null;

    const forest = buildForest(totals, isChildOf, isDescendantOf);

    return forest.map((tree) =>
        map(tree, (t) => ({
            ...t,
            locked: shouldBeLocked(t.cost.value, t.cost.checksum),
        }))
    );
}

export function createCostForest(costs: ProjectCost[] = [], regulations: StaticRegulation[] = []): CostForest {
    const costMap: Partial<Dictionary<ProjectCost>> = keyBy(costs, (cost) => cost.regulation.id);

    const regulationsWithCosts = regulations.map((regulation) => ({
        ...regulation,
        cost: costMap[regulation.id] ?? { regulation },
    }));

    const forest = buildForest(regulationsWithCosts, isChildOf, isDescendantOf);

    const treeWithCalculatedValues = calculateValues(forest);

    return treeWithCalculatedValues.map((tree) =>
        map(tree, (t) => ({
            ...t,
            locked: shouldBeLocked(t.cost.value, t.cost.checksum),
        }))
    );
}

export type NodeChangeFn<T> = (node: Tree<T>) => Tree<T>;
export type ForestChangeFn<T> = (node: Forest<T>, changed: Tree<T>, original: Tree<T>) => Forest<T>;

export function changeNode<T extends { id: number }>(
    forest: Forest<T>,
    path: number[],
    change: NodeChangeFn<T>,
    resolve: NodeChangeFn<T> = (n) => n,
    postChange: ForestChangeFn<T> = (f) => f
): Forest<T> {
    let originalNode: Tree<T> | null = null;
    let changedNode: Tree<T> | null = null;

    function traverse(node: Tree<T>, [current, ...path]: number[]): Tree<T> {
        if (node.id !== current) return node;

        if (path.length === 0) {
            originalNode = node;
            changedNode = change(node);

            return changedNode;
        }

        return resolve({
            ...node,
            children: node.children.map((c) => traverse(c, path)),
        });
    }

    const newForest = forest.map((node) => {
        if (node.id !== path[0]) return node;
        return traverse(node, path);
    });

    // eslint does not realize that those values change. Normally both defined, but might not be (if no node changed)
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (originalNode && changedNode) return postChange(newForest, changedNode, originalNode);

    return newForest;
}

export function shouldBeLocked(value = 0, checksum = 0): boolean {
    if (!checksum) return true;

    return round(value, 2) === round(checksum, 2);
}

export function useChangeDetection<V>(
    value: V,
    comparator: (val1: V | undefined, val2: V) => boolean,
    initialValue?: V
): boolean {
    const [previous, setPrevious] = useState<V | undefined>(initialValue);

    const hasChanged = useMemo(() => !comparator(previous, value), [comparator, previous, value]);

    useEffect(() => {
        if (hasChanged) setPrevious(value);
    }, [hasChanged, value]);

    return hasChanged;
}

/**
 * Creates and returns a cost forest generated from the project at first.
 *
 * When the costs are changed with the change function provided in the return value,
 * the forest is recalculated, stored and will be returned with the next rendern and
 * the on change will be called with the new value.
 *
 * !Important: Does not update when the project's costs are changed externally without using the change function provided in the return value (2nd argument).
 * @param project
 * @param staticData
 * @param regulationName
 * @param onChange
 */
export function useProjectCostForest(
    project: Project,
    staticData: StaticData,
    regulationName: string,
    onChange: (costForest: CostForest) => unknown = noop
): [CostForest, React.Dispatch<React.SetStateAction<CostForest>>] {
    const hasChanged = useChangeDetection(regulationName, eq, regulationName);
    const regulations = useMemo(
        () => orderBy(staticData.regulations[regulationName], 'code'),
        [staticData.regulations, regulationName]
    );
    const [costForest, setCostForest] = useState<CostForest>(() => createCostForest(project.costs, regulations));

    useEffect(() => {
        if (hasChanged) {
            setCostForest(createCostForest(project.costs, regulations));
        }
    }, [hasChanged, project, regulations, regulationName]);

    useEffect(() => {
        onChange(costForest);
    }, [costForest, onChange]);

    const setCostForestInterceptor = useCallback((change: React.SetStateAction<CostForest>) => {
        setCostForest((forest) => {
            const changed = typeof change === 'function' ? change(forest) : change;

            return changed;
        });
    }, []);

    return [costForest, setCostForestInterceptor];
}

export function useTotalCostForest(
    project: Project,
    staticData: StaticData,
    regulationName: string,
    onChange: (costForest: CostForest) => unknown = noop
): [CostForest, React.Dispatch<React.SetStateAction<CostForest>>] {
    const [t] = useTranslation();

    const calculator = useMemo(() => CostCalculator.get(regulationName), [regulationName]);

    const hasChanged = useChangeDetection(regulationName, eq, regulationName);
    const [totalCostForest, setTotalCostForest] = useState<CostForest>(() =>
        createTotalCostForest(project, staticData, regulationName, calculator, t)
    );

    useEffect(() => {
        if (hasChanged) {
            setTotalCostForest(createTotalCostForest(project, staticData, regulationName, calculator, t));
        }
    }, [hasChanged, project, staticData, regulationName, calculator, t]);

    useEffect(() => {
        onChange(totalCostForest);
    }, [totalCostForest, onChange]);

    const setTotalCostForestInterceptor = useCallback((change: React.SetStateAction<CostForest>) => {
        setTotalCostForest((forest) => {
            const changed = typeof change === 'function' ? change(forest) : change;

            return changed;
        });
    }, []);

    return [totalCostForest, setTotalCostForestInterceptor];
}

export function calculateTotalValue(
    calculator: CostCalculator,
    totalCost: CostTree,
    regulationsWithCosts: RegulationWithCost[]
): number {
    const totalCalculationMap: { [key: string]: (_costs: Array<RegulationWithCost>) => number } = {
        [TotalCostsCode.KoA_INVESTMENT_COSTS]: calculator.getInvestmentCosts,
        [TotalCostsCode.KoE_PRODUCTION_COSTS]: calculator.getCreationCosts,
        [TotalCostsCode.KoB_CONSTRUCTION_FACILITY_COSTS]: calculator.getConstructionCosts,
        [TotalCostsCode.KoG_BUILDING_COSTS]: calculator.getBuildingCosts,
    };
    const calculate = totalCalculationMap[totalCost.code];
    return calculate(regulationsWithCosts);
}

export function changeTotalForestNode<N extends { id: number }>(
    forest: Forest<N>,
    id: ID,
    change: (node: Tree<N>) => Tree<N>
): Forest<N> {
    return forest.map((tree) => {
        if (tree.id !== id) return tree;

        return change(tree);
    });
}

export function createTotalCostIfNotExists(totalCosts: TotalProjectCost[], regulationName: string): TotalProjectCost[] {
    const exists = totalCosts.some((tc) => tc.regulationName === regulationName);

    if (exists) return totalCosts;

    return [
        ...totalCosts,
        {
            regulationName,
            buildingCost: 0,
            constructionFacilityCost: 0,
            investmentCost: 0,
            productionCost: 0,
        },
    ];
}

export function changeTotalCost(
    totalCosts: TotalProjectCost[],
    regulationName: string,
    change: (cost: TotalProjectCost) => TotalProjectCost
): TotalProjectCost[] {
    return totalCosts.map((tc) => {
        if (tc.regulationName !== regulationName) return tc;

        return change(tc);
    });
}

/**
 * Applies a value change to a node in a CostForest and changes it's locked property if neccesary.
 * @param change The value change that should be applied to the node
 */
export function changeNodeValue(change: (value: number, node: CostNode) => number) {
    return (n: CostTree): CostTree => {
        const newValue = change(n.cost.value ?? 0, n);

        if (n.cost.value === newValue) return n;

        return {
            ...n,
            cost: {
                ...n.cost,
                value: newValue,
            },
            locked: !n.cost.checksum || newValue === n.cost.checksum,
        };
    };
}

/**
 * Propagates to a node it's new checksum, value and locked status
 *
 * @param n The node in which the propagation should happen
 */
export function propagateNodeValueChanges(n: CostTree): CostTree {
    const checksum = round(
        sumBy(n.children, (n) => n.cost.value ?? 0),
        2
    );

    const propagate = (node: CostTree, sum: number) => ({
        ...node,
        cost: {
            ...node.cost,
            value: sum,
            checksum: sum,
        },
        locked: true,
    });

    const notPropagate = (node: CostTree, sum: number) => ({
        ...node,
        cost: {
            ...node.cost,
            checksum: sum,
        },
        locked: !sum,
    });

    const { checksum: nChecksum = 0, value: nValue = 0 } = n.cost;

    if (nValue === checksum) {
        return propagate(n, checksum);
    }

    if (!n.locked) {
        return notPropagate(n, checksum);
    }

    if (!checksum) {
        return notPropagate(n, checksum);
    }

    if (nValue !== nChecksum) {
        return notPropagate(n, checksum);
    }

    return propagate(n, checksum);
}

export function recalculateDependingQuantities(_: ID[]) {
    return (forest: CostForest, changedNode: CostTree, originalNode: CostTree): CostForest => {
        if (changedNode.cost.value === originalNode.cost.value) return forest;

        return calculateQuantities(forest);
    };
}

/**
 * Applies a locked change to a node in a CostForest and changes it's value if it becomes locked and the checksum is not equal to the valud.
 * @param change The locked change that should be applied to the node
 */
export function changeNodeLocked(change: (value: boolean, node: CostNode) => boolean) {
    return (n: CostTree): CostTree => {
        const locked = change(n.locked, n);

        if (n.locked === locked) return n;

        return {
            ...n,
            cost: {
                ...n.cost,
                value: locked && n.cost.checksum ? n.cost.checksum : n.cost.value,
            },
            locked,
        };
    };
}

/**
 * Propagates to a node if it should be locked and what value it should have.
 *
 * @param n The node in which the propagation should happen
 */
export function propagateLockedChange(n: CostTree): CostTree {
    const checksum = round(
        sumBy(n.children, (n) => n.cost.value ?? 0),
        2
    );

    if (checksum === n.cost.checksum) return n;

    return {
        ...n,
        cost: {
            ...n.cost,
            value: n.locked ? checksum : n.cost.value,
            checksum,
        },
    };
}

/**
 * Applies a quantity change to a node in a CostForest
 * @param change The quantity change that should be applied to the node
 */
export function changeNodeQuantity(change: (value: number, node: CostNode) => number) {
    return (n: CostTree): CostTree => {
        const referenceQuantity = change(n.cost.referenceQuantity ?? 0, n);

        if (referenceQuantity === n.cost.referenceQuantity) return n;

        return {
            ...n,
            cost: {
                ...n.cost,
                referenceQuantity,
            },
        };
    };
}

/**
 * Sets the quantity on all nodes that have the same quantity (same shortname of regulation).
 *
 * !Important: This function assumes that a node has all properties of a {@link StaticRegulation} (shortname!)!
 *
 * @param forest The forest which should be
 * @param changedNode The node in the forest that has been changed
 */
export function updateEquivalentQuantities(
    forest: CostForest,
    changedNode: CostTree,
    originalNode: CostTree
): CostForest {
    if (changedNode === originalNode) return forest;

    // CostForest can be either from a StaticRegulation or a ComputedRegulation -> casting necessary
    const shortnameOf = (n: CostTree) => (n as Tree<StaticRegulation>).shortName.de_DE;

    return forest.map((tree) =>
        map(tree, (n: CostTree) => {
            if (shortnameOf(n) !== shortnameOf(changedNode)) return n;

            return {
                ...n,
                cost: {
                    ...n.cost,
                    referenceQuantity: changedNode.cost.referenceQuantity,
                },
            };
        })
    );
}

/**
 * Applies a description change to a node in a CostForest
 * @param change The description change that should be applied to the node
 */
export function changeNodeDescription(change: (value: TranslationMap, node: CostNode) => TranslationMap) {
    return (n: CostTree): CostTree => ({
        ...n,
        cost: {
            ...n.cost,
            description: change(n.cost.description ?? {}, n),
        },
    });
}

interface ImportResult {
    forest: CostForest;
    corrections: CostNode[];
    overrides: CostNode[];
}

export function importCostsIntoForest(
    forest: CostForest,
    data: [string, string, string][],
    lang: string
): ImportResult {
    const importedCostData = data.map(([code, value, description]): [string, number, string] => [
        code,
        parseFloat(value),
        description,
    ]);
    const importedCosts = importedCostData.map(([code, value, description]) => ({
        code,
        value,
        description,
    }));
    const importedCostsMap = groupBy(importedCosts, ({ code }) => code);

    function traverse(
        node: CostTree,
        mapper: (node: CostTree) => CostTree,
        postMapper: (node: CostTree) => CostTree = identity
    ): CostTree {
        return postMapper({
            ...mapper(node),
            children: node.children.map((child) => traverse(child, mapper, postMapper)),
        });
    }

    function isPrefilled(node: CostTree): boolean {
        return (
            !!node.cost.value ||
            !!node.cost.referenceQuantity ||
            !!Object.values(node.cost.description ?? {}).filter((d) => !!d).length
        );
    }

    const overrides: CostNode[] = [];
    const corrections: CostNode[] = [];

    function combineImportedCosts(
        code: string,
        costs: Array<{ code: string; value: number; description: string }>
    ): {
        code: string;
        value: number;
        description: string;
    } {
        if (!costs.length) {
            return {
                code,
                value: 0,
                description: '',
            };
        }

        const value = round(
            sumBy(costs, ({ value }) => value),
            2
        );
        const description = costs.reduce((acc, { value, description }) => {
            if (acc) return [acc, `"${description ?? ''}" - (${value || 0} CHF)`].join(';\n');

            if (description) return `"${description}" - (${value || 0} CHF)`;

            return acc;
        }, '');

        return {
            code,
            value,
            description,
        };
    }

    function isEmptied(node: CostNode, importedValues: { value: number; description: string }): boolean {
        return !importedValues.value && !!node.cost.value;
    }

    function isChanged(node: CostNode, importedValues: { value: number; description: string }): boolean {
        return !!node.cost.value && importedValues.value !== (node.cost.value ?? 0);
    }

    function overrideNode(node: CostTree): CostTree {
        const importedCosts = importedCostsMap[node.code];

        if (isPrefilled(node)) {
            overrides.push(node);
        }

        const combinedImportedCost = combineImportedCosts(node.code, importedCosts ?? []);

        if (isEmptied(node, combinedImportedCost)) {
            combinedImportedCost.description = [
                '(deleted)',
                `Original: "${node.cost.description?.[lang] ?? ''}"`,
                `Original Value: ${formatValueToLocale(node.cost.value ?? 0, 2)}`,
            ].join('\n');
        } else if (isChanged(node, combinedImportedCost)) {
            combinedImportedCost.description = [
                '(Original)',
                `"${node.cost.description?.[lang] ?? ''}"`,
                `Value: ${formatValueToLocale(node.cost.value ?? 0, 2)}`,
                '',
                '(Imported)',
                `${combinedImportedCost.description}`,
            ].join('\n');
        }

        return {
            ...node,
            cost: {
                ...node.cost,
                value: combinedImportedCost.value,
                description: {
                    [lang]: combinedImportedCost.description,
                },
            },
        };
    }

    function correctNode(node: CostTree): CostTree {
        const checksum = sumBy(node.children, ({ cost }) => cost.value ?? 0);
        const roundedChecksum = round(checksum, 2);

        const corrected = roundedChecksum !== node.cost.value && roundedChecksum;

        if (corrected) {
            corrections.push(node);
        }

        return {
            ...node,
            cost: {
                ...node.cost,
                checksum: roundedChecksum,
                value: roundedChecksum || node.cost.value || 0,
                description: {
                    ...node.cost.description,
                    [lang]: [
                        node.cost.description?.[lang] ?? '',
                        corrected
                            ? `\n\n(adjusted)\n${node.cost.value ?? 0} -> ${roundedChecksum || node.cost.value || 0}`
                            : '',
                    ].join(''),
                },
            },
        };
    }

    const newForest = forest.map((tree) => traverse(tree, overrideNode, correctNode));

    return {
        forest: newForest,
        overrides,
        corrections,
    };
}

export const isStaticRegulation = (reg: Regulation | null | undefined): reg is StaticRegulation => {
    return (
        !!(reg as StaticRegulation | null | undefined)?.name ||
        !!(reg as StaticRegulation | null | undefined)?.ebkphMappings
    );
};

export const isRegulationWithCost = (reg: Regulation | null): reg is StaticRegulationWithCost => {
    return !!(reg as StaticRegulationWithCost | null)?.cost;
};

export function hasProjectChanges(p1?: Project, p2?: Project): boolean {
    if (!hasEqualProjectCosts(p1, p2)) return true;

    return false;
}

export function hasEqualProjectCosts(p1?: Project, p2?: Project): boolean {
    const p1Costs = p1?.costs?.filter(
        (c) => c.value || c.referenceQuantity || Object.values(c.description ?? {}).some((d) => d)
    );
    const p2Costs = p2?.costs?.filter(
        (c) => c.value || c.referenceQuantity || Object.values(c.description ?? {}).some((d) => d)
    );

    const sortedCostsP1 = sortBy(p1Costs ?? [], ({ regulation }) => regulation.id);
    const sortedCostsP2 = sortBy(p2Costs ?? [], ({ regulation }) => regulation.id);

    if (sortedCostsP1.length !== sortedCostsP2.length) return false;

    return sortedCostsP1.every((cp1, index) => {
        const cp2 = sortedCostsP2[index];

        if (cp1.regulation.id !== cp2.regulation.id) return false;
        if (cp1.value !== cp2.value) return false;
        if (cp1.referenceQuantity !== cp2.referenceQuantity) return false;

        return Object.entries(cp1.description ?? {}).every(
            ([lang, desc = '']) => desc === (cp2.description?.[lang] ?? '')
        );
    });
}
