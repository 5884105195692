import React from 'react';
import { Col, Row } from 'reactstrap';
import { TextArea } from '../../../../../FormComponents';

interface Props {
    language: EditorLanguage;
    descriptionType: DescriptionType;
    description: ProjectDescription;
    changeDescription: (description: ProjectDescription) => unknown;
}

export const Description: React.FC<Props> = ({ language, descriptionType, description, changeDescription }) => {
    const changeText = (text: string) =>
        changeDescription({
            ...description,
            text: {
                ...description.text,
                [language.codes.documentation]: text,
            },
        });

    return (
        <Row className="border-bottom py-0">
            <Col className="text-bold" xs={6} sm={5} md={4}>
                {descriptionType.name[language.codes.editor]}
            </Col>
            <Col className="pt-1" xs={6} sm={7} md={8}>
                <TextArea
                    label={descriptionType.name[language.codes.editor]}
                    value={description.text[language.codes.documentation]}
                    onChange={changeText}
                    autoResize={true}
                    maxLength={8000}
                />
            </Col>
        </Row>
    );
};
