import { faAdjust } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classname from 'classnames';
import React from 'react';
import { useAspectRatio } from '../AspectRatio';
import { ImageCanvas } from '../ImageCanvas/ImageCanvas';

interface Props {
    name: string;
    src: string;
    aspectRatio?: number;
    className?: string;
}

export const ImagePreview: React.FC<Props> = ({ name, src, aspectRatio = 1, className = '' }) => {
    const [ref, style] = useAspectRatio<HTMLImageElement>(aspectRatio, [src]);

    const [darkBackground, setBackground] = React.useState(true);

    const toggleBackground = () => setBackground((b) => !b);

    return (
        <div
            ref={ref}
            className={classname('image-preview', className, {
                dark: darkBackground,
                light: !darkBackground,
            })}
            style={style}
        >
            <ImageCanvas
                src={src}
                width={typeof style.width === 'string' ? undefined : style.width}
                height={typeof style.height === 'string' ? undefined : style.height}
            />
            <div className="image-name" data-testid="image-name">
                {name}
            </div>
            <div
                className="background-toggle"
                onClick={toggleBackground}
                data-background={darkBackground ? 'dark' : 'light'}
            >
                <FontAwesomeIcon icon={faAdjust} />
            </div>
        </div>
    );
};
