import { AxiosError } from 'axios';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { editorService } from '../../services';

export function useProjectInputFileDelete(projectId: ID): UseMutationResult<unknown, AxiosError> {
    const queryClient = useQueryClient();

    return useMutation(() => editorService.deleteProjectInputFile(projectId), {
        onSuccess: () => queryClient.setQueryData(['project', projectId, 'inputfile'], undefined),
    });
}
