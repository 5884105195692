import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextButton } from '../Buttons';
import type { InputActions, NavigationActions, NavigationState } from './types';
import { tKey } from './utils';

interface BuildingPlanNavActionsProps extends NavigationState, NavigationActions, InputActions {}

export const BuildingPlanNavActions: React.FC<BuildingPlanNavActionsProps> = ({
    steps,
    previous,
    next,
    openDialog,
    isStart,
    isEnd,
}) => {
    const [t] = useTranslation();

    return (
        <div className="building-plan-importer__nav-actions">
            {steps.length > 1 && <TextButton name={t(tKey('back'))} onClick={previous} disabled={isStart} />}

            {!isEnd && <TextButton name={t('common:continue')} onClick={next} />}

            {isEnd && (
                <TextButton name={t(tKey('choose-input-file'))} onClick={openDialog} testId="choose-input-file" />
            )}
        </div>
    );
};
