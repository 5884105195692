import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextButton } from '../Buttons';
import { ProjectShare } from './ProjectShare';

interface ProjectShareButtonProps {
    projectId: number;
    project?: Project | ProjectOverview;
}

export const ProjectShareButton: React.FC<ProjectShareButtonProps> = ({ projectId, project }) => {
    const [t] = useTranslation();

    const [showShare, setShowShare] = useState(false);
    const openProjectShare = useCallback(() => setShowShare(true), []);
    const closeProjectShare = useCallback(() => setShowShare(false), []);

    const Button = useCallback(
        ({ disabled }) => <TextButton name={t('editor:share:title')} onClick={openProjectShare} disabled={disabled} />,
        [t, openProjectShare]
    );

    return (
        <ProjectShare
            isOpen={showShare}
            projectId={projectId}
            project={project}
            onExit={closeProjectShare}
            Button={Button}
        />
    );
};
