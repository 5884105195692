import React, { useCallback } from 'react';
import { Col, Row } from 'reactstrap';
import { NumberInput, SearchSelect, Select, TextInput } from '../../../../../FormComponents';
import { DateInput } from '../../../../../FormComponents/TextInput/DateInput';
import { FieldOption } from './types';

interface Props {
    name: string;
    value: any;
    passThrough: unknown;
    changeValue: (key: any, value: any) => unknown;
    option: FieldOption;
    language: EditorLanguage;
    testId?: string;
}

export const Common: React.FC<Props> = ({ name, value, changeValue, option, language, passThrough, testId }) => {
    const change = useCallback(
        (newValue: string) => {
            changeValue(
                passThrough,
                option.type === 'text' && option.multiLanguage
                    ? {
                          ...(value || {}),
                          [language.codes.documentation]: newValue,
                      }
                    : newValue
            );
        },
        [changeValue, value, passThrough, option, language.codes.documentation]
    );

    const raiseChange = useCallback(
        (newValue: string) => {
            changeValue(passThrough, newValue);
        },
        [changeValue, passThrough]
    );

    return (
        <Row className="data-view__common border-bottom py-0">
            <Col className="text-bold" xs={6} sm={5} md={4}>
                {name}
            </Col>
            <Col className="pt-1" xs={6} sm={7} md={8}>
                {option.input === 'text' && option.type === 'date' && (
                    <DateInput
                        {...(option.options || {})}
                        value={(option.multiLanguage ? value?.[language.codes.documentation] || '' : value) || ''}
                        label={name}
                        onChange={change}
                        disabled={option.disabled}
                    />
                )}

                {option.input === 'text' && option.type === 'number' && (
                    <NumberInput
                        {...(option.options || {})}
                        value={(option.multiLanguage ? value?.[language.codes.documentation] || '' : value) || ''}
                        label={name}
                        onChange={change}
                        disabled={option.disabled}
                        lazy={true}
                        testId={testId}
                    />
                )}

                {option.input === 'text' && option.type === 'text' && (
                    <TextInput
                        {...(option.options || {})}
                        value={(option.multiLanguage ? value?.[language.codes.documentation] || '' : value) || ''}
                        label={name}
                        onChange={change}
                        disabled={option.disabled}
                        testId={testId}
                    />
                )}

                {option.input === 'select' && (
                    <>
                        {option.search && (
                            <SearchSelect
                                label={name}
                                options={option.options}
                                value={(!value && option.default !== null ? option.default : value) || undefined}
                                onChange={raiseChange}
                                multiple={option.type === 'multiple'}
                                testId={testId}
                            />
                        )}

                        {!option.search && (
                            <Select
                                label={name}
                                options={option.options}
                                value={(!value && option.default !== null ? option.default : value) || undefined}
                                onChange={raiseChange}
                                multiple={option.type === 'multiple'}
                                disabled={option.disabled}
                                testId={testId}
                            />
                        )}
                    </>
                )}
            </Col>
        </Row>
    );
};
