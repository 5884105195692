import { RefObject, useEffect, useState } from 'react';
import { isInViewport } from '../utils';
import { identity } from 'lodash';

const selectParent = (element: HTMLElement) => element.parentElement;
export function useParentInViewport(ref: RefObject<HTMLElement>, fullVisibility = false): boolean {
    return useElementInViewport(ref, { selector: selectParent, fullVisibility });
}

interface Options {
    selector?: (element: HTMLElement) => HTMLElement | undefined | null;
    fullVisibility?: boolean;
}

export function useElementInViewport(
    ref: RefObject<HTMLElement>,
    { selector = identity, fullVisibility = false }: Options = {}
): boolean {
    const [elementInViewport, setElementInViewport] = useState(
        !!ref.current && isInViewport(ref.current, fullVisibility)
    );

    // Update variable when parent is visible in current viewport
    useEffect(() => {
        if (!ref.current) return;

        const element = selector(ref.current);
        if (!element) return;

        const listener = () => {
            const elementInViewport = isInViewport(element, fullVisibility);
            setElementInViewport(elementInViewport);
        };

        window.addEventListener('scroll', listener);

        return () => window.removeEventListener('scroll', listener);
    }, [ref, selector, fullVisibility]);

    return elementInViewport;
}
