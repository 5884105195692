import { i18n, TFunction } from 'i18next';
import { isEqual, isFunction } from 'lodash';
import React, { MouseEvent, useCallback } from 'react';
import { Col, Row } from 'reactstrap';
import { useLanguageContext } from '../../contexts';
import {
    useCostOfRegulation,
    useDialog,
    useEvent,
    useMeasurementOfCode,
    useProject,
    useProjectId,
    useProjects,
    useProjectSave,
    useSearchParam,
    useStaticData,
    useToast,
    useWizardState,
} from '../../effects';
import { editorService } from '../../services';
import type { UserInfo } from '../../types';
import { addIdToBuildingsAndFloors } from '../../utils';
import { LinkButton, TextButton } from '../Buttons';
import { PageHeader } from '../PageHeader';
import { ProjectShareButton } from '../ProjectShare';
import { Spinner } from '../Spinner';
import { EditorView, Page, ProjectEditorNavigation } from './fragments';
import { hasUsageAreaChanged, haveBuildingsChanged, haveCostsChanged } from './utils';
import { BuildingView, CostView, DataView, EnergyView, MeasurementView, MediaView, ViewProps } from './views';

interface Props {
    user: UserInfo;
    t: TFunction;
    i18n: i18n;
    close: () => void;
}

export const ProjectEditor: React.FC<Props> = ({ user, close, t }) => {
    const { data: staticData } = useStaticData();
    const { data: projects, error } = useProjects();

    const projectId = useProjectId();

    // Needed to check whether a wizard is already under way
    const { data: wizardState, isSuccess: wizardStateReady } = useWizardState(projectId);
    const wizardStateExists = !!wizardState;

    const toast = useToast();
    const wizardUnderwayDialog = useDialog({
        title: t('editor:wizard-underway:title'),
        description: t('editor:wizard-underway:message'),
        confirm: t('editor:wizard-underway:save-anyway'),
        cancel: t('editor:wizard-underway:cancel'),
        closeOnOutsideClick: true,
    });

    const [view, changeView] = useSearchParam<EditorView>('view', EditorView.Data);

    const [project, setProject] = React.useState<Project>();
    const { data: projectData, status } = useProject(projectId, {
        onError: close,
        select: addIdToBuildingsAndFloors,
    });

    const { mutate: saveProject, isLoading: isSaving, isSuccess: isSaved } = useProjectSave();

    const hasChanges = projectData && project && !isEqual(projectData, project);

    React.useEffect(() => {
        if (projectId === projectData?.id && project?.id !== projectData.id) {
            setProject(projectData);
        }
    }, [projectId, project, projectData]);

    const { language } = useLanguageContext();

    useEvent(
        window,
        'beforeunload',
        (e: Event) => {
            e.returnValue = true;

            return 'The Project will not be created. Are you sure you want to leave?';
        },
        [],
        process.env.NODE_ENV !== 'development' && !!projectId
    );

    const closeEditor = useCallback(
        (e: MouseEvent<HTMLElement>) => {
            e.preventDefault();
            if (!hasChanges) {
                close();

                return;
            }

            const confirmed = window.confirm(t('editor:leave-editor'));

            if (!confirmed) {
                return;
            }

            close();
        },
        [close, hasChanges, t]
    );

    React.useEffect(() => {
        if (!Object.values(EditorView).includes(view)) {
            changeView(EditorView.Data);
        }
    }, [view, changeView]);

    const currentCosts = useCostOfRegulation('BKP', project);
    const costData = useCostOfRegulation('BKP', projectData);
    const currentUsageArea = useMeasurementOfCode('2.1.1', project);
    const usageAreaData = useMeasurementOfCode('2.1.1', projectData);

    const handleProjectSave = async () => {
        let p = project;
        if (!p || !wizardStateReady) return;

        const costsChanged = haveCostsChanged(currentCosts, costData);
        const buildingsChanged = haveBuildingsChanged(p.buildings, projectData?.buildings ?? []);
        const usageAreaChange = hasUsageAreaChanged(currentUsageArea, usageAreaData);

        const deleteWizardState = costsChanged || buildingsChanged || usageAreaChange;

        if (wizardStateExists && deleteWizardState) {
            const confirmed = await wizardUnderwayDialog();

            if (!confirmed) return;
        }

        p = {
            ...p,
            energyInfo: {
                ...p.energyInfo,
                energies: (p.energyInfo.energies ?? []).filter(({ value }) => !!value),
                treeValues: Object.fromEntries(
                    Object.entries(p.energyInfo.treeValues).map(([type, tree]) => [
                        type,
                        tree.filter(({ watt, co2 }) => !!co2 || !!watt),
                    ])
                ),
            },
            tags: (p.tags ?? []).filter(
                ({ id, name }) => (!!id && id > 0) || Object.values(name).some((name) => !!name.trim())
            ),
            usages: (p.usages ?? []).filter(
                ({ regulation, tag: { id, name } }) =>
                    regulation.id && ((!!id && id > 0) || Object.values(name).some((name) => !!name))
            ),
            functionalUnits: (p.functionalUnits ?? []).filter(
                ({ tag: { id, name }, amount }) =>
                    ((!!id && id > 0) || Object.values(name).some((name) => !!name)) && amount
            ),
        };

        saveProject(p, {
            onError: (e) => toast('Error', e.toString()),
            onSuccess: (newProject) => setProject(newProject),
        });
    };

    const pages: Array<Page> = [
        { name: t('editor:pages:data'), page: EditorView.Data },
        { name: t('editor:pages:measurements'), page: EditorView.Metrics },
        { name: t('editor:pages:buildings'), page: EditorView.Buildings },
        { name: t('editor:pages:costs'), page: EditorView.Costs },
        { name: t('editor:pages:energy'), page: EditorView.Energy },
        { name: t('editor:pages:media'), page: EditorView.Media },
    ];

    const changeProject = React.useCallback((action: React.SetStateAction<Project>) => {
        setProject((p) => {
            if (p) {
                if (isFunction(action)) return action(p);
                else return action;
            }

            console.warn('Something seems to be wrong! "changeProject" is called before project has been loaded.');

            return p;
        });
    }, []);

    const View = getView(view);

    return (
        <div className="project-editor">
            <Row className="gutter-top small-push-bottom">
                <Col xs={5} md={4}>
                    <PageHeader title={t('editor:projects:edit:title')} noGutter={true} noPush={true} />
                </Col>
                <Col xs={4} className="pt-2 ml-auto text-right">
                    {!isSaving && (
                        <LinkButton
                            name={t('editor:actions:return-to-editor')}
                            onClick={closeEditor}
                            testId="back-to-list"
                        />
                    )}
                    {isSaving && <Spinner color="#000" size={14} />}
                </Col>
                <Col xs={12} className="mt-3 d-flex justify-content-between">
                    <div className="d-flex">
                        <TextButton
                            name={isSaving ? <Spinner color="#000" size={12} /> : t('editor:actions:save')}
                            onClick={handleProjectSave}
                            testId="save"
                        />

                        <div className="py-2 ml-3">
                            {((hasChanges && isSaved) || isSaved) && t('editor:actions:everything-up-to-date')}
                        </div>
                    </div>

                    <div className="d-flex">
                        <ProjectShareButton projectId={projectId} project={project} />
                    </div>
                </Col>
            </Row>

            <ProjectEditorNavigation activePage={view} pages={pages} changeView={changeView} />

            {(!staticData || status === 'loading') && (
                <Row className="justify-content-center">
                    <Col xs="auto">
                        <Spinner size={64} color="#000" />
                    </Col>
                </Row>
            )}

            {(error || status === 'error') && (
                <div className="p-3">
                    {t('errors:server-failed')
                        .split('.')
                        .map((part, index, arr) => (
                            <React.Fragment key={index}>
                                {part}
                                {index < arr.length - 1 && <br />}
                            </React.Fragment>
                        ))}
                </div>
            )}

            {!!staticData && status === 'success' && project && (
                <>
                    <View
                        projects={projects || []}
                        project={project}
                        service={editorService}
                        staticData={staticData}
                        changeProject={changeProject}
                        t={t}
                        language={language}
                        user={user}
                        wizardStateExists={wizardStateExists}
                    />

                    <div className="d-flex">
                        <TextButton
                            name={isSaving ? <Spinner color="#000" size={12} /> : t('editor:actions:save')}
                            onClick={handleProjectSave}
                            testId="save"
                        />
                        <div className="py-2 ml-3">
                            {((hasChanges && isSaved) || isSaved) && t('editor:actions:everything-up-to-date')}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

const getView = (view: EditorView): React.FC<ViewProps> =>
    ({
        [EditorView.Data]: DataView,
        [EditorView.Metrics]: MeasurementView,
        [EditorView.Buildings]: BuildingView,
        [EditorView.Costs]: CostView,
        [EditorView.Energy]: EnergyView,
        [EditorView.Media]: MediaView,
    }[view] || (() => null));
