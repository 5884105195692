import { isNumber } from 'lodash';
import { useMemo } from 'react';
import { useStaticData } from './queries';

export function useCostOfRegulation(regulation: Regulations, project: Project | undefined): ProjectCost[] {
    const { data } = useStaticData();

    const { costs } = project ?? {};

    return useMemo(() => {
        if (!data?.regulations[regulation] || !costs) return [];

        const regulations = new Set(data.regulations[regulation].map(({ id }) => id));

        return costs.filter((cost) => isNumber(cost.regulation.id) && regulations.has(cost.regulation.id as number));
    }, [regulation, data, costs]);
}
