import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, match, useHistory, useRouteMatch } from 'react-router-dom';
import type { View, Workbook } from 'tableau-api';
import { useAuthentication } from './contexts';
import { TableauFilter } from './domain/tableau/tableau-tag-filter';
import { useWorkbooksAndViews } from './effects/queries/use-workbooks-and-views';
import { RoutesEnum } from './enums/paths';
import { ActionsEnum } from './license/actions';

type TableauParams = {
    workbookId: string;
    viewId: string;
};
type TableauMatch = match<TableauParams>;

/**
 * Derives the active state of a navigation item:
 *
 * The "currentViewId" is a state value which is used to enable the navigation within the Tableau iframe without
 * triggering a page reload. This causes the URL not to change if the user navigates to different views within the
 * Tableau iframe.
 *
 * By default the "currentViewId" is empty, which means that the URL Param "match.params.view" is used to determine
 * the active state of the navigation item. If the "currentViewId" is set, it has higher priority and the active state
 * is always derived based on the "currentViewId".
 */
const isActive = ({
    workbookId,
    viewId,
    match,
    currentViewId,
}: {
    workbookId: string;
    viewId: string;
    match: TableauMatch | null;
    currentViewId: string;
}): boolean => {
    return Boolean(
        !!match &&
            match.params.workbookId === workbookId &&
            ((!!currentViewId && currentViewId === viewId) || (!currentViewId && match.params.viewId === viewId))
    );
};

const NO_TABLEAU_NAVIGATION_ITEMS: TNavigationItem[] = [];

export const useTableauNavigation = ({
    onNavigated,
}: {
    onNavigated: () => void;
}): {
    setCurrentViewId: React.Dispatch<React.SetStateAction<string>>;
    tableauNavigationitems: TNavigationItem[];
} => {
    const { i18n } = useTranslation();
    const history = useHistory();
    const { user } = useAuthentication();
    const hasUser = !!user;

    /**
     * This state is used to keep track of the currently active view within tableau
     * without the need to change the URL of the page if the change of the view originates
     * from within the Tableau iframe.
     */
    const [currentViewId, setCurrentViewId] = React.useState<string>('');

    const match = useRouteMatch<TableauParams>(RoutesEnum.WORKBOOK_VIEW);

    const hasTableauAccess = hasUser && user?.actions.has(ActionsEnum.ACCESS_TABLEAU);

    const { data, isSuccess } = useWorkbooksAndViews({
        enabled: hasTableauAccess,
    });

    const createTableauGroupNavigationItem = useCallback(
        ({ view, workbook }: { view: View; workbook: Workbook }): INavigationItem => {
            return {
                name: view.name,
                active: isActive({
                    workbookId: workbook.id,
                    viewId: view.id,
                    match,
                    currentViewId,
                }),
                onNavigate: () => {
                    const workbookViewPath = generatePath(RoutesEnum.WORKBOOK_VIEW, {
                        workbookId: workbook.id,
                        viewId: view.id,
                    });
                    history.push(workbookViewPath);
                    onNavigated();

                    if (currentViewId) {
                        // user has navigated, so reset current view and reload
                        const reloadPath = generatePath(RoutesEnum.WORKBOOK_VIEW, {
                            workbookId: workbook.id,
                            viewId: currentViewId,
                        });
                        history.replace(reloadPath);
                        setTimeout(() => {
                            setCurrentViewId('');
                            history.replace(workbookViewPath);
                        }, 0);
                    }
                },
            };
        },
        [currentViewId, history, match, onNavigated]
    );

    const createTableauRootNavigationItem = useCallback(
        (workbook: WorkbookWithViews) => ({
            name: workbook.name,
            items: workbook.views
                .filter((view) => !TableauFilter.isHidden(view))
                .map((view) => createTableauGroupNavigationItem({ view, workbook })),
        }),
        [createTableauGroupNavigationItem]
    );

    return useMemo(() => {
        if (!hasTableauAccess || !isSuccess || data === undefined) {
            return {
                setCurrentViewId,
                tableauNavigationitems: NO_TABLEAU_NAVIGATION_ITEMS,
            };
        }

        const tableauNavigationitems: TNavigationItem[] = createWorkbookWithViews(data.workbooks, data.views)
            .filter((workbook) => TableauFilter.hasLanguage(workbook, i18n.language))
            .map((workbook) => createTableauRootNavigationItem(workbook));

        return {
            setCurrentViewId,
            tableauNavigationitems,
        };
    }, [data, hasTableauAccess, isSuccess, i18n.language, createTableauRootNavigationItem]);
};

export type WorkbookWithViews = Workbook & { views: View[] };

/**
 * For each workbook it adds the views that are related to it.
 */
export function createWorkbookWithViews(workbooks: Workbook[] = [], views: View[] = []): WorkbookWithViews[] {
    const map: Map<string, WorkbookWithViews> = new Map();
    workbooks.forEach((workbook) => map.set(workbook.id, { ...workbook, views: [] }));

    views.forEach((view) => {
        const workbookWithViews = map.get(view.workbook.id);

        if (workbookWithViews) {
            map.set(view.workbook.id, {
                ...workbookWithViews,
                views: [...workbookWithViews.views, view],
            });
        }
    });

    return [...map.values()];
}
