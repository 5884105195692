import { faChevronDown, faChevronRight, faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { sumBy } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useLanguage } from '../../../../../effects';
import { NumberInput } from '../../../../FormComponents';

interface Props {
    tree: EnergyTree;
    locks: Record<number, { watt: boolean; co2: boolean }>;
    toggleLock: (id: number, key: 'watt' | 'co2', set?: boolean) => unknown;
    values: Record<number, ProjectEnergyTreeValue | undefined>;
    onChange: (
        type: string,
        tree: EnergyTree,
        update: (
            value: ProjectEnergyTreeValue,
            values: Record<string, ProjectEnergyTreeValue | undefined>
        ) => ProjectEnergyTreeValue
    ) => unknown;
    isChild?: boolean;
}

const NO_VALUE: ProjectEnergyTreeValue = { tree: { id: -1 }, co2: 0, watt: 0 };

export const EnergyTree: React.FC<Props> = ({ tree, locks, values, onChange, toggleLock }) => {
    const [language] = useLanguage();

    const value = values[tree.id] ?? NO_VALUE;
    const { watt = 0, co2 = 0 } = value;

    const childWattValue = sumBy(tree.children, (tree) => values[tree.id]?.watt ?? 0);
    const childCo2Value = sumBy(tree.children, (tree) => values[tree.id]?.co2 ?? 0);
    const lock = locks[tree.id];

    const hasChildren = !!tree.children.length;

    const wattLocked = childWattValue === watt;
    const co2Locked = childCo2Value === co2;

    useEffect(() => {
        if (!hasChildren) return;
        if (lock.watt && !wattLocked) onChange(tree.type, tree, (val) => ({ ...val, watt: childWattValue }));
    }, [tree, hasChildren, lock.watt, wattLocked, childWattValue, onChange]);

    useEffect(() => {
        if (!hasChildren) return;
        if (lock.co2 && !co2Locked) onChange(tree.type, tree, (val) => ({ ...val, co2: childCo2Value }));
    }, [tree, hasChildren, lock.co2, co2Locked, childCo2Value, onChange]);

    const [expanded, setExpanded] = useState(false);

    const toggle = useCallback(() => setExpanded((e) => !e), []);

    const handleWattChange = useCallback(
        (value: string) => {
            const watt = parseFloat(value);
            onChange(tree.type, tree, (tree) => ({ ...tree, watt }));
            toggleLock(tree.id, 'watt', watt === childWattValue);
        },
        [onChange, tree, toggleLock, childWattValue]
    );

    const handleCo2Change = useCallback(
        (value: string) => {
            const co2 = parseFloat(value);
            onChange(tree.type, tree, (tree) => ({ ...tree, co2 }));
            toggleLock(tree.id, 'co2', co2 === childCo2Value);
        },
        [onChange, tree, toggleLock, childCo2Value]
    );

    const toggleWattLock = useCallback(() => toggleLock(tree.id, 'watt'), [toggleLock, tree.id]);
    const toggleCo2Lock = useCallback(() => toggleLock(tree.id, 'co2'), [toggleLock, tree.id]);

    return (
        <>
            <Row className={classNames('energy-tree-node', 'border-bottom', 'align-items-center')} key={tree.id}>
                <Col onClick={toggle} className="text-center" xs={1}>
                    {hasChildren && !expanded && <FontAwesomeIcon icon={faChevronRight} />}
                    {hasChildren && expanded && <FontAwesomeIcon icon={faChevronDown} />}
                </Col>
                <Col xs={1}>{tree.code}</Col>
                <Col xs={4}>{tree.name[language.codes.editor]}</Col>
                <Col className="pt-2" xs={3}>
                    <NumberInput label="kWh/(m²a)" value={`${watt}`} onChange={handleWattChange} decimals={2} />
                    {hasChildren && (
                        <div className={classNames('energy-tree-lock')} onClick={toggleWattLock}>
                            {lock.watt && <FontAwesomeIcon icon={faLock} />}
                            {!lock.watt && <FontAwesomeIcon icon={faUnlock} />}
                        </div>
                    )}
                    {hasChildren && (
                        <div
                            className={classNames('energy-tree-status', {
                                'energy-tree-status__ok': watt === childWattValue,
                                'energy-tree-status__nok': watt !== childWattValue && !!childWattValue,
                            })}
                        />
                    )}
                </Col>
                <Col className="pt-2" xs={3}>
                    <NumberInput label="kg CO₂/m²a" value={`${co2}`} onChange={handleCo2Change} decimals={2} />

                    {hasChildren && (
                        <div className={classNames('energy-tree-lock')} onClick={toggleCo2Lock}>
                            {lock.co2 && <FontAwesomeIcon icon={faLock} />}
                            {!lock.co2 && <FontAwesomeIcon icon={faUnlock} />}
                        </div>
                    )}

                    {hasChildren && (
                        <div
                            className={classNames('energy-tree-status', {
                                'energy-tree-status__ok': co2 === childCo2Value,
                                'energy-tree-status__nok': co2 !== childCo2Value && !!childCo2Value,
                            })}
                        />
                    )}
                </Col>
            </Row>

            {tree.children.map(
                (childTree) =>
                    (expanded || childTree.children.length > 0) && (
                        <EnergyTree
                            tree={childTree}
                            locks={locks}
                            values={values}
                            toggleLock={toggleLock}
                            onChange={onChange}
                            isChild={true}
                            key={childTree.id}
                        />
                    )
            )}
        </>
    );
};
