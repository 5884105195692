import { Calculator } from './types';

export class BKPCalculator implements Calculator {
    /**
     * KoA Anlagekosten
     */
    public getInvestmentCosts(regulations: Array<RegulationWithCost>): number {
        return regulations
            .filter((regulation) => !regulation.parent)
            .reduce((sum, regulation) => sum + (regulation.cost.value ?? 0), 0);
    }

    /**
     * KoE Erstellungskosten
     */
    public getCreationCosts(regulations: Array<RegulationWithCost>): number {
        return (
            regulations.reduce((acc, regulation) => {
                switch (`${regulation.code}`) {
                    case '1':
                    case '2':
                    case '3':
                    case '4':
                    case '5':
                    case '6':
                    case '7':
                    case '8':
                    case '9':
                        return acc + (regulation.cost.value ?? 0) * 100;
                    default:
                        return acc;
                }
            }, 0) / 100
        );
    }

    /**
     * KoB Bauwerkskosten
     */
    public getConstructionCosts(): number {
        return 0;
    }

    /**
     * KoG Gebäudekosten
     */
    public getBuildingCosts(regulations: Array<RegulationWithCost>): number {
        return (
            regulations.reduce((acc, regulation) => {
                if (`${regulation.code}` === '2') {
                    return acc + (regulation.cost.value ?? 0) * 100;
                }

                return acc;
            }, 0) / 100
        );
    }
}
