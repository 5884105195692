import papaparser from 'papaparse';
import React from 'react';
import { Row } from 'reactstrap';
import { TextButton } from '../Buttons';
import { Spinner } from '../Spinner';
import { CSVPreview } from './CSVPreview';

interface ColumnDefinition {
    name: string;
    type?: 'number' | 'string' | 'boolean';
}

interface Props {
    csv: string | File;
    onData: (data: any) => unknown;
    columns?: string[] | ColumnDefinition[];
    onClose: () => void;
    noPreview?: boolean;
    config?: Partial<papaparser.ParseConfig>;
    onError?: (error: any) => void;
}

function parseText<
    D extends any[][],
    H extends string[] = string[],
    M extends papaparser.ParseMeta = papaparser.ParseMeta
>(text: string, config: papaparser.ParseConfig): Promise<[D, H, M]> {
    return new Promise<[D, H, M]>((resolve, reject) =>
        papaparser.parse<D>(text, {
            ...config,
            complete: (result) =>
                resolve([
                    config.header ? result.data.map((d) => Object.values(d)) : result.data.slice(1),
                    config.header ? Object.keys(result.data[0]) : result.data[0],
                    result.meta,
                ] as any),
            error: (error) => reject(error),
        })
    );
}

function handleParsedData<D extends any[][], H extends string[] = string[]>(
    [data, dataColumns]: [D, H],
    columns?: ColumnDefinition[]
): any[][] {
    const _data = columns
        ? data.map((d) =>
              columns.map((col) => {
                  const colIndex = dataColumns.findIndex((c) => c === col.name);

                  if (colIndex === -1) {
                      throw new Error(`No column with name "${col.name}" available`);
                  }

                  const colData = d[colIndex];

                  switch (columns[colIndex].type) {
                      case 'number':
                          return parseFloat(colData);
                      case 'boolean':
                          return `${colData}`.toLowerCase() === 'true';
                      case 'string':
                      default:
                          return colData;
                  }
              })
          )
        : data;

    return [_data, columns || dataColumns];
}

export const CSVImporter: React.FC<Props> = ({
    csv,
    onData,
    onClose,
    onError = console.error,
    config: configOverrides,
    columns,
    noPreview,
}) => {
    const [text, setText] = React.useState<null | string>(null);
    const columnNames = React.useMemo(
        () =>
            (columns || ([] as any[])).map((col: string | ColumnDefinition) =>
                typeof col === 'string' ? col : col.name
            ),
        [columns]
    );
    const columnDefinition = React.useMemo(
        () =>
            (columns || ([] as any[])).map((col: string | ColumnDefinition) =>
                typeof col === 'string' ? { name: col } : col
            ),
        [columns]
    );
    const [data, setData] = React.useState<null | any[][]>(null);
    const [config] = React.useState<papaparser.ParseConfig>({
        skipEmptyLines: true,
        dynamicTyping: true,
        trimHeaders: true,
        ...configOverrides,
    });

    React.useEffect(() => {
        if (typeof csv === 'string') {
            setText(csv);
        } else {
            csv.text().then(setText);
        }
    }, [csv]);

    const parse = React.useCallback((_text: string, parseConfig: papaparser.ParseConfig, cols: ColumnDefinition[]) => {
        return parseText(_text, parseConfig).then((result) => handleParsedData(result as any, cols));
    }, []);

    const importCSV = React.useCallback(() => {
        if (text) {
            parse(text, noPreview ? config : { ...config, preview: 5 + (config.header ? 0 : 1) }, columnDefinition)
                .then(([parsedData]) => {
                    onData(parsedData || []);
                    setData(parsedData || []);
                })
                .catch(onError);
        }
    }, [text, config, columnDefinition, noPreview, parse, onData, onError]);

    React.useEffect(() => importCSV(), [importCSV]);

    if (noPreview) {
        return null;
    }

    return (
        <div className="csv-importer p-3">
            {!data && <Spinner size={64} color="#000" />}

            {columnNames && data && <CSVPreview headers={columnNames} data={data} config={config} />}

            <Row className="justify-content-center">
                <TextButton className="mx-3" name="Import" onClick={importCSV} disabled={!data} />
                <TextButton className="mx-3" name="Abbrechen" onClick={onClose} disabled={!data} />
            </Row>
        </div>
    );
};
