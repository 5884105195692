// de", "fr", "it", "en"
export default [
    ['Schweiz', 'Suisse', 'Svizzera', 'Switzerland'],
    ['Deutschland', 'Allemagne', 'Germania', 'Germany'],
    ['Frankreich', 'France', 'Francia', 'France'],
    ['Liechtenstein', 'Liechtenstein', 'Liechtenstein', 'Liechtenstein'],
    ['Italien', 'Italie', 'Italia', 'Italy'],
    ['Albanien', 'Albanie', 'Albania', 'Albania'],
    ['Andorra', 'Andorre', 'Andorra', 'Andorra'],
    ['Belgien', 'Belgique', 'Belgio', 'Belgium'],
    ['Bulgarien', 'Bulgarie', 'Bulgaria', 'Bulgaria'],
    ['Dänemark', 'Danemark', 'Danimarca', 'Denmark'],
    ['Finnland', 'Finlande', 'Finlandia', 'Finland'],
    ['Griechenland', 'Grèce', 'Grecia', 'Greece'],
    ['Vereinigtes Königreich', 'Royaume-Uni', 'Regno Unito', 'United'],
    ['Irland', 'Irlande', 'Irlanda', 'Ireland'],
    ['Island', 'Islande', 'Islanda', 'Iceland'],
    ['Luxemburg', 'Luxembourg', 'Lussemburgo', 'Luxembourg'],
    ['Malta', 'Malte', 'Malta', 'Malta'],
    ['Monaco', 'Monaco', 'Monaco', 'Monaco'],
    ['Niederlande', 'Pays-Bas', 'Paesi Bassi', 'Netherlands'],
    ['Norwegen', 'Norvège', 'Norvegia', 'Norway'],
    ['Österreich', 'Autriche', 'Austria', 'Austria'],
    ['Polen', 'Pologne', 'Polonia', 'Poland'],
    ['Portugal', 'Portugal', 'Portogallo', 'Portugal'],
    ['Rumänien', 'Roumanie', 'Romania', 'Romania'],
    ['San Marino', 'Saint-Marin', 'San Marino', 'San Marino'],
    ['Schweden', 'Suède', 'Svezia', 'Sweden'],
    ['Spanien', 'Espagne', 'Spagna', 'Spain'],
    ['Türkei', 'Turquie', 'Turchia', 'Turkey'],
    ['Ungarn', 'Hongrie', 'Ungheria', 'Hungary'],
    ['Vatikanstadt', 'Cité du Vatican', 'Città del Vaticano', 'Vatican City'],
    ['Zypern', 'Chypre', 'Cipro', 'Cyprus'],
    ['Slowakei', 'Slovaquie', 'Slovacchia', 'Slovakia'],
    ['Tschechien', 'Tchéquie', 'Cechia', 'Czech Republic'],
    ['Serbien', 'Serbie', 'Serbia', 'Serbia'],
    ['Kroatien', 'Croatie', 'Croazia', 'Croatia'],
    ['Slowenien', 'Slovénie', 'Slovenia', 'Slovenia'],
    ['Bosnien und Herzegowina', 'Bosnie et Herzegovine', 'Bosnia e Erzegovina', 'Bosnia'],
    ['Montenegro', 'Montenegro', 'Montenegro', 'Montenegro'],
    ['Nordmazedonien', 'Macadoine du Nord', 'Macedonia del Nord', 'Northern Macedonia'],
    ['Kosovo', 'Kosovo', 'Kosovo', 'Kosovo'],
    ['Estland', 'Estonie', 'Estonia', 'Estonia'],
    ['Lettland', 'Lettonie', 'Lettonia', 'Latvia'],
    ['Litauen', 'Lituanie', 'Lituania', 'Lithuania'],
    ['Moldova', 'Moldova', 'Moldova', 'Moldova'],
    ['Russland', 'Russie', 'Russia', 'Russia'],
    ['Ukraine', 'Ukraine', 'Ucraina', 'Ukraine'],
    ['Belarus', 'Belarus', 'Belarus', 'Belarus'],
    ['Äquatorialguinea', 'Guinee equatoriale', 'Guinea equatoriale', 'Equatorial Guinea'],
    ['Äthiopien', 'Ethiopie', 'Etiopia', 'Ethiopia'],
    ['Dschibuti', 'Djibouti', 'Gibuti', 'Djibouti'],
    ['Algerien', 'Algerie', 'Algeria', 'Algeria'],
    ['Angola', 'Angola', 'Angola', 'Angola'],
    ['Botsuana', 'Botswana', 'Botswana', 'Botswana'],
    ['Burundi', 'Burundi', 'Burundi', 'Burundi'],
    ['Benin', 'Benin', 'Benin', 'Benin'],
    ["Cote d'Ivoire", "Cote d'Ivoire", "Cote d'Ivoire", 'Cote'],
    ['Gabun', 'Gabon', 'Gabon', 'Gabon'],
    ['Gambia', 'Gambie', 'Gambia', 'Gambia'],
    ['Ghana', 'Ghana', 'Ghana', 'Ghana'],
    ['Guinea-Bissau', 'Guinee-Bissau', 'Guinea-Bissau', 'Guinea'],
    ['Guinea', 'Guinee', 'Guinea', 'Guinea'],
    ['Kamerun', 'Cameroun', 'Camerun', 'Cameroon'],
    ['Cabo Verde', 'Cabo Verde', 'Cabo Verde', 'Cabo Verde'],
    ['Kenia', 'Kenya', 'Kenia', 'Kenya'],
    ['Komoren', 'Comores', 'Comore', 'Comoros'],
    ['Kongo (Brazzaville)', 'Congo (Brazzaville)', 'Congo (Brazzaville)', 'Congo (Brazzaville)'],
    ['Kongo (Kinshasa)', 'Congo (Kinshasa)', 'Congo (Kinshasa)', 'Congo (Kinshasa)'],
    ['Lesotho', 'Lesotho', 'Lesotho', 'Lesotho'],
    ['Liberia', 'Libéria', 'Liberia', 'Liberia'],
    ['Libyen', 'Libye', 'Libia', 'Libya'],
    ['Madagaskar', 'Madagascar', 'Madagascar', 'Madagascar'],
    ['Malawi', 'Malawi', 'Malawi', 'Malawi'],
    ['Mali', 'Mali', 'Mali', 'Mali'],
    ['Marokko', 'Maroc', 'Marocco', 'Morocco'],
    ['Mauretanien', 'Mauritanie', 'Mauritania', 'Mauritania'],
    ['Mauritius', 'Maurice', 'Maurizio', 'Mauritius'],
    ['Mosambik', 'Mozambique', 'Mozambico', 'Mozambique'],
    ['Niger', 'Niger', 'Niger', 'Niger'],
    ['Nigeria', 'Nigeria', 'Nigeria', 'Nigeria'],
    ['Burkina Faso', 'Burkina Faso', 'Burkina Faso', 'Burkina'],
    ['Simbabwe', 'Zimbabwe', 'Zimbabwe', 'Zimbabwe'],
    ['Ruanda', 'Rwanda', 'Ruanda', 'Rwanda'],
    ['Sambia', 'Zambie', 'Zambia', 'Zambia'],
    ['São Tomé und Príncipe', 'Sao Tomé-et-Principe', 'São Tomé e Príncipe', 'São Tomé and Príncipe'],
    ['Senegal', 'Sénégal', 'Senegal', 'Senegal'],
    ['Seychellen', 'Seychelles', 'Seicelle', 'Seychelles'],
    ['Sierra Leone', 'Sierra Leone', 'Sierra Leone', 'Sierra'],
    ['Somalia', 'Somalie', 'Somalia', 'Somalia'],
    ['Südafrika', 'Afrique du Sud', 'Sudafrica', 'South Africa'],
    ['Sudan', 'Soudan', 'Sudan', 'Sudan'],
    ['Namibia', 'Namibie', 'Namibia', 'Namibia'],
    ['Eswatini', 'Eswatini', 'Eswatini', 'Eswatini'],
    ['Tansania', 'Tanzanie', 'Tanzania', 'Tanzania'],
    ['Togo', 'Togo', 'Togo', 'Togo'],
    ['Tschad', 'Tchad', 'Ciad', 'Chad'],
    ['Tunesien', 'Tunisie', 'Tunisia', 'Tunisia'],
    ['Uganda', 'Ouganda', 'Uganda', 'Uganda'],
    ['Ägypten', 'Égypte', 'Egitto', 'Egypt'],
    [
        'Zentralafrikanische Republik',
        'République centrafricaine',
        'Repubblica centrafricana',
        'Central African Republic',
    ],
    ['Eritrea', 'Érythrée', 'Eritrea', 'Eritrea'],
    ['Südsudan', 'Soudan du Sud', 'Sudan del Sud', 'South Sudan'],
    ['Argentinien', 'Argentine', 'Argentina', 'Argentina'],
    ['Bahamas', 'Bahamas', 'Bahamas', 'Bahamas'],
    ['Barbados', 'Barbade', 'Barbados', 'Barbados'],
    ['Bolivien', 'Bolivie', 'Bolivia', 'Bolivia'],
    ['Brasilien', 'Brésil', 'Brasile', 'Brazil'],
    ['Chile', 'Chili', 'Cile', 'Chile'],
    ['Costa Rica', 'Costa Rica', 'Costa Rica', 'Costa Rica'],
    ['Dominikanische Republik', 'République dominicaine', 'Repubblica dominicana', 'Dominican republic'],
    ['Ecuador', 'Équateur', 'Ecuador', 'Ecuador'],
    ['El Salvador', 'El Salvador', 'El Salvador', 'El Salvador'],
    ['Guatemala', 'Guatemala', 'Guatemala', 'Guatemala'],
    ['Guyana', 'Guyana', 'Guyana', 'Guyana'],
    ['Haiti', 'Haïti', 'Haiti', 'Haiti'],
    ['Belize', 'Belize', 'Belize', 'Belize'],
    ['Honduras', 'Honduras', 'Honduras', 'Honduras'],
    ['Jamaika', 'Jamaïque', 'Giamaica', 'Jamaica'],
    ['Kanada', 'Canada', 'Canada', 'Canada'],
    ['Kolumbien', 'Colombie', 'Colombia', 'Colombia'],
    ['Kuba', 'Cuba', 'Cuba', 'Cuba'],
    ['Mexiko', 'Mexique', 'Messico', 'Mexico'],
    ['Nicaragua', 'Nicaragua', 'Nicaragua', 'Nicaragua'],
    ['Panama', 'Panama', 'Panama', 'Panama'],
    ['Paraguay', 'Paraguay', 'Paraguay', 'Paraguay'],
    ['Peru', 'Pérou', 'Perù', 'Peru'],
    ['Suriname', 'Suriname', 'Suriname', 'Suriname'],
    ['Trinidad und Tobago', 'Trinité-et-Tobago', 'Trinidad e Tobago', 'Trinidad and Tobago'],
    ['Uruguay', 'Uruguay', 'Uruguay', 'Uruguay'],
    ['Venezuela', 'Venezuela', 'Venezuela', 'Venezuela'],
    ['Vereinigte Staaten', 'États-Unis', 'Stati Uniti', 'United States'],
    ['Dominica', 'Dominique', 'Dominica', 'Dominica'],
    ['Grenada', 'Grenade', 'Grenada', 'Grenada'],
    ['Antigua und Barbuda', 'Antigua-et-Barbuda', 'Antigua e Barbuda', 'Antigua and Barbuda'],
    ['St. Lucia', 'Sainte-Lucie', 'Saint Lucia', 'St Lucia'],
    [
        'St. Vincent und die Grenadinen',
        'Saint-Vincent-et-les Grenadines',
        'Saint Vincent e Grenadine',
        'Saint Vincent and the Grenadines',
    ],
    ['St. Kitts und Nevis', 'Saint-Kitts-et-Nevis', 'Saint Kitts e Nevis', 'Saint Kitts and Nevis'],
    ['Afghanistan', 'Afghanistan', 'Afghanistan', 'Afghanistan'],
    ['Bahrain', 'Bahreïn', 'Bahrein', 'Bahrain'],
    ['Bhutan', 'Bhoutan', 'Bhutan', 'Bhutan'],
    ['Brunei Darussalam', 'Brunéi Darussalam', 'Brunei Darussalam', 'Brunei Darussalam'],
    ['Myanmar', 'Myanmar', 'Myanmar', 'Myanmar'],
    ['Sri Lanka', 'Sri Lanka', 'Sri Lanka', 'Sri Lanka'],
    ['China', 'Chine', 'Cina', 'China'],
    ['Indien', 'Inde', 'India', 'India'],
    ['Indonesien', 'Indonésie', 'Indonesia', 'Indonesia'],
    ['Irak', 'Irak', 'Iraq', 'Iraq'],
    ['Iran', 'Iran', 'Iran', 'Iran'],
    ['Israel', 'Israël', 'Israele', 'Israel'],
    ['Japan', 'Japon', 'Giappone', 'Japan'],
    ['Jemen', 'Yémen', 'Yemen', 'Yemen'],
    ['Jordanien', 'Jordanie', 'Giordania', 'Jordan'],
    ['Kambodscha', 'Cambodge', 'Cambogia', 'Cambodia'],
    ['Katar', 'Qatar', 'Qatar', 'Qatar'],
    ['Kuwait', 'Koweït', 'Kuwait', 'Kuwait'],
    ['Laos', 'Laos', 'Laos', 'Laos'],
    ['Libanon', 'Liban', 'Libano', 'Lebanon'],
    ['Malaysia', 'Malaisie', 'Malaysia', 'Malaysia'],
    ['Malediven', 'Maldives', 'Maldive', 'Maldives'],
    ['Oman', 'Oman', 'Oman', 'Oman'],
    ['Mongolei', 'Mongolie', 'Mongolia', 'Mongolia'],
    ['Nepal', 'Népal', 'Nepal', 'Nepal'],
    ['Korea (Nord-)', 'Corée (Nord)', 'Corea (Nord)', 'Korea (North-)'],
    ['Vereinigte Arabische Emirate', 'Émirats arabes unis', 'Emirati arabi uniti', 'United Arab Emirates'],
    ['Pakistan', 'Pakistan', 'Pakistan', 'Pakistan'],
    ['Philippinen', 'Philippines', 'Filippine', 'Philippines'],
    ['Saudi-Arabien', 'Arabie saoudite', 'Arabia Saudita', 'Saudi Arabia'],
    ['Singapur', 'Singapour', 'Singapore', 'Singapore'],
    ['Korea (Süd-)', 'Corée (Sud)', 'Corea (Sud)', 'Korea (South-)'],
    ['Syrien', 'Syrie', 'Siria', 'Syria'],
    ['Thailand', 'Thaïlande', 'Thailandia', 'Thailand'],
    ['Vietnam', 'Vietnam', 'Vietnam', 'Vietnam'],
    ['Bangladesch', 'Bangladesh', 'Bangladesh', 'Bangladesh'],
    ['Timor-Leste', 'Timor-Leste', 'Timor-Leste', 'Timor-Leste'],
    ['Armenien', 'Arménie', 'Armenia', 'Armenia'],
    ['Aserbaidschan', 'Azerbaïdjan', 'Azerbaigian', 'Azerbaijan'],
    ['Georgien', 'Géorgie', 'Georgia', 'Georgia'],
    ['Kasachstan', 'Kazakhstan', 'Kazakstan', 'Kazakhstan'],
    ['Kirgisistan', 'Kirghizistan', 'Kirghizistan', 'Kyrgyzstan'],
    ['Tadschikistan', 'Tadjikistan', 'Tagikistan', 'Tajikistan'],
    ['Turkmenistan', 'Turkménistan', 'Turkmenistan', 'Turkmenistan'],
    ['Usbekistan', 'Ouzbékistan', 'Uzbekistan', 'Uzbekistan'],
    ['Australien', 'Australie', 'Australia', 'Australia'],
    ['Fidschi', 'Fidji', 'Figi', 'Fiji'],
    ['Nauru', 'Nauru', 'Nauru', 'Nauru'],
    ['Vanuatu', 'Vanuatu', 'Vanuatu', 'Vanuatu'],
    ['Neuseeland', 'Nouvelle-Zélande', 'Nuova Zelanda', 'New Zealand'],
    ['Papua-Neuguinea', 'Papouasie-Nouvelle-Guinée', 'Papua Nuova Guinea', 'Papua New Guinea'],
    ['Tonga', 'Tonga', 'Tonga', 'Tonga'],
    ['Samoa', 'Samoa', 'Samoa', 'Samoa'],
    ['Salomoninseln', 'Îles Salomon', 'Isole Salomone', 'Solomon Islands'],
    ['Tuvalu', 'Tuvalu', 'Tuvalu', 'Tuvalu'],
    ['Kiribati', 'Kiribati', 'Kiribati', 'Kiribati'],
    ['Marshallinseln', 'Îles Marshall', 'Isole Marshall', 'Marshall Islands'],
    ['Mikronesien', 'Micronésie', 'Micronesia', 'Micronesia'],
    ['Palau', 'Palaos', 'Palau', 'Palau'],
    ['Cookinseln', 'Îles Cook', 'Isole Cook', 'Cook Islands'],
];
