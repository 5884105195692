import type {
    OrthographicViewOptions,
    PerspectiveViewOptions,
    PlanModel,
} from '@crb-oa-viewer/data-assistant-building-plan';
import React from 'react';
import { BuildingEditorState } from './utils';

export interface ImportState {
    state: BuildingEditorState.Import;
    viewOptions: OrthographicViewOptions;
    model?: PlanModel;
    context?: undefined;
}

export interface ImportControlState {
    state: BuildingEditorState.ImportControl;
    viewOptions: OrthographicViewOptions;
    model?: PlanModel;
    context?: undefined;
}

export interface TerrainSelectionState {
    state: BuildingEditorState.TerrainSelection;
    viewOptions: OrthographicViewOptions;
    model?: PlanModel;
    context?: undefined;
}

export interface BuildingsState {
    state: BuildingEditorState.Buildings;
    viewOptions: PerspectiveViewOptions;
    model?: PlanModel;
    context?: undefined;
}

export interface FloorState {
    state: BuildingEditorState.Floor;
    viewOptions: OrthographicViewOptions;
    model?: PlanModel;
    context: { buildingId: string; floorId: string };
}

export interface ResultState {
    state: BuildingEditorState.Result;
    viewOptions: PerspectiveViewOptions;
    model?: PlanModel;
    context?: undefined;
}

export type State =
    | ImportState
    | ImportControlState
    | TerrainSelectionState
    | BuildingsState
    | FloorState
    | ResultState;

type ChangeStateImportAction = Action<'CHANGE_STATE', { state: BuildingEditorState.Import }>;
type ChangeStateImportControlAction = Action<'CHANGE_STATE', { state: BuildingEditorState.ImportControl }>;
type ChangeStateTerrainSelectionAction = Action<'CHANGE_STATE', { state: BuildingEditorState.TerrainSelection }>;
type ChangeStateBuildingsAction = Action<'CHANGE_STATE', { state: BuildingEditorState.Buildings }>;
type ChangeStateFloorAction = Action<
    'CHANGE_STATE',
    { state: BuildingEditorState.Floor; context: { buildingId: string; floorId: string } }
>;
type ChangeStateResultAction = Action<'CHANGE_STATE', { state: BuildingEditorState.Result }>;
type ChangeModelAction = Action<'CHANGE_MODEL', PlanModel>;
type HighlightContourAction = Action<'HIGHLIGHT_CONTOUR', string>;
type HighlightFloorAction = Action<'HIGHLIGHT_FLOOR', string>;
type HighlightBuildingAction = Action<'HIGHLIGHT_BUILDING', string>;
type BackAction = Action<'BACK'>;
type ResetAction = Action<'RESET'>;

export type Actions =
    | BackAction
    | ChangeStateImportAction
    | ChangeStateImportControlAction
    | ChangeStateTerrainSelectionAction
    | ChangeStateBuildingsAction
    | ChangeStateFloorAction
    | ChangeStateResultAction
    | ChangeModelAction
    | HighlightContourAction
    | HighlightFloorAction
    | HighlightBuildingAction
    | ResetAction;

export const reducer: React.Reducer<State, Actions> = (state, action): State => {
    switch (action.type) {
        case 'CHANGE_STATE':
            switch (action.payload.state) {
                case BuildingEditorState.Import:
                    return {
                        state: BuildingEditorState.Import,
                        viewOptions: { cameraMode: 'orthographic', highlights: [] },
                    };
                case BuildingEditorState.ImportControl:
                    return changeState(state, BuildingEditorState.ImportControl, 'orthographic');
                case BuildingEditorState.TerrainSelection:
                    return changeState(state, BuildingEditorState.TerrainSelection, 'orthographic');
                case BuildingEditorState.Buildings:
                    return changeState(state, BuildingEditorState.Buildings, 'perspective');
                case BuildingEditorState.Floor:
                    return changeState(state, BuildingEditorState.Floor, 'orthographic', action.payload.context);
                case BuildingEditorState.Result:
                    return changeState(state, BuildingEditorState.Result, 'perspective');
                default:
                    return state;
            }
        case 'BACK':
            switch (state.state) {
                case BuildingEditorState.Import:
                    return state;
                case BuildingEditorState.ImportControl:
                    return {
                        state: BuildingEditorState.Import,
                        viewOptions: { cameraMode: 'orthographic', highlights: [] },
                    };
                case BuildingEditorState.TerrainSelection:
                    return changeState(state, BuildingEditorState.ImportControl, 'orthographic');
                case BuildingEditorState.Buildings:
                    return changeState(state, BuildingEditorState.TerrainSelection, 'orthographic');
                case BuildingEditorState.Floor:
                    return changeState(state, BuildingEditorState.Buildings, 'perspective');
                case BuildingEditorState.Result:
                    return changeState(state, BuildingEditorState.Buildings, 'perspective');
                default:
                    return state;
            }
        case 'CHANGE_MODEL':
            return { ...state, model: action.payload };
        case 'HIGHLIGHT_BUILDING':
            if (![BuildingEditorState.Buildings, BuildingEditorState.Result].includes(state.state)) return state;

            return highlight(state, 'building', action.payload);
        case 'HIGHLIGHT_FLOOR':
            if (![BuildingEditorState.Buildings, BuildingEditorState.Result].includes(state.state)) return state;

            return highlight(state, 'floor', action.payload);
        case 'HIGHLIGHT_CONTOUR':
            if (
                ![
                    BuildingEditorState.ImportControl,
                    BuildingEditorState.TerrainSelection,
                    BuildingEditorState.Floor,
                ].includes(state.state)
            )
                return state;

            return highlight(state, 'contour', action.payload);
        case 'RESET':
            return {
                state: BuildingEditorState.Import,
                viewOptions: { cameraMode: 'orthographic', highlights: [] },
            };
        default:
            if (process.env.NODE_ENV === 'development') console.warn('Unhandled action', action);

            return state;
    }
};

function changeState(
    prevState: State,
    state: BuildingEditorState.ImportControl,
    cameraMode: ImportControlState['viewOptions']['cameraMode']
): ImportControlState;
function changeState(
    prevState: State,
    state: BuildingEditorState.TerrainSelection,
    cameraMode: TerrainSelectionState['viewOptions']['cameraMode']
): TerrainSelectionState;
function changeState(
    prevState: State,
    state: BuildingEditorState.Buildings,
    cameraMode: BuildingsState['viewOptions']['cameraMode']
): BuildingsState;
function changeState(
    prevState: State,
    state: BuildingEditorState.Floor,
    cameraMode: FloorState['viewOptions']['cameraMode'],
    context: { buildingId: string; floorId: string }
): FloorState;
function changeState(
    prevState: State,
    state: BuildingEditorState.Result,
    cameraMode: ResultState['viewOptions']['cameraMode']
): ResultState;
function changeState(
    prevState: State,
    state: BuildingEditorState,
    cameraMode: 'orthographic' | 'perspective',
    context?: { buildingId: string; floorId: string }
): State {
    return {
        state,
        viewOptions: {
            cameraMode,
            highlights: [],
            focus: context ? { itemType: 'floor', itemId: context.floorId } : undefined,
        },
        model: prevState.model,
        context,
    } as State;
}

function highlight(prevState: State, itemType: 'building' | 'floor' | 'contour', itemId: string): State {
    return {
        ...prevState,
        viewOptions: {
            ...prevState.viewOptions,
            highlights: [{ eventType: 'hover', itemType, itemId }],
        },
    } as State;
}
