import React from 'react';
import { Col } from 'reactstrap';
import type { BuildingEditorActionItem } from '../types';
import { TextButton } from '../../Buttons';

interface BuildingEditorPlanProps {
    actions?: BuildingEditorActionItem[];
    navPosition: 'top' | 'bottom';
    fullscreen?: boolean;
}

export const BuildingEditorPlan: React.FC<BuildingEditorPlanProps> = ({
    actions,
    navPosition,
    fullscreen,
    children,
}) => {
    const _actions = actions?.map(({ label, callback, disabled }, i) => (
        <TextButton name={label} onClick={callback} disabled={disabled} key={i} />
    ));

    return (
        <Col xs={12} sm={fullscreen ? 12 : 6} className="building-editor-plan mb-5 mb-sm-0">
            <div className="building-editor-plan__building-plan-wrapper">
                {navPosition === 'top' && actions && (
                    <div className="building-editor-plan__actions mb-5 text-right">{_actions}</div>
                )}

                {children}

                {navPosition === 'bottom' && actions && (
                    <div className="building-editor-plan__actions mt-5 text-right">{_actions}</div>
                )}
            </div>
        </Col>
    );
};
