/**
 * IDs are read from static data descriptionTypes
 * Those ids WON'T get changed
 */
export enum ProjectDescriptionTypeIds {
    /**
     * id for Projektinformation / Project information
     */
    KoA = 55742,
    /**
     * id for Raumprogram / Space allocation plan
     */
    KoE = 55748,
    /**
     * id for Construction / Konstruktion
     */
    KoB = 55754,
}
