import axios, { AxiosInstance } from 'axios';
import type { Project, Tag, View, Workbook, Site } from 'tableau-api';

interface TableauServiceConfig {
    siteId?: string;
    basepath?: string;
}

declare interface TableauAPI {
    getSites(options?: PaginationOptions): Promise<unknown[]>;
    getSite(id: string): Promise<Site>;
    getProjects(options?: PaginationOptions): Promise<Project[]>;
    getWorkbooks(options?: PaginationOptions): Promise<Workbook[]>;
    getWorkbook(id: string): Promise<Workbook>;
    getAllViews(): Promise<View[]>;
    getViewsOfWorkbook(workbook: string): Promise<View[]>;
    getView(id: string): Promise<View>;
}

export class TableauService implements TableauAPI {
    protected static API_VERSION = '3.4';

    protected siteId: string;

    protected readonly http: AxiosInstance;

    /**
     * Currently works with version 3.4 of the tableau API.
     *
     * @param basepath The base path on which the api is accessable
     * @param version The version used for tableau
     */
    constructor({ basepath = '/tableau', siteId = '' }: TableauServiceConfig = {}) {
        this.http = axios.create({
            baseURL: `${basepath}/api/${TableauService.API_VERSION}`,
            headers: {
                Accept: 'application/json',
                'X-Tableau-Auth': undefined,
            },
        });

        this.siteId = siteId;
    }

    public setToken(token: string): void {
        this.http.defaults.headers['X-Tableau-Auth'] = token;
    }

    public clearToken(): void {
        delete this.http.defaults.headers['X-Tableau-Auth'];
    }

    public setSiteId(siteId: string): void {
        this.siteId = siteId;
    }

    public async getSites(options: PaginationOptions = {}): Promise<unknown[]> {
        const { data } = await this.http.get('/sites', {
            params: this.toParams(options),
        });

        return data.sites.site;
    }

    public async getSite(id: string): Promise<Site> {
        const { data } = await this.http.get(`/sites/${id}`);

        return data.site;
    }

    public async getProjects(options: PaginationOptions = {}): Promise<Project[]> {
        const { data } = await this.http.get(`/sites/${this.siteId}/projects`, {
            params: this.toParams(options),
        });

        return data.projects.project;
    }

    public async getWorkbooks(options: PaginationOptions = {}): Promise<Workbook[]> {
        const { data } = await this.http.get(`/sites/${this.siteId}/workbooks`, {
            params: this.toParams(options),
        });

        return this.orderTagged(data.workbooks.workbook);
    }

    public async getWorkbooksForUser(user: string, options: PaginationOptions = {}): Promise<Workbook[]> {
        const { data } = await this.http.get(`/sites/${this.siteId}/workbooks/users/${user}/workbooks`, {
            params: this.toParams(options),
        });

        return this.orderTagged(data.workbooks.workbook);
    }

    public async getWorkbook(workbook: string): Promise<Workbook> {
        const { data } = await this.http.get(`/sites/${this.siteId}/workbooks/${workbook}`);

        return data.workbook;
    }

    public async getAllViews(): Promise<View[]> {
        const { data } = await this.http.get(`/sites/${this.siteId}/views`);

        return data.views.view;
    }

    public async getViewsOfWorkbook(workbook: string): Promise<View[]> {
        const { data } = await this.http.get(`/sites/${this.siteId}/workbooks/${workbook}/views`);

        return data.views.view;
    }

    public async getView(workbook: string): Promise<View> {
        const { data } = await this.http.get(`/sites/${this.siteId}/workbooks/${workbook}/views`);

        return data.views.view;
    }

    protected orderTagged<Tagged extends { tags: { tag?: Tag[] } }>(
        taggeds: Tagged[],
        reverse?: boolean
    ): Array<Tagged & { order: number }> {
        return taggeds
            .map((tagged) => {
                const orderLabel = (tagged.tags.tag || []).find(({ label }) => /^order:\d+$/.test(label));

                let order = 0;
                if (orderLabel) {
                    order = parseInt(orderLabel.label.split(':')[1], 10);
                }

                return { ...tagged, order };
            })
            .sort(({ order: orderA }, { order: orderB }) => (orderA - orderB) * (reverse ? -1 : 1));
    }

    protected toParams(options: PaginationOptions = {}): { pageNumber?: number; pageSize?: number } {
        return {
            pageNumber: options.page,
            pageSize: options.size,
        };
    }
}
