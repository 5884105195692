import React, { FormEvent, MouseEvent, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { TextButton } from '../Buttons';
import { Spinner } from '../Spinner';
import { Dialog, DialogProps, useDialogWrapper } from './Dialog';

export interface DialogFormProps<I, O> {
    data: I;
    onData: (data: O) => unknown;
    onValid: (valid: boolean) => unknown;
}

export type DialogFormComponent<I, O> = React.ComponentType<DialogFormProps<I, O>>;

export interface FormDialogProps<I, O>
    extends Omit<
            DialogProps,
            'body' | 'footer' | 'as' | keyof React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>
        >,
        React.DetailedHTMLProps<React.HTMLAttributes<HTMLFormElement>, HTMLFormElement> {
    title?: string;
    form?: DialogFormComponent<I, O>;
    data: I;
    confirm?: string;
    cancel?: string;
    onConfirm?: (event: FormEvent<HTMLElement>, data: O) => unknown;
    onCancel?: (event: MouseEvent<HTMLElement>) => unknown;
}

export const FormDialog = <I, O>({
    form: Form,
    confirm,
    cancel,
    data: inputData,
    onConfirm,
    onCancel,
    ...props
}: FormDialogProps<I, O>): ReactElement => {
    const [loading, setLoading] = React.useState(false);
    const [valid, setValid] = useState(false);
    const [data, setData] = useState<O>();

    const handleConfirm = React.useCallback(
        async (event: FormEvent<HTMLElement>) => {
            event.preventDefault();
            setLoading(true);
            await onConfirm?.(event, data as O);
        },
        [onConfirm, data]
    );

    const handleCancel = React.useCallback(
        async (event: MouseEvent<HTMLElement>) => {
            setLoading(true);
            await onCancel?.(event);
        },
        [onCancel]
    );

    return (
        <Dialog
            {...props}
            as="form"
            body={Form && <Form data={inputData} onData={setData} onValid={setValid} />}
            onSubmit={handleConfirm}
            footer={(
                <>
                    {confirm && !loading && (
                        <TextButton type="submit" name={confirm} disabled={!valid} testId="dialog-confirm" />
                    )}
                    {loading && (
                        <div className="py-1 px-5">
                            <Spinner size={24} color="#000" />
                        </div>
                    )}
                    {cancel && (
                        <TextButton
                            type="button"
                            className="mr-3"
                            name={cancel}
                            onClick={handleCancel}
                            disabled={loading}
                            testId="dialog-cancel"
                        />
                    )}
                </>
              )}
        />
    );
};

export type OpenFormDialog<I, O> = (input: I, overrides?: Partial<UseFormDialogProps<I, O>>) => Promise<false | O>;

export type UseFormDialogProps<I, O> = Omit<FormDialogProps<I, O>, 'data'>;
export function useFormDialog<I, O>({
    className,
    title,
    form,
    cancel,
    confirm,
    onCancel,
    onConfirm,
    closeOnOverlayClick,
    onClose,
}: UseFormDialogProps<I, O>): OpenFormDialog<I, O> {
    const [wrapper, remount] = useDialogWrapper();

    const props = useMemo(
        () => ({
            className,
            title,
            form,
            cancel,
            confirm,
            onCancel,
            onConfirm,
            closeOnOverlayClick,
            onClose,
        }),
        [className, title, form, cancel, confirm, onCancel, onConfirm, closeOnOverlayClick, onClose]
    );

    useEffect(
        () => () => {
            ReactDOM.unmountComponentAtNode(wrapper);
            wrapper.remove();
        },
        [wrapper]
    );

    return useCallback(
        (data: I, overrides?: Partial<UseFormDialogProps<I, O>>) => {
            return new Promise<false | O>((resolve): void => {
                const handleConfirm = async (_: unknown, data: O) => {
                    // Unmount
                    ReactDOM.unmountComponentAtNode(wrapper);

                    resolve(data);

                    props.onClose?.();
                };

                const handleCancel = async () => {
                    // Unmount
                    ReactDOM.unmountComponentAtNode(wrapper);

                    resolve(false);

                    props.onClose?.();
                };

                const handleClose = async () => handleCancel();
                ReactDOM.render(
                    <FormDialog<I, O>
                        {...props}
                        {...overrides}
                        data={data}
                        onCancel={handleCancel}
                        onConfirm={handleConfirm}
                        onClose={handleClose}
                    />,
                    remount()
                );
            });
        },
        [wrapper, props, remount]
    );
}
