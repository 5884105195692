import { TFunctionResult } from 'i18next';
import React from 'react';

export interface TextButtonProps {
    name?: React.ReactChild | React.ReactChildren | null | TFunctionResult;
    type?: 'button' | 'submit' | 'reset';
    className?: string;
    children?: null;
    disabled?: boolean;
    active?: boolean;
    tabIndex?: number;
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => unknown;
    testId?: string;
    title?: string;
}

export const TextButton: React.FC<TextButtonProps> = ({
    type,
    name,
    className,
    disabled,
    active,
    tabIndex,
    onClick,
    testId,
    title,
}) => (
    <button
        type={type}
        className={`button ${className || ''}`}
        onClick={onClick}
        disabled={disabled}
        data-active={active}
        tabIndex={tabIndex}
        data-testid={testId}
        title={title}
    >
        {name}
    </button>
);
