import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthenticationService } from '../services';
import type { UserInfo, SignupResult } from '../types';
import { redirectToExternal } from '../utils/redirect';

interface AuthContext {
    waitingForLicenseService: boolean;
    /**
     * Tableau Ticket
     */
    ticket: string | null;
    user: null | UserInfo;
    ticketUsed: boolean;
    logout: () => Promise<unknown>;
    setTicketToUsed: () => unknown;
    signup: (data: AnyObject) => Promise<SignupResult>;
}

const AuthenticationContext = React.createContext<null | AuthContext>(null);
AuthenticationContext.displayName = 'AuthenticationContext';

interface Props {
    authenticationService: AuthenticationService;
    onLogout?: () => unknown;
}

export const AuthenticationProvider: React.FC<Props> = ({ children, authenticationService, onLogout }) => {
    const [ticketUsed, setTicketUsed] = React.useState(!!sessionStorage.getItem('ticketUsed'));
    const [waitingForLicenseService, setWaitingForLicenseService] = React.useState(false);
    const history = useHistory();

    const logout = useCallback(async () => {
        authenticationService.logout();
        await onLogout?.();

        setTicketUsed(false);
        sessionStorage.removeItem('ticketUsed');

        redirectToExternal(history, new URL('/api/auth/logout', document.URL))();
    }, [authenticationService, history, onLogout]);

    const setTicketToUsed = useCallback(() => {
        sessionStorage.setItem('ticketUsed', 'true');
        setTicketUsed(true);
    }, []);

    const signup = useCallback(
        async (data: NestedFormValues) => {
            const result = await authenticationService.buyProducts(data);

            if (result.status >= 200 && result.status < 300) {
                setWaitingForLicenseService(true);
            }
            return result;
        },
        [authenticationService]
    );

    const { ticket = null } = authenticationService.authentication ?? {};

    return (
        <AuthenticationContext.Provider
            value={{
                waitingForLicenseService,
                ticket,
                user: authenticationService.authentication,
                ticketUsed,
                // Functions
                logout,
                setTicketToUsed,
                signup,
            }}
        >
            {children}
        </AuthenticationContext.Provider>
    );
};

export function useAuthentication(): AuthContext {
    const context = React.useContext(AuthenticationContext);

    if (context) {
        return context;
    }

    throw new Error(
        `"${useAuthentication.name}" can only be used inside a functional component ` +
            `that is a child of the ${AuthenticationProvider.name}`
    );
}
