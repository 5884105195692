import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement } from 'react';
import { useEvent } from '../../../effects';

export interface MenuButtonMenuProps {
    close: () => void;
}

interface Props<P extends MenuButtonMenuProps> {
    className?: string;
    Menu: React.ComponentType<P>;
    menuProps: Omit<P, keyof MenuButtonMenuProps>;
    disabled?: boolean;
}

type MenuButtonComponentType = <P extends MenuButtonMenuProps>(
    value: React.PropsWithChildren<Props<P>>
) => ReactElement | null;
export const MenuButton: MenuButtonComponentType = ({ className = '', children, Menu, menuProps, disabled }) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const [isOpen, setOpen] = React.useState(false);

    const toggle = () => setOpen((o) => !o);
    const close = React.useCallback(() => setOpen(false), []);

    useEvent<MouseEvent>(
        window,
        'mousedown',
        (e) => {
            if (e.target && e.target instanceof Node && !ref.current?.contains(e.target)) {
                close();
            }
        },
        [isOpen],
        isOpen
    );

    return (
        <div className="menu-button-container" ref={ref}>
            <button className={`menu-button ${className}`} type="button" onClick={toggle} disabled={disabled}>
                {children}
            </button>

            {!disabled && isOpen && (
                <menu className="menu-button__menu">
                    <div className="menu-button__menu-close" data-testid="menu-close-button" onClick={close}>
                        <FontAwesomeIcon icon={faChevronUp} />
                    </div>

                    <Menu {...(menuProps as React.ComponentProps<typeof Menu>)} close={close} />
                </menu>
            )}
        </div>
    );
};
