import { sumBy } from 'lodash';
import React from 'react';
import type { AugmentedRegulation } from '../types';

/**
 * A Tree of Regulations is arithmetically "correct" when the following applies for each node:
 * - the node's value is equal to the sum of its children's value
 * OR
 * - all of its children's values are 0
 * Reference ticket: (https://crbcloud.atlassian.net/browse/WMO3-96)
 */
export type Tree<I> = I & { children: Forest<I> };
export type Forest<I> = Tree<I>[];

export type IsChildOfFunction<I> = (parent: I | null, child: I) => boolean;
export type IsDescendantOfFunction<I> = (ancestor: I | null, descendant: I) => boolean;

/**
 * Computes the total value of a forest as the sum of all trees' lowest valued nodes/leaves values.
 * Assumes that each tree is correct (Refer to the definition of Tree for details).
 * @param forest
 */
export function getTotalForestValue(forest: Forest<AugmentedRegulation>): number {
    return sumBy(getAllValuedLowestNodesInForest(forest), ({ cost }) => cost.value || 0);
}

/**
 * Computes the total value of a tree as the sum of all its lowest valued nodes/leaves values.
 * Assumes that the tree is correct (Refer to the definition of Tree for details).
 * @param tree
 */
export function getTotalTreeValue(tree: Tree<AugmentedRegulation>): number {
    if (!tree.children) return 0;
    return sumBy(getAllValuedLowestNodesInTree(tree), ({ cost }) => cost.value || 0);
}

/**
 * Traverses through the tree and connects the children with the parents.
 * @param tree The disconnected tree that should be connected.
 * @param map A optional function that maps the nodes to a different value
 * @param parent The parent (optional, will be used for recursion)
 * @param siblings The siblings of the current node (optional, will be used for recursion)
 * @returns A newly connected tree.
 */
export const connectTree = <I, T extends Tree<I>>(tree: T, parent: T | null = null): T => {
    const connectedTree: T = {
        ...tree,
        parent,
        children: [],
    };

    const children = tree.children.map<T>((child) => connectTree<I, T>(child as T, connectedTree));

    return {
        ...connectedTree,
        children,
    };
};

/**
 * Traverses through the tree and disconnects the children from the parents.
 * @param tree The connected tree that should be disconnected.
 * @returns A disconnected tree.
 */
export const disconnectTree = <I, T extends Tree<I>>(tree: T): T => {
    return {
        ...tree,
        parent: null,
        children: tree.children.map(disconnectTree),
    };
};

/**
 * Find the children if the current node and maps them to another tree.
 * The items that are passed to the new tree will only contain descendants of the current node.
 * @param node The current node, also ancestor of all descendants
 * @param descendants The items that are potential children, but descendants for sure.
 * @param isChildOf A function that returns if a given descendent is a direct child of the current node
 * @param isDescendantOf A function that returns if a given descendent is an ancestor of the current node
 * @returns A tree of specific type
 */
export function buildTree<I>(
    node: I,
    descendants: I[],
    isChildOf: IsChildOfFunction<I>,
    isDescendantOf: IsDescendantOfFunction<I>
): Tree<I> {
    return {
        ...node,
        children: descendants
            .filter((item) => isChildOf(node, item))
            .map((child) => {
                const childDescendants = descendants.filter((i) => isDescendantOf(child, i));
                return buildTree(child, childDescendants, isChildOf, isDescendantOf);
            }),
    };
}

/**
 * Finds the root of each tree and maps each root to a tree.
 *
 * @param items
 * @param isChildOf
 * @param isDescendantOf
 */
export function buildForest<I>(
    items: I[],
    isChildOf: IsChildOfFunction<I>,
    isDescendantOf: IsDescendantOfFunction<I>
): Forest<I> {
    const roots = items.filter((item) => isChildOf(null, item));
    return roots.map((root) => {
        const descendants = items.filter((i) => isDescendantOf(root, i));
        return buildTree(root, descendants, isChildOf, isDescendantOf);
    });
}

/**
 * Traverses through a tree and returns all nodes that don't have children.
 * @param tree The tree which is traversed
 * @returns Nodes without children
 */
export function getAllLeaves<I>(tree: Tree<I>): Tree<I>[] {
    function traverse(acc: Tree<I>[], node: Tree<I>): Tree<I>[] {
        if (node.children.length) {
            return node.children.reduce(traverse, acc);
        }
        acc.push(node);
        return acc;
    }

    return traverse([], tree);
}

export function getAllNodes<I>(tree: Tree<I>): Tree<I>[] {
    function traverse(acc: Tree<I>[], node: Tree<I>): Tree<I>[] {
        acc.push(node);
        if (node.children.length) {
            return node.children.reduce(traverse, acc);
        }
        return acc;
    }

    return traverse([], tree);
}

export function getAllNodesInForest<I>(forest: Forest<I>): Tree<I>[] {
    return forest.map((tree) => getAllNodes(tree)).flat();
}

export function getAllDescendants(tree: Tree<AugmentedRegulation>): Tree<AugmentedRegulation>[] {
    return getAllNodesInForest(tree.children);
}

export function getSumOfDescendants(tree: Tree<AugmentedRegulation>): number {
    const allNodes = getAllDescendants(tree);
    return sumBy(allNodes, ({ cost }) => cost.value || 0);
}

export function getAllValuedLowestNodesInForest(forest: Forest<AugmentedRegulation>): Tree<AugmentedRegulation>[] {
    return forest.map((tree) => getAllValuedLowestNodesInTree(tree)).flat();
}

export function getAllValuedLowestNodesInTree(tree: Tree<AugmentedRegulation>): Tree<AugmentedRegulation>[] {
    function traverse(acc: Tree<AugmentedRegulation>[], node: Tree<AugmentedRegulation>): Tree<AugmentedRegulation>[] {
        const plainSumOfTree = getSumOfDescendants(node);
        if (plainSumOfTree === 0) {
            acc.push(node);
            return acc;
        }
        if (node.children.length) {
            return node.children.reduce(traverse, acc);
        }
        return acc;
    }

    return traverse([], tree);
}

export function getSiblings(node: Tree<AugmentedRegulation>): Tree<AugmentedRegulation>[] {
    if (!node.parent) {
        return [];
    }
    const allChildren = (node.parent as Tree<AugmentedRegulation>).children || [];
    const indexSelf = allChildren.findIndex((child) => child.id === node.id);
    return [...allChildren.slice(0, indexSelf), ...allChildren.slice(indexSelf + 1)];
}

/**
 * Flattens the tree to a 1-dimensional array leaving the connections as is.
 * @param tree A tree of any type.
 * @returns A 1-dimensional array of all elements in the tree.
 */
export function flattenTree<T>(tree: Tree<T>): Tree<T>[] {
    return [tree, ...tree.children.flatMap((child) => flattenTree(child))] as Tree<T>[];
}

/**
 * Given a tree of any type, checks if the predicate applies to at least one element.
 * @param tree A tree of any type.
 * @param predicate A function that is used to determine the result.
 */
export function some<T>(tree: Tree<T>, predicate: (item: Tree<T>) => boolean): boolean {
    return predicate(tree) || tree.children.some((child) => some(child, predicate));
}

/**
 * Given a tree of any type, checks if the predicate applies to all elements.
 * @param tree A tree of any type.
 * @param predicate A function that is used to determine the result.
 */
export function every<T>(tree: Tree<T>, predicate: (item: Tree<T>) => boolean): boolean {
    return predicate(tree) && tree.children.every((child) => every(child, predicate));
}

/**
 * Given a tree of any type, finds the first element that applies to the predicate.
 * @param tree A tree of any type.
 * @param predicate A function that is used to determine the result.
 */
export function find<T>(tree: Tree<T>, predicate: (item: Tree<T>) => boolean): Tree<T> | undefined {
    if (predicate(tree)) return tree;

    for (const child of tree.children) {
        const result = find(child, predicate);

        if (result) return result;
    }

    return undefined;
}

/**
 * Given a tree of any type, maps each item to a new item recursively.
 * @param tree A tree of any type.
 * @param mapper A function that is used to determine the result.
 */
export function map<S, T>(tree: Tree<S>, mapper: (item: Tree<S>) => T): Tree<T> {
    const children = tree.children.map((child) => map(child, mapper));

    return {
        ...mapper(tree),
        children,
    };
}

/**
 * Given a tree of any type, filters all elements that apply to the predicate.
 * @param tree A tree of any type.
 * @param predicate A function that is used to determine the result.
 */
export function filter<T>(tree: Tree<T>, predicate: (item: Tree<T>) => boolean): Tree<T>[] {
    return [...(predicate(tree) ? [tree] : []), ...tree.children.flatMap((child) => filter(child, predicate))];
}

export function useForest<I>(
    items: I[],
    isChildOf: IsChildOfFunction<I>,
    isDescendantOf: IsDescendantOfFunction<I>
): Forest<I> {
    return React.useMemo(() => buildForest(items, isChildOf, isDescendantOf), [items, isChildOf, isDescendantOf]);
}
