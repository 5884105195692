import { groupBy, sumBy } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useEvent } from '../../../../effects';
import { getSIARegulations } from '../../../BuildingEditor/utils';
import { BaseView } from '../View';
import { ViewProps } from '../types';
import { Measurement } from './fragments';
import { MeasurementTree } from './fragments/MeasurementTree';
import { MeasurementForest, useProjectMeasurementForest } from './utils';
import { ForestDiagram } from '../../../ForestDiagram';

const NO_MEASUREMENTS: ProjectMeasurement[] = [];

export const MeasurementView: React.FC<ViewProps> = ({ project, staticData, changeProject, t, ...props }) => {
    const { measurements = NO_MEASUREMENTS } = project;

    const siaRegulations = React.useMemo(() => getSIARegulations(staticData), [staticData]);

    const sia416Regulations = useMemo(() => siaRegulations.filter(({ name }) => name === 'SIA416'), [siaRegulations]);
    const nonSia416Regulations = useMemo(
        () => siaRegulations.filter(({ name }) => name !== 'SIA416'),
        [siaRegulations]
    );
    const nonSia416RegulationsWithValues = useMemo(() => {
        const regs = nonSia416Regulations.map((regulation) => {
            const value = measurements.find((measurement) => measurement.regulation.id === regulation.id)?.value || 0;

            return {
                regulation,
                value,
            };
        });

        return groupBy(regs, ({ regulation }) => regulation.name);
    }, [nonSia416Regulations, measurements]);

    const sia416Forest = useProjectMeasurementForest(sia416Regulations, project.measurements);

    // TODO: https://developer.mozilla.org/en-US/docs/Web/API/ClipboardEvent
    useEvent<ClipboardEvent>(
        window,
        'copy',
        (e) => {
            e.preventDefault();
            e.clipboardData?.setData('text/plain', 'Data');
        },
        []
    );
    useEvent<ClipboardEvent>(window, 'paste', (e) => console.log('paste:', e.clipboardData?.getData('text/plain')), []);

    const changeMeasurement = useCallback(
        (regulationId: ID, update: (measurement: ProjectMeasurement) => ProjectMeasurement) => {
            changeProject(({ measurements = [], ...p }) => {
                const previousMeasurement = measurements.find(({ regulation }) => regulation.id === regulationId);

                if (!previousMeasurement) {
                    // Add
                    return {
                        ...p,
                        measurements: [
                            ...measurements,
                            update({
                                regulation: { id: regulationId },
                                value: 0,
                            }),
                        ],
                    };
                }

                // edit
                return {
                    ...p,
                    measurements: measurements.map((measurement) => {
                        if (measurement.regulation.id !== regulationId) return measurement;

                        return update(measurement);
                    }),
                };
            });
        },
        [changeProject]
    );

    const [locks, setLocks] = useState<Record<ID, boolean>>(() => createLocks(sia416Forest)[0]);

    const changeLocked = useCallback((path: number[], change: (locked: boolean) => boolean) => {
        const id = path.pop();

        if (!id) return;

        setLocks((locks) => ({
            ...locks,
            [id]: change(locks[id]),
        }));
    }, []);

    const total = useMemo(() => sumBy(sia416Forest, ({ measurement }) => measurement?.value ?? 0), [sia416Forest]);

    return (
        <BaseView name="measurement" title={t('editor:projects:creator:measurement-sheet:title')}>
            <div className="mb-4">
                <div>
                    <h3>SIA 416</h3>
                </div>

                <div className="mb-3">
                    <ForestDiagram
                        forest={sia416Forest}
                        total={total}
                        getId={getMeasurementCode}
                        getName={getMeasurementCode}
                        getValue={getMeasurementValue}
                    />
                </div>

                {sia416Forest.map((tree) => (
                    <MeasurementTree
                        path={[]}
                        tree={tree}
                        changeMeasurement={changeMeasurement}
                        locks={locks}
                        changeLocked={changeLocked}
                        t={t}
                        language={props.language}
                        key={tree.id}
                    />
                ))}
            </div>

            <div className="mb-4">
                <div>
                    <h3>SIA 116</h3>
                </div>
                {nonSia416RegulationsWithValues['SIA116'].map((regulation) => (
                    <Measurement
                        measurement={regulation}
                        changeMeasurement={changeMeasurement}
                        language={props.language}
                        t={t}
                        key={regulation.regulation.id}
                    />
                ))}
            </div>

            <div className="mb-4">
                <div>
                    <h3>Mengen</h3>
                </div>
                {nonSia416RegulationsWithValues['Mengen'].map((regulation) => (
                    <Measurement
                        measurement={regulation}
                        changeMeasurement={changeMeasurement}
                        language={props.language}
                        t={t}
                        key={regulation.regulation.id}
                    />
                ))}
            </div>
        </BaseView>
    );
};

function createLocks(siaRegulations: MeasurementForest): [Record<ID, boolean>, number] {
    return siaRegulations.reduce(
        ([acc, total], reg) => {
            const [childLocks, childSum] = createLocks(reg.children);
            const value = reg.measurement?.value ?? 0;

            if (!reg.children.length) return [acc, total + value];

            return [
                {
                    ...acc,
                    [reg.id]: !childSum || value === childSum,
                    ...childLocks,
                },
                total + value,
            ];
        },
        [{}, 0]
    );
}

const getMeasurementCode = (node: MeasurementForest[0]) => node.code;
const getMeasurementValue = (node: MeasurementForest[0]) => node.measurement?.value;
