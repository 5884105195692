import React from 'react';
import { TableauService } from '../services';

/**
 * Tableau uses a workbook and sheet file structure, much like Microsoft Excel.
 * A workbook contains sheets. A sheet can be a worksheet, a dashboard, or a story.
 *
 * https://help.tableau.com/current/pro/desktop/en-us/environment_workspace.htm
 */
interface Context {
    tableauService: TableauService;
}

const TableauContext = React.createContext<Context | null>(null);

interface Props {
    tableauService: TableauService;
}

export const TableauProvider: React.FC<Props> = ({ children, tableauService }) => {
    return (
        <TableauContext.Provider
            value={{
                tableauService,
            }}
        >
            {children}
        </TableauContext.Provider>
    );
};

export function useTableauService(): Context {
    const context = React.useContext(TableauContext);

    if (context) {
        return context;
    }

    throw new Error(
        `"${useTableauService.name}" can only be used inside a functional component ` +
            `that is a child of the ${TableauProvider.name}`
    );
}
