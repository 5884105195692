import cs from 'classnames';
import React from 'react';
import { NavigationLink } from './NavigationLink';

interface Props {
    className?: string;
    item: TNavigationItem;
}

export const NavigationItem: React.FC<Props> = ({ className = '', item }) => (
    <div className={className}>
        <h2
            className={cs('navigation__title', {
                'navigation__title--hoverable': item.onClick,
            })}
            onClick={item.onClick}
            tabIndex={item.onClick ? 0 : undefined}
        >
            {item.name}
        </h2>
        <ul className="navigation__list">
            {(item.items || []).map(({ name: itemName, active, onNavigate }, index) => (
                <li key={index} className="navigation__list-item">
                    <NavigationLink name={itemName} active={active} onClick={onNavigate} key={index} />
                </li>
            ))}
        </ul>
    </div>
);
