import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { IconButton } from '../../../Buttons';
import { SelectOption } from '../types';

interface Props {
    option: SelectOption;
    deleteValue: (option: SelectOption) => unknown;
}

export const SelectedOption: React.FC<Props> = ({ option, deleteValue }) => {
    const deleteValueCallback = React.useCallback(() => void deleteValue(option), [option, deleteValue]);

    return (
        <div
            className="py-2 pl-3 pr-5  form__search-select__option"
            data-label={option.label}
            key={option.value}
            data-testid="selected-option"
        >
            {option.label}

            <IconButton onClick={deleteValueCallback}>
                <FontAwesomeIcon icon={faTimes} />
            </IconButton>
        </div>
    );
};
