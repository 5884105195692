import { TFunction } from 'i18next';
import React, { useCallback } from 'react';
import { Col, Row } from 'reactstrap';
import { override, valueFromPath } from '../../../../../../utils/project-utils';
import { Common } from '../Common/Common';
import { FieldOption } from '../Common/types';

interface Props {
    project: Project;
    language: EditorLanguage;
    staticData: StaticData;
    changeProject: (update: (project: Project) => Project) => unknown;
    t: TFunction;
}

const fields: Array<[string, FieldOption]> = [
    [
        'projectSpecificReadings.parkingSpaces',
        { input: 'text', type: 'number', options: { decimals: 0 }, testId: 'parking-spaces' },
    ],
    [
        'projectSpecificReadings.cycleParkingSpaces',
        { input: 'text', type: 'number', options: { decimals: 0 }, testId: 'cycle-parking-spaces' },
    ],
    [
        'projectSpecificReadings.vehicleChargingStations',
        { input: 'text', type: 'number', options: { decimals: 0 }, testId: 'charging-stations' },
    ],
];

export const Mobility: React.FC<Props> = ({ project, language, changeProject, t }) => {
    const handleChange = useCallback(
        (path: string, value: string) => {
            changeProject((p) => override(p, path, Number.parseInt(value)));
        },
        [changeProject]
    );

    return (
        <Row className="small-push-bottom">
            <Col xs={12}>
                <h3 className="mb-3">{t('editor:project:parameters:projectSpecificReadings:mobility')}</h3>
                <Row className="align-items-start">
                    <Col xs={12}>
                        {fields.map(([field, options]) => (
                            <Common
                                name={t(`editor:project:parameters:${field.replace(/\./g, ':')}`)}
                                value={
                                    options.getValue
                                        ? options.getValue(project)
                                        : valueFromPath(project, field.split('.'))
                                }
                                changeValue={handleChange}
                                option={options}
                                language={language}
                                key={field}
                                passThrough={field}
                                testId={options.testId}
                            />
                        ))}
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};
