import classnames from 'classnames';
import React, { ChangeEvent, ClipboardEvent, FocusEvent, useCallback } from 'react';

type Props = FormFieldComponentProps<
    string,
    {
        id?: string;

        autoComplete?: string;
        autoFocus?: boolean;
        autoResize?: boolean;
        label?: string;

        className?: string;
        wrapperClassName?: string;

        required?: boolean;
        gutterTop?: boolean;
        disabled?: boolean;

        maxLength?: number;

        rows?: number;
        allowFormattedTextPaste?: boolean;
    }
>;

const truncate = (str: string, maxLength?: number) => (maxLength == null ? str : str.substr(0, maxLength));

export const TextArea: React.FC<Props> = ({
    value,
    onChange,
    autoComplete,
    autoFocus,
    autoResize,
    className,
    wrapperClassName,
    disabled,
    error,
    form,
    gutterTop,
    id,
    label,
    onTouched,
    required,
    maxLength,
    rows,
    allowFormattedTextPaste,
}) => {
    const handleChange = useCallback(
        ({ currentTarget }: ChangeEvent<HTMLTextAreaElement>) => {
            onChange(truncate(currentTarget.value, maxLength));
        },
        [onChange, maxLength]
    );

    const handleBlur = useCallback(
        ({ target }: FocusEvent<HTMLDivElement>) => {
            onChange(truncate(target.innerHTML, maxLength));
        },
        [maxLength, onChange]
    );

    const handlePaste = useCallback(
        (event: ClipboardEvent<HTMLDivElement>) => {
            if (event === null) return;

            let content = event.clipboardData.getData('text');

            if (!allowFormattedTextPaste) {
                content = content.replace(/(<([^>]+)>)/gi, '');
            }

            let affix,
                suffix = '';
            const selection = window.getSelection();
            if (selection && event.currentTarget.contains(selection.anchorNode)) {
                let text = '';
                const target = selection.anchorNode;
                if (target instanceof HTMLDivElement) {
                    text = target.innerHTML;
                } else if (target instanceof Text) {
                    text = target.data;
                }
                const range = selection.getRangeAt(0);

                affix = text.substring(0, range.startOffset);
                suffix = text.substring(range.endOffset);
                content = affix + content + suffix;
            }

            (event.target as HTMLDivElement).innerText = content;
            event.preventDefault();

            if (selection?.anchorNode) {
                let target = selection.anchorNode as Text;

                if (target instanceof HTMLDivElement) {
                    target = target.childNodes.item(0) as Text;
                }

                try {
                    const offset = Math.min(content.length - suffix.length, target.length);
                    selection.getRangeAt(0).setEnd(target, offset);
                    selection.getRangeAt(0).setStart(target, offset);
                } catch (error) {
                    console.warn(error);
                }
            }
        },
        [allowFormattedTextPaste]
    );

    return (
        <div
            className={classnames(
                'form__input',
                {
                    'gutter-top': gutterTop,
                    'form__input--error': form && form.state === 'error' && !!error,
                },
                wrapperClassName
            )}
        >
            {!autoResize && (
                <textarea
                    id={id}
                    value={value || ''}
                    className={classnames(className, 'form__input-field', {
                        'form__input-field--filled': (value || '').length > 0,
                    })}
                    onChange={handleChange}
                    onClick={onTouched}
                    onTouchStart={onTouched}
                    required={required}
                    autoComplete={autoComplete}
                    autoFocus={autoFocus}
                    disabled={disabled}
                    maxLength={maxLength}
                    rows={rows}
                />
            )}

            {autoResize && (
                <div
                    id={id}
                    className={classnames(className, 'form__input-field', {
                        'form__input-field--filled': (value || '').length > 0,
                    })}
                    contentEditable={true}
                    onBlur={handleBlur}
                    onClick={onTouched}
                    onTouchStart={onTouched}
                    dangerouslySetInnerHTML={{ __html: value }}
                    onPaste={handlePaste}
                    data-testid="autoResize-editable-textArea"
                />
            )}

            {label && (
                <label className="form__input-label" htmlFor="input-mail">
                    <span className="form__input-label-content">
                        {label} {required ? '*' : ''}
                    </span>
                </label>
            )}
        </div>
    );
};
