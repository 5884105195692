import React, { useCallback } from 'react';

export interface SidebarProps {
    close: () => unknown;
}

interface Props {
    willClose: () => unknown;
    close: () => unknown;
    open?: boolean;
    active?: boolean;
    header?: React.ComponentType;
}

export const Sidebar: React.FC<Props> = ({ open, active, willClose, close, header: Header, children }) => {
    const [initialized, setInitialized] = React.useState(false);

    // On Mount
    React.useLayoutEffect(() => {
        if (open) {
            const id = setTimeout(() => setInitialized(true));
            return () => clearTimeout(id);
        }
    }, [open]);

    const startClosing = React.useCallback(() => {
        willClose();
        close();
    }, [willClose, close]);

    React.useEffect(() => {
        const EscapeKeyListener = ({ key, keyCode }: { key: string; keyCode: number }) => {
            if (active && (key === 'Escape' || keyCode === 27)) {
                startClosing();
            }
        };

        document.addEventListener('keyup', EscapeKeyListener);

        return () => document.removeEventListener('keyup', EscapeKeyListener);
    }, [active, startClosing]);

    const handleTransitioned = useCallback(() => {
        if (!open) {
            close();
            setInitialized(false);
        }
    }, [open, close]);

    if (!open && !initialized) {
        return null;
    }

    return (
        <aside
            className={['sidebar', open && initialized ? 'sidebar--open' : ''].join(' ')}
            onTransitionEnd={handleTransitioned}
            data-testid="sidebar"
        >
            <button
                className="sidebar__close"
                onClick={startClosing}
                onTransitionEnd={stopPropagation}
                data-testid="close-sidebar"
            />

            {Header && (
                <div className="sidebar__header">
                    <Header />
                </div>
            )}

            <div className="sidebar__content" onTransitionEnd={stopPropagation}>
                {children}
            </div>
        </aside>
    );
};

function stopPropagation(e: { stopPropagation: () => unknown }) {
    e.stopPropagation();
}
