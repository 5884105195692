import React from 'react';
import { useHistory } from 'react-router-dom';
import { useProjectId } from '../../effects';

type OnFailCallback = (props: { requestedPath: string }) => unknown;
type OnPassCallback = () => unknown;

interface Props {
    predicate?: (id?: ID) => boolean;
    onFail?: OnFailCallback;
    onPass?: OnPassCallback;
}

export type ProjectIdPropsGuardProps = Props;

export const ProjectIDGuard: React.FC<Props> = ({ children, predicate, onFail, onPass }) => {
    const history = useHistory();

    const projectId = useProjectId();

    const requestedPath = history.location.pathname;
    const pass = !isNaN(projectId) && projectId >= 0 && projectId < Infinity && (!predicate || predicate(projectId));

    React.useEffect(() => void (pass && onPass?.()), [pass, onPass]);
    React.useEffect(() => void (!pass && onFail?.({ requestedPath })), [pass, requestedPath, onFail]);

    if (!pass) {
        return null;
    }

    return <>{children}</>;
};
