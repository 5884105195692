import type { InputFile } from '@crb-oa-viewer/data-assistant-building-plan';
import axios, { AxiosInstance, CancelToken } from 'axios';
import { isEmpty } from 'lodash';
import { disconnectBkpTree, reconnectBkpTree } from '../../effects';
import type { MappingNames } from '../../enums';
import type { WizardState } from '../../types';
import { addIdToBuildingsAndFloors } from '../../utils';

enum Paths {
    UserInfo = '/userInfo',
    Projects = '/projects/',
    ReferenceProjects = '/projects/references',
    Project = '/projects/{id}',
    ProjectTransfer = '/projects/{id}/transfer',
    ProjectInputFile = '/projects/{projectId}/inputfile',
    WizardState = '/wizard-state/{id}',
    WizardStateComplete = '/wizard-state/{id}/complete',
    ProjectTags = '/project-tags',
    ProjectTag = '/project-tags/{id}',
    FloorUsageTags = '/floor-usage-tags',
    FloorUsageTag = '/floor-usage-tags/{id}',
    FunctionalUnitTags = '/functional-unit-tags',
    FunctionalUnitTag = '/functional-unit-tags/{id}',
    StaticData = '/data/static',
    ProjectMedia = '/project-media/{projectId}',
    ProjectLicences = '/projects/{projectId}/licences',
    ProjectLicence = '/projects/{projectId}/licences/{licenceId}',
    Media = '/project-media/{projectId}/{mediaId}',
    StatsForMapping = '/projects/stats-for-mapping',
    StatisticsForEBKP = '/projects/ebkp-stats',
    WizardInputFile = '/wizard-state/{projectId}/inputfile',
}

const BASE_URL = '/api';

export type StatisticForEBKPPayload = {
    /**
     * If a costIndexId is provided, then the statistics are calculated
     * based on that cost index.
     */
    costIndexId?: number;
    /**
     * If project ids are provided, then only statistics for the given
     * projects are calculated.
     */
    projectIds?: number[];
    /**
     * If regulationsIds are provided, then the statistics are only
     * calculated for the given regulations.
     */
    regulationIds?: number[];
    totalCostTypes?: TotalCostCode[];
    /**
     * The regulation for which the statistics are calculated.
     */
    regulationName: string; // 'eBKP-H2020(A)' | 'eBKP-H2020(B)'
};

export class EditorService {
    protected http: AxiosInstance;

    constructor() {
        this.http = axios.create({
            baseURL: BASE_URL,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });
    }

    public async getProjects(): Promise<ProjectOverview[]> {
        const { data } = await this.http.get<ProjectOverview[]>(Paths.Projects);

        return data;
    }

    public async getReferenceProjects(): Promise<ProjectOverview[]> {
        const { data } = await this.http.get<ProjectOverview[]>(Paths.ReferenceProjects);

        return data;
    }

    public async createProject(project: CreateProjectPayload): Promise<ID> {
        const { data } = await this.http.post<ID>(Paths.Projects, project);

        return data;
    }

    public async getProject(id: ID, cancelToken?: CancelToken): Promise<Project> {
        const response = await this.http.get<Project>(Paths.Project.replace(/\{id\}/g, `${id}`), {
            cancelToken,
        });

        return addIdToBuildingsAndFloors(response.data);
    }

    public async editProject(project: ProjectUpdate): Promise<Project> {
        await this.http.put<ID>(Paths.Project.replace(/\{id\}/g, `${project.id}`), project);

        return this.getProject(project.id);
    }

    public async transferProject(projectId: number, receiverId: string): Promise<void> {
        await this.http.put<ID>(Paths.ProjectTransfer.replace(/\{id\}/g, `${projectId}`), JSON.stringify(receiverId));
    }

    public async uploadProjectInputFile(projectId: ID, data: InputFileUpload): Promise<void> {
        return this.uploadInputFile(Paths.ProjectInputFile, projectId, data);
    }

    public async getProjectInputFile(projectId: ID): Promise<InputFile> {
        return this.downloadInputFile(Paths.ProjectInputFile, projectId);
    }

    public async deleteProjectInputFile(projectId: ID): Promise<void> {
        await this.http.delete<string>(Paths.ProjectInputFile.replace('{projectId}', `${projectId}`));
    }

    public async duplicateProject(projectId: number): Promise<number> {
        const { data } = await this.http.post<number>(Paths.Project.replace(/\{id\}/g, `${projectId}`));

        return data;
    }

    public async updateProjectsVisibility(payload: ProjectVisibilityUpdatePayload): Promise<void> {
        const { data } = await this.http.put(`${Paths.Projects}update-user-published`, payload);

        return data;
    }

    public async deleteProject(id: ID): Promise<void> {
        await this.http.delete<Project>(Paths.Project.replace(/\{id\}/g, `${id}`));
    }

    public async deleteProjects(ids: ID[]): Promise<ID[]> {
        // TODO: Replace with single API call
        await Promise.all(ids.map((id) => this.deleteProject(id)));

        return ids;
    }

    public async uploadWizardInputFile(projectId: ID, data: InputFileUpload): Promise<void> {
        return this.uploadInputFile(Paths.WizardInputFile, projectId, data);
    }

    public async getWizardInputFile(projectId: ID): Promise<InputFile> {
        return this.downloadInputFile(Paths.WizardInputFile, projectId);
    }

    public async deleteWizardInputFile(projectId: ID): Promise<void> {
        await this.http.delete<string>(Paths.WizardInputFile.replace('{projectId}', `${projectId}`));
    }

    public getMedia(projectId: ID, mediaID?: ID): string {
        return `${BASE_URL}${mediaID ? Paths.Media : Paths.ProjectMedia}`
            .replace(/\{projectId\}/g, `${projectId}`)
            .replace(/\{mediaId\}/g, `${mediaID}`);
    }

    public async uploadMedia(
        projectId: ID,
        data: { file: string | Blob; name: string; mimeType: string }
    ): Promise<ProjectMedia> {
        const formData = new FormData();

        formData.set('file', data.file);
        formData.set('name', data.name);
        formData.set('mimeType', data.mimeType);

        const { data: media } = await this.http.post<ProjectMedia>(
            Paths.ProjectMedia.replace('{projectId}', `${projectId}`),
            formData
        );

        return media;
    }

    public async changeMediaData(projectId: ID, mediaID: ID, data: { name: string; order: number }): Promise<ID> {
        const { data: id } = await this.http.put<ID>(
            Paths.Media.replace('{projectId}', `${projectId}`).replace('{mediaId}', `${mediaID}`),
            data
        );

        return id;
    }

    public async deleteMedia(projectId: ID, mediaID: ID): Promise<void> {
        await this.http.delete<void>(
            Paths.Media.replace('{projectId}', `${projectId}`).replace('{mediaId}', `${mediaID}`)
        );
    }

    public async getStaticData(): Promise<StaticData> {
        const { data } = await this.http.get<StaticData>(Paths.StaticData);

        return data;
    }

    public async getWizardState(id: ID): Promise<undefined | WizardState> {
        const response = await this.http.get<WizardState | undefined>(Paths.WizardState.replace(/\{id\}/g, `${id}`));
        const wizardState = response.data;

        // We attach the BKP tree nodes to their parents when they have one
        return wizardState && reconnectBkpTree(wizardState);
    }

    public async getProjectLicences(projectId: number): Promise<ProjectLicence[]> {
        const { data } = await this.http.get(Paths.ProjectLicences.replace(/\{projectId\}/g, `${projectId}`));

        return data;
    }

    public async createProjectLicence(projectId: number, licence: ProjectLicence): Promise<void> {
        await this.http.post(Paths.ProjectLicences.replace(/\{projectId\}/g, `${projectId}`), licence);
    }

    public async editProjectLicence(projectId: number, licence: ProjectLicence): Promise<void> {
        const path = Paths.ProjectLicence.replace(/\{projectId\}/g, `${projectId}`).replace(
            /\{licenceId\}/g,
            `${licence.id}`
        );

        await this.http.delete(path);
    }

    public async deleteProjectLicence(projectId: number, licence: ProjectLicence): Promise<void> {
        const path = Paths.ProjectLicence.replace(/\{projectId\}/g, `${projectId}`).replace(
            /\{licenceId\}/g,
            `${licence.id}`
        );

        await this.http.delete(path);
    }

    public async getProjectTags(): Promise<ProjectTag[]> {
        const response = await this.http.get<ProjectTag[]>(Paths.ProjectTags);

        return response.data;
    }

    public async createProjectTag(tag: TagCreate): Promise<unknown> {
        const res = await this.http.post(Paths.ProjectTags, tag);

        return res.data;
    }

    public async updateProjectTag(id: number, tag: TagCreate): Promise<unknown> {
        const res = await this.http.put(Paths.ProjectTag.replace('{id}', `${id}`), tag);

        return res.data;
    }

    public async deleteProjectTag(id: number): Promise<void> {
        await this.http.delete(Paths.ProjectTag.replace('{id}', `${id}`));
    }

    public async replaceProjectTag(id: number, replacementId: number): Promise<void> {
        await this.http.delete(Paths.ProjectTag.replace('{id}', `${id}`), {
            params: {
                strategy: 'REPLACE',
                replacement_id: replacementId,
            },
        });
    }

    public async getFloorUsageTags(): Promise<FloorUsageTag[]> {
        const response = await this.http.get<FloorUsageTag[]>(Paths.FloorUsageTags);

        return response.data;
    }

    public async createFloorUsageTag(tag: TagCreate): Promise<number> {
        const res = await this.http.post(Paths.FloorUsageTags, tag);

        return res.data;
    }

    public async updateFloorUsageTag(id: number, tag: TagCreate): Promise<number> {
        const res = await this.http.put(Paths.FloorUsageTag.replace('{id}', `${id}`), tag);

        return res.data;
    }

    public async deleteFloorUsageTag(id: number): Promise<void> {
        await this.http.delete(Paths.FloorUsageTag.replace('{id}', `${id}`));
    }

    public async replaceFloorUsageTag(id: number, replacementId: number): Promise<void> {
        await this.http.delete(Paths.FloorUsageTag.replace('{id}', `${id}`), {
            params: {
                strategy: 'REPLACE',
                replacement_id: replacementId,
            },
        });
    }

    public async getFunctionalUnitTags(): Promise<FunctionalUnitTag[]> {
        const response = await this.http.get<FunctionalUnitTag[]>(Paths.FunctionalUnitTags);

        return response.data;
    }

    public async createFunctionalUnitTag(tag: TagCreate): Promise<number> {
        const res = await this.http.post(Paths.FunctionalUnitTags, tag);

        return res.data;
    }

    public async updateFunctionalUnitTag(id: number, tag: TagCreate): Promise<number> {
        const res = await this.http.put(Paths.FunctionalUnitTag.replace('{id}', `${id}`), tag);

        return res.data;
    }

    public async deleteFunctionalUnitTag(id: number): Promise<void> {
        await this.http.delete(Paths.FunctionalUnitTag.replace('{id}', `${id}`));
    }

    public async replaceFunctionalUnitTag(id: number, replacementId: number): Promise<void> {
        await this.http.delete(Paths.FunctionalUnitTag.replace('{id}', `${id}`), {
            params: {
                strategy: 'REPLACE',
                replacement_id: replacementId,
            },
        });
    }

    public async updateWizardState(id: ID, wizardState: WizardState): Promise<ID> {
        // We need to make sure the object we want to serialize as JSON is not circular,
        // thus we need to make the nodes in the BKP tree forget they have parents.
        const jsonSerializableWizardState = disconnectBkpTree(wizardState);
        const { data } = await this.http.put<ID>(
            Paths.WizardState.replace(/\{id\}/g, `${id}`),
            jsonSerializableWizardState
        );

        return data;
    }

    public async deleteWizardState(id: ID): Promise<void> {
        await this.http.delete<WizardState>(Paths.WizardState.replace(/\{id\}/g, `${id}`));
    }

    public async completeWizardState(project: Project): Promise<Project> {
        await this.http.post<ID>(Paths.WizardStateComplete.replace(/\{id\}/g, `${project.id}`), project);

        return this.getProject(project.id);
    }

    public async getStatsForMapping(
        mappingName: MappingNames,
        projectIds: ID[],
        sourceRegulationName: string,
        targetRegulationName: string
    ): Promise<ProjectStats> {
        if (isEmpty(projectIds)) {
            return {
                regulationStats: [],
            };
        }

        const { data } = await this.http.post<ProjectStats>(Paths.StatsForMapping, {
            mappingName,
            projectIds,
            sourceRegulationName,
            targetRegulationName,
        });

        return data;
    }

    public async getStatisticsForEBKP({
        regulationName,
        projectIds,
        costIndexId,
        regulationIds,
        totalCostTypes,
    }: StatisticForEBKPPayload): Promise<ProjectStats> {
        const { data } = await this.http.post<ProjectStats>(Paths.StatisticsForEBKP, {
            regulationName,
            projectIds,
            costIndexId,
            regulationIds,
            totalCostTypes,
        });

        return data;
    }

    protected async uploadInputFile(path: Paths, projectId: ID, data: InputFileUpload): Promise<void> {
        const formData = new FormData();

        const file = await fetch(data.src);

        formData.set('file', await file.blob(), data.name);
        formData.set('extension', data.extension);

        await this.http.put(path.replace('{projectId}', `${projectId}`), formData);
    }

    protected async downloadInputFile(path: Paths, projectId: ID): Promise<InputFile> {
        return this.http.get(path.replace('{projectId}', `${projectId}`)).then((res) => {
            return {
                src: `data:application/octet-stream;base64,${res.data.src}`,
                extension: res.data.extension,
            };
        });
    }
}

export const editorService = new EditorService();
