import { useMemo } from 'react';
import { Tree, Forest, useForest } from '../../../../effects';
import { keyBy } from 'lodash';

export type MeasurementRegulationForest = Forest<StaticRegulation>;
export type MeasurementTree = Tree<StaticRegulation & { measurement?: ProjectMeasurement }>;
export type MeasurementForest = Forest<StaticRegulation & { measurement?: ProjectMeasurement }>;

const isChildOf = (parent: StaticRegulation | null, reg: StaticRegulation) =>
    (reg.parent?.id ?? null) === (parent?.id ?? null);
const isDescendantOf = (ancestor: StaticRegulation | null, reg: StaticRegulation) =>
    reg.code.startsWith(ancestor?.code ?? '');

export function useMeasurementForest(regulations: StaticRegulation[]): MeasurementRegulationForest {
    return useForest(regulations, isChildOf, isDescendantOf);
}

const NO_MEASUREMENTS: ProjectMeasurement[] = [];
export function useProjectMeasurementForest(
    regulations: StaticRegulation[],
    measurements: ProjectMeasurement[] = NO_MEASUREMENTS
): MeasurementForest {
    const forest = useMeasurementForest(regulations);

    const measurementMap = useMemo(() => keyBy(measurements, 'regulation.id'), [measurements]);

    return useMemo(() => {
        return mapTree<StaticRegulation, StaticRegulation>(forest, (reg, children) => ({
            ...reg,
            measurement: measurementMap[reg.id],
            children,
        }));
    }, [forest, measurementMap]);
}

export function mapTree<T, R = T>(
    roots: Tree<T>[],
    map: (node: Tree<T>, children: Tree<R>[]) => Tree<R>,
    getChildren: (node: Tree<T>) => Tree<T>[] = ({ children }) => children
): Tree<R>[] {
    if (roots.length === 0) return [];

    return roots.map((node) => map(node, mapTree(getChildren(node), map, getChildren)));
}
