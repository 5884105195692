import type { PerspectiveHighlight } from '@crb-oa-viewer/data-assistant-building-plan';
import { faChevronDown, faChevronUp, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { VolumeEvaluationCalculations } from '../../types';
import { codeOf, getSignedFloorNumber } from '../../utils';
import { NumberInput } from '../FormComponents';
import { IconButton, TextInput } from '../index';
import { FloorEdit } from './FloorEdit';

interface BuildingEditProps {
    className?: string;
    building: Building;
    calculations?: VolumeEvaluationCalculations;
    isOpen: boolean;
    highlights: Highlight[];
    onOpenToggle: (buildingId: string) => void;
    onModelItemEvent?: (modelItemEvent: { event: PerspectiveHighlight; buildingId: string }) => void;
    onBuildingUpdate: (newBuilding: Building) => void;
    onBuildingDelete: (buildingId: string) => void;
    isAreaEditable?: boolean;
    showRelativeHeight: boolean;
}

function getHighestSignedFloorNumber(building: Building): number | null {
    const max = Math.max(...building.floors.map((f) => getSignedFloorNumber(f)));
    return max !== -Infinity ? max : null;
}
function getLowestSignedFloorNumber(building: Building): number | null {
    const min = Math.min(...building.floors.map((f) => getSignedFloorNumber(f)));
    return min !== Infinity ? min : null;
}

function getFloorNumberAndFloorType(signedFloorNumber: number): { floorNumber: number; floorType: FloorType } {
    let floorType: FloorType = 'GROUND';
    if (signedFloorNumber > 0) {
        floorType = 'ABOVE_GROUND';
    }
    if (signedFloorNumber < 0) {
        floorType = 'UNDER_GROUND';
    }
    return { floorNumber: Math.abs(signedFloorNumber), floorType };
}

enum FLOOR_POSITION {
    TOP = 'top',
    BOTTOM = 'bottom',
}

function createFloor(position: FLOOR_POSITION, building: Building): Building {
    let newSignedFloorNumber: number | null;
    let contours: string[];

    if (position === FLOOR_POSITION.TOP) {
        const hsfn = getHighestSignedFloorNumber(building);
        newSignedFloorNumber = hsfn !== null ? hsfn + 1 : hsfn;

        const floorBelow = building.floors.find((f) => getSignedFloorNumber(f) === hsfn);
        contours = floorBelow?.contours ?? [];
    } else {
        const lsfn = getLowestSignedFloorNumber(building);
        newSignedFloorNumber = lsfn !== null ? lsfn - 1 : lsfn;

        const floorBelow = building.floors.find((f) => getSignedFloorNumber(f) === lsfn);
        contours = floorBelow?.contours ?? [];
    }
    newSignedFloorNumber = newSignedFloorNumber ?? 0;

    const { floorNumber, floorType } = getFloorNumberAndFloorType(newSignedFloorNumber);

    const floor: Floor = {
        id: uuidv4(),
        floorType,
        floorNumber,
        height: 3,
        contours,
        area: 0,
        floorFunctions: [],
    };

    return {
        ...building,
        floors: [...building.floors, floor].sort((a, b) => getSignedFloorNumber(b) - getSignedFloorNumber(a)),
    };
}

function deleteFloor(floorId: string, building: Building): Building {
    return {
        ...building,
        floors: [...building.floors.filter((e) => e.id !== floorId)],
    };
}

function isFloorDeletable(floorId: string, building: Building): boolean {
    const floor = building.floors.find((f) => f.id === floorId);
    if (!floor) {
        return false;
    }

    const isOnlyFloor = building.floors.length === 1;
    const isTopFloor = getHighestSignedFloorNumber(building) === getSignedFloorNumber(floor);
    const isBottomFloor = getLowestSignedFloorNumber(building) === getSignedFloorNumber(floor);
    const isNotEG = getSignedFloorNumber(floor) !== 0;

    return isOnlyFloor || (isNotEG && (isTopFloor || isBottomFloor));
}
const tPrefix = 'editor:bkp-to-ebkph-wizard:volume-evaluation:model-creation-form:';

export const BuildingEdit: React.FC<BuildingEditProps> = ({
    className,
    building,
    isOpen,
    highlights,
    onOpenToggle,
    onBuildingUpdate,
    onModelItemEvent,
    onBuildingDelete,
    isAreaEditable,
    showRelativeHeight,
}) => {
    const [t, i18n] = useTranslation();
    const languageCode = codeOf(i18n.language);
    const handleOpenToggle = React.useCallback(() => onOpenToggle(building.id), [building.id, onOpenToggle]);

    const onFloorDeleted = React.useCallback(
        (floorId: string) => {
            onBuildingUpdate(deleteFloor(floorId, building));
        },
        [building, onBuildingUpdate]
    );

    const handleAddBottomFloor = useCallback(
        (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.preventDefault();
            onBuildingUpdate(createFloor(FLOOR_POSITION.BOTTOM, building));
        },
        [onBuildingUpdate, building]
    );
    const handleAddTopFloor = useCallback(
        (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.preventDefault();
            onBuildingUpdate(createFloor(FLOOR_POSITION.TOP, building));
        },
        [onBuildingUpdate, building]
    );

    const handleOpenFloorEditView = React.useMemo(
        () =>
            onModelItemEvent
                ? (floorId: string) => {
                      onModelItemEvent({
                          event: {
                              itemId: floorId,
                              itemType: 'floor',
                              eventType: 'select',
                          },
                          buildingId: building.id,
                      });
                  }
                : undefined,
        [onModelItemEvent, building]
    );
    const handleFloorHover = React.useCallback(
        (floorId) => {
            onModelItemEvent?.({
                event: {
                    itemId: floorId,
                    itemType: 'floor',
                    eventType: 'hover',
                },
                buildingId: building.id,
            });
        },
        [onModelItemEvent, building]
    );

    const handleFloorUpdate = React.useCallback(
        (newFloor) => {
            const newBuilding = {
                ...building,
                floors: building.floors.map((floor) => (floor.id === newFloor.id ? newFloor : floor)),
            };
            onBuildingUpdate(newBuilding);
        },
        [building, onBuildingUpdate]
    );

    const handleNameChange = React.useCallback(
        (newName: string) => {
            const newBuilding = {
                ...building,
                name: {
                    ...building.name,
                    [languageCode]: newName,
                },
            };
            onBuildingUpdate(newBuilding);
        },
        [languageCode, building, onBuildingUpdate]
    );

    const handleRelativeElevationChange = React.useCallback(
        (referenceHeight) => {
            const newBuilding = {
                ...building,
                referenceHeight: isNaN(parseFloat(referenceHeight)) ? 0 : referenceHeight,
            };
            onBuildingUpdate(newBuilding);
        },
        [building, onBuildingUpdate]
    );

    const handleDeleteClick = React.useCallback(() => {
        onBuildingDelete(building.id);
    }, [building.id, onBuildingDelete]);

    const isHoveringBuilding =
        highlights.filter((h) => h.itemType === 'building' && h.itemId === building.id).length > 0;
    const isHoveringFloor = (floorId: string) =>
        highlights.filter((h) => h.itemType === 'floor' && h.itemId === floorId).length > 0;

    return (
        <Table className="gutter-top w-100 building-edit" responsive={false}>
            <thead>
                <tr>
                    <th className="border-top-0" />
                    <th className="border-top-0 text-left">{t(`${tPrefix}name`)}</th>
                    <th className="border-top-0 text-center">{t(`${tPrefix}height`)}</th>
                    <th className="border-top-0 text-center">{t(`${tPrefix}area`)}</th>
                    <th className="border-top-0 text-right">{t(`${tPrefix}actions`)}</th>
                </tr>
            </thead>
            <tbody>
                <tr className={classNames(className, 'hoverable')} data-hover={isHoveringBuilding}>
                    <td onClick={handleOpenToggle} data-testid="building-toggle-open">
                        <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
                    </td>
                    <td colSpan={3} className="py-1">
                        <TextInput
                            wrapperClassName="mb-0 overflow-visible"
                            value={building.name[languageCode]}
                            onChange={handleNameChange}
                            lazy={true}
                            testId="building-name"
                        />
                    </td>

                    <td className="py-1 text-right">
                        <IconButton onClick={handleDeleteClick} testId="building-delete-building">
                            <FontAwesomeIcon icon={faTrash} />
                        </IconButton>
                    </td>
                </tr>
                {showRelativeHeight && (
                    <tr>
                        <td className="border-top-0" />
                        <td className="border-top-0"> {t(`${tPrefix}relative-elevation`)}</td>
                        <td className="py-1 border-top-0">
                            <NumberInput
                                wrapperClassName="mb-0 overflow-visible"
                                value={building.referenceHeight.toString()}
                                onChange={handleRelativeElevationChange}
                                decimals={2}
                                numberDefaultValue={0}
                                lazy={true}
                                testId="building-relative-height"
                                className="text-right"
                            />
                        </td>
                    </tr>
                )}

                {isOpen && (
                    <>
                        <tr>
                            <td colSpan={5} className="border-top-0">
                                {t(`${tPrefix}floors`)}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <IconButton onClick={handleAddTopFloor} testId="building-add-top-floor">
                                    <FontAwesomeIcon icon={faPlus} />
                                </IconButton>
                            </td>
                        </tr>
                        {building.floors.map((floor) => (
                            <FloorEdit
                                floor={floor}
                                isAreaEditable={isAreaEditable}
                                highlighted={isHoveringFloor(floor.id)}
                                onOpenFloorEditView={handleOpenFloorEditView}
                                onDelete={isFloorDeletable(floor.id, building) ? onFloorDeleted : undefined}
                                onFloorUpdate={handleFloorUpdate}
                                onHover={handleFloorHover}
                                key={floor.id}
                            />
                        ))}
                        {building.floors.length > 0 && (
                            <tr>
                                <td colSpan={5}>
                                    <IconButton onClick={handleAddBottomFloor} testId="building-add-bottom-floor">
                                        <FontAwesomeIcon icon={faPlus} />
                                    </IconButton>
                                </td>
                            </tr>
                        )}
                    </>
                )}
            </tbody>
        </Table>
    );
};
