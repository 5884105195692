import React, { useCallback, useEffect, useMemo } from 'react';
import { useLanguage } from '../../../../../effects';
import { NumberInput, Select, SelectOption, TextInput } from '../../../../FormComponents';
import { Col, Row } from 'reactstrap';

interface EnergyParameterProps<V extends ProjectEnergyParameter> {
    type: EnergyParameterType;
    value: V;
    onChange: (type: EnergyParameterType, update: (value: V) => ProjectEnergyParameter) => unknown;
}

export const EnergyParameter = ({
    type,
    value,
    onChange,
}: EnergyParameterProps<ProjectEnergyParameter>): React.ReactElement => {
    const [language] = useLanguage();

    const options: SelectOption<EnergyParameterTypeValue>[] = useMemo(() => {
        return type.values.map<SelectOption<EnergyParameterTypeValue>>((value) => ({
            label: value.value[language.codes.editor],
            value,
            key: value.id,
        }));
    }, [type, language]);

    const defaultValue = useMemo(() => type.values.find(({ default: isDefault }) => isDefault), [type]);
    useEffect(() => {
        if (type.type !== 'ENUMERATED') return;
        if (value.valueType !== 'ENUMERATED') return;
        if (!defaultValue) return;

        if (value.value.id === -1) onChange(type, (value) => ({ ...value, value: defaultValue }));
    }, [type, onChange, value, defaultValue]);

    const handleSelectionChange = useCallback(
        (value: EnergyParameterTypeValue) => {
            onChange(type, (param) => ({
                ...param,
                value,
            }));
        },
        [onChange, type]
    );

    const handleNumChange = useCallback(
        (value: string) => {
            onChange(type, (param) => ({
                ...param,
                num: parseFloat(value),
            }));
        },
        [onChange, type]
    );

    const handleTextChange = useCallback(
        (text: string) => {
            onChange(type, (param) => ({ ...param, text }));
        },
        [onChange, type]
    );

    const val = type.values.find(({ id }) => {
        if (!value) return false;
        if (value.valueType === 'ENUMERATED') return value.value.id === id;

        return false;
    });

    return (
        <Row className="align-items-center py-2 border-bottom" key={type.id}>
            <Col xs={6}>{type.name[language.codes.editor]}</Col>
            <Col>
                {isEnumParameter(value, type) && (
                    <Select
                        label={type.name[language.codes.editor]}
                        value={val}
                        options={options}
                        onChange={handleSelectionChange}
                        disableScroll={true}
                    />
                )}

                {isNumericParameter(value, type) && (
                    <NumberInput
                        label={type.name[language.codes.editor]}
                        value={value.num.toString()}
                        onChange={handleNumChange}
                        decimals={2}
                    />
                )}

                {isTextParameter(value, type) && (
                    <TextInput
                        label={type.name[language.codes.editor]}
                        value={value.text}
                        onChange={handleTextChange}
                    />
                )}
            </Col>
        </Row>
    );
};

const isEnumParameter = (
    value: ProjectEnergyParameter,
    type: EnergyParameterType
): value is ProjectEnergyParameterEnumerated => type.type === 'ENUMERATED' || value.valueType === 'ENUMERATED';

const isNumericParameter = (
    value: ProjectEnergyParameter,
    type: EnergyParameterType
): value is ProjectEnergyParameterNumeric => type.type === 'NUMERIC' || value.valueType === 'NUMERIC';

const isTextParameter = (
    value: ProjectEnergyParameter,
    type: EnergyParameterType
): value is ProjectEnergyParameterText => type.type === 'TEXT' || value.valueType === 'TEXT';
