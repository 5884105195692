import { noop } from 'lodash';
import React, { useCallback } from 'react';
import { BaseTextInput, DefaultTextInputProps, defaultTransform, useDefaultChangeAndBlur } from './BaseTextInput';

export interface DateInputProps extends DefaultTextInputProps {
    format?: string; // TODO
}

type Props = FormFieldComponentProps<string, DateInputProps>;

export const DateInput: React.FC<Props> = ({ value = '', type = 'date', lazy, onChange, onBlur = noop, ...props }) => {
    const { currentValue, handleBlur, setCurrentValue } = useDefaultChangeAndBlur(
        value,
        type,
        lazy,
        onChange,
        onBlur,
        value ?? ''
    );

    const handleChange = useCallback(
        ({ target }: React.ChangeEvent<HTMLInputElement>) => {
            const nValue = target.value.split('.').reverse().join('-');
            setCurrentValue(nValue);

            if (!lazy && isDateValid(nValue)) {
                onChange(defaultTransform(type, nValue));
            }

            return;
        },
        [lazy, onChange, type, setCurrentValue]
    );

    return (
        <BaseTextInput
            value={displayValue(type, currentValue)}
            onChange={handleChange}
            onBlur={handleBlur}
            type={type}
            placeholder="dd.MM.yyyy"
            max="9999-12-31"
            {...props}
        />
    );
};

function isSafari() {
    const userAgent = navigator.userAgent.toLowerCase();
    return userAgent.includes('safari') && !userAgent.includes('chrom');
}

function displayValue(type: Props['type'], value: string): string {
    if (isSafari()) {
        const dateParts = value.split('-');

        return dateParts.reverse().join('.');
    }

    return value;
}

// FORMAT: yyyy-MM-dd
const DATE_REGEX = /^\d{4,}-[01]\d-[0123]\d$/;

function isDateValid(date: string) {
    return DATE_REGEX.test(date) && !isNaN(new Date(date).getTime());
}
