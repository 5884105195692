import React, { useCallback } from 'react';
import { SelectOption } from './types';

interface OptionProps {
    option: SelectOption;
    className?: string;
    selected?: boolean;
    selectOption: (option: SelectOption) => void;
    disableScroll?: boolean;
}

export const Option: React.FC<OptionProps> = ({ option, selectOption, selected, disableScroll, className }) => {
    const optionRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (optionRef.current && option.focused && !disableScroll) {
            optionRef.current.scrollIntoView({
                behavior: 'auto',
                block: 'center',
            });
        }
    }, [option.focused, disableScroll]);

    React.useEffect(() => {
        if (optionRef.current && selected && !disableScroll) {
            optionRef.current.scrollIntoView({
                behavior: 'auto',
                block: 'center',
            });
        }
    }, [selected, disableScroll]);

    const handleClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            selectOption(option);
        },
        [selectOption, option]
    );

    return (
        <div
            className={`select-option px-3 ${className || ''}`}
            onClick={handleClick}
            data-selected={selected}
            data-disabled={option.disabled}
            data-focused={option.focused}
            data-testid="select-option"
            ref={optionRef}
        >
            {option.title && <div className="select__option-title">{option.title}</div>}
            <div className={option.title ? 'pt-4 pb-2' : 'py-3'}>{option.label}</div>
        </div>
    );
};
