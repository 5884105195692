import { useMemo } from 'react';
import { toString } from './utils';

export function useRegulationSelectorValue(
    regulation: ObjectReference<Regulation> | undefined | null,
    regulations: StaticRegulation[],
    language: string
): [StaticRegulation | null, number | null, string] {
    const valueId = regulation?.id ?? null;

    const regValue = useMemo(() => regulations.find(({ id }) => id === valueId), [valueId, regulations]);
    const valueName = toString(regValue, language);

    return [regValue ?? null, valueId, valueName];
}
