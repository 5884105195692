import { History, createHashHistory } from 'history';
import type { i18n as I18n } from 'i18next';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Router } from 'react-router-dom';
/**
 * This import adds the Tableau API to the window object.
 * The tableau api can then be accessed via window.tableau.
 */
import 'tableau-api';

import { config } from './config';
import { LanguageProvider } from './contexts';
import { RoutesEnum } from './enums/paths';
import { i18n } from './i18n';
import { ActionsEnum } from './license/actions';
import { AuthenticationService, CustomerCareService, ResourcesService, TableauService } from './services';
import type { UserInfo } from './types';

import { Application } from './Application';

import './styles';
import { ErrorBoundary } from './components';

async function prepareI18n(): Promise<I18n> {
    const language = localStorage.getItem('LANG') || config.languages.default;

    await i18n.changeLanguage(language);
    document.documentElement.lang = i18n.language;

    i18n.on('languageChanged', (lang) => {
        document.documentElement.lang = lang;
        localStorage.setItem('LANG', lang);
    });

    return i18n;
}

function setupTableauAccess(tableauService: TableauService, userInfo: UserInfo | null) {
    const hasTableauAccess = !!userInfo?.actions.has(ActionsEnum.ACCESS_TABLEAU);
    if (hasTableauAccess && userInfo?.token) tableauService.setToken(userInfo.token);
    if (hasTableauAccess && userInfo?.siteId) tableauService.setSiteId(userInfo.siteId);

    return hasTableauAccess;
}

function loginRedirect(history: History, hasTableauAccess: boolean) {
    if (!hasTableauAccess) return false;
    if (history.location.pathname !== '/') return false;

    const params = new URLSearchParams(window.location.search);
    window.location.search = '';
    const redirectPath = params.get('redirect') || RoutesEnum.MAIN;

    params.delete('redirect');
    history.push({ pathname: redirectPath, search: '' });

    return true;
}

prepareI18n().then(async (i18nInstance) => {
    const authService = new AuthenticationService();
    const resourcesService = new ResourcesService();
    const customerCareService = new CustomerCareService();

    const userInfo = await authService.init();

    const tableauService = new TableauService();

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                staleTime: Infinity,
                refetchOnWindowFocus: false,
            },
        },
    });

    const history = createHashHistory();

    const hasTableauAccess = setupTableauAccess(tableauService, userInfo);
    loginRedirect(history, hasTableauAccess);

    ReactDOM.render(
        <StrictMode>
            <QueryClientProvider client={queryClient}>
                <I18nextProvider i18n={i18nInstance}>
                    <LanguageProvider>
                        <Router history={history}>
                            <ErrorBoundary>
                                <Application
                                    resourcesService={resourcesService}
                                    authService={authService}
                                    tableauService={tableauService}
                                    customerCareService={customerCareService}
                                />
                            </ErrorBoundary>
                        </Router>
                    </LanguageProvider>
                </I18nextProvider>
            </QueryClientProvider>
        </StrictMode>,
        document.getElementById('root')
    );
});
