import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberInput } from '../FormComponents';
import { IconButton } from '../index';

interface FloorEditProps {
    className?: string;
    floor: Floor;
    highlighted: boolean;
    onOpenFloorEditView?: (floorId: string) => void;
    onDelete?: (floorId: string) => void;
    onHover: (floorId: string) => void;
    onFloorUpdate?: (newFloor: Floor) => void;
    defaultHeight?: number;
    isAreaEditable?: boolean;
}

export const FloorEdit: React.FC<FloorEditProps> = ({
    className,
    floor,
    highlighted,
    onOpenFloorEditView,
    onDelete,
    onHover,
    onFloorUpdate,
    defaultHeight = 3,
    isAreaEditable = true,
}) => {
    const handleDelete = useCallback(() => onDelete?.(floor.id), [floor.id, onDelete]);
    const handleAction = useCallback(() => onOpenFloorEditView?.(floor.id), [floor.id, onOpenFloorEditView]);
    const handleHover = useCallback(() => onHover(floor.id), [floor.id, onHover]);
    const handleHeightChange = useCallback(
        (heightString: string) => {
            const height = parseFloat(heightString);
            onFloorUpdate?.({ ...floor, height: isNaN(height) || height <= 0 ? defaultHeight : height });
        },
        [floor, onFloorUpdate, defaultHeight]
    );
    const handleAreaChange = useCallback(
        (AreaString: string) => {
            const area = parseFloat(AreaString);
            onFloorUpdate?.({ ...floor, area: isNaN(area) || area <= 0 ? 0 : area });
        },
        [onFloorUpdate, floor]
    );
    const [t] = useTranslation();
    const tt = (key: string) => t(`editor:project:floor:floorTypes:${key}`);

    return (
        <tr
            className={classNames('hoverable floor-edit', className)}
            onMouseEnter={handleHover}
            data-hover={highlighted}
            data-testid="floor-entry-wrapper"
        >
            <td />
            <td>
                {floor.floorNumber ? `${floor.floorNumber} ` : ''}
                {tt(floor.floorType)}
            </td>
            <td className="py-1">
                <NumberInput
                    wrapperClassName="mb-0 overflow-visible"
                    value={`${floor.height ?? defaultHeight}`}
                    numberDefaultValue={defaultHeight}
                    onChange={handleHeightChange}
                    decimals={2}
                    lazy={true}
                    className="text-right"
                    testId="floor-height"
                />
            </td>
            <td className="py-1 text-center" data-testid="floor-area-wrapper">
                {isAreaEditable && (
                    <NumberInput
                        wrapperClassName="mb-0 overflow-visible"
                        value={floor.area == null || floor.area < 0 ? '' : floor.area.toFixed(2)}
                        onChange={handleAreaChange}
                        decimals={2}
                        lazy={true}
                        testId="floor-area-field"
                        className="text-right"
                    />
                )}
                {!isAreaEditable && (floor.area == null || floor.area < 0 ? '' : floor.area.toFixed(2))}
            </td>
            <td className="py-1 text-right">
                {onOpenFloorEditView && (
                    <IconButton className="mr-2" onClick={handleAction} testId="floor-open-edit-view">
                        <FontAwesomeIcon icon={faEdit} />
                    </IconButton>
                )}

                {onDelete && (
                    <IconButton onClick={handleDelete} testId="floor-delete">
                        <FontAwesomeIcon icon={faTrash} />
                    </IconButton>
                )}
            </td>
        </tr>
    );
};
