import type { AxiosError } from 'axios';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { editorService } from '../../services';
import type { WizardState } from '../../types';

export function useWizardStateSave(id: ID): UseMutationResult<ID, AxiosError, WizardState> {
    const queryClient = useQueryClient();

    return useMutation((wizardState: WizardState) => editorService.updateWizardState(id, wizardState), {
        onMutate: async (wizardState) => {
            await queryClient.cancelQueries(['wizard-state', id]);

            const oldValue = queryClient.getQueryData(['wizard-state', id]);

            queryClient.setQueryData(['wizard-state', id], wizardState);

            return oldValue;
        },
        onError: (_error, _ws, ctx) => void queryClient.setQueryData(['wizard-state', id], ctx),
        onSettled: () => void queryClient.invalidateQueries(['wizard-state', id]),
    });
}
