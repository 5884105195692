import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'i18next';
import React, { FC, useCallback, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { ProjectTag } from './ProjectTag';
import { TagEditor } from '../../../../../TagEditor';
import { TextButton } from '../../../../../Buttons';
import { useConfirmDialog } from '../../../../../Dialog';
import {
    useProjectTagCreate,
    useProjectTagDelete,
    useProjectTagReplace,
    useProjectTagUpdate,
    useProjectTags,
} from '../../../../../../effects';

interface Props {
    project: Project;
    language: EditorLanguage;
    changeProject: (update: (oldProject: Project) => Project) => unknown;
    t: TFunction;
}

export const ProjectTags: FC<Props> = ({ project, language, changeProject, t }) => {
    const [state, setState] = useState({ editorOpen: false });

    const { data: tags } = useProjectTags();

    const { mutateAsync: createTag } = useProjectTagCreate();
    const { mutateAsync: updateTag } = useProjectTagUpdate();
    const { mutateAsync: deleteTag } = useProjectTagDelete();
    const { mutateAsync: replaceTag } = useProjectTagReplace();

    const openDeletionConfirmDialog = useConfirmDialog({
        title: t('editor:project:parameters:projectTags:confirmDelete.title'),
        description: t('editor:project:parameters:projectTags:confirmDelete.description'),
        confirm: t('editor:project:parameters:projectTags:confirmDelete.confirm'),
        cancel: t('editor:project:parameters:projectTags:confirmDelete.cancel'),
    });

    const openReplacementConfirmDialog = useConfirmDialog({
        title: t('editor:project:parameters:projectTags:replaceDelete.title'),
        description: t('editor:project:parameters:projectTags:replaceDelete.description'),
        confirm: t('editor:project:parameters:projectTags:replaceDelete.confirm'),
        cancel: t('editor:project:parameters:projectTags:replaceDelete.cancel'),
    });

    const { tags: projectTags = [] } = project;
    const selectedTagIds = projectTags.map(({ id }) => id).filter((id): id is number => typeof id === 'number');

    const changeProjectTag = useCallback(
        (tag, index) => {
            changeProject(({ tags = [], ...p }) => ({
                ...p,
                tags: tags.map((t, i) => {
                    if (i === index) return tag;

                    return t;
                }),
            }));
        },
        [changeProject]
    );

    const deleteProjectTag = useCallback(
        (index) => {
            changeProject(({ tags = [], ...p }) => ({
                ...p,
                tags: tags.filter((_, i) => i !== index),
            }));
        },
        [changeProject]
    );

    const addProjectTag = useCallback(() => {
        changeProject((project) => ({
            ...project,
            tags: [...(project.tags ?? []), { id: -1, name: {} }],
        }));
    }, [changeProject]);

    const openTagEditor = useCallback(() => setState((s) => ({ ...s, editorOpen: true })), []);
    const closeTagEditor = useCallback(() => setState((s) => ({ ...s, editorOpen: false })), []);

    const handleTagCreate = useCallback(
        async (tag: TagCreate) => {
            await createTag(tag);
        },
        [createTag]
    );

    const handleTagEdit = useCallback(
        async (tag: Tag) => {
            if (tag.id && tag.id > 0) await updateTag([tag.id, tag]);
        },
        [updateTag]
    );

    const handleTagDelete = useCallback(
        async (tag: Tag) => {
            if (await openDeletionConfirmDialog()) {
                if (tag.id && tag.id > 0) await deleteTag(tag.id);
            }
        },
        [openDeletionConfirmDialog, deleteTag]
    );

    const handleTagReplace = useCallback(
        async (tag: Tag, replacement: Tag) => {
            if (await openReplacementConfirmDialog()) {
                if (tag.id && tag.id > 0 && replacement.id && replacement.id > 0)
                    await replaceTag([tag.id, replacement.id]);
            }
        },
        [openReplacementConfirmDialog, replaceTag]
    );

    return (
        <Row xs={12} className="small-push-bottom">
            <Col>
                <div className="d-flex justify-content-between mb-3">
                    <h3>{t('editor:project:parameters:projectTags:title')}</h3>
                    <TextButton name={t('editor:project:parameters:projectTags:showTags')} onClick={openTagEditor} />
                </div>
                {projectTags.map((tag, i) => (
                    <ProjectTag
                        tag={tag}
                        language={language}
                        selectedTagIds={selectedTagIds}
                        onChange={changeProjectTag}
                        onDelete={deleteProjectTag}
                        index={i}
                        t={t}
                        key={`${!tag.id || tag.id === -1 ? -i : tag.id}`}
                    />
                ))}
                <div className="mt-3 project-editor__action" onClick={addProjectTag} data-testid="add-project-tag">
                    {`${t('editor:project:parameters:projectTags:addProjectTag')} `}
                    <FontAwesomeIcon icon={faPlus} />
                </div>
            </Col>

            {state.editorOpen && tags && (
                <TagEditor
                    title={t('editor:project:parameters:projectTags:tagEditor:title')}
                    tags={tags}
                    language={language}
                    onCreate={handleTagCreate}
                    onEdit={handleTagEdit}
                    onDelete={handleTagDelete}
                    onReplace={handleTagReplace}
                    onClose={closeTagEditor}
                    t={t}
                />
            )}
        </Row>
    );
};
