import React from 'react';
import { useStaticData, useProjects, useProjectId, useProject } from '../effects';
import { addIdToBuildingsAndFloors } from '../utils';

interface Context {
    currentProject?: Project;
    staticData?: StaticData;
}

const EditorContext = React.createContext<Context | null>(null);

export type EditorContextType = Context;

export const EditorProvider: React.FC = ({ children }) => {
    const projectId = useProjectId();

    const { data: currentProject } = useProject(projectId, { select: addIdToBuildingsAndFloors });
    const { data: projectOverview } = useProjects();
    const { data: staticData } = useStaticData();

    const value = React.useMemo(
        () => ({
            currentProject,
            projects: projectOverview,
            staticData,
        }),
        [currentProject, projectOverview, staticData]
    );

    return <EditorContext.Provider value={value}>{children}</EditorContext.Provider>;
};

export function useEditor(): Context {
    const context = React.useContext(EditorContext);

    if (context) {
        return context;
    }

    throw new Error(
        `"${useEditor.name}" can only be used inside a functional component ` +
            `that is a child of the ${EditorProvider.name}`
    );
}
