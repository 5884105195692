import { TFunction } from 'i18next';
import React from 'react';
import { Col, Row } from 'reactstrap';

interface Props {
    media: ProjectMedia;
    selected?: boolean;
    isPreview?: boolean;
    t: TFunction;
}

export const DraggableMediaEntry: React.FC<Props> = ({ media, selected, isPreview, t }) => {
    return (
        <Col xs={12} className="media-item border-bottom py-3" data-selected={selected}>
            <Row>
                <Col xs={isPreview ? 1 : 4} className="media-number">
                    {media.order + 1}
                </Col>
                {isPreview && (
                    <Col xs={3} className="media-preview-label">
                        {t('editor:projects:creator:media-sheet:main-image')}
                    </Col>
                )}
                <Col className="media-name" data-testid="media-name">
                    {media.name}
                </Col>
            </Row>
        </Col>
    );
};
