import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'i18next';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { SearchSelect, TextInput } from '../../../../../FormComponents';

interface Props {
    member: ProjectMember;
    role?: ProjectMemberRole;
    initialRole?: ProjectMemberRole;
    roles: ProjectMemberRole[];
    language: EditorLanguage;
    index: number;
    onChange: (member: ProjectMember, index: number) => void;
    onDelete: (index: number) => void;
    t: TFunction;
}

export const Member: React.FC<Props> = ({
    member,
    role,
    initialRole,
    roles,
    language,
    onDelete,
    t,
    onChange: changeMember,
    index,
}) => {
    const changeRole = (roleId: ID) => changeMember({ ...member, role: { id: roleId } }, index);
    const changeName = (name: string) => changeMember({ ...member, name }, index);
    const raiseDelete = () => {
        onDelete(index);
    };

    const handleBlur = () => {
        if (!role && initialRole) {
            changeRole(initialRole.id);
        }
    };

    return (
        <Row className="data-view__member border-bottom py-0">
            <Col className="text-bold" xs={4} lg={4}>
                <SearchSelect
                    label={t('editor:project:parameters:participants:role')}
                    value={role?.id}
                    onChange={changeRole}
                    options={roles
                        .map(({ id, name }) => ({
                            label: name[language.codes.editor] || name.de_DE || Object.values(name || {})[0],
                            value: id,
                            search: [name[language.codes.editor] || name.de_DE || Object.values(name || {})[0]],
                        }))
                        .sort(({ label: label1 }, { label: label2 }) => label1.localeCompare(label2))}
                    onBlur={handleBlur}
                    autoFocus={!role?.id}
                    testId="participant-role"
                />
            </Col>
            <Col className="pt-1" xs={5} lg={6}>
                <TextInput
                    label={t('editor:project:parameters:participants:name')}
                    value={member.name}
                    onChange={changeName}
                    required={true}
                    testId="participant-name"
                />
            </Col>
            <Col
                xs={3}
                lg={2}
                className="project-editor__action"
                onClick={raiseDelete}
                data-testid="delete-participant"
            >
                {`${t('editor:project:parameters:participants:delete-participant')} `}
                <FontAwesomeIcon icon={faTimes} />
            </Col>
        </Row>
    );
};
