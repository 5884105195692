import { History } from 'history';
import { useMemo } from 'react';

export function redirectToExternal(history: History, to: URL, inNewTab = false, params: URLSearchParams | null = null) {
    return (): (({ requestedPath }: { requestedPath: string }) => void) => {
        if (typeof window === 'undefined') {
            return redirectWith(history)(to.toString());
        }

        if (params != null) {
            for (const [key, value] of params.entries()) {
                to.searchParams.set(key, value);
            }
        }

        if (inNewTab) {
            window.open(to.toString(), '_blank')?.focus();
        } else {
            window.location.href = to.toString();
        }
        return redirectWith(history)(to.toString());
    };
}

function redirectWith(history: History) {
    return (pathname: string) =>
        ({ requestedPath }: { requestedPath: string }) => {
            const params = new URLSearchParams(history.location.search);
            params.set('redirect', requestedPath);

            history.replace({
                search: params.toString(),
                pathname,
            });
        };
}

export function useRedirectUrl(url: string, languageCode: string): URL {
    return useMemo(() => {
        const idpUrl = new URL(url, document.URL);
        idpUrl.searchParams.set('language', languageCode);

        return idpUrl;
    }, [url, languageCode]);
}
