import React from 'react';
import { useEvent } from '../../effects';
import { createDebounce } from '../../utils';
import { Spinner } from '../Spinner';

type Source = string | CanvasImageSource;

interface Props {
    src: string;
    width?: number;
    height?: number;
    grayscale?: boolean;
}

const createImage = (src: string) =>
    new Promise<HTMLImageElement>((resolve, reject) => {
        const img = document.createElement('img');

        img.setAttribute('crossorigin', 'anonymous'); // Temp, TODO: Remove

        img.src = src;

        img.addEventListener('load', () => resolve(img));
        img.addEventListener('error', reject);
    });

const drawImage = async (canvas: HTMLCanvasElement | null, source: Source, options: { center: boolean }) => {
    let image: CanvasImageSource;

    if (typeof source === 'string') {
        image = await createImage(source);
    } else {
        image = source;
    }

    const imgWidth = typeof image.width === 'number' ? image.width : image.width.baseVal.value;
    const imgHeight = typeof image.height === 'number' ? image.height : image.height.baseVal.value;

    draw(canvas, async (_canvas, ctx) => {
        if (options.center) {
            ctx.save();

            ctx.translate(_canvas.width / 2, _canvas.height / 2);

            const minScale = { x: 1, y: 1 };

            minScale.x = _canvas.width / imgWidth;
            minScale.y = _canvas.height / imgHeight;

            ctx.scale(Math.min(minScale.x, minScale.y), Math.min(minScale.x, minScale.y));

            ctx.translate(imgWidth / -2, imgHeight / -2);
        }

        ctx.clearRect(0, 0, imgWidth, imgHeight);
        ctx.drawImage(image, 0, 0);

        if (options.center) {
            ctx.restore();
        }
    });
};

const draw = (
    canvas: HTMLCanvasElement | null,
    callback: (canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D) => void
) => {
    if (!canvas) throw new Error('HTMLCanvasElement missing');

    const ctx = canvas && canvas.getContext('2d');

    if (!ctx) throw new Error('2D context unavailable!');

    callback(canvas, ctx);
};

interface ImageState {
    src: string | null;
    loaded: boolean;
    error?: boolean;
}

export const ImageCanvas: React.FC<Props> = ({ src, width, height }) => {
    const [image, setImage] = React.useState<ImageState>({
        src: null,
        loaded: true,
    });
    const ref = React.useRef<HTMLCanvasElement>(null);

    useEvent(
        window,
        'resize',
        createDebounce(() => {
            if (src) drawCycle(src);
        }, 250),
        []
    );

    const drawCycle = (source: string) => {
        setImage((i) => ({ ...i, loaded: false, error: false }));

        return new Promise((resolve) =>
            draw(ref.current, (canvas, ctx) => {
                ctx.clearRect(0, 0, canvas.width, canvas.height);

                resolve(drawImage(canvas, source, { center: true }));
            })
        )
            .then(() => setImage((i) => ({ ...i, src: source, loaded: true })))
            .catch(() => setImage((i) => ({ ...i, src: source, error: true })));
    };

    React.useEffect(() => {
        if (image.loaded) {
            setImage((i) => ({ ...i, src }));
        }

        if (ref.current && (image.loaded || image.error) && image.src !== src) {
            drawCycle(src);
        }
    }, [src, image.src, image.loaded, image.error]);

    return (
        <div className="image-canvas">
            <canvas ref={ref} width={width} height={height} />

            {!image.error && !image.loaded && image.src && (
                <div className="spinner-container">
                    <Spinner size={48} />
                </div>
            )}

            {image.error && <div className="image-canvas-error">Unable to load image</div>}
        </div>
    );
};
