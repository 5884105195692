import type { AxiosError } from 'axios';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { editorService } from '../../services';

export function useProject(
    id: ID,
    options?: UseQueryOptions<Project, AxiosError, Project, ['project', ID]>
): UseQueryResult<Project> {
    return useQuery(['project', id], () => editorService.getProject(id), options);
}
