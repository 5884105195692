import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'i18next';
import React, { useCallback, useMemo } from 'react';
import { Col, Row } from 'reactstrap';
import { useStaticRegulations } from '../../../../../../effects';
import { NumberInput, RegulationSelector, TagSelector } from '../../../../../FormComponents';
import { orderBy } from 'lodash';

interface Props {
    usage: FloorUsage;
    language: EditorLanguage;
    tags: FloorUsageTag[];
    onChange: (usage: FloorUsage, index: number) => void;
    onDelete: (index: number) => void;
    t: TFunction;
    index: number;
}

export const FloorUsageRow: React.FC<Props> = ({ usage, index, tags, onChange, onDelete, language, t }) => {
    let regulations = useStaticRegulations('SIA416');
    regulations = useMemo(() => {
        const ordered = orderBy(regulations, ({ code }) => code.split(':')[0]);

        return [
            ...ordered.filter((reg) => reg.code !== '1' && !reg.code.startsWith('1.')),
            ...ordered.filter((reg) => reg.code === '1' || reg.code.startsWith('1.')),
        ];
    }, [regulations]);
    const lang = language.codes.editor;

    const defaultRegulation = useMemo(
        () => regulations.find(({ shortName }) => shortName.de_DE === 'NGF'),
        [regulations]
    );

    const handleTagChange = useCallback(
        (tag: FloorUsageTag) => {
            onChange({ ...usage, tag }, index);
        },
        [onChange, usage, index]
    );

    const handleRegulationChange = useCallback(
        (regulation: StaticRegulation) => {
            onChange({ ...usage, regulation }, index);
        },
        [onChange, usage, index]
    );

    const handleAreaChange = useCallback(
        (value: string) => {
            const area = parseFloat(value);
            onChange({ ...usage, area }, index);
        },
        [onChange, usage, index]
    );

    const handleDelete = useCallback(() => onDelete(index), [onDelete, index]);

    const { tag } = usage;

    return (
        <Row className="data-view__project-tag border-bottom py-1">
            <Col className="pt-1">
                <TagSelector
                    label={t('editor:project:parameters:floorUsages:tagName')}
                    tag={tag}
                    tags={tags}
                    language={lang}
                    onChange={handleTagChange}
                    autoFocus={true}
                    t={t}
                    testId="floor-usage-tag-name"
                />
            </Col>
            <Col>
                <RegulationSelector
                    regulation={usage.regulation}
                    regulations={regulations}
                    onChange={handleRegulationChange}
                    label={t('editor:project:parameters:floorUsages:regulation')}
                    language={lang}
                    defaultRegulation={defaultRegulation}
                />
            </Col>
            <Col>
                <NumberInput
                    label={t('editor:project:parameters:floorUsages:area')}
                    value={`${usage.area}`}
                    onChange={handleAreaChange}
                />
            </Col>
            <Col
                xs={3}
                lg={2}
                className="project-editor__action"
                onClick={handleDelete}
                data-testid="delete-floor-usage"
            >
                {`${t('editor:project:parameters:floorUsages:deleteFloorUsage')} `}
                <FontAwesomeIcon icon={faTimes} />
            </Col>
        </Row>
    );
};
