import React, { Fragment, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogFormProps, OpenFormDialog, UUIDInput, useFormDialog } from '../../components';
import { useProjectTransfer } from '../../effects';

export interface ProjectTransferFormProps {
    receiverId: string;
}

export const ProjectTransferForm: React.FC<DialogFormProps<ProjectOverview[], ProjectTransferFormProps>> = ({
    data,
    onData,
    onValid,
}) => {
    const [t] = useTranslation();

    const [uuid, setUUID] = useState({
        value: '',
        valid: false,
    });

    const handleChange = useCallback(
        (value: string, valid: boolean) => {
            setUUID({ value, valid });
            onValid(valid);
            onData({ receiverId: value });
        },
        [onValid, onData]
    );

    return (
        <section>
            <p>
                {t('project:transfer:content', { count: data.length })
                    .split('\n')
                    .map((part, i) => (
                        <Fragment key={i}>
                            {part}
                            <br />
                        </Fragment>
                    ))}
            </p>
            <UUIDInput
                label={t('project:transfer:receiverId')}
                value={uuid.value}
                onChange={handleChange}
                autoFocus={true}
                error={!uuid.valid ? 'Invalid UUID' : undefined}
                required={true}
            />
        </section>
    );
};

export function useProjectTransferDialog(): OpenFormDialog<ProjectOverview[], void> {
    const [t] = useTranslation();
    const { mutateAsync } = useProjectTransfer();

    const openFormDialog = useFormDialog({
        title: t('project:transfer:dialog-title'),
        form: ProjectTransferForm,
        cancel: t('project:transfer:cancel'),
        confirm: t('project:transfer:confirm'),
        closeOnOverlayClick: false,
    });

    return useCallback(
        async (projects: ProjectOverview[]) => {
            const data = await openFormDialog(projects, {
                title: t('project:transfer:dialog-title', { count: projects.length }),
            });

            const projectIds = projects.map(({ id }) => id);
            if (data) {
                await mutateAsync({ ...data, projectIds });
            }
        },
        [openFormDialog, mutateAsync, t]
    );
}
