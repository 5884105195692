import React, { FC, DragEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Col } from 'reactstrap';
import { TextButton } from '../../../Buttons';
import { UploaderComponentProps } from '../../../FileUploader';
import { TextInput } from '../../../FormComponents';
import { ImagePreview } from '../../../ImagePreview';
import { Spinner } from '../../../Spinner';

export interface MediaProps extends UploaderComponentProps {
    media: ProjectMedia;
    project: Project;
    uploading: boolean;
    canUpload: boolean;
    uploadError: string;
    onNameChange: (name: string) => void;
    onDelete: () => void;
    onSave: () => void;
}

const preventDefault = (e: DragEvent) => {
    e.preventDefault();
};

export const Media: FC<MediaProps> = ({
    project,
    media,
    uploading,
    canUpload,
    uploadError,
    onNameChange: changeName,
    uploadFile,
    openFileDialog,
    onDelete: deleteMedia,
    onSave,
}) => {
    const { t } = useTranslation();

    const hasMedia = media !== undefined && media !== null;

    const handleDrop = useCallback(
        (event: DragEvent<HTMLDivElement>) => {
            event.preventDefault();
            if (media === undefined || media === null) {
                const files = [...event.dataTransfer.files];
                uploadFile(files);
            }
        },
        [media, uploadFile]
    );

    return (
        <Col xs={12} md={6} onDragOver={preventDefault} onDrop={handleDrop}>
            <div className="text-right position-sticky" style={{ top: '25%' }}>
                {!hasMedia && canUpload && (
                    <div className="upload-area p-3 text-center" onClick={openFileDialog}>
                        {!uploading && (
                            <>
                                <div>
                                    <b>{t('editor:projects:creator:media-sheet:click-here')}</b>
                                </div>
                                <div>
                                    <small>{t('editor:projects:creator:media-sheet:or')}</small>
                                </div>
                                <div>
                                    <div>
                                        <b>{t('editor:projects:creator:media-sheet:drag-and-drop')}</b>
                                    </div>
                                    {t('editor:projects:creator:media-sheet:to-upload')}
                                </div>

                                <div className="image-upload__constraints">
                                    <small>
                                        {t('editor:projects:creator:media-sheet:image-constraint:accepted-types')}
                                        <br />
                                        {t('editor:projects:creator:media-sheet:image-constraint:max-file-size')}
                                        <br />
                                        {t('editor:projects:creator:media-sheet:image-constraint:resize-note')}
                                    </small>
                                </div>

                                <div className="upload-area__error" data-testid="upload-error">
                                    {uploadError}
                                </div>
                            </>
                        )}
                        {uploading && <Spinner size={64} color="#000" />}
                    </div>
                )}

                {!hasMedia && !canUpload && (
                    <div className="upload-area">
                        <div className="p-3 text-center">{t('editor:projects:creator:media-sheet:limit-reached')}</div>
                    </div>
                )}

                {hasMedia && (
                    <>
                        <TextButton
                            className="mt-3 w-xs-100 w-md-auto"
                            name={t('editor:projects:creator:media-sheet:upload')}
                            onClick={openFileDialog}
                            testId="open-upload-dialog"
                        />

                        <ImagePreview
                            className="mt-3"
                            name={media.name}
                            src={`/api/project-media/${project.id}/${media.id}`}
                            aspectRatio={3 / 2}
                        />

                        <div className="mt-3">
                            <TextInput
                                label={t('editor:projects:creator:media-sheet:filename')}
                                value={media.name}
                                onChange={changeName}
                                lazy={true}
                                testId="file-name-input"
                            />
                        </div>

                        <div className="d-flex justify-content-end mt-3">
                            <TextButton
                                name={t('editor:projects:creator:media-sheet:delete-image')}
                                onClick={deleteMedia}
                                testId="delete-image"
                            />

                            <TextButton
                                className="ml-3"
                                name={t('editor:projects:creator:media-sheet:update-image-name')}
                                onClick={onSave}
                                testId="update-image-name"
                            />
                        </div>
                    </>
                )}
            </div>
        </Col>
    );
};
