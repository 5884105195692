import React from 'react';
import { Col, Row } from 'reactstrap';
import { nonNumberChars } from '../../../../utils';
import { NumberInput } from '../../../FormComponents';

interface EnergyProps {
    energy?: ProjectBuildingEnergy;
    unit: EnergyUnit;
    changeEnergy: (energy: ProjectBuildingEnergy) => void;
    language: EditorLanguage;
}

export const Energy: React.FC<EnergyProps> = ({ energy, unit, language, changeEnergy }) => {
    const changeValue = (value?: string) =>
        changeEnergy({
            energyUnit: unit,
            value: value ? Math.min(Number.MAX_VALUE, parseFloat(`${value.replace(nonNumberChars, '')}`)) : 0,
        });

    return (
        <Row className="border-bottom" data-testid="energy-row">
            <Col xs={8} className="px-0 pr-1 d-flex align-items-center">
                {unit.name[language.codes.editor] || Object.values(unit.name)[0]}
            </Col>
            <Col xs={4} className="px-0 pr-1 mt-1">
                <NumberInput label="Value" value={`${energy?.value || ''}`} onChange={changeValue} decimals={2} />
            </Col>
        </Row>
    );
};
