import { Dictionary } from 'lodash';
import { some, Tree } from '../../effects';
import { IProjectFilterData } from './ProjectFilterMenu';

export function createProjectFilter(
    state: IProjectFilterData,
    buildingClassificationTreeMap: Dictionary<Tree<StaticBuildingClassification>>
): (project: ProjectOverview) => boolean {
    // Get the category for the current filter
    const category = state.category ? buildingClassificationTreeMap[state.category] : undefined;

    return (project: ProjectOverview) => {
        const matchesName =
            !state.name?.trim() ||
            Object.values(project.name).some((name) => name.toLowerCase().includes(state.name.toLowerCase()));

        if (!matchesName) {
            return false;
        }

        const matchesSource =
            !state.source.trim() || project.source?.source?.toLowerCase()?.includes(state.source.toLowerCase());

        if (!matchesSource) {
            return false;
        }

        const matchesCategory = !category || some(category, (c) => c.id === project.buildingClassPartners?.id);

        if (!matchesCategory) {
            return false;
        }

        const matchesCanton = !state.canton || project.cantonCode === state.canton;

        if (!matchesCanton) {
            return false;
        }

        const year = new Date(project.moveIn).getFullYear();
        const matchesYear = state.yearMin <= year && state.yearMax >= year;

        if (!matchesYear) {
            return false;
        }

        const matchesRegulation = Object.entries(state.regulations).every(
            ([name, required]) => !required || project.arithmeticalFlags?.[name]
        );

        if (!matchesRegulation) {
            return false;
        }

        return true;
    };
}
