import { TFunction } from 'i18next';
import React, { useCallback } from 'react';
import { Col, Row } from 'reactstrap';
import { ActionsEnum } from '../../../../../../license/actions';
import type { UserInfo } from '../../../../../../types';
import { override, valueFromPath } from '../../../../../../utils';
import { SearchSelectOption, SelectOption } from '../../../../../FormComponents';
import { Common } from './Common';
import { FieldOption, ParametersFieldOptions } from './types';

function extractLeaves(
    array: StaticBuildingClassification[]
): [
    Array<StaticBuildingClassification & { children: number }>,
    { [key: number]: StaticBuildingClassification & { children: number } }
] {
    const treeMap: {
        [key: number]: StaticBuildingClassification & { children: number };
    } = {};
    array.forEach((p) => {
        const old = treeMap[p.id];

        treeMap[p.id] = {
            ...p,
            children: old && old.children ? old.children + 1 : 0,
        };

        if (p.parent) {
            const parent = treeMap[p.parent.id];

            if (!parent) {
                treeMap[p.parent.id] = {
                    id: p.parent.id,
                    parent: null,
                    classificationCode: '',
                    description: {},
                    children: 1,
                };
            } else {
                parent.children += 1;
            }
        }
    });
    const leaves = Object.values(treeMap)
        .filter((partner) => partner.children === 0)
        .sort((a, b) => {
            if (a.classificationCode > b.classificationCode) {
                return 1;
            }

            return -1;
        });

    return [leaves, treeMap];
}

function compareAlphabetically({ label: aa }: { label: string }, { label: bb }: { label: string }) {
    const a = aa.toLowerCase();
    const b = bb.toLowerCase();

    if (a > b) {
        return 1;
    }
    if (a < b) {
        return -1;
    }
    return 0;
}

export const getBuildingClassOptions = (
    buildingClassificationSubset: StaticBuildingClassification[],
    languageCode: string
): SearchSelectOption[] => {
    const [opts, optsMap] = extractLeaves(buildingClassificationSubset);

    return opts.map((p) => {
        const parent = p.parent?.id ? optsMap[p.parent.id] : p;

        return {
            value: p.id,
            label: `${p.classificationCode} ${p.description[languageCode]}`,
            title: `
                ${parent.classificationCode}
                ${parent.description[languageCode] || parent.description.de_DE}
            `,
            search: [parent.classificationCode, parent.description[languageCode] || parent.description.de_DE],
        };
    });
};

export const getMunicipalityOptions = (locations: StaticLocation[]): SearchSelectOption[] => {
    return locations
        .map((location) => {
            return {
                value: location.id,
                label: `${location.zipCode} ${location.municipality}`,
            };
        })
        .sort(compareAlphabetically);
};

interface Props {
    projects: ProjectBase[];
    project: Project;
    language: EditorLanguage;
    staticData: StaticData;
    user: UserInfo;
    changeProject: (update: (project: Project) => Project) => unknown;
    t: TFunction;
}

const parameterValue = (proj: Project, id: number) => proj.parameters?.find((p) => p.typeId === id)?.valueId;

export const Commons: React.FC<Props> = (props) => {
    const { project, changeProject, t, language, staticData, user } = props;

    const handleParameterChange = useCallback(
        (typeId: number, valueId: number) => {
            changeProject((p) => {
                const parameters = p.parameters ?? [];
                const par = parameters.find((par) => par.typeId === typeId);
                const changed = par?.valueId !== valueId;

                if (!changed) return p;

                if (!par) {
                    return {
                        ...p,
                        parameters: [...parameters, { typeId, valueId }],
                    };
                }

                return {
                    ...p,
                    parameters: parameters.map((par) => {
                        if (par.typeId !== typeId) return par;

                        return { typeId, valueId: valueId };
                    }),
                };
            });
        },
        [changeProject]
    );

    const handleLocationChange = useCallback(
        (_p: string, id: ID) => {
            const location = staticData.locations.find((loc) => loc.id === id);

            if (location == null) {
                console.error('Error: Location not found. This indicates a bug in the code.');
                return;
            }

            changeProject((p) => ({
                ...p,
                projectAddress: {
                    ...(p.projectAddress || {}),
                    location: {
                        ...(p.projectAddress?.location || {}),
                        id,
                        zipCode: location.zipCode,
                        municipality: location.municipality,
                        country: location.country || p.projectAddress?.location?.country,
                        canton: location.canton || p.projectAddress?.location?.canton,
                    },
                },
                greaterRegion: location.canton?.greaterRegion || p.greaterRegion,
            }));
        },
        [changeProject, staticData]
    );

    const fieldsLeftCol = React.useMemo<Array<[string, FieldOption]>>(() => {
        return [
            ['id', { input: 'text', type: 'text', disabled: true, testId: 'project-id' }],
            ['name', { input: 'text', type: 'text', multiLanguage: true, testId: 'project-name' }],
            [
                'creator',
                {
                    input: 'text',
                    type: 'text',
                    disabled: true,
                    testId: 'project-creator',
                },
            ],
            ['owner', { input: 'text', type: 'text' }],
            ...(user.actions.has(ActionsEnum.APPROVE_PROJECT)
                ? [
                      [
                          'adminStatus',
                          {
                              input: 'select',
                              options: ['Approved', 'Not approved'].map((value) => ({ label: value, value })),
                              default: 'Not approved',
                          },
                      ] as [string, FieldOption],
                  ]
                : []),
            ['constructionTime.moveIn', { input: 'text', type: 'date' }],
            ['constructionTime.constructionStart', { input: 'text', type: 'date' }],
            ['constructionTime.planningStart', { input: 'text', type: 'date' }],
            ['constructionTime.competition', { input: 'text', type: 'date' }],
            ['constructionTime.duration', { input: 'text', type: 'number', testId: 'construction-duration' }],
        ];
    }, [user.actions]);

    const fieldsRightColP1 = React.useMemo<Array<[string, FieldOption]>>(
        () => [
            [
                'buildingClassPartners.id',
                {
                    input: 'select',
                    search: true,
                    options: getBuildingClassOptions(staticData.buildingClassification.partners, language.codes.editor),
                },
            ],
            [
                'buildingClassCrb.id',
                {
                    input: 'select',
                    search: true,
                    options: getBuildingClassOptions(staticData.buildingClassification.crb, language.codes.editor),
                },
            ],
            [
                'projectAddress.location.country',
                {
                    input: 'select',
                    options: [
                        {
                            value: 'Switzerland',
                            label: t('editor:project:parameters:projectAddress:location:countries:switzerland'),
                        },
                        {
                            value: 'Liechtenstein',
                            label: t('editor:project:parameters:projectAddress:location:countries:liechtenstein'),
                        },
                    ],
                    disabled: true,
                },
            ],
            [
                'greaterRegion.id',
                {
                    input: 'select',
                    options: staticData.greaterRegions.map((gr) => ({
                        label: gr.name[language.codes.editor],
                        value: gr.id,
                    })),
                    disabled: true,
                },
            ],
            [
                'projectAddress.location.canton.cantonName',
                { input: 'text', type: 'text', disabled: true, testId: 'canton-name' },
            ],
        ],
        [staticData, language, t]
    );

    const fieldsRightColP2 = React.useMemo<Array<[string, FieldOption]>>(() => {
        return [
            ['projectAddress.city', { input: 'text', type: 'text' }],
            ['projectAddress.streetName', { input: 'text', type: 'text' }],
            ['projectAddress.streetNumber', { input: 'text', type: 'text' }],
            ['projectAddress.additional', { input: 'text', type: 'text' }],
            [
                'projectAddress.location.position',
                {
                    input: 'text',
                    type: 'text',
                    disabled: true,
                    getValue: (p) => p.projectAddress?.location?.position?.name[language.codes.editor],
                },
            ], // name is translation map
            [
                'projectAddress.bfsNumber',
                { input: 'text', type: 'number', options: { decimals: 0 }, testId: 'bfs-number' },
            ],
            ['projectAddress.egidNumber', { input: 'text', type: 'text' }],
            [
                'projectAddress.latitude',
                { input: 'text', type: 'number', options: { decimals: 6 }, testId: 'latitude' },
            ],
            [
                'projectAddress.longitude',
                { input: 'text', type: 'number', options: { decimals: 6 }, testId: 'longitude' },
            ],
            ['projectAddress.plusCodes', { input: 'text', type: 'text' }],
        ];
    }, [language]);

    const municipalityOptions = React.useMemo(
        () =>
            ({
                input: 'select',
                search: true,
                options: getMunicipalityOptions(staticData.locations),
            } as FieldOption),
        [staticData]
    );

    const parameterFields = React.useMemo(() => {
        return staticData.parameters.map<ParametersFieldOptions>((parType) => {
            const ret: ParametersFieldOptions = {
                label: parType.name[language.codes.editor],
                id: parType.id,
                input: 'select',
                options: parType.values.map<SelectOption>((p) => ({
                    value: p.id,
                    label: p.name[language.codes.editor],
                })),
            };
            return ret;
        });
    }, [staticData, language]);

    const handleChange = useCallback(
        (path: string, value: string) => {
            changeProject((p) => override(p, path, value));
        },
        [changeProject]
    );

    return (
        <Row className="small-push-bottom">
            <Col xs={12}>
                <Row className="align-items-start">
                    <Col xs={12} lg={6}>
                        {fieldsLeftCol.map(([field, options]) => (
                            <Common
                                name={t(`editor:project:parameters:${field.replace(/\./g, ':')}`)}
                                value={
                                    options.getValue
                                        ? options.getValue(project)
                                        : valueFromPath(project, field.split('.'))
                                }
                                changeValue={handleChange}
                                option={options}
                                language={language}
                                key={field}
                                passThrough={field}
                                testId={options.testId}
                            />
                        ))}
                        {parameterFields.map((par) => (
                            <Common
                                name={par.label}
                                value={parameterValue(project, par.id)}
                                changeValue={handleParameterChange}
                                option={par}
                                language={language}
                                key={par.id}
                                passThrough={par.id}
                            />
                        ))}
                    </Col>
                    <Col xs={12} lg={6}>
                        {fieldsRightColP1.map(([field, options]) => (
                            <Common
                                name={t(`editor:project:parameters:${field.replace(/\./g, ':')}`)}
                                value={
                                    options.getValue
                                        ? options.getValue(project)
                                        : valueFromPath(project, field.split('.'))
                                }
                                changeValue={handleChange}
                                option={options}
                                language={language}
                                key={field}
                                passThrough={field}
                                testId={options.testId}
                            />
                        ))}

                        <Common
                            name={t('editor:project:parameters:projectAddress:location:municipality')}
                            value={project.projectAddress?.location?.id}
                            changeValue={handleLocationChange}
                            option={municipalityOptions}
                            language={language}
                            passThrough="projectAddress.location.municipality"
                            testId="municipality"
                        />

                        {fieldsRightColP2.map(([field, options]) => (
                            <Common
                                name={t(`editor:project:parameters:${field.replace(/\./g, ':')}`)}
                                value={
                                    options.getValue
                                        ? options.getValue(project)
                                        : valueFromPath(project, field.split('.'))
                                }
                                changeValue={handleChange}
                                option={options}
                                language={language}
                                key={field}
                                passThrough={field}
                                testId={options.testId}
                            />
                        ))}
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};
