import React, { useEffect, useState, useRef } from 'react';
import type { FC } from 'react';

interface HTMLLoader {
    src: string;
    onLoad?: (node: HTMLElement) => void;
}

export const HTMLLoader: FC<HTMLLoader> = ({ src, onLoad }) => {
    const ref = useRef<HTMLDivElement>(null);

    const [parser] = useState(new DOMParser());
    const [dom, setDom] = useState<Document>();

    useEffect(() => {
        fetch(src)
            .then((res) => res.text())
            .then((text) => parser.parseFromString(text, 'text/html'))
            .then((html) => preProcesssing(src, html))
            .then((html) => setDom(html));
    }, [src, parser]);

    useEffect(() => {
        const mountingPoint = ref.current;

        // clear
        for (const child of ref.current?.children ?? []) {
            child.remove();
        }

        // mount
        if (mountingPoint && dom) {
            const body = dom.body;
            mountingPoint.innerHTML = body.innerHTML;

            postProcessing(mountingPoint);

            onLoad?.(mountingPoint);
        }
    }, [dom, onLoad]);

    return <div ref={ref} />;
};

async function preProcesssing(absolutePath: string, html: Document): Promise<Document> {
    const lastSlashIndex = absolutePath.lastIndexOf('/');
    const folder = absolutePath.substring(0, lastSlashIndex);

    // Replace sources
    await Promise.all(
        [...html.querySelectorAll('[src]')].map(async (element) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const src = element.getAttribute('src')!;

            // Ignore s3 sources
            if (src.startsWith('/s3/') || src.startsWith('s3/')) return null;

            return fetch(src, { method: 'HEAD' })
                .then((res) => {
                    (
                        [
                            [HTMLImageElement, 'image'],
                            [HTMLVideoElement, 'video'],
                            [HTMLAudioElement, 'audio'],
                        ] as const
                    ).forEach(([proto, prefix]) => {
                        if (element instanceof proto) {
                            if (!res.headers.get('Content-Type')?.startsWith(prefix)) {
                                const newSrc = `${folder}/${src}`;
                                element.setAttribute('src', newSrc);
                                console.warn(`Replacing "src": ${src} -> ${newSrc}`);
                            }
                        }
                    });
                })
                .catch(() => null);
        })
    );

    return html;
}

function postProcessing(element: HTMLElement) {
    // Replace document links with js handler
    element.querySelectorAll('[href^="#"]').forEach((element) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const href = element.getAttribute('href')!;

        // Prevent replacement of app routing
        if (href.startsWith('#/')) return;

        element.addEventListener('click', function goToChapter(event: Event) {
            event.preventDefault();

            let target = document.querySelector(href);
            if (!target) target = document.querySelector(`[name="${href.substring(1)}"]`);

            target?.scrollIntoView({ block: 'start' });
        });
    });
}
