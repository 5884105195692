import { sumBy } from 'lodash';
import { filter, map } from '../../../../../effects';
import { CostForest, CostNode } from '../types';
import BKP from './mappings.BKP.json';
import eBKP_H from './mappings.eBKP-H.json';
import eBKP_H2020A from './mappings.eBKP-H2020_A.json';
import eBKP_H2020B from './mappings.eBKP-H2020_B.json';
import eBKP_TA from './mappings.eBKP-T_A.json';
import eBKP_TB from './mappings.eBKP-T_B.json';

export interface RegulationReferenceMapping {
    name: string;
    refs: string[];
}

function getMapping(name: string): RegulationReferenceMapping[] {
    switch (name) {
        case 'BKP':
            return BKP;
        case 'eBKP-H':
            return eBKP_H;
        case 'eBKP-H2020(A)':
            return eBKP_H2020A;
        case 'eBKP-H2020(B)':
            return eBKP_H2020B;
        case 'eBKP-T(A)':
            return eBKP_TA;
        case 'eBKP-T(B)':
            return eBKP_TB;
        default:
            return [];
    }
}

export function findMappingFor(
    node: CostNode,
    mappings = getMapping(node.name)
): RegulationReferenceMapping | undefined {
    return mappings.find((mapping) => mapping.name === node.shortName.de_DE);
}

export function filterReferenceNodes(forest: CostForest, mapping: RegulationReferenceMapping): CostNode[] {
    return forest.flatMap((tree) => filter(tree, (node) => mapping.refs.includes(node.code)));
}

/**
 * Returns true if the referenceQuantity is calculated from other costs
 * @param node
 */
export function isCostDependingQuantity(node: CostNode): boolean {
    return node.measureUnit === 'CHF' && !!node.shortName.de_DE && !!findMappingFor(node);
}

export function calculateQuantities(forest: CostForest): CostForest {
    return forest.map((tree) =>
        map(tree, (node) => {
            const name = node.shortName.de_DE;

            const mapping = findMappingFor(node);

            if (!mapping) {
                if (node.measureUnit === 'CHF') console.warn(`Missing quantity mapping for "${name}"`);

                return node;
            }

            const references = filterReferenceNodes(forest, mapping);

            if (mapping.refs.length && references.length === 0) {
                console.warn(`Missing references of mapping "${mapping.name}"`);
            }

            const referenceQuantity = sumBy(references, ({ cost }) => cost.value ?? 0);

            if (node.cost.referenceQuantity === referenceQuantity) return node;

            return {
                ...node,
                cost: {
                    ...node.cost,
                    referenceQuantity,
                },
            };
        })
    );
}
