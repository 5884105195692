import React, { PropsWithChildren, forwardRef } from 'react';
import { Container, Row } from 'reactstrap';

type Props = PropsWithChildren<Record<string, unknown>>;

export const Header = forwardRef<HTMLElement, Props>(function Header({ children }, ref) {
    return (
        <header className="header" ref={ref}>
            <Container>
                <Row>{children}</Row>
            </Container>
        </header>
    );
});
