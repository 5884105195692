import type { AxiosResponse } from 'axios';
import React from 'react';
import { CustomerCareService } from '../services/customer-care';

interface Context {
    submitFeedback: (formData: AnyObject) => Promise<AxiosResponse>;
    submitCustomerServiceRequest: (formData: AnyObject) => Promise<AxiosResponse>;
}

const CustomerCareContext = React.createContext<null | Context>(null);

interface Props {
    customerCareService: CustomerCareService;
}

export const CustomerCareProvider: React.FC<Props> = ({ children, customerCareService }) => {
    const submitFeedback = async (formData: AnyObject): Promise<AxiosResponse> =>
        customerCareService.submitFeedback(formData);

    const submitCustomerServiceRequest = async (formData: AnyObject): Promise<AxiosResponse> =>
        customerCareService.submitCustomerServiceRequest(formData);

    return (
        <CustomerCareContext.Provider
            value={{
                submitFeedback,
                submitCustomerServiceRequest,
            }}
        >
            {children}
        </CustomerCareContext.Provider>
    );
};

export function useCustomerCare(): Context {
    const context = React.useContext(CustomerCareContext);

    if (context) {
        return context;
    }

    throw new Error(
        `"${useCustomerCare.name}" can only be used inside a functional component ` +
            `that is a child of the ${CustomerCareProvider.name}`
    );
}
