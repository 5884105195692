import React, { useCallback } from 'react';
import { SelectBase } from './SelectBase';
import { OptionAlignment, SelectOption } from './types';

type Props = FormFieldComponentProps<
    any,
    {
        id?: string;
        label?: React.ReactNode;
        valueLabel?: string;

        options: SelectOption[];
        alignOptions?: OptionAlignment;

        required?: boolean;
        className?: string;
        autoFocus?: boolean;
        multiple?: boolean;
        disabled?: boolean;
        disableScroll?: boolean;
        testId?: string;
    }
>;

export const Select: React.FC<Props> = ({
    className,
    label,
    valueLabel,
    value,
    options,
    alignOptions,
    required,
    onChange,
    multiple,
    disabled,
    disableScroll,
    testId,
}) => {
    const [focused, setFocused] = React.useState(false);
    const [selection, setSelection] = React.useState(-1);

    const selectedOptions = (options || []).filter((o) => {
        return multiple && value instanceof Array ? value.includes(o.value) : o.value === value;
    });

    React.useEffect(() => {
        if (focused) {
            setSelection(
                Math.max(
                    options.findIndex((option) => option.value === value),
                    0
                )
            );

            const listener = (e: KeyboardEvent) => {
                switch (e.key) {
                    case 'ArrowUp':
                        e.preventDefault();
                        setSelection((f) => Math.max(0, f - 1));
                        break;
                    case 'ArrowDown':
                        e.preventDefault();
                        setSelection((f) => Math.min(options.length - 1, f + 1));
                        break;
                    case 'Escape':
                        blur();
                        break;
                    case 'Enter':
                        changeValue(options[selection]);
                        break;
                }
            };

            window.addEventListener('keydown', listener);

            return () => {
                window.removeEventListener('keydown', listener);
            };
        }
    }, [focused]); // eslint-disable-line react-hooks/exhaustive-deps

    const focus = useCallback(() => setFocused(true), []);
    const blur = useCallback(() => {
        setFocused(false);
        setSelection(-1);
    }, []);

    const changeValue = useCallback(
        (val: any) => {
            onChange(val);
            blur();
        },
        [onChange, blur]
    );

    return (
        <SelectBase
            label={label}
            value={value}
            onChange={changeValue}
            options={options.map((option, i) => (i === selection ? { ...option, focused: true } : option))}
            className={className}
            alignOptions={alignOptions}
            required={required}
            disabled={disabled}
            focused={focused}
            multiple={multiple}
            disableScroll={disableScroll}
            onFocus={focus}
            onBlur={blur}
            testId={testId}
        >
            {selectedOptions.length > 0 && (
                <>
                    {multiple && value instanceof Array && (
                        <>
                            {valueLabel ? `${valueLabel || ''} ` : ''}

                            {selectedOptions.map((op, i) => (
                                <div className="select__option" key={i}>
                                    {op.title && <div className="select__option-title">{op.title}</div>}
                                    {op.label}
                                </div>
                            ))}
                        </>
                    )}

                    {!multiple && `${valueLabel ? `${valueLabel || ''} ` : ''}${selectedOptions[0].label}`}
                </>
            )}
        </SelectBase>
    );
};
