import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import type { View, Workbook } from 'tableau-api';
import { useTableauService } from '../../contexts/TableauContext';
import { TableauService } from '../../services';

export type WorkbooksAndViews = {
    workbooks: Workbook[];
    views: View[];
};

/**
 * A function that fetchtes workbooks and views at the same time.
 */
async function loadWorkbooksAndViews(tableauService: TableauService): Promise<WorkbooksAndViews> {
    const fetchWorkbooks = tableauService.getWorkbooks();
    const fetchViews = tableauService.getAllViews();

    const [workbooks, views] = await Promise.all([fetchWorkbooks, fetchViews]);

    return {
        workbooks,
        views,
    };
}

type UseWorkbooksAndViewsOptions = Omit<
    UseQueryOptions<WorkbooksAndViews, AxiosError, WorkbooksAndViews, ['workbooksAndViews']>,
    'query' | 'queryFn' | 'queryKey'
>;

export const useWorkbooksAndViews = (
    override: Partial<UseWorkbooksAndViewsOptions> = {}
): UseQueryResult<WorkbooksAndViews, AxiosError> => {
    const { tableauService } = useTableauService();

    return useQuery(['workbooksAndViews'], () => loadWorkbooksAndViews(tableauService), {
        retry: 1,
        ...override,
    });
};
