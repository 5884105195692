import { TFunction } from 'i18next';
import React from 'react';
import { SearchSelect } from '../../../FormComponents';

interface Props {
    indices: StaticCostIndex[];
    value: StaticCostIndex;
    onChange: (value: StaticCostIndex) => void;
    t: TFunction;
    className?: string;
}

export const CostIndexList: React.FC<Props> = ({ indices, value, onChange, t, className }) => {
    const options = React.useMemo(
        () =>
            indices.map((index) => {
                const label = index.date.split('-').reverse().join('.');

                return {
                    label,
                    value: index.id,
                    key: index.id,
                    search: [label],
                };
            }),
        [indices]
    );

    const changeCostIndex = (id: ID) => {
        const newIndex = indices.find((index) => index.id === id);

        if (newIndex) {
            onChange(newIndex);
        }
    };

    return (
        <SearchSelect
            label={t('editor:projects:creator:cost-sheet:cost-index:level')}
            value={value?.id}
            options={options}
            onChange={changeCostIndex}
            className={className}
        />
    );
};
