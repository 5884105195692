import React, { DragEvent, useCallback } from 'react';
import { UploaderComponentProps } from '../FileUploader';

type Props = UploaderComponentProps & { text: string };

export const ProjectImportArea: React.FC<Props> = ({ text, openFileDialog, uploadFile }) => {
    const handleDrop = useCallback(
        (event: DragEvent<HTMLElement>) => {
            event.preventDefault();

            if (event.dataTransfer.files.length) uploadFile([...event.dataTransfer.files]);
        },
        [uploadFile]
    );

    const preventDefault = useCallback((event) => event?.preventDefault(), []);

    return (
        <div
            className="project-importer__area position-relative"
            onClick={openFileDialog}
            onDrop={handleDrop}
            onDragEnter={preventDefault}
            onDragOver={preventDefault}
        >
            <p>{text}</p>
            <input
                onClick={preventDefault}
                type="file"
                className="opacity-0 position-absolute inset-0"
                style={{ opacity: 0 }}
            />
        </div>
    );
};
