import type { FeedbackMessage } from '@crb-oa-viewer/data-assistant-building-plan';
import { faExclamationCircle, faInfoCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';

interface FeedbackMessagesProps {
    messages: FeedbackMessage[];
}

export const FeedbackMessagesView: React.FC<FeedbackMessagesProps> = ({ messages }) => (
    <Container className="small-push-bottom small-push-top">
        {messages.map((message, index) => (
            <Row key={index} className="py-3">
                <Col xs="auto">
                    {message.type === 'info' ? (
                        <FontAwesomeIcon color="green" icon={faInfoCircle} data-testid="info-message-icon" />
                    ) : message.type === 'warning' ? (
                        <FontAwesomeIcon color="orange" icon={faExclamationCircle} data-testid="warning-message-icon" />
                    ) : (
                        <FontAwesomeIcon color="red" icon={faTimesCircle} data-testid="error-message-icon" />
                    )}
                </Col>
                <Col>{message.message}</Col>
            </Row>
        ))}
    </Container>
);
