import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';
import { SearchSelect, SearchSelectOption, SelectOption, Spinner, TextInput } from '../../components';
import {
    getBuildingClassOptions,
    getMunicipalityOptions,
} from '../../components/ProjectEditor/views/DataView/fragments';

interface Props {
    costIndices: StaticCostIndex[];
    locations: StaticLocation[];
    partners: StaticBuildingClassification[];
    languageCode: string;
    onSubmit: (data: CreateProjectPayload) => Promise<unknown>;
}

interface State {
    submitting?: boolean;
    valid?: boolean;
    error?: string;
    submissions: number;
}

export const CreateProjectForm: React.FC<Props> = ({ languageCode, costIndices, locations, partners, onSubmit }) => {
    const [t] = useTranslation();

    const [state, setState] = React.useState<State>({ submissions: 0 });
    const [project, setProject] = React.useState<Partial<CreateProjectPayload>>({});

    React.useEffect(() => {
        const validName = project.name && project.name[languageCode] && project.name[languageCode].length > 0;
        const validCostIndexDate = project.costIndexDate && (project.costIndexDate || '').length > 0;
        const validLocation = project.projectAddress && project.projectAddress.location.id;
        const validBuildingClassPartners = project.buildingClassPartners && project.buildingClassPartners.id;

        setState((s) => ({
            ...s,
            valid: !!(validName && validCostIndexDate && validLocation && validBuildingClassPartners),
        }));
    }, [project, languageCode]);

    const handleProjectName = useCallback(
        (name) =>
            setProject((p) => ({
                ...p,
                name: {
                    ...p.name,
                    [languageCode]: name,
                },
            })),
        [setProject, languageCode]
    );

    const handleLocation = useCallback(
        (id: ID) => {
            const location = locations.find((loc) => loc.id === id);

            if (location == null) {
                console.error('Error: Location not found. This indicates a bug in the code.');
                return;
            }

            setProject((p) => ({
                ...p,
                projectAddress: {
                    location: {
                        id,
                    },
                },
                greaterRegion: location.canton?.greaterRegion,
            }));
        },
        [locations, setProject]
    );

    const handleCostIndexDate = useCallback(
        (costIndexDate) => setProject((p) => ({ ...p, costIndexDate })),
        [setProject]
    );

    const handleBuildingClassPartners = useCallback(
        (id) => setProject((p) => ({ ...p, buildingClassPartners: { id } })),
        [setProject]
    );

    const submit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (
            state.valid &&
            project.costIndexDate &&
            project.greaterRegion &&
            project.buildingClassPartners &&
            project.name &&
            project.projectAddress?.location.id
        ) {
            setState((s) => ({ ...s, submitting: true }));
            try {
                await onSubmit({
                    costIndexDate: project.costIndexDate,
                    greaterRegion: { id: project.greaterRegion.id },
                    buildingClassPartners: { id: project.buildingClassPartners.id },
                    name: project.name,
                    projectAddress: {
                        location: {
                            id: project.projectAddress.location.id,
                        },
                    },
                });
                setState((s) => ({ ...s, submitting: false }));
            } catch (e) {
                setState((s) => ({ ...s, submitting: false }));
            }
        }
    };

    const municipalityOptions: SearchSelectOption[] = React.useMemo(
        () => getMunicipalityOptions(locations),
        [locations]
    );

    const costIndexDateOptions: SearchSelectOption[] = React.useMemo(
        () =>
            costIndices
                .filter((cIndex) => cIndex.greaterRegion.id === project.greaterRegion?.id)
                .map<SelectOption & { date: Date }>((costIndex) => {
                    const date = new Date(costIndex.date);

                    return {
                        label: date.toISOString().split('T')[0].split('-').reverse().join('.'),
                        value: costIndex.date,
                        date,
                        search: [date.toISOString().split('T')[0].split('-').reverse().join('.')],
                    };
                })
                .sort((option1, option2) => option2.date.getTime() - option1.date.getTime()),
        [costIndices, project.greaterRegion]
    );

    const buildingClassPartnersOptions: SearchSelectOption[] = React.useMemo(
        () => getBuildingClassOptions(partners, languageCode),
        [partners, languageCode]
    );

    const isMissingGreaterRegion = Boolean(project.projectAddress?.location.id && !project.greaterRegion);

    return (
        <Form onSubmit={submit}>
            <Row className="small-push-bottom">
                <Col xs="12">
                    <TextInput
                        label={t('create-project:name')}
                        value={project.name?.[languageCode] || ''}
                        onChange={handleProjectName}
                        required={true}
                        testId="create-project-name"
                    />
                </Col>

                <Col xs="12">
                    <SearchSelect
                        className="mb-3"
                        label={t('editor:project:parameters:projectAddress:location:municipality')}
                        value={project.projectAddress?.location.id}
                        onChange={handleLocation}
                        options={municipalityOptions}
                        required={true}
                        testId="create-municipality"
                    />

                    {isMissingGreaterRegion && (
                        <div className="create-project-error">
                            <FontAwesomeIcon icon={faExclamationTriangle} />
                            <span className="create-project-error__text">{t('create-project:error')}</span>
                        </div>
                    )}
                </Col>

                <Col xs="12">
                    <SearchSelect
                        className="mb-3"
                        label={t('create-project:cost-index:date')}
                        value={project.costIndexDate || undefined}
                        onChange={handleCostIndexDate}
                        options={costIndexDateOptions}
                        disabled={!project.greaterRegion?.id}
                        testId="create-cost-date"
                    />
                </Col>

                <Col xs="12">
                    <SearchSelect
                        className="mb-3"
                        label={t('editor:project:parameters:buildingClassPartners:id')}
                        value={project.buildingClassPartners?.id || undefined}
                        onChange={handleBuildingClassPartners}
                        options={buildingClassPartnersOptions}
                        required={true}
                        testId="create-buildingclass-partners"
                    />
                </Col>

                <Col xs={12}>
                    {!state.valid && state.submissions > 0 && (
                        <div className="form__input form__error">
                            <div>The form is incomplete or invalid</div>
                        </div>
                    )}

                    <button type="submit" className="button" disabled={!state.valid || state.submitting}>
                        {!state.submitting && t('create-project:submit-button')}

                        {state.submitting && <Spinner color="#000" size={32} />}
                    </button>
                </Col>
            </Row>
        </Form>
    );
};
