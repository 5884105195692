export const emailValidator = (): Validator<string> => {
    // Simple
    // eslint-disable-next-line no-useless-escape
    const regex = new RegExp('^.+@.+..+$');

    return (report) => (value) => {
        if (!regex.test(value)) {
            report('Please provide a valid email address');
        }
    };
};
