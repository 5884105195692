/**
 * Returns the minimum or the maximum boundary accordingly if the value exceeds the boundary
 * Returns the value itself if it is within the boundary
 *
 * @param min The minimum boundary
 * @param max The maximum boundary
 * @param value The value to be checked
 *
 * @returns number It can be the value itself or either of the boundaries
 */
export const minmax = (min: number, max: number, value: number): number => {
    return Math.max(0, Math.min(value, 100));
};
