import { isNumber } from 'lodash';
import { useMemo } from 'react';
import { useStaticData } from './queries';

export function useMeasurementOfCode(code: string, project: Project | undefined): ProjectMeasurement | undefined {
    const { data } = useStaticData();

    const { measurements } = project ?? {};

    return useMemo(() => {
        if (!measurements) return undefined;

        const measurementRegulations = [
            ...(data?.regulations.Mengen ?? []),
            ...(data?.regulations.SIA116 ?? []),
            ...(data?.regulations.SIA416 ?? []),
        ];

        if (measurementRegulations.length === 0) return undefined;

        const regulations = new Set(measurementRegulations.filter((r) => r.code === code).map(({ id }) => id));

        return measurements.find(
            (measurement) =>
                isNumber(measurement.regulation?.id) && regulations.has(measurement.regulation?.id as number)
        );
    }, [code, data, measurements]);
}
