import { useMemo } from 'react';
import { useStaticData } from './use-static-data';

export function useStaticRegulations(name?: string): StaticRegulation[] {
    const { data: staticData } = useStaticData();

    const regulations = staticData?.regulations;

    return useMemo(() => {
        if (name) return regulations?.[name] ?? [];

        return Object.values(regulations ?? {})
            .flat()
            .filter((reg): reg is StaticRegulation => !!reg);
    }, [regulations, name]);
}
