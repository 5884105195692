import type { AxiosError } from 'axios';
import { MutationFunction, useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { editorService } from '../../services';
import { extractOverview } from '../../utils';
import { useStaticData } from '../queries';

interface MutationContext {
    oldProjects?: ProjectOverview[];
}

export function useProjectSave(): UseMutationResult<Project, AxiosError | Error, Project, MutationContext | undefined> {
    const queryClient = useQueryClient();
    const { data: staticData } = useStaticData();

    /**
     * Saves the project to the backend.
     *
     * Also deletes the wizard state if it exists
     */
    const saveProject: MutationFunction<Project, Project> = async (project: Project) => {
        const costIndex = staticData?.costIndices.find((ci) => ci.id === project.costIndex.id);

        if (!costIndex) throw new Error('Cost index not provided');

        return editorService.editProject({
            ...project,
            costIndexDate: costIndex.date,
            greaterRegion: costIndex.greaterRegion,
        });
    };

    return useMutation<Project, AxiosError | Error, Project, MutationContext | undefined>(saveProject, {
        onMutate: (project) => {
            const oldProjects = queryClient.getQueryData<ProjectOverview[]>('projects');

            if (!staticData || !oldProjects) return;

            const newProjects = oldProjects.map((overview) => {
                if (overview.id !== project.id) return overview;

                return extractOverview(staticData, project);
            });

            queryClient.setQueryData('projects', newProjects);

            return { oldProjects };
        },
        onSuccess: (project) => void queryClient.setQueryData(['project', project.id], project),
        onSettled: (_d, _e, project) => {
            return Promise.all([
                queryClient.invalidateQueries('projects'),
                queryClient.invalidateQueries(['project-tags']),
                queryClient.invalidateQueries(['floor-usage-tags']),
                queryClient.invalidateQueries(['functional-unit-tags']),
                queryClient.invalidateQueries(['wizard-state', project.id], { inactive: true }),
            ]);
        },
    });
}
