import { createDebounce } from './createDebounce';

export function createListener(
    type: keyof WindowEventMap,
    listener: () => unknown,
    debounce: boolean | number = false
): () => unknown {
    let l = listener;

    if (typeof debounce === 'number' || debounce === true) {
        l = createDebounce(listener, typeof debounce === 'boolean' ? 250 : debounce);
    }

    window.addEventListener(type, l);
    return () => window.removeEventListener(type, l);
}
