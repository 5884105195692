import { UseMutationResult, useMutation, useQueryClient } from 'react-query';

export function useFloorUsageTagMutation<A, R>(
    mutFn: (arg: A) => Promise<R>
): UseMutationResult<unknown, unknown, A, unknown> {
    const queryClient = useQueryClient();

    return useMutation(mutFn, {
        onSuccess: () => {
            queryClient.invalidateQueries(['floor-usage-tags']);
        },
    });
}
