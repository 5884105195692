import classNames from 'classnames';
import { noop } from 'lodash';
import { useCallback } from 'react';
import React from 'react';
import { minmax, percentage } from '../../utils';
import { NumberInput } from '../FormComponents';

interface CostValueProps {
    className?: string;
    label: string;
    value: string;
    locked: boolean;
    labelDataTestId?: string;
    valueDataTestId?: string;
    editable?: boolean;
    onChange?: (value: number) => void;
}

export const CostValue: React.FC<CostValueProps> = ({
    className,
    label,
    value,
    locked,
    editable = false,
    onChange = noop,
}) => {
    const parsedValue = parseFloat(value);

    const handleChange = useCallback(
        (input: string) => {
            if (!!input) {
                const parsedInput = parseFloat(input);
                if (parsedInput !== parseFloat(percentage(parsedValue))) {
                    onChange(minmax(0, 100, parsedInput));
                }
            } else {
                onChange(0);
            }
        },
        [onChange, parsedValue]
    );

    const costValueClassName = classNames('cost-value', className, {
        'cost-value--locked': locked,
    });
    const costValueInputClassName = classNames('cost-value__input-field', {
        'cost-value--locked': locked,
    });

    return editable ? (
        <NumberInput
            labelClassName="cost-value__label mb-0"
            className={costValueInputClassName}
            wrapperClassName={costValueClassName}
            noDefaultClassName={true}
            decimals={2}
            lazy={true}
            value={percentage(parsedValue) ?? '0'}
            label={label}
            disabled={locked}
            onChange={handleChange}
            isPercentage={true}
            testId="cost-value-input"
        />
    ) : (
        <div className={costValueClassName}>
            <span className="cost-value__label">{label}</span>
            <div className="cost-value__value">
                <span data-testid="cost-non-editable-value">{value}</span>
            </div>
        </div>
    );
};
