import type { AxiosError } from 'axios';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { editorService } from '../../services';

export function useProjectLicences(
    projectId: ID,
    options?: UseQueryOptions<ProjectLicence[], AxiosError, ProjectLicence[], ['project-licences', ID]>
): UseQueryResult<ProjectLicence[]> {
    return useQuery(['project-licences', projectId], () => editorService.getProjectLicences(projectId), options);
}
