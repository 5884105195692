import { TFunction } from 'i18next';
import React, { useCallback } from 'react';
import { Col, Row } from 'reactstrap';
import { useDictionary } from '../../../../../../effects';
import { Description } from './Description';

interface Props {
    project: Project;
    staticData: StaticData;
    language: EditorLanguage;
    changeProject: (update: (project: Project) => Project) => unknown;
    t: TFunction;
}

export const Descriptions: React.FC<Props> = ({ language, project, staticData, changeProject, t }) => {
    const changeDescription = useCallback(
        (description: ProjectDescription) => {
            changeProject((p) => ({
                ...p,
                descriptions: (p.descriptions || []).map((d) => (d.type.id === description.type.id ? description : d)),
            }));
        },
        [changeProject]
    );

    const addDescription = useCallback(
        (description: ProjectDescription) => {
            changeProject((p) => ({
                ...p,
                descriptions: [...(p.descriptions || []), description],
            }));
        },
        [changeProject]
    );

    const hasDescription = (typeId: number) =>
        project.descriptions?.some((description) => description.type.id === typeId);

    const descriptionMap = useDictionary(project.descriptions || [], (description) => description.type.id);

    const descriptionsTypes = React.useMemo(
        () => staticData.descriptionTypes.sort((d1, d2) => d1.id - d2.id),
        [staticData.descriptionTypes]
    );

    return (
        <Row className="small-push-bottom">
            <Col xs={12}>
                <h3>{t('editor:projects:creator:data-sheet:descriptions')}</h3>

                {descriptionsTypes.map((descriptionType) => (
                    <Description
                        language={language}
                        descriptionType={descriptionType}
                        description={
                            descriptionMap[descriptionType.id] || {
                                type: { id: descriptionType.id },
                                text: {},
                            }
                        }
                        changeDescription={hasDescription(descriptionType.id) ? changeDescription : addDescription}
                        key={descriptionType.id}
                    />
                ))}
            </Col>
        </Row>
    );
};
