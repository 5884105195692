import classnames from 'classnames';
import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { useEvent } from '../../../effects';
import { Option } from './Option';
import { OptionAlignment, SelectOption } from './types';

type SingleSelectProps = SelectBaseProps<any> & { multiple?: false };
type MultiSelectProps = SelectBaseProps<any[]> & { multiple: true };

interface SelectBaseProps<V> {
    label?: React.ReactNode;
    labelTarget?: string;
    value: V;
    onChange: (value: V) => void;
    options: SelectOption<V>[];

    focused?: boolean;
    disabled?: boolean;
    required?: boolean;
    filled?: boolean;
    selected?: number;

    className?: string;

    alignOptions?: OptionAlignment;
    disableScroll?: boolean;

    testId?: string;

    onFocus?: () => void;
    onBlur?: () => void;
}

export const SelectBase: FC<SingleSelectProps | MultiSelectProps> = ({
    value,
    onChange,
    options,
    alignOptions,
    disableScroll,
    children,
    className,
    disabled,
    focused,
    label,
    multiple,
    required,
    filled: extFilled,
    labelTarget,
    selected = -1,
    testId,
    onFocus = () => null,
    onBlur = () => null,
}) => {
    const ref = useRef<HTMLDivElement>(null);

    /**
     * Closed the Selection if the user clicked outside of this component or if he clicked inside and the default was not prevented.
     */
    useEvent<MouseEvent>(
        window,
        'click',
        (e) => {
            const fromThis = e.target && e.target instanceof Node && ref.current?.contains(e.target);

            if (focused && !fromThis) onBlur();
            else if (fromThis) onFocus();
        },
        [focused]
    );

    const optionsRef = useRef<HTMLDivElement>(null);

    const scrollIndex = focused && selected >= 0 && selected <= options.length ? selected : -1;

    useEffect(() => {
        const optionsElement = optionsRef.current;

        if (scrollIndex >= 0) {
            const scrollElement: HTMLElement = optionsElement?.children.item(scrollIndex) as HTMLElement;

            if (optionsElement && scrollElement) {
                optionsElement.scrollTop = scrollElement.offsetTop;
            }
        }
    }, [scrollIndex]);

    const changeOption = useCallback(
        (option: SelectOption) => {
            if (multiple) {
                if ((value || []).includes(option.value)) {
                    onChange(value.filter((v: any) => v !== option.value));
                } else {
                    onChange([...(value || []), option.value]);
                }
            } else {
                onChange(option.value);
            }
        },
        [multiple, value, onChange]
    );

    const handleClick = useCallback(() => {
        // if (!focused) event.preventDefault();
        if (focused && !multiple) ref.current?.blur();
    }, [focused, multiple]);

    const filled = useMemo(() => {
        if (multiple && value instanceof Array) return value.length > 0;
        return extFilled === undefined ? !!value : extFilled;
    }, [multiple, value, extFilled]);

    const isSelected = useCallback(
        (option) => {
            if (multiple) return (value || []).includes(option.value);
            return value === option.value;
        },
        [multiple, value]
    );

    const visibleOptions = useMemo(() => options.filter(({ hidden }) => !hidden), [options]);

    return (
        <>
            <div
                className={classnames('form__select', className, {
                    'form__select--filled': filled,
                    'form__select--disabled': disabled,
                    'form__select--no-label': !label,
                })}
                onClick={handleClick}
                data-testid={testId}
                ref={ref}
            >
                {label && (
                    <label className="form__select__label" htmlFor={labelTarget}>
                        <span className="form__select__label-content">
                            {label} {required ? '*' : ''}
                        </span>
                    </label>
                )}

                {children}

                <div className="select-menu" hidden={!focused || disabled} data-testid="select-menu">
                    <div ref={optionsRef} className="select-options border py-2" data-align={alignOptions}>
                        {visibleOptions.map((option, index) => (
                            <Option
                                className={index > 0 ? 'border-top' : ''}
                                option={option}
                                selected={isSelected(option)}
                                disableScroll={disableScroll}
                                selectOption={changeOption}
                                key={option.key || option.value || index}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};
