import {
    BuildingPlanDxf,
    BuildingPlanIfc,
    Calculations,
    CalculationsIFC,
    InputFile,
    ModelItemEvent,
    ViewOptions,
} from '@crb-oa-viewer/data-assistant-building-plan';
import classNames from 'classnames';
import { noop } from 'lodash';
import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Table } from 'reactstrap';
import { useFloorUsageTags, useLanguage, useStaticData } from '../../effects';
import { InputFileExtension, MappingNames, MeasureUnit } from '../../enums';
import { BuildingPlanEdit } from '../BuildingPlanEdit';
import { BuildingPlanImporter, BuildingPlanImporterCallbacks } from '../BuildingPlanImporter';
import { ErrorBoundary } from '../ErrorBoundary';
import {
    BuildingEditorForm,
    BuildingEditorPlan,
    ContourSelectionForm,
    ImportControlForm,
    ModelCalculationResults,
    ResultsCalculationQuantitiesView,
} from './fragments';
import { reducer } from './reducer';
import type { BuildingPlanContext } from './types';
import {
    BuildingEditorState,
    changeScaleFactor,
    getBuildingDataFromIFCCalculations,
    getOrderedBuildingsAndPlanData,
    initializeState,
    mapStateToBuildingPlanStep,
    toggleFloorContour,
    toggleTerrainContour,
    updateCalculations,
    getMeasurementsFromIFC,
    getSIARegulations,
    convertToUsages,
} from './utils';
import { BuildingPlanError } from '../BuildingPlanImporter/BuildingPlanImporterError';
import { ValueEntry } from '../BuildingPlanEdit/ValueEntry';

interface BuildingPlanImporterCallbacksRenamed {
    onInputFileImport: BuildingPlanImporterCallbacks['onImport'];
}

interface BuildingEditorCallbacks extends BuildingPlanImporterCallbacksRenamed {
    onState: (state: BuildingEditorState) => unknown;
    onInputFileDiscard: () => unknown;
    onMuahChange: (muah: number) => unknown;
    onUSAChange: (usa: number) => unknown;
    onCalculations: (calculations: Calculations) => unknown;
    onComplete: () => unknown;
}

interface BuildingEditorInputProps {
    inputFile?: InputFile;
    project?: Project;
    onProjectChange: (update: (project: Project) => Project) => void;

    treeType?: MappingNames;
    muah?: number;
    usa?: number;
}

interface BuildingEditorProps extends Partial<BuildingEditorCallbacks>, BuildingEditorInputProps {
    classname?: string;
    wizardMode?: boolean;
    skipImportIntro?: boolean;
    navPosition?: 'top' | 'bottom';
}

export const BuildingEditor: React.FC<BuildingEditorProps> = ({
    classname,

    project,
    inputFile,

    navPosition = 'bottom',
    wizardMode = false,
    treeType,
    skipImportIntro = false,

    onProjectChange: changeProject,
    onInputFileImport = noop,
    onInputFileDiscard = noop,
    onCalculations = noop,
    onState = noop,
    onComplete = noop,

    muah,
    onMuahChange,
    usa,
    onUSAChange,
}) => {
    const [language] = useLanguage();
    const [t] = useTranslation();

    const { data: staticData } = useStaticData();
    const { data: floorUsageTags } = useFloorUsageTags();
    const siaRegulations = useMemo(() => {
        return staticData ? getSIARegulations(staticData) : [];
    }, [staticData]);

    const projectBuildings = project?.buildings;

    const { orderedBuildings, planData } = useMemo(
        () => getOrderedBuildingsAndPlanData(project, language),
        [project, language]
    );

    const handleScaleFactorChange = useCallback(
        (scaleFactor) => changeProject((p) => changeScaleFactor(p, scaleFactor)),
        [changeProject]
    );

    const onTerrainContourToggle = useCallback(
        (contourId) => changeProject((p) => toggleTerrainContour(p, contourId)),
        [changeProject]
    );

    const onFloorContourToggle = useCallback(
        (contourId, context) => changeProject((p) => toggleFloorContour(p, contourId, context)),
        [changeProject]
    );

    const onBuildingsChange = useCallback((buildings) => changeProject((p) => ({ ...p, buildings })), [changeProject]);

    const floorBuildingMap = useMemo(() => {
        const mappingEntries = orderedBuildings.flatMap((b) => b.floors.map((f) => [f.id, b.id]));

        return Object.fromEntries(mappingEntries);
    }, [orderedBuildings]);

    const [state, dispatch] = useReducer(reducer, initializeState(inputFile, planData, wizardMode, treeType, muah));

    useEffect(() => onState(state.state), [state.state, onState]);

    const isIFCFile = (inputFile?.extension as InputFileExtension) === 'ifc';
    const isDXFFile = (inputFile?.extension as InputFileExtension) === 'dxf';

    const [calculations, setCalculations] = useState<Calculations>();

    /**
     * ifc has error when it returns empty quantities
     */
    const hasIFCError = calculations && calculations.quantities.length === 0;

    const quantities = useMemo(() => {
        if (!calculations) return undefined;

        return {
            ...Object.fromEntries(calculations.quantities.map(({ name, value }) => [name, value])),
            USA: usa ?? 0,
            ...(muah ? { MUAH: muah } : {}),
        };
    }, [calculations, usa, muah]);

    const context = useMemo<undefined | BuildingPlanContext>(() => {
        if (!state.context) return undefined;

        const building = projectBuildings?.find(({ id }) => id === state.context.buildingId);
        const floor = building?.floors.find(({ id }) => id === state.context.floorId);

        if (!building || !floor) return undefined;

        return { building, floor };
    }, [state.context, projectBuildings]);

    const terrainContours = useMemo(() => planData.contours ?? [], [planData.contours]);
    const floorContours = useMemo(() => context?.floor.contours ?? [], [context]);

    const selection = useMemo(() => {
        if (state.state === BuildingEditorState.TerrainSelection) return terrainContours;

        if (state.state === BuildingEditorState.Floor) return floorContours;

        return [];
    }, [state.state, terrainContours, floorContours]);

    const viewOptions = useMemo(() => {
        const highlights = selection.map((itemId) => ({
            eventType: 'select',
            itemType: 'contour',
            itemId,
        }));

        return {
            ...state.viewOptions,
            highlights: [...state.viewOptions.highlights, ...highlights],
        } as ViewOptions;
    }, [state.viewOptions, selection]);

    const handlePlanLoaded = useCallback((model) => dispatch({ type: 'CHANGE_MODEL', payload: model }), []);

    const handleDXFCalculations = useCallback(
        (calculations: Calculations) => {
            setCalculations(calculations);
            changeProject((p) => updateCalculations(p, calculations));
            onCalculations(calculations);
        },
        [changeProject, onCalculations]
    );

    const handleIFCCalculations = useCallback(
        (calculations: CalculationsIFC) => {
            setCalculations(calculations);
            onCalculations(calculations);
            if (!floorUsageTags) return;

            changeProject((project) => ({
                ...project,
                measurements: getMeasurementsFromIFC(calculations, siaRegulations),
                buildings: getBuildingDataFromIFCCalculations(calculations),
                usages: calculations.usageCalculations.length
                    ? // When usages are calculated old ones should be removed
                      convertToUsages(siaRegulations, floorUsageTags, calculations.usageCalculations)
                    : // When no usages are calculated old ones should stay
                      project.usages,
            }));
        },
        [onCalculations, changeProject, siaRegulations, floorUsageTags]
    );

    const handleContourHover = useCallback(
        ({ itemId }) =>
            dispatch({
                type: 'HIGHLIGHT_CONTOUR',
                payload: itemId,
            }),
        []
    );

    const handleBuildingHover = useCallback(
        ({ itemId }) =>
            dispatch({
                type: 'HIGHLIGHT_BUILDING',
                payload: itemId,
            }),
        []
    );

    const handleFloorHover = useCallback(
        ({ itemId }) =>
            dispatch({
                type: 'HIGHLIGHT_FLOOR',
                payload: itemId,
            }),
        []
    );

    const openFloorEdit = useCallback(
        ({ itemId }) => {
            const buildingId = floorBuildingMap[itemId];

            if (!buildingId) throw new Error('IllegalArgument');

            dispatch({
                type: 'CHANGE_STATE',
                payload: {
                    state: BuildingEditorState.Floor,
                    context: { buildingId, floorId: itemId },
                },
            });
        },
        [floorBuildingMap]
    );

    const handleModelBuildingEvent = useCallback(
        (event: ModelItemEvent) => {
            // Hover
            if (event.eventType === 'hover') handleBuildingHover(event);
        },
        [handleBuildingHover]
    );

    const handleModelFloorEvent = useCallback(
        (event: ModelItemEvent) => {
            // Hover
            if (event.eventType === 'hover') handleFloorHover(event);

            // Select
            if (event.eventType === 'select') openFloorEdit(event);
        },
        [handleFloorHover, openFloorEdit]
    );

    const handleModelContourSelectEvent = useCallback(
        (event: ModelItemEvent) => {
            // Terrain Contour Selection
            if (state.state === BuildingEditorState.TerrainSelection) onTerrainContourToggle(event.itemId);

            // Floor Contour Selection
            if (state.state === BuildingEditorState.Floor) onFloorContourToggle(event.itemId, state.context);
        },
        [state.state, state.context, onTerrainContourToggle, onFloorContourToggle]
    );

    const handleModelContourEvent = useCallback(
        (event: ModelItemEvent) => {
            // Hover
            if (event.eventType === 'hover') handleContourHover(event);

            // Select
            if (event.eventType === 'select') {
                handleModelContourSelectEvent(event);
            }
        },
        [handleContourHover, handleModelContourSelectEvent]
    );

    const handleModelItemEvent = useCallback(
        (event: ModelItemEvent) => {
            // Building
            if (event.itemType === 'building') handleModelBuildingEvent(event);

            // Floor
            if (event.itemType === 'floor') handleModelFloorEvent(event);

            // Contour
            if (event.itemType === 'contour') handleModelContourEvent(event);
        },
        [handleModelBuildingEvent, handleModelFloorEvent, handleModelContourEvent]
    );

    const handleModelPlanEvent = useCallback(
        ({ event }) => {
            // Building
            if (event.itemType === 'building') handleModelBuildingEvent(event);

            // Floor
            if (event.itemType === 'floor') handleModelFloorEvent(event);
        },
        [handleModelBuildingEvent, handleModelFloorEvent]
    );

    const handleImportComplete = useCallback((isIFCFile) => {
        if (isIFCFile) {
            dispatch({
                type: 'CHANGE_STATE',
                payload: { state: BuildingEditorState.Result },
            });
        } else {
            dispatch({
                type: 'CHANGE_STATE',
                payload: { state: BuildingEditorState.ImportControl },
            });
        }
    }, []);

    const handleImportControlComplete = useCallback(
        () =>
            dispatch({
                type: 'CHANGE_STATE',
                payload: { state: BuildingEditorState.TerrainSelection },
            }),
        []
    );

    const handleTerrainSelectionComplete = useCallback(
        () =>
            dispatch({
                type: 'CHANGE_STATE',
                payload: { state: BuildingEditorState.Buildings },
            }),
        []
    );

    const handleBuildingPlanComplete = useCallback(
        () =>
            dispatch({
                type: 'CHANGE_STATE',
                payload: { state: BuildingEditorState.Result },
            }),
        []
    );

    const handleFloorEditComplete = useCallback(
        () =>
            dispatch({
                type: 'CHANGE_STATE',
                payload: { state: BuildingEditorState.Buildings },
            }),
        []
    );

    const handleBack = useCallback(() => dispatch({ type: 'BACK' }), []);
    const handleComplete = useCallback(() => onComplete(), [onComplete]);

    const handleInputFileImport = useCallback(
        async (inputFile: InputFile, ignoreError: () => unknown) => {
            await onInputFileImport(inputFile, ignoreError);
            const isIFCFile = (inputFile.extension as InputFileExtension) === 'ifc';
            handleImportComplete(isIFCFile);
        },
        [onInputFileImport, handleImportComplete]
    );

    const handleDiscard = useCallback(async () => {
        try {
            await onInputFileDiscard();
            dispatch({ type: 'RESET' });
            setCalculations(undefined);
        } catch (error) {
            // ignored
        }
    }, [onInputFileDiscard]);

    const fa = useMemo(
        () => calculations?.quantities.find((quantity) => quantity.name === 'FA')?.value.toString() ?? '',
        [calculations]
    );

    const handleMuahChange = useCallback((num: string) => onMuahChange?.(parseFloat(num)), [onMuahChange]);
    const handleUSAChange = useCallback((num: string) => onUSAChange?.(parseFloat(num)), [onUSAChange]);

    const fullscreenWrapper = state.state === BuildingEditorState.Import && wizardMode;

    return (
        <Row className={classNames('building-editor', classname)}>
            {(state.state === BuildingEditorState.Import || !inputFile) && (
                <BuildingEditorPlan navPosition={navPosition} fullscreen={fullscreenWrapper}>
                    {wizardMode && (
                        <BuildingPlanImporter
                            initialStep={skipImportIntro ? 'upload' : 'intro'}
                            onImport={handleInputFileImport}
                        />
                    )}

                    {!wizardMode && <BuildingPlanImporter onlyUpload={true} onImport={handleInputFileImport} />}
                </BuildingEditorPlan>
            )}

            {state.state !== BuildingEditorState.Import && inputFile && (
                <BuildingEditorPlan
                    navPosition={navPosition}
                    actions={[
                        {
                            label: t('editor:projects:creator:building-sheet:discard-3d-model'),
                            callback: handleDiscard,
                        },
                    ]}
                >
                    <div className="building-plan-wrapper flex-grow-1">
                        <ErrorBoundary>
                            {isIFCFile ? (
                                <>
                                    {hasIFCError ? <BuildingPlanError error="ifc-error" floating={true} /> : null}
                                    <BuildingPlanIfc file={inputFile} onCalculated={handleIFCCalculations} />
                                </>
                            ) : null}

                            {isDXFFile ? (
                                <BuildingPlanDxf
                                    planData={planData}
                                    planFile={inputFile}
                                    step={mapStateToBuildingPlanStep(state.state)}
                                    viewOptions={viewOptions}
                                    onPlanLoaded={handlePlanLoaded}
                                    onCalculated={handleDXFCalculations}
                                    onModelItemEvent={handleModelItemEvent}
                                />
                            ) : null}
                        </ErrorBoundary>
                    </div>
                </BuildingEditorPlan>
            )}

            {state.state === BuildingEditorState.Import && !wizardMode && (
                <BuildingEditorForm navPosition={navPosition}>
                    <BuildingPlanEdit
                        buildings={orderedBuildings}
                        highlight={state.viewOptions.highlights[0]}
                        onBuildingsUpdate={onBuildingsChange}
                        displayWizardInfo={false}
                        isAreaEditable={true}
                    />
                </BuildingEditorForm>
            )}

            {state.state === BuildingEditorState.ImportControl && (
                <BuildingEditorForm
                    actions={[
                        {
                            label: t('common:continue'),
                            callback: handleImportControlComplete,
                            disabled: !planData.scaleFactor,
                        },
                    ]}
                    navPosition={navPosition}
                >
                    <ImportControlForm
                        scaleFactor={planData.scaleFactor}
                        defaultScaleFactor={1}
                        onScaleFactorChange={handleScaleFactorChange}
                        onComplete={handleImportControlComplete}
                    />
                </BuildingEditorForm>
            )}

            {state.state === BuildingEditorState.TerrainSelection && (
                <BuildingEditorForm
                    actions={[
                        { label: t('common:back'), callback: handleBack },
                        {
                            label: t('common:continue'),
                            callback: handleTerrainSelectionComplete,
                            disabled: !planData.contours?.length,
                        },
                    ]}
                    navPosition={navPosition}
                >
                    <ContourSelectionForm
                        className="contour-selection-form--terrain"
                        contours={state.model?.contours ?? []}
                        selection={planData.contours}
                        hovering={state.viewOptions.highlights[0]?.itemId}
                        onHover={handleContourHover}
                        onSelectionToggle={handleModelItemEvent}
                    />
                </BuildingEditorForm>
            )}

            {state.state === BuildingEditorState.Buildings && (
                <BuildingEditorForm
                    actions={[
                        { label: t('common:back'), callback: handleBack },
                        wizardMode ? { label: t('common:continue'), callback: handleBuildingPlanComplete } : null,
                    ]}
                    navPosition={navPosition}
                >
                    <BuildingPlanEdit
                        buildings={orderedBuildings}
                        isAreaEditable={false}
                        treeType={treeType}
                        displayWizardInfo={wizardMode}
                        onModelItemEvent={handleModelPlanEvent}
                        onBuildingsUpdate={onBuildingsChange}
                        muah={muah}
                        onMuahChange={onMuahChange}
                        usa={usa}
                        onUsaChange={onUSAChange}
                    />
                </BuildingEditorForm>
            )}

            {state.state === BuildingEditorState.Floor && (
                <BuildingEditorForm
                    actions={[{ label: t('common:confirm'), callback: handleFloorEditComplete }]}
                    navPosition={navPosition}
                >
                    <ContourSelectionForm
                        className="contour-selection-form--floor"
                        contours={state.model?.contours ?? []}
                        selection={context?.floor.contours}
                        hovering={state.viewOptions.highlights[0]?.itemId}
                        context={context}
                        onHover={handleContourHover}
                        onSelectionToggle={handleModelItemEvent}
                    />
                </BuildingEditorForm>
            )}

            {state.state === BuildingEditorState.Result && calculations && !isIFCFile && (
                <>
                    <BuildingEditorForm
                        actions={[
                            { label: t('common:edit'), callback: handleBack },
                            { label: t('common:continue'), callback: handleComplete },
                        ]}
                        navPosition={navPosition}
                    >
                        <ModelCalculationResults
                            buildings={orderedBuildings}
                            highlight={viewOptions.highlights[0]}
                            calculations={calculations}
                            onModelItemEvent={handleModelItemEvent}
                        />
                    </BuildingEditorForm>

                    <Col xs={12} md={6}>
                        <ResultsCalculationQuantitiesView quantities={quantities} />
                    </Col>
                </>
            )}

            {state.state === BuildingEditorState.Result && isIFCFile && (
                <>
                    <BuildingEditorForm
                        actions={wizardMode ? [{ label: t('common:continue'), callback: handleComplete }] : undefined}
                        navPosition={navPosition}
                    >
                        {calculations && !hasIFCError && (
                            <>
                                <ModelCalculationResults
                                    buildings={getBuildingDataFromIFCCalculations(calculations as CalculationsIFC)}
                                    highlight={viewOptions.highlights[0]}
                                    calculations={calculations}
                                    onModelItemEvent={handleModelItemEvent}
                                />
                                <Table className="gutter-top">
                                    <tbody>
                                        {wizardMode && (
                                            <>
                                                <ValueEntry
                                                    value={fa}
                                                    labelKey1="fa"
                                                    labelKey2="fa-long"
                                                    onValueUpdated={noop}
                                                    readOnly={true}
                                                    unit="m²"
                                                    decimals={2}
                                                    className="table-border-section"
                                                />

                                                {treeType === MappingNames.TreeWithH && onMuahChange && (
                                                    <ValueEntry
                                                        value={muah?.toString() ?? ''}
                                                        labelKey1="muah"
                                                        labelKey2="muah-long"
                                                        onValueUpdated={handleMuahChange}
                                                        unit="m²"
                                                        decimals={2}
                                                    />
                                                )}

                                                <ValueEntry
                                                    value={usa?.toString() ?? ''}
                                                    labelKey1="usa"
                                                    labelKey2="usa-long"
                                                    onValueUpdated={handleUSAChange}
                                                    unit={MeasureUnit.SQUARE_METER}
                                                    decimals={2}
                                                />
                                            </>
                                        )}
                                    </tbody>
                                </Table>
                            </>
                        )}
                    </BuildingEditorForm>

                    {wizardMode ? (
                        <Col xs={12} md={6}>
                            <ResultsCalculationQuantitiesView quantities={quantities} siaRegulations={siaRegulations} />
                        </Col>
                    ) : null}
                </>
            )}
        </Row>
    );
};
