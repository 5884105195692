import { noop } from 'lodash';
import React from 'react';
import { BaseTextInput, DefaultTextInputProps, useDefaultChangeAndBlur } from './BaseTextInput';

type Props = FormFieldComponentProps<string, DefaultTextInputProps>;

export const TextInput: React.FC<Props> = ({ value = '', onChange, onBlur = noop, lazy, type = 'text', ...props }) => {
    const { currentValue, handleChange, handleBlur } = useDefaultChangeAndBlur(value, type, lazy, onChange, onBlur);
    return <BaseTextInput value={currentValue} onChange={handleChange} onBlur={handleBlur} type={type} {...props} />;
};
