import React from 'react';
import ReactDOM from 'react-dom';
import { Spinner, TextButton } from '../components';

interface DialogConfig {
    title?: string;
    description?: React.ReactNode;
    cancel: string;
    confirm?: string;
    closeOnOutsideClick?: boolean;
}

interface ConfirmDialogProps {
    className?: string;
    title?: string;
    description?: React.ReactNode;
    confirm?: string;
    cancel: string;
    onConfirm: () => unknown;
    onCancel: () => unknown;
    onOverlayClick?: () => void;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
    className,
    title,
    description,
    confirm,
    cancel,
    onConfirm,
    onCancel,
    onOverlayClick,
}) => {
    const [loading, setLoading] = React.useState(false);

    const onDialogClick = React.useCallback((e: React.MouseEvent) => e.stopPropagation(), []);

    const raiseOverlayClick = React.useCallback(() => {
        if (!loading) onOverlayClick?.();
    }, [loading, onOverlayClick]);

    const handleConfirm = React.useCallback(async () => {
        setLoading(true);
        await onConfirm();
    }, [onConfirm]);

    const handleCancel = React.useCallback(async () => {
        setLoading(true);
        await onCancel();
    }, [onCancel]);

    return (
        <div className={`dialog__overlay ${className || ''}`} onClick={raiseOverlayClick}>
            <div className="dialog__content" onClick={onDialogClick} data-testid="dialog-content">
                <div className="dialog__header">{title}</div>
                <div className="dialog__body">{description}</div>
                <div className="dialog__footer">
                    {confirm && !loading && (
                        <TextButton name={confirm} onClick={handleConfirm} testId="dialog-confirm" />
                    )}
                    {loading && (
                        <div className="py-1 px-5">
                            <Spinner size={24} color="#000" />
                        </div>
                    )}
                    {cancel && (
                        <TextButton
                            className="mr-3"
                            name={cancel}
                            onClick={handleCancel}
                            disabled={loading}
                            testId="dialog-cancel"
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export type UseDialog = () => Promise<boolean>;

const DIALOG_CONTAINER_ID = `dialog-container`;

export function useDialog(config: DialogConfig, className?: string): UseDialog {
    const node = React.useMemo(() => {
        const existingNode = document.getElementById(DIALOG_CONTAINER_ID);

        if (existingNode) return existingNode;

        const newNode = document.createElement('div');
        newNode.id = DIALOG_CONTAINER_ID;

        document.body.appendChild(newNode);

        return newNode;
    }, []);

    const unmount = React.useCallback((): void => {
        ReactDOM.unmountComponentAtNode(node);
        node.innerHTML = '';
    }, [node]);

    React.useEffect(() => () => unmount(), [unmount]);

    return React.useCallback<UseDialog>(() => {
        return new Promise<boolean>((resolve): void => {
            const handleConfirm = async () => {
                unmount();
                resolve(true);
            };

            const handleCancel = async () => {
                unmount();
                resolve(false);
            };

            const handleOverlayClick = () => config.closeOnOutsideClick && handleCancel();

            ReactDOM.render(
                <ConfirmDialog
                    className={className}
                    title={config.title}
                    description={config.description}
                    confirm={config.confirm}
                    cancel={config.cancel}
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                    onOverlayClick={handleOverlayClick}
                />,
                node
            );
        });
    }, [
        node,
        unmount,
        config.cancel,
        config.confirm,
        config.title,
        config.description,
        config.closeOnOutsideClick,
        className,
    ]);
}
