import React from 'react';
import { HomeScreenImagesResult, ResourcesService } from '../services/resources/resources-service';

interface Context {
    homescreenResources: HomeScreenImagesResult;
    fetchHomeScreenImages: () => Promise<HomeScreenImagesResult>;
}

const ResourcesContext = React.createContext<null | Context>(null);

interface Props {
    resourcesService: ResourcesService;
}

export const ResourcesProvider: React.FC<Props> = ({ children, resourcesService }) => {
    const [homescreenResources, setHomeScreenResources] = React.useState<HomeScreenImagesResult>({
        Placeholder_1: [],
        Placeholder_2: [],
        Placeholder_3: [],
    });

    const fetchHomeScreenImages = async (): Promise<HomeScreenImagesResult> => {
        const data = await resourcesService.fetchHomeScreenResources();
        setHomeScreenResources(data);

        return data;
    };

    return (
        <ResourcesContext.Provider
            value={{
                homescreenResources,
                // Functions
                fetchHomeScreenImages,
            }}
        >
            {children}
        </ResourcesContext.Provider>
    );
};

export function useResource(): Context {
    const context = React.useContext(ResourcesContext);

    if (context) {
        return context;
    }

    throw new Error(
        `"${useResource.name}" can only be used inside a functional component ` +
            `that is a child of the ${ResourcesProvider.name}`
    );
}
