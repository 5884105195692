import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuthentication } from '../../contexts';
import type { UserInfo } from '../../types';

type OnFailCallback = (props: { requestedPath: string }) => unknown;
type OnPassCallback = () => unknown;

interface Props {
    predicate?: (user: UserInfo) => boolean;
    onFail?: OnFailCallback;
    onPass?: OnPassCallback;
}

export type AuthenticationGuardProps = Props;

export const AuthenticationGuard: React.FC<Props> = ({ children, predicate, onFail, onPass }) => {
    const history = useHistory();
    const { user } = useAuthentication();

    const requestedPath = history.location.pathname;
    const pass = user && (!predicate || predicate(user));

    React.useEffect(() => void (pass && onPass?.()), [pass, onPass]);
    React.useEffect(() => void (!pass && onFail?.({ requestedPath })), [pass, requestedPath, onFail]);

    if (!pass) {
        return null;
    }

    return <>{children}</>;
};
