import type { AxiosError } from 'axios';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { MappingNames } from '../../enums';
import { editorService } from '../../services';

export function useRegulationStatsForMapping(
    mappingName: MappingNames | undefined,
    projectIds: ID[],
    sourceRegulationName: string,
    targetRegulationName: string
): UseQueryResult<ProjectStats | undefined, AxiosError> {
    return useQuery(
        ['regulation-statistics', 'mapping', mappingName, projectIds],
        () => {
            if (!mappingName) return undefined;

            return editorService.getStatsForMapping(
                mappingName,
                projectIds,
                sourceRegulationName,
                targetRegulationName
            );
        },
        { staleTime: 600_0000, refetchOnWindowFocus: false }
    );
}

export function useRegulationStatisticForMapping(
    regulationId: ID,
    mappingName: MappingNames | undefined,
    projectIds: ID[],
    sourceRegulationName: string,
    targetRegulationName: string
): UseQueryResult<RegulationStats | undefined, AxiosError> {
    const projectsStats = useRegulationStatsForMapping(
        mappingName,
        projectIds,
        sourceRegulationName,
        targetRegulationName
    );

    const result = {
        ...projectsStats,
        data: findRegulation(projectsStats.data?.regulationStats, regulationId),
    } as UseQueryResult<RegulationStats | undefined, AxiosError>;

    return result;
}

type EBKPParameters = Parameters<typeof editorService.getStatisticsForEBKP>[0];
/**
 * Fetches the project statistics
 */
export function useEBKPStatistics(
    { regulationName, regulationIds, costIndexId, projectIds, totalCostTypes }: EBKPParameters,
    options: UseQueryOptions<ProjectStats, AxiosError> = {}
): UseQueryResult<ProjectStats, AxiosError<any>> {
    return useQuery<ProjectStats, AxiosError>(
        ['ebkp-statistics', regulationName, { projectIds, costIndexId, regulationIds }],
        () => {
            return editorService.getStatisticsForEBKP({
                regulationName,
                projectIds,
                costIndexId,
                regulationIds,
                totalCostTypes,
            });
        },
        {
            cacheTime: 7_200_000,
            staleTime: 3_600_000,
            refetchOnWindowFocus: false,
            ...options,
        }
    );
}

type UseEBKPStatistcsReturn = Omit<ReturnType<typeof useEBKPStatistics>, 'data'> & {
    data?: RegulationStats;
};
/**
 * Fetches the statistics for one particular regulation
 */
export function useEBKPStatisticsForRegulation({
    regulationId,
    regulationName,
    projectIds,
}: {
    regulationId?: number;
    regulationName: string;
    projectIds?: number[];
}): UseEBKPStatistcsReturn {
    const { data: projectStatistics, ...rest } = useEBKPStatistics(
        {
            regulationIds: regulationId !== undefined ? [regulationId] : undefined,
            regulationName,
            projectIds,
        },
        {
            enabled: regulationId !== undefined,
        }
    );
    const statistic = projectStatistics?.regulationStats.find((item) => item.regulationId === regulationId);

    return {
        data: statistic,
        ...rest,
    };
}

function findRegulation(regulationStats: RegulationStats[] = [], regulationId: number): RegulationStats | undefined {
    return regulationStats.find((item) => item.regulationId === regulationId);
}
