export function getLevels(regulation: StaticRegulation): string[] {
    switch (regulation.name) {
        case 'BKP':
            return getBKPLevels(regulation);
        case 'eBKP-H':
            return getEBKPHLevels(regulation);
        case 'eBKP-T':
        case 'eBKP-T(A)':
        case 'eBKP-T(B)':
            return getEBKPTLevels(regulation);
        case 'eBKP-H2020':
        case 'eBKP-H2020(A)':
        case 'eBKP-H2020(B)':
            return getEBKPH2020Levels(regulation);
    }

    return [regulation.code];
}

export function getLevel(regulation: StaticRegulation): number {
    switch (regulation.name) {
        case 'BKP':
            return getBKPLevel(regulation);
        case 'eBKP-H':
            return getEBKPHLevel(regulation);
        case 'eBKP-T':
        case 'eBKP-T(A)':
        case 'eBKP-T(B)':
            return getEBKPTLevel(regulation);
        case 'eBKP-H2020':
        case 'eBKP-H2020(A)':
        case 'eBKP-H2020(B)':
            return getEBKPH2020Level(regulation);
    }

    return 0;
}

function getBKPLevels(regulation: StaticRegulation): string[] {
    return regulation.code.replace(/\./g, '').split('');
}

function getBKPLevel(regulation: StaticRegulation): number {
    return getBKPLevels(regulation).length;
}

function getEBKPHLevels(regulation: StaticRegulation): string[] {
    return regulation.code.replace(/\./g, ' ').split(' ');
}

function getEBKPHLevel(regulation: StaticRegulation): number {
    return getEBKPHLevels(regulation).length;
}

function getEBKPTLevels(regulation: StaticRegulation): string[] {
    return regulation.code.replace(/\./g, ' ').split(' ');
}

function getEBKPTLevel(regulation: StaticRegulation): number {
    return getEBKPTLevels(regulation).length;
}

function getEBKPH2020Levels(regulation: StaticRegulation): string[] {
    const major = regulation.code.charAt(0);
    const minors = regulation.code
        .substr(1)
        .split('.')
        .filter((s) => !!s);

    return [major, ...minors];
}

function getEBKPH2020Level(regulation: StaticRegulation): number {
    return getEBKPH2020Levels(regulation).length;
}
