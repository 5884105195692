import React from 'react';

import { Col, Row } from 'reactstrap';
import { ViewProps } from '../types';
import { BaseView } from '../View';
import { Commons, Descriptions, FloorUsages, Members, ProjectTags } from './fragments';
import { Sources } from './fragments/Common/Sources';
import { FunctionalUnits } from './fragments/FunctionalUnits/FunctionalUnits';
import { Mobility } from './fragments/Mobility/Mobility';
import { StatutoryPlanningFramework } from './fragments/StatutoryPlanningFramework/StatutoryPlanningFramework';

export const DataView: React.FC<ViewProps> = ({ projects, project, staticData, language, user, changeProject, t }) => (
    <BaseView name="data" title={t('editor:projects:creator:data-sheet:title')}>
        <Commons
            projects={projects}
            project={project}
            language={language}
            staticData={staticData}
            user={user}
            changeProject={changeProject}
            t={t}
        />

        <Members
            project={project}
            roles={staticData.memberRoles}
            language={language}
            changeProject={changeProject}
            t={t}
        />

        <ProjectTags project={project} language={language} changeProject={changeProject} t={t} />

        <FloorUsages project={project} language={language} changeProject={changeProject} t={t} />

        <FunctionalUnits
            changeProject={changeProject}
            project={project}
            language={language}
            staticData={staticData}
            t={t}
        />

        <Row>
            <Col xs="12" lg="6" xl="5">
                <Mobility
                    changeProject={changeProject}
                    project={project}
                    language={language}
                    staticData={staticData}
                    t={t}
                />
            </Col>
            <Col xs="12" lg="6" xl="5">
                <StatutoryPlanningFramework
                    changeProject={changeProject}
                    project={project}
                    language={language}
                    staticData={staticData}
                    t={t}
                />
            </Col>
        </Row>

        <Descriptions
            language={language}
            project={project}
            staticData={staticData}
            changeProject={changeProject}
            t={t}
        />

        <Sources language={language} project={project} changeProject={changeProject} projects={projects} />
    </BaseView>
);
