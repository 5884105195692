import React from 'react';
import { TextInput } from '../../TextInput';
import { SelectBase } from '../SelectBase';
import { SearchSelectOption } from '../types';
import { SelectedOption } from './SelectedOption';

interface SearchSelectProps extends FormFieldComponentProps<any> {
    label: string;
    options: SearchSelectOption[];
    id?: string;
    autoFocus?: boolean;
    required?: boolean;
    disabled?: boolean;
    className?: string;
    multiple?: boolean;
    strict?: boolean;
    testId?: string;

    onBlur?: () => void;
    onFocus?: () => void;
}

export const SearchSelect: React.FC<SearchSelectProps> = ({
    label,
    value,
    onChange,
    required,
    disabled,
    autoFocus,
    className,
    multiple,
    testId,
    onFocus = () => null,
    onBlur = () => null,
    ...props
}) => {
    const [focused, setFocused] = React.useState({
        current: false,
        previous: false,
    });
    const [selection, setSelection] = React.useState<number>(-1);

    const selectedOption = React.useMemo(
        () => props.options.find((option) => option.value === value),
        [value, props.options]
    );

    const [search, setSearch] = React.useState(multiple ? '' : selectedOption?.label || '');

    React.useEffect(() => {
        if (focused.current !== focused.previous) {
            if (focused.current) onFocus();
            else onBlur();
        }
    }, [focused, onFocus, onBlur]);

    React.useEffect(() => {
        if (selectedOption) {
            setSearch(selectedOption.label);
        }
    }, [value, selectedOption]);

    let options = React.useMemo<Array<SearchSelectOption & { searchOpts: string[] }>>(
        () =>
            props.options.map((option) => ({
                ...option,
                searchOpts: [
                    option.label.toLowerCase(),
                    `${option.value}`.toLowerCase(),
                    ...(option.search || []).map((s) => s.toLowerCase()),
                ],
            })),
        [props.options]
    );

    options = React.useMemo(
        () =>
            options.map((option) => {
                if (search.trim().length === 0) {
                    return option;
                }

                const searchSplit = search.toLowerCase().split(' ');

                const hidden = props.strict
                    ? !option.label.toLowerCase().includes(search.toLowerCase())
                    : searchSplit.some((s) => !option.searchOpts.some((opt) => opt.includes(s)));

                return {
                    ...option,
                    hidden,
                };
            }),
        [options, search, props.strict]
    );

    const selectedOptions: SearchSelectOption[] = (options || []).filter((o) => {
        if (multiple && value instanceof Array) return value.includes(o.value);
        return o.value === value;
    });

    const resetSearch = React.useCallback(() => {
        if (multiple) {
            setSearch('');
        } else {
            setSearch(options.find((option) => option.value === value)?.label || '');
        }
    }, [multiple, options, value]);

    const changeOption = React.useCallback(
        (newValue: any) => {
            resetSearch();

            if (multiple) {
                onChange(newValue);
            } else {
                onChange(newValue);
                setFocused((prev) => ({
                    current: false,
                    previous: prev.current,
                }));
            }
        },
        [multiple, onChange, resetSearch]
    );

    const deleteValue = React.useCallback(
        (option: SearchSelectOption) => {
            changeOption(selectedOptions.filter((opt) => opt.value !== option.value).map(({ value: val }) => val));
        },
        [changeOption, selectedOptions]
    );

    const focus = () => {
        if (focused.current === false) {
            setFocused((prev) => ({ current: true, previous: prev.current }));

            setSearch('');

            if (!multiple) {
                setSelection(options.findIndex((option: any) => option.value === value));
            }
        }
    };

    const blur = () => {
        if (focused.current === true) {
            setFocused((prev) => ({ current: false, previous: prev.current }));
            setSelection(-1);
            resetSearch();
        }
    };

    const stopPropagation = React.useCallback((e: React.SyntheticEvent<HTMLElement>) => e.stopPropagation(), []);

    return (
        <SelectBase
            value={value}
            onChange={changeOption}
            options={options}
            className={`form__search-select ${className || ''}`}
            multiple={multiple}
            required={required}
            disabled={disabled}
            focused={focused.current}
            selected={selection}
            onFocus={focus}
            onBlur={blur}
            testId={testId}
        >
            {multiple && selectedOptions.length > 0 && (
                <div className="values" onClick={stopPropagation}>
                    {selectedOptions.map((option) => (
                        <SelectedOption option={option} deleteValue={deleteValue} key={option.key || option.value} />
                    ))}
                </div>
            )}

            {focused.current ? (
                <TextInput
                    value={search}
                    onChange={setSearch}
                    label={`${label} ${required ? '*' : ''}`}
                    autoFocus={true}
                    autoComplete="off"
                    noDefaultClassName={true}
                    wrapperClassName="form__search-select__input"
                    disabled={disabled}
                    {...props}
                />
            ) : (
                <TextInput
                    value={search}
                    onChange={setSearch}
                    label={`${label} ${required ? '*' : ''}`}
                    autoFocus={autoFocus}
                    autoComplete="off"
                    noDefaultClassName={true}
                    wrapperClassName="form__search-select__input"
                    disabled={disabled}
                    {...props}
                />
            )}
        </SelectBase>
    );
};
