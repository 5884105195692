import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'i18next';
import React, { useCallback, useState } from 'react';
import { Col, Row } from 'reactstrap';
import {
    useFunctionalUnitTagCreate,
    useFunctionalUnitTagDelete,
    useFunctionalUnitTagReplace,
    useFunctionalUnitTagUpdate,
    useFunctionalUnitTags,
} from '../../../../../../effects';
import { FunctionalUnit } from './FunctionalUnit';
import { TextButton } from '../../../../../Buttons';
import { TagEditor } from '../../../../../TagEditor';
import { useConfirmDialog } from '../../../../../Dialog';

interface Props {
    project: Project;
    language: EditorLanguage;
    staticData: StaticData;
    changeProject: (update: (project: Project) => Project) => unknown;
    t: TFunction;
}

const NO_TAGS = [] as Tag[];
export const FunctionalUnits: React.FC<Props> = ({ project, changeProject, t, language }) => {
    const [state, setState] = useState({
        editorOpen: false,
    });

    const { data: tags = NO_TAGS } = useFunctionalUnitTags();

    const { mutateAsync: createTag } = useFunctionalUnitTagCreate();
    const { mutateAsync: updateTag } = useFunctionalUnitTagUpdate();
    const { mutateAsync: deleteTag } = useFunctionalUnitTagDelete();
    const { mutateAsync: replaceTag } = useFunctionalUnitTagReplace();

    const openDeletionConfirmDialog = useConfirmDialog({
        title: t('editor:project:parameters:functionalUnits:tags:confirmDelete.title'),
        description: t('editor:project:parameters:functionalUnits:tags:confirmDelete.description'),
        confirm: t('editor:project:parameters:functionalUnits:tags:confirmDelete.confirm'),
        cancel: t('editor:project:parameters:functionalUnits:tags:confirmDelete.cancel'),
    });

    const openReplacementConfirmDialog = useConfirmDialog({
        title: t('editor:project:parameters:functionalUnits:tags:replaceDelete.title'),
        description: t('editor:project:parameters:functionalUnits:tags:replaceDelete.description'),
        confirm: t('editor:project:parameters:functionalUnits:tags:replaceDelete.confirm'),
        cancel: t('editor:project:parameters:functionalUnits:tags:replaceDelete.cancel'),
    });

    const functionalUnits = React.useMemo(() => project.functionalUnits || [], [project.functionalUnits]).map(
        (functionalUnit, index) => ({ ...functionalUnit, index })
    );

    const changeFunctionalUnit = useCallback(
        (update: (unit: FunctionalUnitModel) => FunctionalUnitModel, index: number) => {
            changeProject((p) => ({
                ...p,
                functionalUnits: (p.functionalUnits || []).map((functionalUnit, i) => {
                    if (index === i) {
                        return update(functionalUnit);
                    }
                    return functionalUnit;
                }),
            }));
        },
        [changeProject]
    );

    const deleteFunctionalUnit = useCallback(
        (index: number) => {
            changeProject((p) => ({
                ...p,
                functionalUnits: (p.functionalUnits || []).filter((_, i) => i !== index),
            }));
        },
        [changeProject]
    );

    const addFunctionalUnit = useCallback(() => {
        changeProject((p) => ({
            ...p,
            functionalUnits: [
                ...p.functionalUnits,
                {
                    tag: { id: -1, name: {} },
                    amount: 1,
                } as FunctionalUnitModel,
            ],
        }));
    }, [changeProject]);

    const openTagEditor = useCallback(() => setState((s) => ({ ...s, editorOpen: true })), []);
    const closeTagEditor = useCallback(() => setState((s) => ({ ...s, editorOpen: false })), []);

    const handleTagCreate = useCallback(
        async (tag: TagCreate) => {
            await createTag(tag);
        },
        [createTag]
    );

    const handleTagEdit = useCallback(
        async (tag: Tag) => {
            if (tag.id && tag.id > 0) await updateTag([tag.id, tag]);
        },
        [updateTag]
    );

    const handleTagDelete = useCallback(
        async (tag: Tag) => {
            if (await openDeletionConfirmDialog()) {
                if (tag.id && tag.id > 0) await deleteTag(tag.id);
            }
        },
        [deleteTag, openDeletionConfirmDialog]
    );

    const handleTagReplace = useCallback(
        async (tag: Tag, replacement: Tag) => {
            if (await openReplacementConfirmDialog()) {
                if (tag.id && tag.id > 0 && replacement.id && replacement.id > 0)
                    await replaceTag([tag.id, replacement.id]);
            }
        },
        [replaceTag, openReplacementConfirmDialog]
    );

    return (
        <Row xs={12} className="small-push-bottom">
            <Col>
                <div className="d-flex justify-content-between mb-3">
                    <h3 className="mb-3">{t('editor:project:parameters:functionalUnits:title')}</h3>
                    <TextButton
                        name={t('editor:project:parameters:functionalUnits:tags:showTags')}
                        onClick={openTagEditor}
                    />
                </div>
                {(functionalUnits || []).map((functionalUnit) => (
                    <FunctionalUnit
                        index={functionalUnit.index}
                        functionalUnit={functionalUnit}
                        tags={tags}
                        onChange={changeFunctionalUnit}
                        onDelete={deleteFunctionalUnit}
                        t={t}
                        language={language}
                        key={functionalUnit.index}
                    />
                ))}
                <div
                    className="mt-3 project-editor__action"
                    onClick={addFunctionalUnit}
                    data-testid="add-functional-unit"
                >
                    {`${t('editor:project:parameters:functionalUnits:addFunctionalUnit')} `}
                    <FontAwesomeIcon icon={faPlus} />
                </div>
            </Col>

            {state.editorOpen && tags && (
                <TagEditor
                    title={t('editor:project:parameters:functionalUnits:tags:tagEditor:title')}
                    tags={tags}
                    language={language}
                    onCreate={handleTagCreate}
                    onEdit={handleTagEdit}
                    onDelete={handleTagDelete}
                    onReplace={handleTagReplace}
                    onClose={closeTagEditor}
                    t={t}
                />
            )}
        </Row>
    );
};
