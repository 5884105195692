import React from 'react';
import { connectTree, Forest, IsChildOfFunction, IsDescendantOfFunction, useForest } from './tree';

const isChildOf: IsChildOfFunction<StaticBuildingClassification> = (parent, child) => child.parent?.id === parent?.id;

const isDescendantOf: IsDescendantOfFunction<StaticBuildingClassification> = (ancestor, descendant) => {
    return (
        !ancestor ||
        isChildOf(ancestor, descendant) ||
        descendant.classificationCode.startsWith(ancestor.classificationCode + '.')
    );
};

export function useClassficiationTree(
    classifications: StaticBuildingClassification[],
    connected = false
): Forest<StaticBuildingClassification> {
    const forest = useForest(classifications, isChildOf, isDescendantOf);

    return React.useMemo(() => {
        if (connected) {
            return forest.map((tree) => connectTree(tree));
        }

        return forest;
    }, [connected, forest]);
}
