import { CancelToken } from 'axios';
import ExcelJS from 'exceljs';
import type { TFunction } from 'i18next';
import { capitalize, keyBy, orderBy, sumBy } from 'lodash';

async function loadTemplate(lang: string) {
    const res = await fetch(`${process.env.PUBLIC_URL}/templates/elektrosmog_${lang.toUpperCase()}.xlsx`);

    return res.arrayBuffer();
}

export async function exportElektrosmog(
    project: Project,
    staticData: StaticData,
    usageTags: FloorUsageTag[],
    lang: EditorLanguage,
    t: TFunction,
    cancelToken: CancelToken
): Promise<void> {
    let buffer = await loadTemplate(lang.editor);

    let workbook = new ExcelJS.Workbook();
    workbook = await workbook.xlsx.load(buffer);

    setWMCredits(workbook, project, staticData, lang.codes.editor, t);
    setProjectInformation(workbook, project, staticData, lang.codes.editor);
    setBaseQuantities(workbook, project, staticData, usageTags, t);
    setCreationCosts(workbook, project, staticData);
    setCostIndices(workbook, project, staticData, lang.codes.editor);
    setEnergy(workbook, project, staticData);

    if (cancelToken.reason) return;

    buffer = await workbook.xlsx.writeBuffer();

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (cancelToken.reason) return;

    download(`${project.id}.elektrosmog.xlsx`, buffer, {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
}

function download(name: string, buffer: ArrayBuffer, options?: BlobPropertyBag) {
    const blob = new Blob([buffer], options);

    const link = document.createElement('a');
    link.style.display = 'none';
    document.body.appendChild(link);

    link.href = URL.createObjectURL(blob);
    link.download = name;
    link.click();
}

function setWMCredits(
    book: ExcelJS.Workbook,
    project: Project,
    staticData: StaticData,
    lang: string,
    t: TFunction
): void {
    const sheet = book.getWorksheet('WM-Credits');
    if (!sheet) return;

    const name = textOf(project.name, lang);
    const canton = project.projectAddress?.location?.canton;

    const buildingClassId = (project.buildingClassPartners as ObjectReference | undefined)?.id;
    const buildingClass = buildingClassId
        ? staticData.buildingClassification.partners.find(({ id }) => id === buildingClassId)
        : undefined;

    const classificationCode = buildingClass?.classificationCode ?? '';

    const { streetName, streetNumber } = project.projectAddress ?? {};
    const { zipCode, municipality } = project.projectAddress?.location ?? {};
    const street = join([streetName, streetNumber]);
    const zipCity = join([zipCode, municipality]);

    const rolesMap: Record<string, ProjectMemberRole | undefined> = keyBy(staticData.memberRoles, 'id');
    const builderType = staticData.memberRoles.find(({ name }) => name.de_DE.includes('Name Bauherrschaft'));
    const architectType = staticData.memberRoles.find(({ name }) => name.de_DE.includes('Architektur'));
    const engineerType = staticData.memberRoles.find(({ name }) => name.de_DE === 'Tragwerksplanung');

    const builders: ProjectMember[] = [];
    const architects: ProjectMember[] = [];
    const engineers: ProjectMember[] = [];
    const otherMembers: ProjectMember[] = [];

    project.members?.forEach((member) => {
        if (member.role.id === builderType?.id) builders.push(member);
        else if (member.role.id === architectType?.id) architects.push(member);
        else if (member.role.id === engineerType?.id) engineers.push(member);
        else otherMembers.push(member);
    });

    const organisationDescriptionType = staticData.descriptionTypes.find(
        ({ name }) => name.de_DE === 'Projektorganisation'
    );
    const organisation = project.descriptions?.find(({ type }) => type.id === organisationDescriptionType?.id)?.text;

    sheet.getCell('A1').value = `${name} \n${join([project.projectAddress?.city, canton?.cantonCode])}`;
    sheet.getCell('A2').value = [
        `Werkmaterial ${join([classificationCode, project.source?.internalNumber], '/')}`,
        textOf(buildingClass?.description, lang),
    ].join(' \n');

    sheet.getCell('B4').value = join([street, zipCity]);
    sheet.getCell('B5').value = builders.map((member) => memberToString(member)).join(' \n');
    sheet.getCell('B6').value = architects.map((member) => memberToString(member)).join(' \n');
    sheet.getCell('B7').value = engineers.map((member) => memberToString(member)).join(' \n');
    sheet.getCell('B8').value = otherMembers
        .map((member) => memberToString(member, textOf(rolesMap[member.role.id]?.name, lang)))
        .join(' \n');

    sheet.getCell('B10').value = textOf(organisation, lang);
    sheet.getCell('B11').value = toDate(project.constructionTime?.competition, lang) || capitalize(t('common:no'));
    sheet.getCell('B12').value = toDate(project.constructionTime?.planningStart, lang);
    sheet.getCell('B13').value = toDate(project.constructionTime?.constructionStart, lang);
    sheet.getCell('B14').value = toDate(project.constructionTime?.moveIn, lang);
}

function memberToString(member: ProjectMember, role?: string): string {
    if (!role) return member.name;

    return `${role}: ${member.name}`;
}

function setProjectInformation(book: ExcelJS.Workbook, project: Project, staticData: StaticData, lang: string) {
    const sheet = book.getWorksheet('Projektinformation');
    if (!sheet) return;

    const projectInfoType = staticData.descriptionTypes.find(({ name }) => name.de_DE === 'Projektinformation');
    const roomProgrammType = staticData.descriptionTypes.find(({ name }) => name.de_DE === 'Raumprogram');
    const constructionType = staticData.descriptionTypes.find(({ name }) => name.de_DE === 'Konstruktion');
    const technicType = staticData.descriptionTypes.find(({ name }) => name.de_DE === 'Gebäudetechnik');

    const projectInfo = project.descriptions?.find(({ type }) => projectInfoType && type.id === projectInfoType.id);
    const roomProgramm = project.descriptions?.find(({ type }) => roomProgrammType && type.id === roomProgrammType.id);
    const construction = project.descriptions?.find(({ type }) => constructionType && type.id === constructionType.id);
    const technik = project.descriptions?.find(({ type }) => technicType && type.id === technicType.id);

    sheet.getCell('A2').value = textOf(projectInfo?.text, lang);
    sheet.getCell('A4').value = textOf(roomProgramm?.text, lang);
    sheet.getCell('A6').value = textOf(construction?.text, lang);
    sheet.getCell('A8').value = textOf(technik?.text, lang);
}

function setBaseQuantities(
    book: ExcelJS.Workbook,
    project: Project,
    staticData: StaticData,
    usageTags: FloorUsageTag[],
    t: TFunction
) {
    const sheet = book.getWorksheet('Grundmengen');
    if (!sheet) return;

    const regMeasurements: Record<string, number> = {};

    const quantityOf = (shortname: string): [string, number] => {
        const regulation = staticData.regulations.SIA416.find(({ shortName }) => shortName.de_DE === shortname);

        if (!regulation) return ['', 0];

        const value = project.measurements?.find(({ regulation: reg }) => reg.id === regulation.id)?.value ?? 0;

        if (value) regMeasurements[shortname] = value;

        return [toNum(value), value];
    };

    const floors = project.buildings[0]?.floors ?? [];
    const floorArea = (floorType: FloorType, floorNumber: number): string => {
        const floor = floors.find((floor) => floor.floorType === floorType && floor.floorNumber === floorNumber);

        return toNum(floor?.area ?? 0);
    };

    const getFloorUsageByTagName = (nameDe: string) => {
        const usageTag = usageTags.find(({ name }) => name.de_DE === nameDe);

        if (!usageTag) return undefined;

        return project.usages.find(({ tag }) => tag.id === usageTag.id);
    };
    const service = getFloorUsageByTagName('Dienstleistung');
    const living = getFloorUsageByTagName('Wohnen');
    const office = getFloorUsageByTagName('Büro');
    const tagIds = [service?.tag.id, living?.tag.id, office?.tag.id];
    const otherUsages = project.usages.filter(({ tag }) => !tagIds.includes(tag.id));

    const [gsfText] = quantityOf('GSF');
    sheet.getCell('C4').value = gsfText;

    const [ggfText] = quantityOf('GGF');
    sheet.getCell('C5').value = ggfText;

    const [ufText] = quantityOf('UF');
    sheet.getCell('C6').value = ufText;

    const [bufText] = quantityOf('BUF');
    sheet.getCell('C7').value = bufText;

    const [uufText] = quantityOf('UUF');
    sheet.getCell('C8').value = uufText;

    const [gvText] = quantityOf('GV SIA 416');
    sheet.getCell('C11').value = gvText;

    const [gfText, total] = quantityOf('GF');
    sheet.getCell('C12').value = gfText;

    sheet.getCell('C13').value = floorArea('UNDER_GROUND', 1);
    sheet.getCell('C14').value = floorArea('UNDER_GROUND', 2);
    sheet.getCell('C15').value = floorArea('GROUND', 0);
    sheet.getCell('C16').value = floorArea('ABOVE_GROUND', 1);
    sheet.getCell('C17').value = floorArea('ABOVE_GROUND', 2);
    sheet.getCell('C18').value = floorArea('ABOVE_GROUND', 3);
    sheet.getCell('C19').value = floorArea('ABOVE_GROUND', 4);
    sheet.getCell('C20').value = floorArea('ABOVE_GROUND', 5);
    sheet.getCell('C21').value = floorArea('ABOVE_GROUND', 6);
    sheet.getCell('C22').value = floorArea('ABOVE_GROUND', 7);
    sheet.getCell('C23').value = floorArea('ABOVE_GROUND', 8);
    sheet.getCell('C24').value = floorArea('ABOVE_GROUND', 9);
    sheet.getCell('C25').value = floorArea('ABOVE_GROUND', 10);
    sheet.getCell('C26').value = floorArea('ABOVE_GROUND', 11);
    sheet.getCell('C27').value = floorArea('ABOVE_GROUND', 12);

    const [ngfText, ngf] = quantityOf('NGF');
    sheet.getCell('C28').value = ngfText;
    sheet.getCell('E28').value = toNum((ngf / (total || 1)) * 100);

    const [kfText, kf] = quantityOf('KF');
    sheet.getCell('C29').value = kfText;
    sheet.getCell('E29').value = toNum((kf / (total || 1)) * 100);

    const [nfText, nf] = quantityOf('NF');
    sheet.getCell('C30').value = nfText;
    sheet.getCell('E30').value = toNum((nf / (total || 1)) * 100);

    sheet.getCell('C31').value = service?.area ?? 0;
    sheet.getCell('C32').value = living?.area ?? 0;
    sheet.getCell('C33').value = office?.area ?? 0;
    sheet.getCell('C34').value = sumBy(otherUsages, 'area');

    const [vfText, vf] = quantityOf('VF');
    sheet.getCell('C35').value = vfText;
    sheet.getCell('E35').value = toNum((vf / (total || 1)) * 100);

    const [ffText, ff] = quantityOf('FF');
    sheet.getCell('C36').value = ffText;
    sheet.getCell('E36').value = toNum((ff / (total || 1)) * 100);

    const [hnfText, hnf] = quantityOf('HNF');
    sheet.getCell('C37').value = hnfText;
    sheet.getCell('E37').value = toNum((hnf / (total || 1)) * 100);

    const [nnfText, nnf] = quantityOf('NNF');
    sheet.getCell('C38').value = nnfText;
    sheet.getCell('E38').value = toNum((nnf / (total || 1)) * 100);

    sheet.getCell('C39').value = toNum(project.functionalUnits[0]?.amount ?? 0);
    sheet.getCell('C40').value = toNum(project.projectSpecificReadings?.parkingSpaces);
    sheet.getCell('C41').value = toNum(project.projectSpecificReadings?.vehicleChargingStations);
    sheet.getCell('C42').value = toNum(project.projectSpecificReadings?.cycleParkingSpaces);

    sheet.getCell('C47').value = toNum(project.projectSpecificReadings?.plotRatio ?? 0);
    sheet.getCell('C48').value = project.projectSpecificReadings?.zoneAllocation ?? '';
    sheet.getCell('C49').value = capitalize(
        project.projectSpecificReadings?.siteMasterplan ? t('common:yes') : t('common:no')
    );
    sheet.getCell('C50').value = capitalize(
        project.projectSpecificReadings?.plotRatioBonus ? t('common:yes') : t('common:no')
    );
}

function setCreationCosts(book: ExcelJS.Workbook, project: Project, staticData: StaticData) {
    const sheet = book.getWorksheet('Erstellungskosten');
    if (!sheet) return;

    let rootRegulations = staticData.regulations.BKP.filter(({ code }) => code.length === 1 && code !== '0');
    rootRegulations = orderBy(rootRegulations, 'code');

    const rootRegulationsWithCost = rootRegulations.map((reg) => ({
        reg,
        cost: project.costs?.find(({ regulation }) => regulation.id === reg.id),
    }));
    const total = sumBy(rootRegulationsWithCost, 'cost.value');

    const rootBuildingCostRegulation = rootRegulationsWithCost[1];
    const buildingCostTotal = rootBuildingCostRegulation.cost?.value ?? 0;

    let buildingCostRegulations = staticData.regulations.BKP.filter(
        ({ code }) => code.startsWith('2') && code.length === 2
    );
    buildingCostRegulations = orderBy(buildingCostRegulations, 'code');

    const buildingCosts = buildingCostRegulations.map((reg) => ({
        reg,
        cost: project.costs?.find(({ regulation }) => regulation.id === reg.id),
    }));

    sheet.getCell('C4').value = toNum(rootRegulationsWithCost[0].cost?.value, COST_FORMAT);
    sheet.getCell('E4').value = toNum(((rootRegulationsWithCost[0].cost?.value ?? 0) / (total || 1)) * 100);
    sheet.getCell('C5').value = toNum(rootRegulationsWithCost[1].cost?.value, COST_FORMAT);
    sheet.getCell('E5').value = toNum(((rootRegulationsWithCost[1].cost?.value ?? 0) / (total || 1)) * 100);
    sheet.getCell('C6').value = toNum(rootRegulationsWithCost[2].cost?.value, COST_FORMAT);
    sheet.getCell('E6').value = toNum(((rootRegulationsWithCost[2].cost?.value ?? 0) / (total || 1)) * 100);
    sheet.getCell('C7').value = toNum(rootRegulationsWithCost[3].cost?.value, COST_FORMAT);
    sheet.getCell('E7').value = toNum(((rootRegulationsWithCost[3].cost?.value ?? 0) / (total || 1)) * 100);
    sheet.getCell('C8').value = toNum(rootRegulationsWithCost[4].cost?.value, COST_FORMAT);
    sheet.getCell('E8').value = toNum(((rootRegulationsWithCost[4].cost?.value ?? 0) / (total || 1)) * 100);
    sheet.getCell('C9').value = toNum(rootRegulationsWithCost[5].cost?.value, COST_FORMAT);
    sheet.getCell('E9').value = toNum(((rootRegulationsWithCost[5].cost?.value ?? 0) / (total || 1)) * 100);
    sheet.getCell('C10').value = toNum(rootRegulationsWithCost[6].cost?.value, COST_FORMAT);
    sheet.getCell('E10').value = toNum(((rootRegulationsWithCost[6].cost?.value ?? 0) / (total || 1)) * 100);
    sheet.getCell('C11').value = toNum(rootRegulationsWithCost[7].cost?.value, COST_FORMAT);
    sheet.getCell('E11').value = toNum(((rootRegulationsWithCost[7].cost?.value ?? 0) / (total || 1)) * 100);
    sheet.getCell('C12').value = toNum(rootRegulationsWithCost[8].cost?.value, COST_FORMAT);
    sheet.getCell('E12').value = toNum(((rootRegulationsWithCost[8].cost?.value ?? 0) / (total || 1)) * 100);
    sheet.getCell('C13').value = toNum(total, COST_FORMAT);

    sheet.getCell('C15').value = toNum(rootBuildingCostRegulation.cost?.value, COST_FORMAT);
    sheet.getCell('C16').value = toNum(buildingCosts[0].cost?.value, COST_FORMAT);
    sheet.getCell('E16').value = toNum(((buildingCosts[0].cost?.value ?? 0) / (buildingCostTotal || 1)) * 100);
    sheet.getCell('C17').value = toNum(buildingCosts[1].cost?.value, COST_FORMAT);
    sheet.getCell('E17').value = toNum(((buildingCosts[1].cost?.value ?? 0) / (buildingCostTotal || 1)) * 100);
    sheet.getCell('C18').value = toNum(buildingCosts[2].cost?.value, COST_FORMAT);
    sheet.getCell('E18').value = toNum(((buildingCosts[2].cost?.value ?? 0) / (buildingCostTotal || 1)) * 100);
    sheet.getCell('C19').value = toNum(buildingCosts[3].cost?.value, COST_FORMAT);
    sheet.getCell('E19').value = toNum(((buildingCosts[3].cost?.value ?? 0) / (buildingCostTotal || 1)) * 100);
    sheet.getCell('C20').value = toNum(buildingCosts[4].cost?.value, COST_FORMAT);
    sheet.getCell('E20').value = toNum(((buildingCosts[4].cost?.value ?? 0) / (buildingCostTotal || 1)) * 100);
    sheet.getCell('C21').value = toNum(buildingCosts[5].cost?.value, COST_FORMAT);
    sheet.getCell('E21').value = toNum(((buildingCosts[5].cost?.value ?? 0) / (buildingCostTotal || 1)) * 100);
    sheet.getCell('C22').value = toNum(buildingCosts[6].cost?.value, COST_FORMAT);
    sheet.getCell('E22').value = toNum(((buildingCosts[6].cost?.value ?? 0) / (buildingCostTotal || 1)) * 100);
    sheet.getCell('C23').value = toNum(buildingCosts[7].cost?.value, COST_FORMAT);
    sheet.getCell('E23').value = toNum(((buildingCosts[7].cost?.value ?? 0) / (buildingCostTotal || 1)) * 100);
    sheet.getCell('C24').value = toNum(buildingCosts[8].cost?.value, COST_FORMAT);
    sheet.getCell('E24').value = toNum(((buildingCosts[8].cost?.value ?? 0) / (buildingCostTotal || 1)) * 100);
    sheet.getCell('C25').value = toNum(buildingCosts[9].cost?.value, COST_FORMAT);
    sheet.getCell('E25').value = toNum(((buildingCosts[9].cost?.value ?? 0) / (buildingCostTotal || 1)) * 100);
}

function setCostIndices(
    book: ExcelJS.Workbook,
    project: Project,
    { regulations, costIndices, greaterRegions }: StaticData,
    lang: string
) {
    const sheet = book.getWorksheet('Kostenkennwerte');
    if (!sheet) return;

    const { BKP, SIA416 } = regulations;

    const costIndex = costIndices.find((costIndex) => costIndex.id === project.costIndex.id);
    const greaterRegion = greaterRegions.find((greaterRegion) => greaterRegion.id === costIndex?.greaterRegion.id);

    const bkp2 = BKP.find(({ code }) => code === '2');
    const costBkp2 = project.costs?.find((cost) => cost.regulation.id === bkp2?.id)?.value ?? 0;

    const bkp4 = BKP.find(({ code }) => code === '4');
    const costBkp4 = project.costs?.find((cost) => cost.regulation.id === bkp4?.id)?.value ?? 0;

    const siaGV = SIA416.find(({ shortName }) => shortName.de_DE === 'GV SIA 416');
    const valueGV = project.measurements?.find(({ regulation }) => regulation.id === siaGV?.id)?.value ?? 0;
    const siaGF = SIA416.find(({ shortName }) => shortName.de_DE === 'GF');
    const valueGF = project.measurements?.find(({ regulation }) => regulation.id === siaGF?.id)?.value ?? 0;
    const siaBUF = SIA416.find(({ shortName }) => shortName.de_DE === 'BUF');
    const valueBUF = project.measurements?.find(({ regulation }) => regulation.id === siaBUF?.id)?.value ?? 0;

    const functionalUnitsAmount = sumBy(project.functionalUnits, 'amount');

    sheet.getCell('C3').value = toNum(costBkp2 / (valueGV || 1), COST_INDEX_FORMAT);
    sheet.getCell('C4').value = toNum(costBkp2 / (valueGF || 1), COST_INDEX_FORMAT);
    sheet.getCell('C5').value = toNum(costBkp2 / (functionalUnitsAmount || 1), COST_INDEX_FORMAT);
    sheet.getCell('C6').value = toNum(costBkp4 / (valueBUF || 1), COST_INDEX_FORMAT);

    sheet.getCell('C7').value = toDate(costIndex?.date, lang);
    sheet.getCell('C8').value = textOf(greaterRegion?.name, lang);
}

function setEnergy(book: ExcelJS.Workbook, project: Project, { energyUnits, regulations }: StaticData) {
    const sheet = book.getWorksheet('Energiekennwerte');
    if (!sheet) return;

    const { SIA416 } = regulations;
    const siaGF = SIA416.find(({ shortName }) => shortName.de_DE === 'GF');
    const valueGF = project.measurements?.find(({ regulation }) => regulation.id === siaGF?.id)?.value ?? 0;

    const ebf = energyUnits.find(({ name }) => name.de_DE.includes(' EBF'));
    const aEbf = energyUnits.find(({ name }) => name.de_DE.includes('A/EBF'));
    const qh = energyUnits.find(({ name }) => name.de_DE.includes('Qh') && name.de_DE.includes('kWh'));
    const qww = energyUnits.find(({ name }) => name.de_DE.includes('Qww') && name.de_DE.includes('kWh'));
    const heatRecoveryCoefficient = energyUnits.find(({ name }) =>
        name.de_DE.includes('Wärmerückgewinnungskoeffizient')
    );
    const chpCoefficient = energyUnits.find(
        ({ name }) => name.de_DE.includes('Stromkennzahl') && name.de_DE.includes('380')
    );
    const flowTemperatur = energyUnits.find(({ name }) => name.de_DE.includes('Vorlauftemperatur'));
    const shareRenewableEnergy = energyUnits.find(({ name }) => name.de_DE.includes('Anteil erneuerbare Energie'));
    const sharePhotovoltaic = energyUnits.find(({ name }) => name.de_DE.includes('Anteil Fotovoltaik'));

    const findByUnit = (unit: EnergyUnit | undefined) =>
        project.energyInfo.energies.find(({ energyUnit }) => energyUnit.id === unit?.id);

    const ebfValue = findByUnit(ebf)?.value ?? 0;
    const aEbfValue = findByUnit(aEbf)?.value ?? 0;
    const qhValue = findByUnit(qh)?.value ?? 0;
    const shareRenewableEnergyValue = findByUnit(shareRenewableEnergy)?.value ?? 0;
    const heatRecoveryCoefficientValue = findByUnit(heatRecoveryCoefficient)?.value ?? 0;
    const qwwValue = findByUnit(qww)?.value ?? 0;
    const flowTemperaturValue = findByUnit(flowTemperatur)?.value ?? 0;
    const chpCoefficientValue = findByUnit(chpCoefficient)?.value ?? 0;
    const sharePhotovoltaicValue = findByUnit(sharePhotovoltaic)?.value ?? 0;

    if (ebfValue) sheet.getCell('C3').value = toSquareMeter(ebfValue);
    if (ebfValue && valueGF) sheet.getCell('C4').value = toPercentage(ebfValue / (valueGF || 1));
    if (aEbfValue) sheet.getCell('C5').value = toUnit(aEbfValue);
    if (qhValue) sheet.getCell('C6').value = toUnit(qhValue, 'kWh/m²a');
    if (shareRenewableEnergyValue) sheet.getCell('C7').value = toPercentage(shareRenewableEnergyValue);
    if (heatRecoveryCoefficientValue) sheet.getCell('C8').value = toPercentage(heatRecoveryCoefficientValue);
    if (qwwValue) sheet.getCell('C9').value = toUnit(qwwValue, 'kWh/m²a');
    if (flowTemperaturValue) sheet.getCell('C10').value = toCelsius(flowTemperaturValue);
    if (chpCoefficientValue) sheet.getCell('C11').value = toPercentage(chpCoefficientValue);
    if (sharePhotovoltaicValue) sheet.getCell('C12').value = toPercentage(sharePhotovoltaicValue);
}

function textOf(translations: TranslationMap | undefined, lang: string) {
    if (!translations) return '';

    return translations[lang] || translations.de_DE || Object.values(translations)[0];
}

function toDate(date: string | undefined, lang: string): string {
    if (!date) return '';

    const d = new Date(date);

    lang = lang.substring(0, 2);
    const formatter = new Intl.DateTimeFormat(`${lang}-CH`, { month: 'long', year: 'numeric' });
    return formatter.format(d);
}

const createSwissFormatter = (options?: Intl.NumberFormatOptions) => new Intl.NumberFormat('de-CH', options);

const SWISS_NUM_FORMAT = createSwissFormatter({ useGrouping: true, maximumFractionDigits: 1 });
const COST_FORMAT = createSwissFormatter({ useGrouping: true, maximumFractionDigits: 0 });
const COST_INDEX_FORMAT = createSwissFormatter({ useGrouping: true, maximumFractionDigits: 0 });
function toNum(num = 0, formatter = SWISS_NUM_FORMAT): string {
    return formatter.format(num).replaceAll('’', "'");
}

const createNumberFormatterForUnit = (unit: string | undefined, options?: Intl.NumberFormatOptions) =>
    createSwissFormatter({ ...options, unit } as Intl.NumberFormatOptions);

const TEMP_FORMAT = createNumberFormatterForUnit('celsius', { minimumFractionDigits: 1, maximumFractionDigits: 1 });
function toCelsius(value: number | undefined, formatter = TEMP_FORMAT) {
    return format(value, formatter, '°C');
}

const PERCENTAGE_FORMAT = createNumberFormatterForUnit('percent', {
    minimumFractionDigits: 1,
    maximumFractionDigits: 2,
});
function toPercentage(value: number | undefined) {
    return format(value, PERCENTAGE_FORMAT, '%', true);
}

const SQUARE_METER_FORMAT = createSwissFormatter({ minimumFractionDigits: 1, maximumFractionDigits: 1 });
function toSquareMeter(value: number | undefined) {
    return format(value, SQUARE_METER_FORMAT, 'm²');
}

function toUnit(value: number | undefined, unit?: string, options?: Intl.NumberFormatOptions) {
    if (value === undefined) return '';

    const formatter = createNumberFormatterForUnit(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 1,
        ...options,
    });

    return format(value, formatter, unit);
}

function format(value: number | undefined, formatter: Intl.NumberFormat, unit?: string, close?: boolean): string {
    if (value === undefined) return '';

    const formattedValue = formatter.format(value);

    if (!unit) return formattedValue;
    if (close) return `${formattedValue}${unit}`;

    return `${formattedValue} ${unit}`;
}

function join(args: unknown[], separator = ' ') {
    return args.filter((arg) => arg !== undefined && arg !== null).join(separator);
}
