import type {
    Steps as BuildingPlanStep,
    Calculations,
    CalculationsIFC,
    FloorArea,
    InputFile,
    PlanData,
    QuantitiesKey,
    QuantitiesKeyIFC,
    UsageCalculation,
} from '@crb-oa-viewer/data-assistant-building-plan';
import { Dictionary, keyBy } from 'lodash';
import type { InputFileExtension } from '../../enums';
import { getSignedFloorNumber, mapProjectBuildingToBuildingPlanBuilding } from '../../utils';
import { MappingNames } from './../../enums/bkp-to-ebkph';
import type { State } from './reducer';

export enum BuildingEditorState {
    Import = 'import',
    ImportControl = 'control',
    TerrainSelection = 'terrain',
    Buildings = 'buildings',
    Floor = 'floor',
    Result = 'result',
}

type InitialStates =
    | BuildingEditorState.Import
    | BuildingEditorState.ImportControl
    | BuildingEditorState.TerrainSelection
    | BuildingEditorState.Buildings
    | BuildingEditorState.Result;

export function getInitialState(
    inputFile: InputFile | undefined,
    planData: PlanData,
    wizardMode?: boolean,
    treeType?: MappingNames,
    muah?: number
): InitialStates {
    /**
     * Show Import step when there's no file
     */
    if (!inputFile) return BuildingEditorState.Import;
    /**
     * Show Result step when it is ifc file
     */
    if ((inputFile.extension as InputFileExtension) === 'ifc') return BuildingEditorState.Result;
    /**
     * Detect following step when it is dxf file
     */
    if ((inputFile.extension as InputFileExtension) === 'dxf') {
        if (!planData.scaleFactor) return BuildingEditorState.ImportControl;
        if (!planData.contours || planData.contours.length === 0) return BuildingEditorState.TerrainSelection;
        if (!wizardMode || (treeType === MappingNames.TreeWithH && !muah)) return BuildingEditorState.Buildings;
    }

    return BuildingEditorState.Result;
}

export function initializeState(
    inputFile: InputFile | undefined,
    planData: PlanData,
    wizardMode?: boolean,
    treeType?: MappingNames,
    muah?: number
): State {
    const state = getInitialState(inputFile, planData, wizardMode, treeType, muah);

    switch (state) {
        case BuildingEditorState.Import:
            return createState(BuildingEditorState.Import, 'orthographic');
        case BuildingEditorState.ImportControl:
            return createState(BuildingEditorState.ImportControl, 'orthographic');
        case BuildingEditorState.TerrainSelection:
            return createState(BuildingEditorState.TerrainSelection, 'orthographic');
        case BuildingEditorState.Buildings:
            return createState(BuildingEditorState.Buildings, 'perspective');
        case BuildingEditorState.Result:
            return createState(BuildingEditorState.Result, 'perspective');
        default:
            throw new Error('IllegalArgument');
    }
}

function createState(state: BuildingEditorState, cameraMode: 'orthographic' | 'perspective'): State {
    return {
        state,
        viewOptions: { cameraMode, highlights: [] },
    } as State;
}

export function mapStateToBuildingPlanStep(step: BuildingEditorState): BuildingPlanStep {
    switch (step) {
        case BuildingEditorState.ImportControl:
            return 'setScaleFactor';
        case BuildingEditorState.TerrainSelection:
            return 'selectContour';
        case BuildingEditorState.Buildings:
            return 'defineStructure';
        case BuildingEditorState.Floor:
            return 'defineStructure';
        case BuildingEditorState.Result:
            return 'showResults';
        default:
            throw new Error('Invalid argument');
    }
}
const changePlanData = (project: Project, change: React.SetStateAction<ProjectPlanData | undefined>): Project => ({
    ...project,
    planData: typeof change === 'function' ? change(project.planData) : change,
});

export const changeDXF = (project: Project, dxf: string | undefined | null): Project =>
    changePlanData(project, (d = { scaleFactor: 1 }) => (dxf ? { ...d, file: { src: dxf } } : undefined));

export const changeScaleFactor = (project: Project, scaleFactor: number): Project =>
    changePlanData(project, (d) => ({ ...d, scaleFactor }));

export const changeTerrainContours = (project: Project, terrainContours?: string[]): Project =>
    changePlanData(project, (d = { scaleFactor: 1 }) => ({ ...d, terrainContours }));

export const toggleTerrainContour = (project: Project, contourId: string): Project =>
    changePlanData(project, ({ terrainContours = [], ...d } = { scaleFactor: 1 }) => {
        const containsId = terrainContours.includes(contourId);
        terrainContours = terrainContours.filter((cId) => cId !== contourId);

        if (!containsId) terrainContours.push(contourId);

        return {
            ...d,
            terrainContours,
        };
    });

export const toggleFloorContour = (
    project: Project,
    contourId: string,
    context: { buildingId: string; floorId: string }
): Project => ({
    ...project,
    buildings: project.buildings.map((b) => {
        if (b.id !== context.buildingId) return b;

        return {
            ...b,
            floors: b.floors.map((f) => {
                if (f.id !== context.floorId) return f;

                let { contours = [] } = f;
                const containsId = contours.includes(contourId);
                contours = contours.filter((cId) => cId !== contourId);

                if (!containsId) contours.push(contourId);

                return {
                    ...f,
                    contours,
                };
            }),
        };
    }),
});

export const changeFloorContours = (
    project: Project,
    buildingId: string,
    floorId: string,
    contours: string[] = []
): Project => ({
    ...project,
    buildings: project.buildings.map((b) => {
        if (b.id !== buildingId) return b;

        return {
            ...b,
            floors: b.floors.map((f) => {
                if (f.id !== floorId) return f;

                return {
                    ...f,
                    contours,
                };
            }),
        };
    }),
});

export const updateCalculations = (project: Project, calculations: Calculations): Project => {
    const floorAreaMap: Dictionary<FloorArea | undefined> = keyBy(calculations.floorAreas, ({ floorId }) => floorId);

    const floors = project.buildings.flatMap((b) => b.floors);
    const floorAreaChanged = floors.some((f) => (f.area || 0) !== Math.max(floorAreaMap[f.id]?.area || 0, 0));

    if (!floorAreaChanged) return project;

    // TODO: What else needs to be updated?

    return {
        ...project,
        buildings: project.buildings.map((b) => ({
            ...b,
            floors: b.floors.map((f) => ({
                ...f,
                area: Math.max(floorAreaMap[f.id]?.area || 0, 0),
            })),
        })),
    };
};

export function orderBuildingFloors(buildings: Building[], language: EditorLanguage): Building[] {
    const floorRank = (floor: Floor) => floor.floorNumber * (floor.floorType === 'UNDER_GROUND' ? -1 : 1);

    return buildings
        .map((b) => ({
            ...b,
            // Sort by floor rank
            floors: [...b.floors].sort((f1, f2) => floorRank(f2) - floorRank(f1)),
        }))
        .sort((b1, b2) => {
            const langOrder = [language.codes.documentation, language.codes.editor, 'de_DE', 'en_GB', 'fr_FR', 'it_IT'];

            // Sort by name in langOrder
            for (const lang in langOrder) {
                if (b1.name[lang] && b2.name[lang]) return b1.name[lang].localeCompare(b2.name[lang]);
            }

            // Return as is
            return 0;
        });
}

export function getOrderedBuildingsAndPlanData(
    project: Project | undefined,
    language: EditorLanguage
): { orderedBuildings: Building[]; planData: PlanData } {
    const orderedBuildings = orderBuildingFloors(project?.buildings || [], language);
    const planBuildings = orderedBuildings.map((b) =>
        mapProjectBuildingToBuildingPlanBuilding(b, language.codes.editor)
    );
    const planData = {
        buildings: planBuildings,
        contours: project?.planData?.terrainContours,
        scaleFactor: project?.planData?.scaleFactor,
    };

    return {
        orderedBuildings,
        planData,
    };
}

/**
 * IFC only has one building so far.
 */
const DEFAULT_IFC_BUILDING_NAME: TranslationMap = {
    de_DE: 'Gebäude 1',
    en_GB: 'Building 1',
    fr_FR: 'Bâtiment 1',
    it_IT: 'Edificio 1',
};
export function getBuildingDataFromIFCCalculations(calculations: CalculationsIFC): Building[] {
    const floors: Floor[] = calculations.floorsCalculations
        .sort((a, b) => getSignedFloorNumber(b) - getSignedFloorNumber(a))
        .map((item, index) => ({
            ...item,
            id: index.toString(),
            floorFunctions: [],
        }));

    const building = {
        id: '1',
        name: DEFAULT_IFC_BUILDING_NAME,
        floors,
        referenceHeight: 0,
    };
    return [building];
}
/**
 * Get measurements from IFC calculations.
 * Not all measurements from IFC calculations are used in SIA. Only update values which are used in SIA.
 */
export function getMeasurementsFromIFC(
    calculations: CalculationsIFC,
    siaRegulations: StaticRegulation[]
): ProjectMeasurement[] {
    const quantityShortNameToValueMap = createShortNameToValueMap(calculations);

    return siaRegulations.map((regulation) => ({
        regulation,
        value: quantityShortNameToValueMap.get(regulation.shortName.de_DE as QuantitiesKey | QuantitiesKeyIFC) ?? 0,
    }));
}

/**
 * Map of quantity short name to quantity value
 */
function createShortNameToValueMap({ quantities }: CalculationsIFC) {
    const map: Map<QuantitiesKey | QuantitiesKeyIFC, number> = new Map();

    for (const quantity of quantities) {
        map.set(quantity.name, quantity.value);
    }

    return map;
}

const SIA416_CODE_TO_HIDE = '5';
/**
 * Retrieve sorted SIA Regulations form static data
 */
export function getSIARegulations(staticData: StaticData): StaticRegulation[] {
    const SIA116Regulations = staticData.regulations.SIA116;

    let SIA416Regulations = staticData.regulations.SIA416;
    const needSIA416_5_Filter = SIA416Regulations.some((r) => r.code.startsWith(`${SIA416_CODE_TO_HIDE}:`));
    if (needSIA416_5_Filter) {
        SIA416Regulations = SIA416Regulations.filter((r) => r.code !== SIA416_CODE_TO_HIDE);
    }

    const MengenRegulations = staticData.regulations.Mengen;

    const SIARegulations = SIA116Regulations.concat(SIA416Regulations).concat(MengenRegulations);

    const SIARegulationsWithCategoryCodeNum = SIARegulations.map(addCategoryCodeNumToRegulation);

    const sortedSIARegulations = SIARegulationsWithCategoryCodeNum.sort(sortByCategoryCodeNum);

    return sortedSIARegulations;
}

/**
 * Add extracted number of each level of code, in order to sort regulation easily.
 */
function addCategoryCodeNumToRegulation(regulation: StaticRegulation) {
    /**
     * Extract category numbers from regulation code.
     */
    const [firstLevelCategoryCode, secondLevelCategoryCode, thirdLevelCategoryCode, forthLevelCategoryCode] =
        regulation.code.split(/[^\d.]/g)[0].split('.');

    return {
        ...regulation,
        firstLevelCategoryCodeNum: parseInt(firstLevelCategoryCode) || 0,
        secondLevelCategoryCodeNum: parseInt(secondLevelCategoryCode) || 0,
        thirdLevelCategoryCodeNum: parseInt(thirdLevelCategoryCode) || 0,
        forthLevelCategoryCodeNum: parseInt(forthLevelCategoryCode) || 0,
    };
}

type RegulationWithOrders = StaticRegulation & {
    firstLevelCategoryCodeNum: number;
    secondLevelCategoryCodeNum: number;
    thirdLevelCategoryCodeNum: number;
    forthLevelCategoryCodeNum: number;
};
/**
 * Regulation codes have the style like '5.1.2.3'.
 */
function sortByCategoryCodeNum(r1: RegulationWithOrders, r2: RegulationWithOrders) {
    return (
        r1.firstLevelCategoryCodeNum - r2.firstLevelCategoryCodeNum ||
        r1.secondLevelCategoryCodeNum - r2.secondLevelCategoryCodeNum ||
        r1.thirdLevelCategoryCodeNum - r2.thirdLevelCategoryCodeNum ||
        r1.forthLevelCategoryCodeNum - r2.forthLevelCategoryCodeNum ||
        r1.code.length - r2.code.length
    );
}

export function convertToUsages(
    regulations: StaticRegulation[],
    usageTags: FloorUsageTag[],
    usageCalculations: UsageCalculation[]
): FloorUsage[] {
    return usageCalculations
        .map(
            (usageCalculation): Partial<FloorUsage> => ({
                area: usageCalculation.floorArea,
                regulation: regulations.find(({ shortName }) => shortName.de_DE === usageCalculation.crbCode),
                tag: usageTags.find(({ name }) => name.de_DE === usageCalculation.usage) ?? {
                    id: -1,
                    name: { de_DE: usageCalculation.usage },
                },
            })
        )
        .filter((usage): usage is FloorUsage => !!usage.regulation && !!usage.tag);
}
