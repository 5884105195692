import { useStaticData } from './queries';
import { MappingNames } from '../enums';
import { useMemo } from 'react';

export function useDefaultRegulationPartitions(mappingName?: MappingNames): RegulationPartition[] | undefined {
    const { data: staticData } = useStaticData();

    return useMemo(() => {
        if (!mappingName) return;

        return staticData?.regulationMappings.find(({ name }) => name === mappingName)?.defaultRegulationPartitions;
    }, [staticData, mappingName]);
}

export function useDefaultRegulationPartition(
    mappingName: MappingNames | undefined,
    regulationId: ID
): number | undefined {
    const partitions = useDefaultRegulationPartitions(mappingName);

    return useMemo(
        () => partitions?.find(({ regulation }) => regulation.id === regulationId)?.size,
        [partitions, regulationId]
    );
}
