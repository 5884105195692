import classNames from 'classnames';
import React from 'react';

interface Props {
    name: string;
    path: string;
    component: React.ComponentType<FormFieldComponentProps<any>>;
    props: any;
    field: Field;
    form: {
        valid: boolean;
        state: FormState;
        getValue: (path: string) => any | null;
    };
    changeValue: (value: any, meta: FieldMeta) => unknown;
    onTouched: () => unknown;
    register: () => unknown;
}

export const FormFieldRenderer: React.FC<Props> = (props) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => void props.register(), []);

    const { field } = props;

    if (!field) {
        return null;
    }

    const changeValue = (value: any, meta: FieldMeta) => props.changeValue(value, meta);

    const touch = () => {
        if (!props.field.meta.touched) {
            props.onTouched();
        }
    };

    const className = classNames(
        props.props.className,
        field.valid ? 'valid' : 'invalid',
        props.field.meta.touched ? 'touched' : 'pristine'
    );

    return (
        <props.component
            {...props.props}
            className={className}
            name={props.name}
            value={field.value}
            onChange={changeValue}
            onTouched={touch}
            meta={{
                touched: field.meta.touched,
                pristine: field.meta.pristine,
            }}
            form={props.form}
        />
    );
};
