export function useTagSelectorValue(
    tag: ProjectTag | undefined | null,
    language: string
): [ProjectTag | null, number | null, string] {
    if (!tag) return [null, null, ''];

    const valueId = tag.id;
    const valueName = tag.name[language] ?? tag.name.de_DE ?? Object.values(tag.name)[0] ?? '';

    return [tag, valueId, valueName];
}
