import React from 'react';
import { Container } from 'reactstrap';

interface State {
    hasError: boolean;
    error?: Error | null;
}

export interface ErrorBoundaryFallbackProps {
    error: Error;
}

interface Props extends Record<string, unknown> {
    fallback?: React.ComponentType<ErrorBoundaryFallbackProps>;
}

export class ErrorBoundary extends React.PureComponent<Props, State> {
    public static getDerivedStateFromError(error: Error): State {
        return { hasError: true, error };
    }
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    public render(): React.ReactNode {
        if (this.state.hasError) {
            const { fallback: Fallback } = this.props;

            if (Fallback && this.state.error) return <Fallback error={this.state.error} />;

            return (
                <Container className="justify-content-center">
                    <h1 data-testid="error-heading">Something went wrong</h1>
                    <b>{this.state.error ? this.state.error.message : ''}</b>
                </Container>
            );
        }

        return this.props.children || null;
    }
}
