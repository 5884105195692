import type { Calculations, ModelItemEvent } from '@crb-oa-viewer/data-assistant-building-plan';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'reactstrap';
import { BuildingCalculationResults } from './BuildingCalculationResults';
import { FeedbackMessagesView } from './FeedbackMessagesView';

interface Props {
    buildings: Building[];
    highlight?: Highlight;
    onModelItemEvent: (modelItemEvent: ModelItemEvent) => void;
    calculations?: Calculations;
}

const handleDelete = (ids: Set<string>, id: string): Set<string> => {
    const idsSet = new Set(ids);
    idsSet.delete(id);
    return idsSet;
};

const handleAdd = (ids: Set<string>, id: string): Set<string> => {
    return new Set(ids).add(id);
};

export const ModelCalculationResults: React.FC<Props> = ({ buildings, highlight, onModelItemEvent, calculations }) => {
    const [openBuildings, setOpenBuildings] = React.useState(new Set(buildings.map((item) => item.id)));

    const toggleBuilding = React.useCallback(
        (id: string) =>
            setOpenBuildings(openBuildings.has(id) ? handleDelete(openBuildings, id) : handleAdd(openBuildings, id)),
        [openBuildings]
    );

    const highlights = useMemo(() => {
        return highlight ? [highlight] : [];
    }, [highlight]);

    const { t } = useTranslation();
    const tt = (key: string, options?: AnyObject) =>
        t(`editor:bkp-to-ebkph-wizard:volume-evaluation:results:${key}`, options);

    return (
        <>
            <Table className="gutter-top w-100 model-calculation-results" responsive={true}>
                <thead>
                    <tr>
                        <th className="border-top-0" />
                        <th className="border-top-0 text-left">{tt('name')}</th>
                        <th className="border-top-0 text-center">{tt('height')}</th>
                        <th className="border-top-0 text-right">{tt('area')}</th>
                    </tr>
                </thead>
                <tbody>
                    {buildings.map((building) => (
                        <BuildingCalculationResults
                            key={building.id}
                            building={building}
                            isOpen={openBuildings.has(building.id)}
                            highlights={highlights}
                            onOpenToggle={toggleBuilding}
                            onModelItemEvent={onModelItemEvent}
                        />
                    ))}
                </tbody>
            </Table>

            {calculations && <FeedbackMessagesView messages={calculations.messages} />}
        </>
    );
};
