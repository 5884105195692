import { AxiosError } from 'axios';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { editorService } from '../../services';

export function useProjectInputFileUpload(projectId: ID): UseMutationResult<unknown, AxiosError, InputFileUpload> {
    const queryClient = useQueryClient();

    return useMutation((file: InputFileUpload) => editorService.uploadProjectInputFile(projectId, file), {
        onSuccess: () => queryClient.invalidateQueries(['project', projectId, 'inputfile'], { inactive: true }),
    });
}
