import React from 'react';

interface Props {
    show?: boolean;
}

export const SidebarOverlay: React.FC<Props> = ({ show }) => {
    const [initialized, setInitialized] = React.useState(false);

    React.useLayoutEffect(() => {
        if (show) {
            const id = setTimeout(() => setInitialized(true));
            return () => clearTimeout(id);
        }
    }, [show]);

    const onTransitioned = () => {
        if (!show) {
            setInitialized(false);
        }
    };

    if (!show && !initialized) {
        return null;
    }

    return (
        <div
            className={`sidebar-overlay ${initialized && show ? 'sidebar-overlay--show' : ''}`}
            onTransitionEnd={onTransitioned}
        />
    );
};
