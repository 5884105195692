import classNames from 'classnames';
import React from 'react';
import { TextButton } from '../../Buttons';
import type { BuildingEditorActionItem } from '../types';

interface BuildingEditorFormActionProps {
    className?: string;
    action: BuildingEditorActionItem;
}

export const BuildingEditorFormAction: React.FC<BuildingEditorFormActionProps> = ({ className, action }) => (
    <div className={classNames('building-editor-form-action', className)}>
        <TextButton name={action.label} onClick={action.callback} disabled={action.disabled} />
    </div>
);
