export function codeOf(language: string): LanguageWithCode {
    switch (language) {
        case 'de':
            return 'de_DE';
        case 'fr':
            return 'fr_FR';
        case 'it':
            return 'it_IT';
        case 'en':
            return 'en_GB';
        default:
            return `${language}_${language.toUpperCase()}`;
    }
}

export function resolveTranslation(translationMap: TranslationMap, language: string): string {
    return translationMap[codeOf(language)];
}
