import React, { useCallback } from 'react';
import { Container, Row } from 'reactstrap';
import { useDialog, useProjectInputFileDelete, useProjectInputFileUpload } from '../../../../effects';
import { useProjectInputFile } from '../../../../effects/queries/use-project-input-file';
import { BuildingEditor } from '../../../BuildingEditor';
import { Spinner } from '../../../Spinner';
import { ViewProps } from '../types';
import { BaseView } from '../View';

export const BuildingView: React.FC<ViewProps> = ({ project, t, changeProject }) => {
    const { data: inputFile, isLoading: isProjectInputFileDownloading } = useProjectInputFile(project.id);

    const { mutate: uploadProjectInputFile } = useProjectInputFileUpload(project.id);
    const handleInputFileImport = useCallback(
        (inputFile) =>
            new Promise((res, rej) =>
                uploadProjectInputFile(inputFile, {
                    onSuccess: res,
                    onError: rej,
                })
            ),
        [uploadProjectInputFile]
    );

    const { mutateAsync: deleteProjectInputFile } = useProjectInputFileDelete(project.id);
    const inputFileDeleteDialog = useDialog({
        title: t('editor:projects:creator:building-sheet.dialog.delete-input-file.title'),
        description: t('editor:projects:creator:building-sheet.dialog.delete-input-file.description'),
        confirm: t('editor:projects:creator:building-sheet.dialog.delete-input-file.confirm'),
        cancel: t('editor:projects:creator:building-sheet.dialog.delete-input-file.cancel'),
    });
    const handleInputFileDiscard = useCallback(async () => {
        const result = await inputFileDeleteDialog();

        if (!result) throw new Error('Aborted');

        await deleteProjectInputFile({});

        changeProject((p) => ({
            ...p,
            buildings: p.buildings.map((b) => ({
                ...b,
                floors: b.floors.map((f) => ({
                    ...f,
                    contours: [],
                })),
            })),
            planData: undefined,
        }));
    }, [inputFileDeleteDialog, deleteProjectInputFile, changeProject]);

    return (
        <BaseView name="building" title={t('editor:projects:creator:building-sheet:title')}>
            <Container className="project-editor-view-content">
                {isProjectInputFileDownloading && (
                    <Row className="justify-content-center">
                        <Spinner />
                    </Row>
                )}

                {!isProjectInputFileDownloading && (
                    <BuildingEditor
                        classname="building-editor--project"
                        inputFile={inputFile}
                        project={project}
                        onProjectChange={changeProject}
                        navPosition="bottom"
                        onInputFileImport={handleInputFileImport}
                        onInputFileDiscard={handleInputFileDiscard}
                    />
                )}
            </Container>
        </BaseView>
    );
};
