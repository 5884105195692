import type { ModelItemEvent } from '@crb-oa-viewer/data-assistant-building-plan';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { codeOf } from '../../../utils';
import { FloorCalculationResults } from './FloorCalculationResults';

interface BuildingCalculationResults {
    className?: string;
    building: Building;
    isOpen: boolean;
    highlights: Highlight[];
    onOpenToggle: (id: string) => void;
    onModelItemEvent: (modelItemEvent: ModelItemEvent) => void;
}

export const BuildingCalculationResults: React.FC<BuildingCalculationResults> = ({
    className,
    building,
    isOpen,
    highlights,
    onOpenToggle,
    onModelItemEvent,
}) => {
    const { i18n } = useTranslation();
    const handleOpenToggle = React.useCallback(() => onOpenToggle(building.id), [building.id, onOpenToggle]);

    const handleFloorHover = React.useCallback(
        (floorId) => {
            onModelItemEvent({
                itemId: floorId,
                itemType: 'floor',
                eventType: 'hover',
            });
        },
        [onModelItemEvent]
    );

    const isHoveringBuilding =
        highlights.filter((h) => h.itemType === 'building' && h.itemId === building.id).length > 0;
    const isHoveringFloor = (floorId: string) =>
        highlights.filter((h) => h.itemType === 'floor' && h.itemId === floorId).length > 0;

    return (
        <>
            <tr className={classNames(className, 'hoverable')} data-hover={isHoveringBuilding}>
                <td onClick={handleOpenToggle} data-testid="building-toggle-open">
                    <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
                </td>
                <td colSpan={3}>{building.name[codeOf(i18n.language)]}</td>
            </tr>

            {isOpen && (
                <>
                    {building.floors.map((floor) => (
                        <FloorCalculationResults
                            floor={floor}
                            highlighted={isHoveringFloor(floor.id)}
                            onHover={handleFloorHover}
                            key={floor.id}
                        />
                    ))}
                </>
            )}
        </>
    );
};
