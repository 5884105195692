import React, { MouseEvent, useCallback, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { TextButton } from '../Buttons';
import { Spinner } from '../Spinner';
import { Dialog, DialogProps, useDialogWrapper } from './Dialog';

export interface ConfirmDialogProps extends Omit<DialogProps, 'body' | 'footer'> {
    title?: string;
    description?: string;
    confirm?: string;
    cancel?: string;
    onConfirm?: (event: MouseEvent<HTMLElement>) => unknown;
    onCancel?: (event: MouseEvent<HTMLElement>) => unknown;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
    description,
    confirm,
    cancel,
    onConfirm,
    onCancel,
    ...props
}) => {
    const [loading, setLoading] = React.useState(false);

    const handleConfirm = React.useCallback(
        async (event: MouseEvent<HTMLElement>) => {
            setLoading(true);
            await onConfirm?.(event);
        },
        [onConfirm]
    );

    const handleCancel = React.useCallback(
        async (event: MouseEvent<HTMLElement>) => {
            setLoading(true);
            await onCancel?.(event);
        },
        [onCancel]
    );

    return (
        <Dialog
            {...props}
            body={description}
            footer={(
                <>
                    {confirm && !loading && (
                        <TextButton name={confirm} onClick={handleConfirm} testId="dialog-confirm" />
                    )}
                    {loading && (
                        <div className="py-1 px-5">
                            <Spinner size={24} color="#000" />
                        </div>
                    )}
                    {cancel && (
                        <TextButton
                            className="mr-3"
                            name={cancel}
                            onClick={handleCancel}
                            disabled={loading}
                            testId="dialog-cancel"
                        />
                    )}
                </>
              )}
        />
    );
};

export type OpenConfirmDialog = () => Promise<boolean>;

export function useConfirmDialog({
    className,
    title,
    description,
    cancel,
    confirm,
    onCancel,
    onConfirm,
    closeOnOverlayClick,
    onClose,
}: ConfirmDialogProps): OpenConfirmDialog {
    const [wrapper, remount] = useDialogWrapper();

    const props = useMemo(
        () => ({
            className,
            title,
            description,
            cancel,
            confirm,
            onCancel,
            onConfirm,
            closeOnOverlayClick,
            onClose,
        }),
        [className, title, description, cancel, confirm, onCancel, onConfirm, closeOnOverlayClick, onClose]
    );

    useEffect(() => () => void ReactDOM.unmountComponentAtNode(wrapper), [wrapper, props]);
    useEffect(() => () => wrapper.remove(), [wrapper]);

    return useCallback(() => {
        return new Promise<boolean>((resolve): void => {
            const handleConfirm = async () => {
                // Unmount
                ReactDOM.unmountComponentAtNode(wrapper);

                resolve(true);

                props.onClose?.();
            };

            const handleCancel = async () => {
                // Unmount
                ReactDOM.unmountComponentAtNode(wrapper);

                resolve(false);

                props.onClose?.();
            };

            const handleClose = async () => handleCancel();

            ReactDOM.render(
                <ConfirmDialog {...props} onCancel={handleCancel} onConfirm={handleConfirm} onClose={handleClose} />,
                remount()
            );
        });
    }, [wrapper, props, remount]);
}
