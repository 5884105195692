import React, { FormEvent, useCallback } from 'react';
import { Form as BootstrapForm } from 'reactstrap';
import { nest } from '../../utils';
import { FormContext } from './FormContext';

type HTMLFormProps = React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>;

interface FormProps extends Omit<HTMLFormProps, 'onSubmit' | 'onChange'> {
    onSubmit: (values: NestedFormValues) => unknown;
    // TODO: change signature
    onChange?: (values: NestedFormValues, valid: boolean) => unknown;
}

interface State {
    state: FormState;
    fields: Fields;
    valid: boolean;
}

export const Form: React.FC<React.PropsWithChildren<FormProps>> = ({ className, onSubmit, onChange, children }) => {
    const [state, setState] = React.useState<State>({
        state: 'start',
        fields: {},
        valid: true,
    });

    const handleSubmit = useCallback(
        (e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            const { valid, values } = generateSummary(state.fields);

            if (valid) {
                const nestedValue = nest(values);
                onSubmit(nestedValue);
            }

            setState((oldState) => ({ ...oldState, state: 'sending' }));
        },
        [onSubmit, state.fields]
    );

    const changeField = (path: string, value: any, valid: boolean, meta: FieldMeta) => {
        setState((oldState) => {
            const newState = {
                ...oldState,
                fields: {
                    ...oldState.fields,
                    [path]: {
                        ...oldState.fields[path],
                        value,
                        valid,
                        meta: {
                            ...(oldState.fields[path] || {}).meta,
                            ...meta,
                        },
                    },
                },
            };

            const summary = generateSummary(newState.fields);

            if (onChange) {
                const nestedValue = nest(summary.values);
                onChange(nestedValue, summary.valid);
            }

            return {
                ...newState,
                valid: summary.valid,
            };
        });
    };

    const touchField = (path: string) =>
        setState((oldState) => ({
            ...oldState,
            fields: {
                ...oldState.fields,
                [path]: {
                    ...oldState.fields[path],
                    meta: { ...oldState.fields[path].meta, touched: true },
                },
            },
        }));

    const registerField = (path: string, value: any, info: { valid: boolean; name: string }): (() => void) => {
        setState((oldState) => ({
            ...oldState,
            fields: {
                ...oldState.fields,
                [path]: {
                    value,
                    ...info,
                    meta: {
                        pristine: true,
                        touched: false,
                    },
                },
            },
        }));

        return () => unregisterField(path);
    };

    const unregisterField = (path: string): void => {
        setState(({ ...newState }) => {
            delete newState.fields[path];

            return { ...newState };
        });
    };

    const getField = (path: string): Field | null => state.fields[path];

    return (
        <BootstrapForm className={className} onSubmit={handleSubmit}>
            <FormContext.Provider
                value={{
                    path: '',
                    fields: state.fields,
                    state: state.state,
                    registerField,
                    changeField,
                    touchField,
                    getField,
                    unregisterField,
                    valid: state.valid,
                }}
            >
                {children}
            </FormContext.Provider>
        </BootstrapForm>
    );
};

function generateSummary(fields: Fields): FormSummary {
    return Object.entries(fields).reduce<FormSummary>(
        (acc, [name, field]: [string, Field]) => ({
            ...acc,
            values: {
                ...acc.values,
                [name]: field.value,
            },
            valid: acc.valid && field.valid,
        }),
        { values: {}, valid: true }
    );
}
