import type { TableauDevice } from 'tableau-api';
import { config } from '../config';

export function getViewport(width: number = window.innerWidth): TableauDevice {
    if (width <= config.tableau.breakpoints.mobile) {
        return 'phone';
    } else if (width <= config.tableau.breakpoints.tablet) {
        return 'tablet';
    } else {
        return 'desktop';
    }
}
