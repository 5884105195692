import { useEditor } from '../contexts';
import { useLocale } from '../effects';

export const useCurrentProjectName = (): string => {
    const locale = useLocale();
    const { currentProject } = useEditor();

    return currentProject?.name[locale] ?? getDefaultProjectName(currentProject);
};

const getDefaultProjectName = (project?: Project) => Object.values<string>(project?.name ?? {})[0];
