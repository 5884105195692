import countriesTable from './countries';
import feedbackTopics from './feedbackTopics';

// TODO: improve environment detection algorithm
// for instance by passing an external list of ip addresses and host names
function getEnvironment() {
    switch (window.location.host) {
        case 'werk-material.online':
        case '52.29.175.219':
            return 'PROD';
        case 'esvvv.online':
        case '18.185.97.195':
            return 'TEST';
        default:
            return 'LOCAL';
    }
}

function getRecaptchaSiteKey() {
    if (getEnvironment() === 'PROD') {
        return process.env.REACT_APP_RECAPTCHA_SITE_KEY as string;
    } else {
        return '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'; // recaptcha testing key
    }
}

const config = {
    env: getEnvironment(),
    tableau: {
        url: process.env.REACT_APP_TABLEAU_URL || `${window.location.origin}/tableau`,
        breakpoints: {
            mobile: 576,
            tablet: 768,
        },
        options: {
            width: '100%',
            height: '100%',
            hideTabs: true,
            hideToolbar: true,
            device: 'desktop',
        },
    },
    languages: {
        default: 'de',
        languages: [
            {
                id: 'de',
                name: 'DE',
            },
            {
                id: 'fr',
                name: 'FR',
            },
            {
                id: 'it',
                name: 'IT',
            },
            {
                id: 'en',
                name: 'EN',
            },
        ],
    },
    company: {
        name: 'CRB Zürich',
        phone: '+41 44 456 45 45',
        mail: 'werk-material@crb.ch',
        partners: {
            main: [
                {
                    name: 'crb',
                    link: 'https://www.crb.ch/',
                    imageSrc: 'images/logos/crb-logo.png',
                },
                {
                    name: 'werk',
                    link: 'https://www.wbw.ch/',
                    imageSrc: 'images/logos/logo-werk.png',
                },
            ],
            secondary: [
                {
                    name: 'bfs',
                    link: 'https://www.bfs.admin.ch/',
                    imageSrc: 'images/logos/logo-bundesamtstatistik.png',
                },
                {
                    name: 'sia',
                    link: 'https://www.sia.ch/',
                    imageSrc: 'images/logos/logo_sia.png',
                },
            ],
        },
    },
    // TODO: fetch products from the backend
    // WARN: DO NOT CHANGE THE IDS! the backend uses them to apply some business logic
    products: {
        werk: [
            {
                id: 1,
                name: {
                    de: 'Objektarten werk-material.online',
                    fr: "Types d'ouvrage werk-material.online",
                    it: 'Tipi di opere werk-material.online',
                    en: 'werk-material.online facility types',
                },
                description: {
                    de: 'Beinhaltet mehr als 1’000 nach BKP und eBKP abgerechnete Objekte. Die einheitlich definierten Kennwerte helfen bei der Analyse, der Berechnung und dem Vergleich von Baukosten. Inklusive eBKP-H Kalkulator Pro',
                    fr: "Contient plus de 1’000 ouvrages décomptés selon le CFC et l'eCCC. Les valeurs référentielles uniformément définies aident à l'analyse, au calcul et à la comparaison des coûts de construction. Y compris Calculateur Pro eCCC-Bât",
                    it: "Contiene i dati di oltre 1’000 oggetti suddivisi secondo CCC ed eCCC. I valori di riferimento definiti in modo univoco aiutano nell'analisi, nel calcolo e nel confronto dei costi di costruzione. Compreso Calcolatore Pro eCCC-E",
                    en: 'Contains over 1’000 projects with accounting based on the BCC and eCC construction cost classifications. The uniformly defined rates facilitate analysis, costing and construction cost comparisons. Including eBKP-H Calculator Pro',
                },
                price: 500, // CHF
                mwst: 7.7,
                publisher: 'werk-ag',
            },
            {
                id: 3,
                name: {
                    de: 'eBKP-H Kalkulator Pro',
                    fr: 'Calculateur Pro eCCC-Bât',
                    it: 'Calcolatore Pro eCCC-E',
                    en: 'eBKP-H Calculator Pro',
                },
                description: {
                    de: 'Kostenpflichtige Verwendung von 7 Basis-Funktionen inkl. Datenspeicherung',
                    fr: 'Utilisation payante des sept fonctions de base avec sauvegarde des données',
                    it: 'Utilizzo a pagamento delle 7 funzioni di base compreso il salvataggio dei dati',
                    en: 'Chargeable use of 7 basic functions incl. data storage',
                },
                price: 250, // CHF
                mwst: 7.7,
                publisher: 'werk-ag',
            },
            {
                id: 4,
                name: {
                    de: 'eBKP-H Kalkulator',
                    fr: 'Calculateur eCCC-Bât',
                    it: 'Calcolatore eCCC-E',
                    en: 'eBKP-H Calculator',
                },
                description: {
                    de: 'Kostenlose Verwendung der drei Basis-Funktionen',
                    fr: 'Utilisation gratuite des trois fonctions de base',
                    it: 'Utilizzo gratuito delle tre funzione di base',
                    en: 'Free use of the three basic functions',
                },
                price: 0, // CHF
                mwst: 7.7,
                publisher: 'werk-ag',
            },
        ],

        crb: {
            id: 2,
            name: {
                de: 'BFS Kostenkennwerte für Berechnungselemente',
                fr: "Eléments calculés sur la base de valeurs référentielles de l'OFS",
                it: 'UST Valori di riferimento per gli elementi di calcolo',
                en: 'FSO cost rates for calculation elements',
            },
            description: {
                de: `Die durchschnittlichen Kostenkennwerte sind für ca. 100
                Berechnungselementen des Hochbaus halbjährlich im Juni und Dezember erhältlich.
                Sie bieten den Schweizer Planern repräsentative Grundlagen zum Preisniveau der jeweiligen regionalen
                Märkte und unterstützen sie damit bei der Kalkulation ihrer Projekte. Die Kennwerte sind – basierend auf
                dem eBKP-H – nach den sieben Grossregionen der Schweiz aufgeschlüsselt. Die Daten werden vom Bundesamt für
                Statistik BFS produziert und von CRB zur Verfügung gestellt.
            `,
                fr: `Les valeurs référentielles moyennes d'une centaine d'éléments calculés dans le bâtiment sont disponibles
                tous les 6 mois en juin et décembre. Grâce à elles, les concepteurs suisses bénéficient de bases de prix
                représentatives des marchés régionaux et d'un référentiel de calcul des prix pour leurs projets. Le classement
                de ces valeurs - sur la base de l'eCCC-Bât - respecte les 7 grandes régions suisses. L'Office fédérale de la
                statistique OFS produit ces données et CRB les met à disposition.
            `,
                it: `I valori medi di riferimento sono disponibili semestralmente, in giugno e dicembre, per ca. 100 elementi di calcolo.
                Essi forniscono ai progettisti in Svizzera una base rappresentativa per il livello dei prezzi nelle rispettive regioni e li
                supportano nel calcolo dei loro progetti. I valori di riferimento, definiti sulla base dell'eCCC-E, sono ripartiti secondo le
                sette grandi regioni della Svizzera. I dati sono prodotti dall'Ufficio federale di statistica UST e messi a disposizione dal CRB.
            `,
                en: `Every six months, in June and December, average cost rates are published for around 100 calculation elements for buildings.
                By providing designers with price benchmarks for the various regional markets, these data assist Swiss designers in costing their own projects.
                The rates, which are based on the eCC-BC, are further broken down to provide tailored details for Switzerland's seven major regions.
                The data are produced by the Swiss Federal Statistical Office (FSO) and distributed by CRB.
            `,
            },
            price: 500,
            mwst: 7.7,
            publisher: 'crb',
        },
    },
    captcha: {
        // this key is the testing key provided by google. It is safe to have it under source control
        siteKey: getRecaptchaSiteKey(),
    },
    countries: {
        de: countriesTable.map((row: string[]) => row[0]),
        fr: countriesTable.map((row: string[]) => row[1]),
        it: countriesTable.map((row: string[]) => row[2]),
        en: countriesTable.map((row: string[]) => row[3]),
        default: {
            de: countriesTable[0][0],
            fr: countriesTable[0][1],
            it: countriesTable[0][2],
            en: countriesTable[0][0],
        },
    },
    feedbackTopics: {
        de: feedbackTopics.map((f) => f[0]),
        fr: feedbackTopics.map((f) => f[1]),
        it: feedbackTopics.map((f) => f[2]),
        en: feedbackTopics.map((f) => f[3]),
    },
    MIN_PASSWORD_LENGTH: 6,
};

export { config };
