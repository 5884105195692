import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'i18next';
import React, { useCallback, useMemo, useState } from 'react';
import { Container } from 'reactstrap';
import { useAuthentication } from '../../contexts';
import { TextInput } from '../FormComponents';
import { Overlay } from '../Overlay';
import { TagEditorCreateRow } from './TagEditorCreateRow';
import { TagEditorRow } from './TagEditorRow';

interface Props {
    title: string;
    tags: Tag[];
    language: EditorLanguage;
    onCreate: (tag: TagCreate) => unknown;
    onEdit: (tag: Tag) => unknown;
    onDelete: (tag: Tag) => unknown;
    onReplace: (tag: Tag, replacement: Tag) => unknown;
    onClose: () => unknown;
    t: TFunction;
}

export const TagEditor: React.FC<Props> = ({
    title,
    tags,
    language,
    onCreate,
    onEdit,
    onDelete,
    onReplace,
    onClose,
    t,
}) => {
    const { user } = useAuthentication();

    const [search, setSearch] = useState({ text: '' });

    const privateTags = useMemo(() => {
        const searchText = search.text.toLowerCase();

        return tags
            .filter(({ owner }) => owner === user?.username)
            .filter(({ name }) => Object.values(name).some((name) => name.toLowerCase().includes(searchText)));
    }, [tags, user, search]);

    useMemo(() => tags.filter(({ owner }) => owner !== user?.username), [tags, user]);

    const changeSearch = useCallback((text) => {
        setSearch((s) => ({ ...s, text }));
    }, []);

    return (
        <Overlay>
            <div className="tag-editor">
                <div className="tag-editor__header">
                    <h3>{title}</h3>
                    <div role="button" onClick={onClose}>
                        <FontAwesomeIcon icon={faTimes} />
                    </div>
                </div>
                <Container className="tag-editor__body pt-0">
                    <Container className="sticky-top pt-3 bg-white mb-3">
                        <p>{t('editor:tagEditor:description')}</p>
                        <TextInput label={t('editor:tagEditor:search')} value={search.text} onChange={changeSearch} />
                    </Container>
                    <Container>
                        <TagEditorCreateRow onSave={onCreate} language={language} t={t} />

                        {privateTags.map((tag, i) => (
                            <TagEditorRow
                                tag={tag}
                                tags={tags}
                                index={i}
                                language={language}
                                onChange={onEdit}
                                onDelete={onDelete}
                                onReplace={onReplace}
                                t={t}
                                key={tag.id ?? -i}
                            />
                        ))}
                    </Container>
                </Container>
            </div>
        </Overlay>
    );
};
