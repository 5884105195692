import React, { useCallback, useEffect, useState } from 'react';
import { useLanguage } from '../../effects';
import { DialogFormProps } from '../Dialog';
import { Radio } from '../FormComponents';

export interface TagEditorReplacementFormProps {
    text: string;
}

export const TagEditorReplacementForm: React.FC<DialogFormProps<Tag[], Tag> & TagEditorReplacementFormProps> = ({
    text,
    data,
    onData,
    onValid,
}) => {
    const [replacement, setReplacement] = useState<Tag>();
    const [language] = useLanguage();

    const valid = !!replacement;
    useEffect(() => void onValid(valid), [onValid, valid]);

    const handleReplacementSelect = useCallback(
        (tag: ProjectTag | undefined = undefined) => {
            setReplacement(tag);
            if (tag) onData(tag);
        },
        [onData]
    );

    return (
        <div>
            {text && <p>{text}</p>}

            <div className="mb-3">
                {data.map((tag, i) => (
                    <Radio
                        label={tag.name[language.codes.editor]}
                        option={tag}
                        value={replacement}
                        onChange={handleReplacementSelect}
                        onUnset={handleReplacementSelect}
                        variant="toggle"
                        key={tag.id ?? -i}
                    />
                ))}
            </div>
        </div>
    );
};
