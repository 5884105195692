import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';

import { RouteComponentProps } from 'react-router';
import { PageHeader } from '../components';

export const ErrorPage: React.FC<RouteComponentProps<{ whatFor: string }>> = ({ match }) => {
    const [t] = useTranslation();

    const whatFor = match.params.whatFor;
    const isValidMessageKey = ['no-required-roles'].includes(whatFor);
    const message = whatFor && whatFor.length > 0 && isValidMessageKey ? whatFor : 'server-failed';

    return (
        <Container>
            <PageHeader title={t('errors:error-title')} />
            <Row>
                <Col xs="12">
                    <h3 dangerouslySetInnerHTML={{ __html: t(`errors:${message}`) }} />
                </Col>
            </Row>
        </Container>
    );
};

export default ErrorPage;
