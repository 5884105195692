import React, { MouseEvent, useCallback } from 'react';

interface Props {
    name: string;
    active?: boolean;
    onClick: () => unknown;
}

export const NavigationLink: React.FC<Props> = ({ name, active, onClick }) => {
    const handleClick = useCallback(
        (e: MouseEvent<HTMLElement>) => {
            e.preventDefault();
            onClick();
        },
        [onClick]
    );

    return (
        <a href="/" className={`navigation__link ${active ? 'navigation__link--active' : ''}`} onClick={handleClick}>
            {name}
        </a>
    );
};
