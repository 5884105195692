import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatValueToLocale } from '../../utils';

interface BoxPlotProps {
    statistic?: RegulationStats;
    status?: 'loading' | 'success' | 'error' | 'idle';
    showLabel?: boolean;
    percentage?: boolean;
    unit?: string;
    start?: number;
    end?: number;
}

const EMPTY_STATS = {
    count: 0,
    median: 0,
    max: 0,
    mean: 0,
    min: 0,
    percentile05: 0,
    percentile20: 0,
    percentile40: 0,
    percentile60: 0,
    percentile80: 0,
    percentile95: 0,
};

function createDefault(status?: 'loading' | 'success' | 'error' | 'idle') {
    if (status === 'success') return EMPTY_STATS;

    return undefined;
}

export const BoxPlot: React.FC<BoxPlotProps> = ({
    status,
    statistic = createDefault(status),
    showLabel,
    percentage,
    unit = percentage ? '%' : '',
    start = 0,
    end = 1,
}) => {
    const [t] = useTranslation();
    const tt = React.useCallback(
        (key, options = undefined) => (options ? t(`editor:box-plot:${key}`, options) : t(`editor:box-plot:${key}`)),
        [t]
    );

    const empty = status === 'success' && statistic === EMPTY_STATS;
    const median = statistic?.median ?? 0;

    const getRelativeValue = (num: number) => ((num - start) / (end - start || 1)) * 100;
    const relativeMedian = getRelativeValue(median);

    const createStyles = (from = 0, to = 0) => ({
        left: `${getRelativeValue(from)}%`,
        right: `${100 - getRelativeValue(to)}%`,
    });

    const quintileClassName = (className: string) => classNames('box-plot__quintiles', className);

    return (
        <div
            className={classNames('box-plot', {
                'box-plot--loading': status === 'loading',
                'box-plot--empty': empty,
            })}
        >
            <span
                className={quintileClassName('box-plot__quintiles--outer')}
                style={createStyles(statistic?.min, statistic?.max)}
            />
            <span
                className={quintileClassName('box-plot__quintiles--middle')}
                style={createStyles(statistic?.percentile20, statistic?.percentile80)}
            />
            <span
                className={quintileClassName('box-plot__quintiles--inner')}
                style={createStyles(statistic?.percentile40, statistic?.percentile60)}
            />

            <span className="box-plot__median" style={{ left: `${relativeMedian}%` }} />

            {showLabel && (
                <small className="box-plot__label" style={{ left: `${relativeMedian}%` }}>
                    {tt('median', {
                        median: percentage ? Math.round(median * 100) : formatValueToLocale(Math.round(median)),
                        unit,
                    })}
                </small>
            )}

            {empty && <small className="box-plot__empty-label">{tt('no-data')}</small>}
        </div>
    );
};
