import React, { ReactElement, useCallback } from 'react';
import { Validators } from '../../../validators';
import { FormContext } from '../FormContext';
import { FormFieldRenderer } from './FormFieldRenderer';

type FormFieldProps<
    Props extends FormFieldComponentProps<any, Record<string, unknown>>,
    Value = Props extends FormFieldComponentProps<infer V> ? V : never,
    CProps = Props extends FormFieldComponentProps<Value, infer C> ? C : never
> = React.PropsWithChildren<{
    name: string;
    value?: Value;

    Component: React.ComponentType<Props>;

    validator?: Validator;
}> &
    (keyof CProps extends never
        ? {
              Props?: CProps;
          }
        : {
              Props: CProps;
          });

type FormFieldComponentType = <
    ComponentProps extends FormFieldComponentProps<any, Record<string, unknown>>,
    Value = ComponentProps extends FormFieldComponentProps<infer V> ? V : never
>(
    value: React.PropsWithChildren<FormFieldProps<ComponentProps, Value>>
) => ReactElement | null;

export const FormField: FormFieldComponentType = (props) => {
    // Don't update name
    const [name] = React.useState(props.name);

    if (!name || name.length === 0 || !/^[a-zA-Z_]+$/.test(name)) {
        throw new Error(
            'Invalid property "name". The name must be a string that contains only the characters of the alphabet.'
        );
    }

    const required = (props.Props as FormFieldComponentProps<unknown, { required?: boolean }>).required;

    const isValid = useCallback(
        (value) => {
            if (props.validator) {
                const report = Validators.execute(props.validator, value);
                return !report || report.severity !== 'error';
            }

            if (required === true && !value) {
                return false;
            }

            return true;
        },
        [props.validator, required]
    );

    const createChange = useCallback(
        function <V>(change: (path: string, value: V, valid: boolean, meta: FieldMeta) => void, path: string) {
            return (value: V, meta: FieldMeta) => {
                change(path, value, isValid(value), meta);
            };
        },
        [isValid]
    );

    return (
        <FormContext.Consumer>
            {(context) => {
                const path = `${context.path}${name}`;
                const field = context.fields[path];

                const register = () =>
                    context.registerField(path, props.value, {
                        name,
                        valid: isValid(props.value),
                    });

                const touch = () => context.touchField(path);

                return (
                    <FormFieldRenderer
                        name={name}
                        path={path}
                        field={field}
                        register={register}
                        changeValue={createChange(context.changeField, path)}
                        onTouched={touch}
                        form={{
                            state: context.state,
                            valid: context.valid,
                            getValue: (searchedPath: string) => {
                                const searchedField = context.getField(searchedPath);

                                if (searchedField) {
                                    return searchedField.value;
                                }

                                return null;
                            },
                        }}
                        component={props.Component as any}
                        props={props.Props}
                    />
                );
            }}
        </FormContext.Consumer>
    );
};
