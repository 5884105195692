import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'i18next';
import React, { useCallback, useMemo } from 'react';
import { Col, Row } from 'reactstrap';
import { useProjectTags } from '../../../../../../effects';
import { TagSelector } from '../../../../../FormComponents';

interface Props {
    tag: ProjectTag;
    language: EditorLanguage;
    selectedTagIds: number[];
    onChange: (projectTag: ProjectTag, index: number) => void;
    onDelete: (index: number) => void;
    t: TFunction;
    index: number;
}

export const ProjectTag: React.FC<Props> = ({ tag, index, selectedTagIds, onChange, onDelete, language, t }) => {
    const { data: existingTags = [] } = useProjectTags();
    const lang = language.codes.documentation;

    const handleChange = useCallback(
        (tag: ProjectTag) => {
            onChange(tag, index);
        },
        [onChange, index]
    );

    const handleDelete = useCallback(() => onDelete(index), [onDelete, index]);

    selectedTagIds = useMemo(() => {
        if (tag.id) selectedTagIds.filter((id) => id !== tag.id);

        return selectedTagIds;
    }, [selectedTagIds, tag.id]);

    return (
        <Row className="data-view__project-tag border-bottom py-1">
            <Col className="pt-1">
                <TagSelector
                    label={t('editor:project:parameters:projectTags:projectTagName')}
                    tag={tag}
                    tags={existingTags}
                    hiddenTags={selectedTagIds}
                    language={lang}
                    onChange={handleChange}
                    t={t}
                    autoFocus={true}
                    testId="project-tag-name"
                />
            </Col>
            <Col
                xs={3}
                lg={2}
                className="project-editor__action"
                onClick={handleDelete}
                data-testid="delete-project-tag"
            >
                {`${t('editor:project:parameters:projectTags:deleteProjectTag')} `}
                <FontAwesomeIcon icon={faTimes} />
            </Col>
        </Row>
    );
};
