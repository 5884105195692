import React, { ReactElement } from 'react';

interface FileUploaderProps<
    Component extends React.ComponentType<UploaderComponentProps & Record<string, unknown>>,
    ComponentProps extends Record<string, unknown> = Component extends React.ComponentType<infer P>
        ? Omit<P, keyof UploaderComponentProps>
        : never
> {
    multiple?: boolean;
    accept?: string;
    uploadFile: (fileOrfiles: File | File[]) => unknown;
    Component: Component;
    Props: ComponentProps;
}

export type UploaderComponentProps = {
    openFileDialog: () => unknown;
    uploadFile: (fileOrFiles: File | File[]) => unknown;
};

type FileUploaderComponentType = <C extends React.ComponentType<UploaderComponentProps>>(
    props: React.PropsWithChildren<FileUploaderProps<C>>
) => ReactElement | null;
export const FileUploader: FileUploaderComponentType = ({ Component, ...props }) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const formRef = React.useRef<HTMLFormElement>(null);

    const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        const files = [...(target.files || [])].filter((file) => {
            return !props.accept || file.type.match(new RegExp(props.accept));
        });

        if (files.length > 0) {
            upload(files);

            if (formRef.current) {
                formRef.current.reset();
            }
        }
    };

    const openFileDialog = () => inputRef.current && inputRef.current.click();

    const upload = (fileOrFiles: File | File[]) => {
        const files = [fileOrFiles]
            .flatMap((fileOrFiles2) => (fileOrFiles2 instanceof Array ? fileOrFiles2 : [fileOrFiles2]))
            .filter((file) => !props.accept || new RegExp(props.accept).test(file.type));

        if (files.length === 0) {
            return;
        }

        if (props.multiple) {
            if (Array.isArray(fileOrFiles)) {
                props.uploadFile(fileOrFiles);
            } else {
                props.uploadFile([fileOrFiles]);
            }
        } else {
            if (Array.isArray(fileOrFiles)) {
                props.uploadFile(fileOrFiles[0]);
            } else {
                props.uploadFile(fileOrFiles);
            }
        }
    };

    return (
        <>
            <form ref={formRef} hidden={true}>
                <input
                    ref={inputRef}
                    accept={props.accept}
                    type="file"
                    multiple={props.multiple}
                    onChange={handleChange}
                />
            </form>

            <Component {...(props.Props as any)} openFileDialog={openFileDialog} uploadFile={upload} />
        </>
    );
};
