import axios, { AxiosError, AxiosInstance } from 'axios';
import { DateTime } from 'luxon';
import { i18n } from '../../i18n';
import { ActionsEnum } from '../../license/actions';
import type { SignupResult, UserInfo } from '../../types';

/**
 * The sequence diagram of the authentication flow can be found in the documentation.
 **/

/**
 * The login on the IAM is done via a redirect flow. Once the IAM redirects back to the application
 * this code is passed as a query parameter in the 302 redirect location. This code is then used
 * to authenticate with the WMO backend.
 */
const SEARCH_PARAM_IAM_CODE = 'code';

type UserInfoResponse = Omit<UserInfo, 'actions'> & {
    actions: ActionsEnum[];
};
function mapUserInfoRESTResponse(response: UserInfoResponse): UserInfo {
    return {
        ...response,
        actions: new Set(response.actions),
    };
}

export class AuthenticationService {
    protected http: AxiosInstance;
    protected _authentication: UserInfo | null = null;
    protected _lastLicenseCheckRetry: DateTime;

    constructor(authentication: UserInfo | null = null) {
        this.http = axios.create({
            baseURL: '/api',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });
        this._authentication = authentication;
        this._lastLicenseCheckRetry = DateTime.now();
    }

    /**
     * The init() method is called when the application is started (e.g. on every page load).
     *
     * To authenticate with the backend, a code is needed. The code is provided by the IAM. If the user has
     * a code (e.g. after a redirect from the IAM) the code is used to authenticate with the backend. The code is
     * provided by the IAM as a query parameter in the URL.
     *
     * If the user has no code, it means the user is either already authenticated or not authenticated at all. To check
     * if the user is authenticated, the backend is asked for the user info. If the user is authenticated, the user info
     * is returned. If the user is not authenticated, the backend returns a 401 error.
     * The 401 error is currently ignored. To understand if the user is logged in, the UI checks if the user info is available,
     * which is not the case if a 401 error is returned.
     */
    public async init(): Promise<UserInfo | null> {
        try {
            const params = new URLSearchParams(window.location.search);
            const code = params.get(SEARCH_PARAM_IAM_CODE);
            const authenticated = this.authentication?.signupStatus === 'AUTHENTICATED';

            let userInfoResponse: UserInfoResponse;
            if (!authenticated && code) {
                const { data } = await this.http.post<UserInfoResponse>('/auth/login', { code });
                userInfoResponse = data;
            } else {
                const { data } = await this.http.get<UserInfoResponse>('/auth/user-info');
                userInfoResponse = data;
            }

            const userInfo = mapUserInfoRESTResponse(userInfoResponse);
            this.setAuthentication(userInfo);
            return userInfo;
        } catch (e) {
            return null;
        }
    }

    public async buyProducts(data: NestedFormValues): Promise<SignupResult> {
        try {
            const response = await this.http.post('/auth/buy-product', data, {
                headers: {
                    'Accept-Language': i18n.language,
                },
            });

            return {
                status: response.status,
                message: 'ok',
            };
        } catch (error) {
            const err = error as AxiosError;

            console.error(err);
            return {
                status: err.response?.status || 500,
                message: i18n.t('errors:server-failed'),
            };
        }
    }

    public logout(): void {
        this._authentication = null;
    }

    protected setAuthentication(authentication: UserInfo): void {
        this._authentication = authentication;
    }

    get authentication(): UserInfo | null {
        return this._authentication;
    }
}
