export function isInViewport(element: HTMLElement, fullVisibility: boolean): boolean {
    const rect = element.getBoundingClientRect();

    const viewheight = window.innerHeight || document.documentElement.clientHeight;

    if (fullVisibility) {
        if (rect.top >= 0) return true;
        if (rect.bottom <= viewheight) return true;
    }

    if (rect.bottom >= 0) return true;
    if (rect.top >= viewheight) return true;

    return false;
}
