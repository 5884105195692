import { noop } from 'lodash';
import React, { ReactElement, useCallback } from 'react';

interface Props<Value> extends FormFieldComponentProps<Value> {
    label: React.ReactNode;
    option: Value;
    variant?: 'set' | 'toggle';
    onUnset?: () => unknown;
}

type RadioComponentType = <S>(value: Props<S>) => ReactElement | null;
export const Radio: RadioComponentType = ({ label, option, value, variant = 'set', onChange, onUnset }) => {
    const selected = value === option;

    const change = useCallback(() => {
        if (!selected) {
            onChange(option);
        } else if (variant === 'toggle') {
            onUnset?.();
        }
    }, [onChange, onUnset, selected, option, variant]);

    return (
        <label className="form__radio" onPointerUp={change}>
            <input type="radio" checked={selected} onChange={noop} />
            <span className="form__radio-checkmark" />
            {label}
        </label>
    );
};
