import { useMemo } from 'react';
import { SelectOption } from '../types';
import { matchesRegulation, toString } from './utils';

export function useRegulationSelectorOptions(
    regulations: StaticRegulation[],
    value: StaticRegulation | null,
    text: string,
    language: string
): SelectOption<StaticRegulation>[] {
    const valueId = value?.id;

    // Map tag to option
    let options = useMemo<(SelectOption<StaticRegulation> & { selected?: boolean })[]>(() => {
        return regulations.map<SelectOption<StaticRegulation>>((value, i) => ({
            title: value.name,
            label: toString(value, language),
            value,
            disabled: false,
            focused: false,
            hidden: false,
            key: value.id ?? -i,
        }));
    }, [regulations, language]);

    options = useMemo(() => {
        const valueId = value?.id;

        return options.map((opt) => ({
            ...opt,
            selected: opt.value.id === valueId,
        }));
    }, [options, value]);

    const hasValue = !!value;
    options = useMemo(() => {
        if (hasValue) {
            return options;
        }

        return options.map((option) => {
            if (text && !valueId) {
                const hidden = !matchesRegulation(option.value, text, language);

                return { ...option, hidden };
            }

            return option;
        });
    }, [options, text, language, valueId, hasValue]);

    return options;
}
