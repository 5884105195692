import React from 'react';
import { Col } from 'reactstrap';
import type { BuildingEditorActionItem } from '../types';
import { BuildingEditorFormAction } from './BuildingEditorFormAction';

interface BuildingEditorFormProps {
    actions?: Array<undefined | null | BuildingEditorActionItem>;
    navPosition: 'top' | 'bottom';
}

export const BuildingEditorForm: React.FC<BuildingEditorFormProps> = ({ actions, navPosition, children }) => (
    <Col className="building-editor-form" xs={12} sm={6}>
        <div className="h-100 d-flex flex-column">
            {actions && navPosition === 'top' && (
                <div className="building-editor-form__actions mb-5">
                    {actions.map((action, index) => action && <BuildingEditorFormAction action={action} key={index} />)}
                </div>
            )}

            {children}

            {actions && navPosition === 'bottom' && (
                <div className="building-editor-form__actions mt-5">
                    {actions.map((action, index) => action && <BuildingEditorFormAction action={action} key={index} />)}
                </div>
            )}
        </div>
    </Col>
);
