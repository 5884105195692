import React from 'react';
import type { TabSwitchEvent, View, Viz, VizCreateOptions } from 'tableau-api';
import { config } from '../../config';
import { createListener, getViewport } from '../../utils';

/**
 * Tableau
 * The tableau api is added to the window object via the import statement in the
 * project entry point.
 */
const { tableau } = window;

interface Props {
    /**
     * The view to display.
     */
    view: View;
    /**
     * The class name to apply to the container.
     */
    className?: string;
    /**
     * The server url to connect to the tableau server.
     */
    server: string;
    /**
     * The ticket is used to authenticate the tableau visualizer
     * the first time it is loaded.
     */
    ticket?: string | null;
    /**
     * Indicates if the ticket has been used. If the ticket has been used,
     * the visualizer will not use the ticket again.
     */
    ticketUsed?: boolean;

    vizRef?: React.Ref<Viz>;

    options?: VizCreateOptions;
    /**
     * Callback to notify parent that the viz has been loaded.
     */
    onLoaded?: () => void;
    /**
     * Notifies parent if the view changed within the iframe
     */
    onViewChange: (newViewId: string) => void;
    /**
     * The instance id must be preserved for the entire lifetime of the program and must be always the same.
     * We need the same instanceId to preserve filters set by the user. It acts kind of like a session identifier.
     */
    vizInstanceId?: string;
    /**
     * Callback to notify parent about the viz instance id has been updated. The viz instance id acts kind of like
     * a session identifier.
     */
    onVizInstanceIdUpdated?: (newInstanceId: string) => void;
    /**
     * Scale the viz to fit the container and update the
     * container on window resize.
     */
    scale?: boolean;
    /**
     * Padding left and right of the viz.
     */
    scalePadding?: number;
    /**
     * Callback for scale changes to notify parent.
     * @deprecated Never used.
     */
    onScale?: (scale: number) => void;
}

interface State {
    viz: Viz | null;
    /**
     * A clean up function which is called when the component unmounts
     * to remove event listener.
     */
    removeListener: () => unknown;
}

class TableauVisualizerComp extends React.Component<Props, State> {
    public state: State = {
        viz: null,
        removeListener: () => null,
    };

    /**
     * A function that is
     */
    private scaleHandler: () => void = () => null;

    /**
     * The reference to the visualizer container.
     */
    private ref = React.createRef<HTMLDivElement>();

    private get view() {
        return this.props.view;
    }

    /**
     * Scales the viz to fit the the page.
     */
    private scale = (container: HTMLElement, padding: number) => {
        const iframe = container.getElementsByTagName('iframe').item(0) || null;

        if (!iframe) {
            return;
        }

        let scaleFactor = 1;
        // Adjust scale factor if padding is desired and it would break out of the page.
        if (iframe.clientWidth + padding >= container.clientWidth) {
            scaleFactor = container.clientWidth / (iframe.clientWidth + padding);
        }

        iframe.style.transform = getScaleTransform(scaleFactor);
        this.props.onScale?.(scaleFactor);

        /**
         * In development, resize the container depending on the tableau worksheet default size (1200px * 5000px)
         */
        if (process.env.NODE_ENV === 'development') {
            container.style.height = `${5000 * scaleFactor}px`;
        }
        /**
         * In production, resize the container according to the available document element
         */
        if (iframe.src.startsWith(window.location.origin) && iframe.contentDocument) {
            const iframeContentHeight = iframe.contentDocument.documentElement.clientHeight * scaleFactor;
            container.style.height = `${iframeContentHeight}px`;
        }
    };

    /**
     * If scaleing of the viz is enabled, this will setup the
     * resize listener and applys an initial scaling.
     */
    private applyScale = () => {
        if (this.props.scale) {
            const container = document.getElementById('tableau-container');

            if (container) {
                // Padding left and right
                const padding = this.props.scalePadding || 0;

                this.scaleHandler = () => this.scale(container, padding);

                // Apply scale
                this.scaleHandler();

                window.addEventListener('resize', this.scaleHandler);
            }
        }
    };

    /**
     * Callback which is called from the wizard
     * when it is done loading the viz.
     */
    private vizLoaded = () => {
        this.props.onLoaded?.();

        if (this.props.scale) {
            // Resize the viz to fit the container
            this.scaleHandler();
        }
    };

    public componentDidMount(): void {
        const iframeUrl = getIframeUrl(this.props);
        this.createViz(iframeUrl);
        this.applyScale();
    }

    public componentWillUnmount(): void {
        window.removeEventListener('resize', this.scaleHandler);

        this.destroyViz();
    }

    public shouldComponentUpdate(nextProps: Props): boolean {
        switch (true) {
            case nextProps.server !== this.props.server: // Server url changed
            case nextProps.view.id !== this.view.id: // View changed
            case Object.keys(this.props.options || {}).length !== Object.keys(nextProps.options || {}).length: // Option count changed
            case Object.entries(this.props.options || {}).some(
                ([key, value]: [string, unknown]) => value !== nextProps.options?.[key as keyof VizCreateOptions]
            ): // Option value changed
                return true;
        }

        return false;
    }

    public componentDidUpdate(): void {
        this.destroyViz();
        const iframeUrl = getIframeUrl(this.props);
        this.createViz(iframeUrl);
    }

    public render(): JSX.Element {
        return (
            <div id="tableau-container" className={this.props.className} data-scale={this.props.scale} ref={this.ref} />
        );
    }

    protected getURL(): string {
        const { server, view, ticket, ticketUsed } = this.props;
        const workbookName = view.contentUrl.replace('/sheets', '');

        /**
         * If you display a tableau view for the first time, you need to use a unique_ticket to authenticate the user with tableau
         * through the /trusted/<unique_ticket> endpoint. This will add the cookie 'workgroup_session_id', which allows subsequent
         * requests to tableau to be done without the unique_ticket.
         */
        if (!ticketUsed && ticket) {
            // http://<server_name>/trusted/<unique_ticket>/views/<workbook_name>
            return `${server}/trusted/${ticket}/views/${workbookName}`;
        } else {
            // http://<server_name>/views/<workbook_name>
            return `${server}/views/${workbookName}`;
        }
    }

    private updateVizRef = (viz: Viz | null): void => {
        const { vizRef } = this.props;

        if (vizRef) {
            if (typeof vizRef === 'function') {
                vizRef(viz);
            } else {
                (vizRef as React.MutableRefObject<Viz | null>).current = viz;
            }
        }
    };

    protected createViz = (url: string): void => {
        // NOTE: the instance id must be preserved for the entire lifetime of the program and must be always the same.
        // We need the same instanceId to preserve filters set by the user. It acts kind of like a session identifier.
        if (this.state.viz && this.state.viz.getInstanceId() !== this.props.vizInstanceId) {
            this.props.onVizInstanceIdUpdated?.(this.state.viz.getInstanceId());
        }
        const options: VizCreateOptions = {
            ...this.props.options,
            device: getViewport(),
            instanceIdToClone: this.props.vizInstanceId,
        };
        // eslint-disable-next-line prefer-const
        let viz: Viz | null;
        const callback = () => {
            this.vizLoaded();
            if (viz) {
                this.props.onVizInstanceIdUpdated?.(viz.getInstanceId());
                viz.addEventListener(tableau.TableauEventName.TAB_SWITCH as string, (e) => {
                    this.props.onViewChange((e as TabSwitchEvent).getNewSheetName());
                });
            }
        };
        viz = createVisualizerInstance(this.ref.current, url, options, callback);

        if (viz) {
            this.updateVizRef(viz);

            // Resize listener
            const removeListener = createListener(
                'resize',
                () => {
                    if (viz) {
                        viz.refreshSize();
                    }
                },
                500
            );

            this.setState((state) => {
                state.removeListener();

                return { ...state, viz, removeListener };
            });
        }
    };

    /**
     * Dispose the viz and remove the resize listener.
     */
    protected destroyViz = (): void => {
        const { viz, removeListener } = this.state;

        removeListener();

        if (viz) {
            viz.dispose();
            this.updateVizRef(null);
        }
    };
}

/**
 * Creates the tableau viz object and inserts it into the given element.
 * Calls the callback when the viz is loaded.
 */
const createVisualizerInstance = (
    element: HTMLElement | undefined | null,
    url: string,
    options: VizCreateOptions = {},
    callback?: () => void
): Viz | null => {
    if (element) {
        const viz = new tableau.Viz(element, url, {
            ...config.tableau.options,
            ...options,
            onFirstVizSizeKnown: () => {
                if (viz) {
                    try {
                        viz.refreshSize();
                    } catch (err) {
                        /* continue regardless of error */
                    }
                }
            },
            onFirstInteractive: () => callback?.(),
        });

        return viz;
    }

    return null;
};

const getScaleTransform = (scale: number) => `translate(-50%, -50%) scale(${scale}) translate(0px, 50%)`;

/**
 * Determines the iframe url for the given parameters.
 *
 * If you display a tableau view for the first time, you need to use a unique_ticket to authenticate the user with tableau
 * through the /trusted/<unique_ticket> endpoint. This will add the cookie 'workgroup_session_id', which allows subsequent
 * requests to tableau to be done without the unique_ticket.
 */
function getIframeUrl({
    server,
    view,
    ticket,
    ticketUsed,
}: {
    server: string;
    view: View;
    ticket?: string | null;
    ticketUsed?: boolean;
}): string {
    const workbookName = view.contentUrl.replace('/sheets', '');

    if (!ticketUsed && ticket) {
        // http://<server_name>/trusted/<unique_ticket>/views/<workbook_name>
        return `${server}/trusted/${ticket}/views/${workbookName}`;
    } else {
        // http://<server_name>/views/<workbook_name>
        return `${server}/views/${workbookName}`;
    }
}

export default React.forwardRef<Viz, Props>((props, ref) => {
    return <TableauVisualizerComp {...props} vizRef={ref} />;
});
