import { faCaretLeft, faCaretRight, faGripLinesVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { uniqueId } from 'lodash';
import React from 'react';

interface RangeInputProps {
    id?: string;
    className?: string;
    noDefaultClassName?: boolean;
    wrapperClassName?: string;

    label?: string;

    disabled?: boolean;
    required?: boolean;

    min?: number;
    max?: number;
    rangeMin?: number;
    rangeMax?: number;

    gutterTop?: boolean;

    lazy?: boolean;

    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => unknown;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => unknown;
}

type Props = FormFieldComponentProps<{ min: number; max: number }, RangeInputProps>;

enum RangeInputStatus {
    Equal = 'equal',
    Regular = 'regular',
    Inverted = 'inverted',
}

const noop = () => undefined;

export const RangeInput: React.FC<Props> = ({
    value,
    onChange,
    className,
    disabled,
    error,
    form,
    gutterTop,
    id,
    label,
    lazy,
    noDefaultClassName,
    onBlur = noop,
    onFocus = noop,
    onTouched = noop,
    required,
    wrapperClassName,

    rangeMin = 0,
    rangeMax = 100,
    min: minValue = rangeMin,
    max: maxValue = rangeMax,
}) => {
    const uid = React.useMemo(() => id ?? uniqueId(), [id]);

    const [val, setValue] = React.useState(value || { min: minValue, max: maxValue });
    const [drag, setDrag] = React.useState<{ type: 'min' | 'max'; start: number }>();

    const state = React.useMemo(() => {
        if (val.min === val.max) return RangeInputStatus.Equal;
        if (val.min < val.max) return RangeInputStatus.Regular;
        return RangeInputStatus.Inverted;
    }, [val.min, val.max]);

    const sliderRef = React.useRef<HTMLDivElement>(null);

    const previousValue = React.useRef(value);
    const externalValueChanged = value.min !== previousValue.current.min || value.max !== previousValue.current.max;
    React.useEffect(() => {
        if (externalValueChanged) {
            previousValue.current = value;
            setValue({ min: value.min, max: value.max });
        }
    }, [value, externalValueChanged]);

    React.useEffect(() => {
        if (!lazy && !drag) {
            onChange({
                min: Math.min(val.min, val.max),
                max: Math.max(val.min, val.max),
            });
        }
    }, [val, drag, onChange, lazy]);

    const blur = (e: React.FocusEvent<HTMLInputElement>) => {
        e.stopPropagation();

        if (lazy) {
            onChange({
                min: Math.min(val.min, val.max),
                max: Math.max(val.min, val.max),
            });
        }

        onBlur(e);
    };

    const changeValue = React.useCallback(
        (type: 'min' | 'max', newVal: number) => {
            setValue((v) => {
                const newValue = { ...v, [type]: newVal };

                return {
                    min: Math.min(Math.min(newValue.min, newValue.max), maxValue),
                    max: Math.max(Math.max(newValue.min, newValue.max), minValue),
                };
            });
        },
        [minValue, maxValue]
    );

    const handleMinKey = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            changeValue('min', event.target.valueAsNumber);
        },
        [changeValue]
    );

    const handleMaxKey = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            changeValue('max', event.target.valueAsNumber);
        },
        [changeValue]
    );

    React.useEffect(() => {
        if (drag) {
            const moveListener = (e: MouseEvent) => {
                const x = sliderRef.current?.getBoundingClientRect().x ?? 0;
                const width = sliderRef.current?.getBoundingClientRect().width ?? 1;

                const percentage = Math.min(width, Math.max(e.clientX, x) - x) / width;
                changeValue(drag.type, Math.round(rangeMin + (rangeMax - rangeMin) * percentage));
            };
            const releaseListener = () => setDrag(undefined);

            window.addEventListener('mousemove', moveListener);
            window.addEventListener('mouseup', releaseListener);

            return () => {
                window.removeEventListener('mousemove', moveListener);
                window.removeEventListener('mouseup', releaseListener);
            };
        }
    }, [drag, rangeMin, rangeMax, changeValue]);

    const startDragMin = React.useCallback(
        (ev: React.MouseEvent<HTMLElement>) => !disabled && setDrag({ type: 'min', start: ev.clientX }),
        [disabled]
    );
    const startDragMax = React.useCallback(
        (ev: React.MouseEvent<HTMLElement>) => !disabled && setDrag({ type: 'max', start: ev.clientX }),
        [disabled]
    );

    return (
        <div
            id={uid}
            className={classnames(className, wrapperClassName, {
                form__range: !noDefaultClassName,
                'gutter-top': gutterTop,
                'form__input--error': form && form.state === 'error' && !!error,
            })}
            onClick={onTouched}
            onTouchStart={onTouched}
            onFocus={onFocus}
            onBlur={blur}
        >
            {label && (
                <label htmlFor={uid}>
                    <span>
                        {label} {required ? '*' : ''}
                    </span>
                </label>
            )}

            <div className="range-input__mouse-input mb-1">
                <div className="py-2" ref={sliderRef}>
                    <hr />
                </div>

                <span
                    className="range-input__slider"
                    style={{
                        left: `${((val.min - rangeMin) / (rangeMax - rangeMin)) * 100}%`,
                    }}
                    onMouseDown={startDragMin}
                >
                    {state === RangeInputStatus.Equal && <FontAwesomeIcon icon={faGripLinesVertical} />}
                    {state === RangeInputStatus.Regular && <FontAwesomeIcon icon={faCaretLeft} />}
                    {state === RangeInputStatus.Inverted && <FontAwesomeIcon icon={faCaretRight} />}
                </span>

                <span
                    className="range-input__slider"
                    style={{
                        left: `${((val.max - rangeMin) / (rangeMax - rangeMin)) * 100}%`,
                    }}
                    onMouseDown={startDragMax}
                >
                    {state === RangeInputStatus.Equal && <FontAwesomeIcon icon={faGripLinesVertical} />}
                    {state === RangeInputStatus.Regular && <FontAwesomeIcon icon={faCaretRight} />}
                    {state === RangeInputStatus.Inverted && <FontAwesomeIcon icon={faCaretLeft} />}
                </span>
            </div>

            <div className="range-input__key-input">
                <input
                    value={Math.min(val.min, val.max)}
                    min={rangeMin}
                    max={maxValue}
                    type="number"
                    onChange={handleMinKey}
                    disabled={disabled}
                />
                <input
                    value={Math.max(val.min, val.max)}
                    min={minValue}
                    max={rangeMax}
                    type="number"
                    onChange={handleMaxKey}
                    disabled={disabled}
                />
            </div>
        </div>
    );
};
