import { debounce, round } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { formatValueToLocale, isMobile, nonNumberChars } from '../../../utils';
import { BaseTextInput, DefaultTextInputProps } from './BaseTextInput';

export interface NumberInputProps {
    decimals?: number;
    numberDefaultValue?: number;
    isPercentage?: boolean;
}

type Props = FormFieldComponentProps<string, NumberInputProps & DefaultTextInputProps>;

const INT_MIN = -Math.pow(2, 31);
const INT_MAX = Math.pow(2, 31) - 1;

export const NumberInput: React.FC<Props> = ({
    value = '',
    type = 'number',
    onChange,
    lazy,
    isPercentage = false,
    ...props
}) => {
    const { numberDefaultValue = 0, decimals = 0 } = props as NumberInputProps;

    const [focused, setFocused] = useState(false);
    const [text, setText] = useState(value || '');

    useEffect(() => {
        if (!focused) {
            setText(formatValueToLocale(parseFloat(value || '0') || 0, props.decimals).replace(nonNumberChars, ''));
        } else if (!parseFloat(parseFloat(value).toFixed(props.decimals))) {
            setText('');
        }
    }, [value, focused, props.decimals]);

    const focus = useCallback(() => setFocused(true), []);

    const onChangeHandler = useMemo(() => {
        const transform = (value: string) =>
            toNumber(value, {
                defaultValue: numberDefaultValue,
                decimals,
                isPercentage,
            });

        if (!lazy) return (value: string) => onChange(transform(value));

        return debounce((value: string) => onChange(transform(value)), 300);
    }, [lazy, numberDefaultValue, decimals, isPercentage, onChange]);

    const change = useCallback(
        (newValue = '') => {
            const numValue = parseNumber(newValue, { decimals });
            setText(numValue);
            onChangeHandler(numValue);
        },
        [decimals, onChangeHandler]
    );

    const handleChange = useCallback(
        ({ target }: React.ChangeEvent<HTMLInputElement>) => change(target.value),
        [change]
    );

    const handlePaste = useCallback(
        (e: React.ClipboardEvent<HTMLElement>) => {
            e.preventDefault();
            const value1 = `${e.clipboardData.getData('Text')}`;
            if (value1.match(/^-?\d{1,3}('?\d{3})*(\.\d*)?$/g)) {
                change(value1);
            }
        },
        [change]
    );

    const handleBlur = useCallback(() => setFocused(false), []);

    return (
        <BaseTextInput
            value={displayValue(focused ? text : value, props, focused)}
            onChange={handleChange}
            onBlur={handleBlur}
            onPaste={handlePaste}
            onFocus={focus}
            type={isMobile() ? type : 'text'}
            {...props}
        />
    );
};

export function displayValue(value: string, props: NumberInputProps, focused: boolean): string {
    if (focused) {
        // null, undefined, empty string or 0
        if (!value) return '';

        const float = parseFloat(value);

        if (isNaN(float)) return '';

        if (value.includes('.') && props.decimals && props.decimals > 0) {
            const decimals = value.substring(value.indexOf('.') + 1);

            if (decimals.length > props.decimals) return float.toFixed(props.decimals);
        }

        return value;
    }

    if (isMobile()) {
        return value || '0';
    }

    return formatValueToLocale(parseFloat(value || '0') || 0, props.decimals);
}

/**
 * Returns if value is 0 when rounded correctly
 * @param value
 * @param decimals
 */
export function isZeroish(value: number, decimals = 0): boolean {
    return round(value, decimals) === 0;
}

interface NumberParseOptions {
    decimals?: number;
}

export function parseNumber(text: string, options: NumberParseOptions = {}): string {
    const textValue = (text || '0').replace(nonNumberChars, '');

    if (isNaN(parseFloat(textValue))) return '';

    if (!options.decimals) {
        const intVal = parseInt(textValue);

        if (intVal > INT_MAX) return INT_MAX.toFixed(0);
        if (intVal < INT_MIN) return INT_MIN.toFixed(0);

        return [textValue.startsWith('0') ? '0' : '', intVal.toFixed(0)].join('');
    } else {
        const [ints, decimals] = textValue.split('.', 2);

        if (decimals) {
            if (options.decimals > 0) {
                return [parseInt(ints), decimals.substring(0, options.decimals)].join('.');
            }
        }

        return [parseInt(ints), textValue.charAt(ints.length) === '.' ? '.' : ''].join('');
    }
}

interface ToNumberOptions {
    defaultValue?: number;
    decimals?: number;
    isPercentage?: boolean;
}

export function toNumber(text: string, options: ToNumberOptions = {}): string {
    let textValue = text.replace(nonNumberChars, '');
    const value = parseFloat(textValue);

    if (text === '' || isNaN(value)) {
        textValue = Number(options.defaultValue).toFixed(options.decimals);
    } else if (Number.isInteger(value)) {
        textValue = value.toFixed(options.decimals);
    }

    if (options.isPercentage && value < 0) {
        return Number(0).toFixed(options.decimals);
    } else if (options.isPercentage && value > 100) {
        return Number(100).toFixed(options.decimals);
    }

    return textValue;
}
