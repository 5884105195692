import { History } from 'history';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Link, Redirect, Route, Switch, generatePath, useHistory } from 'react-router-dom';
import { Col } from 'reactstrap';
import {
    ErrorBoundary,
    ErrorBoundaryFallbackProps,
    Footer,
    HTMLLoader,
    Header,
    LanguageSelector,
    LinkButton,
    Navigation,
    Sidebars,
    Spinner,
    TextButton,
} from './components';
import { ScrollupButton } from './components/ScrollupButton/ScrollupButton';
import { useAuthentication } from './contexts';
import { RoutesEnum } from './enums/paths';
import { AuthenticationGuard } from './guards';
import { useElementInViewport } from './hooks';
import { ActionsEnum } from './license/actions';
import ErrorPage from './pages/ErrorPage';
import { PathEstimator } from './pages/Estimator/PathEstimator';
import type { UserInfo } from './types';
import { useTableauNavigation } from './useTableauNavigation';
import { getAWSBucketSrc } from './utils';
import { redirectToExternal, useRedirectUrl } from './utils/redirect';

const Home = React.lazy(() => import('./pages/Home'));
const DataAssistant = React.lazy(() => import('./pages/DataAssistant'));
const FeedbackPage = React.lazy(() => import('./pages/FeedbackPage'));
const CustomerServicePage = React.lazy(() => import('./pages/CustomerServicePage'));
const ThankYouPage = React.lazy(() => import('./pages/ThankYouPage'));
const WaitingPage = React.lazy(() => import('./pages/WaitingPage'));
const ProjectEditorPage = React.lazy(() => import('./pages/ProjectEditorPage'));
const ProjectEstimationPage = React.lazy(() => import('./pages/Estimator/ProjectEstimationPage'));

const CUSTOMER_TOKEN_PARAM = 'customerToken';
const CUSTOMER_ID_PARAM = 'customerId';

/**
 * Removes the customerToken and customerId params from the URL.
 * The customerToken and customerId are only required for the login flow.
 * Once the login flow is completed, these params are no longer needed.
 */
const removeUrlCredentialParams = () => {
    const url = new URL(window.location.href);

    const originalSize = url.searchParams.toString();
    url.searchParams.delete(CUSTOMER_TOKEN_PARAM);
    url.searchParams.delete(CUSTOMER_ID_PARAM);

    if (originalSize !== url.searchParams.toString()) window.history.pushState(null, '', url.toString());
};

const goToShop = (lang: string) => {
    lang = ({ en: 'de' } as Record<string, string | undefined>)[lang] ?? lang;

    const webshopMapping: Record<string, string | undefined> = {
        'https://werk-material.int.crb.ch': `https://webshop.int.crb.ch/${lang}/node/werk-material-online-443`,
        'https://werk-material.crb.ch': `https://webshop.crb.ch/${lang}/node/werk-material-online-604?node_id=604`,
    };

    const location =
        webshopMapping[window.location.origin] ?? `https://webshop.int.crb.ch/${lang}/node/werk-material-online-443`;

    if (location) window.open(location, '_blank');
};

const goToAccount = () => {
    const mapping: Record<string, string | undefined> = {
        'https://werk-material.int.crb.ch': 'https://sso.int.crb.ch/realms/crb-int/account/#/security/signingin',
        'https://werk-material.online': 'https://sso.crb.ch/realms/crb-prod/account/#/security/signingin',
    };

    const location =
        mapping[window.location.origin] ?? 'https://sso.int.crb.ch/realms/crb-int/account/#/security/signingin';

    if (location) window.open(location, '_blank');
};

const WebshopRedirect: FC = () => {
    const [, i18n] = useTranslation();
    const history = useHistory();

    useEffect(() => {
        goToShop(i18n.language);
        history.goBack();
    }, [history, i18n.language]);

    return null;
};

export const Routes: React.FC = () => {
    const history = useHistory();
    const [t, i18n] = useTranslation();
    const idpUrl = useRedirectUrl(RoutesEnum.LOGIN_REDIRECT, i18n.language);
    const { user, waitingForLicenseService, ticket } = useAuthentication();
    const hasUser = !!user;

    /**
     * The isRequesting value indicates if the customer credentials endpoint is currently being called.
     */
    const [isRequesting, setIsRequesting] = React.useState<boolean>(false);
    /**
     * The storeCredentialsHasBeenCalled value indicates if the customer credentials endpoint has been called.
     * Once the storeCrednetials endpoint has been called, it means the login flow has been completed.
     */
    const [storeCredentialsHasBeenCalled, setStoreCrendentialsHasBeenCalled] = React.useState<boolean>(false);
    const [sidebars, setSidebars] = React.useState<string[]>([]);
    const [vizInstanceId, setVizInstanceId] = React.useState<string>('');

    const onVizInstanceIdUpdated = useCallback((newVizInstanceId: string) => {
        if (newVizInstanceId) setVizInstanceId(newVizInstanceId);
    }, []);

    /**
     * -------------------------------------
     * --------- LOGIN STATE LOGIC ---------
     * -------------------------------------
     */
    React.useEffect(() => {
        if (storeCredentialsHasBeenCalled || isRequesting || user?.signupStatus === 'AUTHORIZED') {
            return;
        }

        setIsRequesting(false);
        setStoreCrendentialsHasBeenCalled(true);
        removeUrlCredentialParams();
    }, [user, history, isRequesting, storeCredentialsHasBeenCalled]);

    const showProductSelectionScreen = useMemo(() => {
        /**
         * The user receives the signupStatus 'AUTHENTICATED' as soon as the user is logged in. In a second step
         * the storeCredentials enpoint is called to find out if the user has a valid license or has ordered a license.
         * If the user does not have a license yet and has not ordered any license, the signUpStatus stays 'AUTHORIZED'.
         * Thus we can derive that if the store credentials endpoint has been called and the signupStatus is still 'AUTHORIZED',
         * then the user does not have a license yet and we should display the product selection screen, so that the user can
         * order a license.
         */
        if (hasUser && storeCredentialsHasBeenCalled) {
            return user?.signupStatus === 'AUTHENTICATED';
        }
        return false;
    }, [hasUser, user, storeCredentialsHasBeenCalled]);
    if (hasUser && waitingForLicenseService && history.location.pathname !== RoutesEnum.WAITING_FOR) {
        history.push(RoutesEnum.WAITING_FOR);
    }

    /**
     * --------------------------------------
     * ---- NAVIGATION ITEMS AND SIDEBAR ----
     * --------------------------------------
     */

    const openSidebar = useCallback((name: string) => {
        setSidebars((oldSidebars) => [...removeSidebarNameFrom(oldSidebars, name), name]);
    }, []);

    const closeSidebar = React.useCallback((name: string) => {
        setSidebars((oldSidebars) => removeSidebarNameFrom(oldSidebars, name));
    }, []);

    const { tableauNavigationitems, setCurrentViewId: setCurrentTableauView } = useTableauNavigation({
        onNavigated: useCallback(() => closeSidebar('navigation'), [closeSidebar]),
    });

    const canViewProjects = user?.actions.has(ActionsEnum.VIEW_PROJECTS);
    const navigationItemEditorPage = useMemo(
        () => ({
            name: canViewProjects ? t('editor:name') : t('editor:object-calculator'),
            className: 'u-cursor-pointer',
            onClick: () => {
                history.push(RoutesEnum.EDITOR);
                closeSidebar('navigation');
            },
        }),
        [history, closeSidebar, t, canViewProjects]
    );

    const navigationItems: TNavigationItem[] = useMemo(
        () => [
            ...tableauNavigationitems,
            navigationItemEditorPage,
            {
                name: t('common:user'),
                items: [
                    {
                        name: t('navigation:order'),
                        onNavigate: () => goToShop(i18n.language),
                    },
                    {
                        name: t('navigation:change-password'),
                        onNavigate: goToAccount,
                    },
                ],
            },
        ],
        [tableauNavigationitems, navigationItemEditorPage, t, i18n.language]
    );

    const Glossary = useMemo(() => {
        const glossaryUrl = {
            test: '/templates/test/template.html',
            development: getAWSBucketSrc('Glossar', `Glossar_${i18n.language.toUpperCase()}.htm`),
            production: getAWSBucketSrc('Glossar', `Glossar_${i18n.language.toUpperCase()}.htm`),
        }[process.env.NODE_ENV];

        const Glossary = () => <HTMLLoader src={glossaryUrl} />;

        return Glossary;
    }, [i18n.language]);

    const Disclaimer = useMemo(() => {
        const disclaimerUrl = {
            test: '/templates/test/template.html',
            development: getAWSBucketSrc('Disclaimer', `Disclaimer_${i18n.language.toUpperCase()}.htm`),
            production: getAWSBucketSrc('Disclaimer', `Disclaimer_${i18n.language.toUpperCase()}.htm`),
        }[process.env.NODE_ENV];

        const Disclaimer = () => <HTMLLoader src={disclaimerUrl} />;

        return Disclaimer;
    }, [i18n.language]);

    const footerUrl = useMemo(
        () => `${process.env.REACT_APP_S3_PATH}/Footer/index_${i18n.language}.html`,
        [i18n.language]
    );

    const redirectToIdp = useCallback(() => redirectToExternal(history, idpUrl)(), [history, idpUrl]);
    const onWaitingDone = redirectToIdp;
    const onAuthenticationFail = onWaitingDone;
    const onAuthenticationPass = useCallback(() => executeRedirect(history), [history]);

    const sidebarsDefinition = useMemo(
        () => [
            {
                name: 'navigation',
                header: SidebarHeader,
                component: () => <Navigation items={navigationItems} />,
                props: {},
            },
        ],
        [navigationItems]
    );

    const WaitingPageInstance = useMemo(() => {
        const WaitingPageInstance = () => (
            <WaitingPage
                headerTranslationKey="common:thank-you"
                bodyTranslationKey="common:waiting-for-sync"
                checkIntervalSeconds={60}
                checkIfWaitingIsDone={onWaitingDone}
            />
        );

        return WaitingPageInstance;
    }, [onWaitingDone]);

    return (
        <>
            <div className="wrapper">
                <AppHeader user={user} openSidebar={openSidebar} />
                <main id="page-content">
                    <ErrorBoundary fallback={DataAssistantFallback}>
                        <React.Suspense fallback={<div />}>
                            <Switch>
                                <Route path={RoutesEnum.BASE} exact={true}>
                                    {showProductSelectionScreen ? <WebshopRedirect /> : <Home />}
                                </Route>

                                {hasUser ? (
                                    <Route path={RoutesEnum.WAITING_FOR} exact={true} component={WaitingPageInstance} />
                                ) : null}
                                <Route path={RoutesEnum.FEEDBACK} exact={true} component={FeedbackPage} />
                                <Route
                                    path={RoutesEnum.CUSTOMER_SERVICE}
                                    exact={true}
                                    component={CustomerServicePage}
                                />
                                <Route path={RoutesEnum.ERROR} exact={true} component={ErrorPage} />
                                <Route path={RoutesEnum.THANK_YOU} exact={true} component={ThankYouPage} />
                                <Route path={RoutesEnum.GLOSSARY} exact={true} component={Glossary} />
                                <Route path={RoutesEnum.DISCLAIMER} exact={true} component={Disclaimer} />

                                <Route path={RoutesEnum.REGISTER} exact={true}>
                                    <WebshopRedirect />
                                </Route>

                                <Route path={RoutesEnum.MAIN} exact={true}>
                                    <Main user={user} />
                                </Route>

                                <Route path={RoutesEnum.EDITOR}>
                                    <AuthenticationGuard onFail={onAuthenticationFail} onPass={onAuthenticationPass}>
                                        <ProjectEditorPage />
                                    </AuthenticationGuard>
                                </Route>

                                <Route path={PathEstimator.Estimator}>
                                    <AuthenticationGuard onFail={onAuthenticationFail} onPass={onAuthenticationPass}>
                                        <ProjectEstimationPage />
                                    </AuthenticationGuard>
                                </Route>

                                <Route path={RoutesEnum.WORKBOOK_VIEW}>
                                    <AuthenticationGuard onFail={onAuthenticationFail} onPass={onAuthenticationPass}>
                                        <ErrorBoundary fallback={DataAssistantFallback}>
                                            <DataAssistant
                                                onViewChange={setCurrentTableauView}
                                                vizInstanceId={vizInstanceId}
                                                onVizInstanceIdUpdated={onVizInstanceIdUpdated}
                                            />
                                        </ErrorBoundary>
                                    </AuthenticationGuard>
                                </Route>

                                <Redirect to={!!ticket && !!user ? RoutesEnum.MAIN : RoutesEnum.BASE} />
                            </Switch>
                        </React.Suspense>
                    </ErrorBoundary>
                </main>
            </div>
            <Footer>
                <HTMLLoader src={footerUrl} />
            </Footer>

            <Sidebars names={sidebars} sidebars={sidebarsDefinition} closeSidebar={closeSidebar} />
        </>
    );
};

const removeSidebarNameFrom = (array: string[], sidebarName: string) => array.filter((name) => name !== sidebarName);

interface AppHeaderProps {
    user: UserInfo | null;
    openSidebar: (name: 'navigation') => unknown;
}

const AppHeader: React.FC<AppHeaderProps> = ({ user, openSidebar }) => {
    const ref = useRef<HTMLElement>(null);
    const inViewport = useElementInViewport(ref);
    const { logout } = useAuthentication();
    const history = useHistory();
    const [t, i18n] = useTranslation();

    const idpUrl = useRedirectUrl(RoutesEnum.LOGIN_REDIRECT, i18n.language);
    const hasUser = !!user;

    const scrollupButton = useMemo(() => {
        if (inViewport) return null;

        const main = document.querySelector('main');
        if (!main) return null;
        if (main.clientHeight < window.innerHeight) return null;

        return createPortal(
            <div className="scrollup-container">
                <ScrollupButton />
            </div>,
            main
        );
    }, [inViewport]);

    const redirectToLogin = useCallback(() => redirectToExternal(history, idpUrl)(), [history, idpUrl]);
    const handleNavigationSidebarOpen = useCallback(() => openSidebar('navigation'), [openSidebar]);

    return (
        <Header ref={ref}>
            <Col xs="auto" className="align-self-center">
                <Link to={hasUser ? RoutesEnum.MAIN : RoutesEnum.BASE}>
                    <img src="/images/logos/logo-werk-material.svg" className="responsive" alt="werk-material" />
                </Link>
            </Col>
            <Col className="ml-auto align-self-center justify-content-center d-flex align-items-center" xs="auto">
                <LanguageSelector />
            </Col>
            <Col className="align-self-center justify-content-center d-flex align-items-center" xs="auto">
                {!hasUser ? (
                    <TextButton name={t('common:login')} onClick={redirectToLogin} key="login" testId="login" />
                ) : (
                    <>
                        <LinkButton name={t('common:logout')} onClick={logout} key="logout" testId="logout" />
                        <button
                            className="header__navigation-toggle"
                            onClick={handleNavigationSidebarOpen}
                            aria-label="Toggle navigation menu"
                            data-testid="toggle-menu"
                            key="sidebar"
                        />
                    </>
                )}
            </Col>

            {scrollupButton}
        </Header>
    );
};

/**
 * The main page is responsible for redirecting the user to the correct page depending on the values of the user.
 */
const Main: React.FC<{
    user?: UserInfo | null;
}> = ({ user }) => {
    if (user?.signupStatus === 'AUTHORIZED') {
        if (user.actions.has(ActionsEnum.ACCESS_TABLEAU)) {
            const path = generatePath(RoutesEnum.WORKBOOK_VIEW);

            return <Redirect to={path} />;
        }

        return <Redirect to={RoutesEnum.EDITOR} />;
    }

    return (
        <div className="d-flex justify-content-center">
            <Spinner size={48} color="#000" />
        </div>
    );
};

const executeRedirect = (history: History) => () => {
    const params = new URLSearchParams(history.location.search);
    const redirect = params.get('redirect');

    if (redirect) {
        params.delete('redirect');

        history.replace({
            pathname: redirect,
            search: params.toString(),
        });
    }
};

const SidebarHeader: FC = () => {
    const { user } = useAuthentication();
    const [t] = useTranslation();

    const [copied, setCopied] = useState(false);
    const [copyTooltipOpen, setCopyTooltipOpen] = useState(false);

    const email = user?.email;
    const username = user?.username;

    useEffect(() => {
        if (!copyTooltipOpen) setCopied(false);
    }, [copyTooltipOpen]);

    const openCopyTooltip = useCallback(() => setCopyTooltipOpen(true), []);
    const closeCopyTooltip = useCallback(() => setCopyTooltipOpen(false), []);

    const copyUsernameToClipboard = useCallback(async () => {
        if (!username) return;

        await navigator.clipboard.writeText(username).catch(console.error);
        setCopied(true);
    }, [username]);

    if (!user) return null;

    return (
        <div className="userinfo">
            <div className="userinfo__email">
                {t('userinfo:userEmail')}: {email}
            </div>

            <div onClick={copyUsernameToClipboard}>
                <small
                    id="userinfo__username"
                    className="userinfo__username"
                    onMouseOver={openCopyTooltip}
                    onMouseLeave={closeCopyTooltip}
                >
                    {t('userinfo:userId')}: {username}
                </small>
                {/* TODO: Readd Tooltip (maybe different library) */}
                {/* Causes freeze */}
                {/* <Tooltip target="userinfo__username" isOpen={copyTooltipOpen} placement="right">
                    {!copied && t('userinfo:copy:click')}
                    {copied && t('userinfo:copy:clicked')}
                </Tooltip> */}
                {copied ? '' : undefined}
            </div>
        </div>
    );
};

const DataAssistantFallback: React.FC<ErrorBoundaryFallbackProps> = ({ error }) => {
    useEffect(() => console.error({ error }), [error]);

    return <div />;
};
