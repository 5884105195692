// conversion as specified over here:
// https://ti8mad.sharepoint.com/:x:/r/sites/account-crb/crb_werkmaterial/_layouts/15/Doc.aspx?sourcedoc=%7BD6DEAFA9-3DCA-4E36-A0F0-E9F80B856118%7D&file=BKP_to_eBKP-H_FINAL_kl_nodes_Redistribution%20und%20MwST%20rechnung.xlsx&action=default&mobileredirect=true

import { groupBy, sumBy } from 'lodash';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { getAllLeaves, getTotalForestValue } from '../effects';
import { WizardState } from '../types';
import { roundTree } from './useRoundedBKPTree';

const defaultVatRate = 0.077;

export function useVatRedistribution({
    staticData,
    wizardState,
}: {
    staticData?: StaticData;
    wizardState: WizardState;
}): undefined | bkphValue[] {
    /**
     * Redistributes the VAT by extracting the taxed part of each category (except A which is not vat relevant)
     * and maps it to the category Z.
     */
    const { bkp, project: currentProject } = wizardState;

    return useMemo(() => {
        if (!staticData || !bkp || Object.keys(bkp).length === 0 || !currentProject) {
            return;
        }

        const roundedBKPTree = roundTree(bkp);

        const applicableVatRate = getApplicableVatRate(currentProject, staticData);
        const leaves = getAllLeaves(roundedBKPTree);
        const leavesByEbkphLetter = Object.entries(groupBy(leaves, (leaf) => leaf.ebkphLetter));
        const bkphValues = leavesByEbkphLetter
            .map(([id, regs]) => ({
                code: id,
                value: getTotalForestValue(regs),
            }))
            .sort((a, b) => b.code.length - a.code.length ?? a.code.localeCompare(b.code));

        return vatRedistribution(bkphValues, applicableVatRate);
    }, [bkp, staticData, currentProject]);
}

export function getApplicableVatRate(project: Project, staticData: StaticData): number {
    const now = DateTime.now();
    const costIndexDate = getCostIndexDate(project, staticData, now);
    const applicableVatRate = findVatRateByDate(costIndexDate, staticData.vatRates) ?? defaultVatRate;

    return applicableVatRate;
}

export function getCostIndexDate(currentProject: Project, staticData: StaticData, fallback: DateTime): DateTime {
    const costIndexId = currentProject.costIndex.id;
    const costIndex = staticData.costIndices.find((costIndex) => costIndex.id === costIndexId);
    return costIndex ? DateTime.fromSQL(costIndex.date) : fallback;
}

export type bkphValue = {
    code: string;
    value: number;
};

export function findVatRateByDate(date: DateTime, vatRates: VatRate[]): number | undefined {
    const parsedVatRates = vatRates.map((rate) => ({ ...rate, validFrom: DateTime.fromSQL(rate.validFrom) }));
    const filteredVatRates = parsedVatRates.filter((rate) => rate.validFrom <= date);
    const sortedVatRates = filteredVatRates.sort((a, b) => b.validFrom.toMillis() - a.validFrom.toMillis());

    return sortedVatRates[0]?.value;
}

export function vatRedistribution(bkphValues: bkphValue[], vatRate: number): bkphValue[] {
    const total = sumBy(bkphValues, ({ value }) => value ?? 0);
    const A = bkphValues.find((e) => e.code === 'A')?.value ?? 0;
    const Z = bkphValues.find((e) => e.code === 'Z')?.value ?? 0;
    const totalWithoutA = total - A;
    const totalWithoutAAndZ = totalWithoutA - Z;

    const debitWithVat = totalWithoutA / totalWithoutAAndZ; // sollMitMwSt
    const withoutVat = bkphValues // ohneMwstIst
        .filter((e) => !['A', 'Z'].includes(e.code))
        .map(({ code, value }) => ({ code, value: value / (1 + vatRate) }));

    const withoutVatDebit = withoutVat.map((e) => ({ ...e, value: e.value * debitWithVat })); // ohneMwstSoll
    const withoutVatDebitTotal = sumBy(withoutVatDebit, ({ value }) => value ?? 0);

    const final = [
        {
            code: 'A',
            value: A,
        },
        ...withoutVatDebit,
        {
            code: 'Z',
            value: withoutVatDebitTotal * vatRate,
        },
    ];

    return final;
}
