import classNames from 'classnames';
import { noop } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NumberInput } from '../FormComponents';

interface ValueEntryProps {
    className?: string;
    readOnly?: boolean;
    value: string;
    onValueUpdated?: (newValue: string) => void;
    labelKey1: string;
    labelKey2: string;
    decimals?: number;
    unit?: string;
}

const tPrefix = 'editor:bkp-to-ebkph-wizard:volume-evaluation:model-creation-form:';

export const ValueEntry: React.FC<ValueEntryProps> = ({
    className,
    readOnly = false,
    value,
    onValueUpdated = noop,
    labelKey2,
    labelKey1,
    decimals = 0,
    unit,
}) => {
    const [t] = useTranslation();
    const handleOnChange = React.useCallback(
        (newValue) => !readOnly && onValueUpdated(newValue),
        [onValueUpdated, readOnly]
    );

    return (
        <tr className={classNames('hoverable', className)} data-testid="value-entry-wrapper">
            <td />
            <td className="py-1">{t(`${tPrefix}${labelKey1}`)}</td>
            <td className="py-1" colSpan={2}>
                {t(`${tPrefix}${labelKey2}`)}
            </td>
            <td className="py-1">
                <NumberInput
                    wrapperClassName="mb-0 overflow-visible"
                    disabled={readOnly}
                    value={isNaN(+value) ? '0' : value}
                    onChange={handleOnChange}
                    decimals={decimals}
                    lazy={true}
                    label={unit}
                />
            </td>
        </tr>
    );
};
