import type { PerspectiveHighlight } from '@crb-oa-viewer/data-assistant-building-plan';
import { flatMap, noop } from 'lodash';
import { get, sumBy } from 'lodash/fp';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Form, Row, Table } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { MappingNames, MeasureUnit } from '../../enums';
import { TextButton } from '../Buttons';
import { BuildingEdit } from './BuildingEdit';
import { ValueEntry } from './ValueEntry';

export interface BuildingPlanEditProps {
    buildings: Building[];
    highlight?: Highlight;
    onComplete?: () => void;
    onModelItemEvent?: (modelItemEvent: { event: PerspectiveHighlight; buildingId: string }) => void;
    onBuildingsUpdate: (updatedBuildings: Building[]) => void;
    muah?: number;
    onMuahChange?: (newMuah: number) => void;
    usa?: number;
    onUsaChange?: (newUsa: number) => void;
    treeType?: MappingNames;
    displayWizardInfo?: boolean;
    isAreaEditable?: boolean;
}

const tPrefix = 'editor:bkp-to-ebkph-wizard:volume-evaluation:model-creation-form:';
const sumByArea = sumBy<Floor>(get('area'));

export const isBuildingPlanEditValid = (muah?: number, treeType?: MappingNames): boolean =>
    treeType === MappingNames.TreeNoH || !!muah;

export const stringToTranslationMap = (s: string): TranslationMap => ({ de_DE: s, en_GB: s, it_IT: s, fr_FR: s });

export const BuildingPlanEdit: React.FC<BuildingPlanEditProps> = ({
    buildings,
    highlight,
    onModelItemEvent,
    onComplete,
    onBuildingsUpdate,
    muah,
    onMuahChange,
    usa,
    onUsaChange,
    treeType,
    displayWizardInfo,
    isAreaEditable,
}) => {
    const [t] = useTranslation();

    const [closedBuildings, setClosedBuildings] = React.useState<Set<string>>(new Set());

    const handleOpenToggleBuilding = React.useCallback(
        (id: string) =>
            setClosedBuildings((b) => {
                const newSet = new Set(b);
                b.has(id) ? newSet.delete(id) : newSet.add(id);
                return newSet;
            }),
        []
    );
    const handleSubmit = React.useCallback(() => onComplete?.(), [onComplete]);
    const highlights = React.useMemo(() => (highlight ? [highlight] : []), [highlight]);

    const handleBuildingAdd = React.useCallback(() => {
        const id = uuidv4();
        onBuildingsUpdate([
            ...buildings,
            {
                id,
                name: stringToTranslationMap(`Gebäude ${buildings.length + 1}`),
                floors: [] as Floor[],
                referenceHeight: 0,
            } as Building,
        ]);
    }, [buildings, onBuildingsUpdate]);

    const handleBuildingUpdate = React.useCallback(
        (updateBuilding: Building) => {
            onBuildingsUpdate(buildings.map((b) => (b.id === updateBuilding.id ? updateBuilding : b)));
        },
        [buildings, onBuildingsUpdate]
    );

    const handleBuildingDelete = React.useCallback(
        (buildingId: string) => {
            onBuildingsUpdate(buildings.filter((b) => b.id !== buildingId));
        },
        [buildings, onBuildingsUpdate]
    );

    const totalArea = sumByArea(flatMap(buildings.map((b) => b.floors)));

    const handleMuahChange = useCallback((muah) => onMuahChange?.(parseFloat(muah)), [onMuahChange]);
    const handleUsaChange = useCallback((usa) => onUsaChange?.(parseFloat(usa)), [onUsaChange]);

    return (
        <Form className="model-creation-form" onSubmit={handleSubmit}>
            <Container>
                {displayWizardInfo && (
                    <>
                        <Row className="very-small-push-bottom">
                            <Col xs={12}>
                                <h3>{t(`${tPrefix}title`)}</h3>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12}>
                                <p>{t(`${tPrefix}description`)}</p>
                            </Col>
                        </Row>
                    </>
                )}
                <Row>
                    {buildings.map((building) => (
                        <BuildingEdit
                            key={building.id}
                            showRelativeHeight={buildings.length > 1}
                            building={building}
                            isOpen={!closedBuildings.has(building.id)}
                            highlights={highlights}
                            onOpenToggle={handleOpenToggleBuilding}
                            onModelItemEvent={onModelItemEvent}
                            onBuildingUpdate={handleBuildingUpdate}
                            onBuildingDelete={handleBuildingDelete}
                            isAreaEditable={isAreaEditable}
                        />
                    ))}
                    <Table className="gutter-top">
                        <tbody>
                            <tr>
                                <td colSpan={5}>
                                    <TextButton
                                        type="button"
                                        name={t(`${tPrefix}add-building`)}
                                        onClick={handleBuildingAdd}
                                        testId="add-building"
                                    />
                                </td>
                            </tr>

                            {displayWizardInfo && (
                                <>
                                    <ValueEntry
                                        value={totalArea < 0 ? '0' : totalArea.toString()}
                                        labelKey1="fa"
                                        labelKey2="fa-long"
                                        onValueUpdated={noop}
                                        readOnly={true}
                                        unit={MeasureUnit.SQUARE_METER}
                                        decimals={2}
                                        className="table-border-section"
                                    />

                                    {treeType === MappingNames.TreeWithH && onMuahChange && (
                                        <ValueEntry
                                            value={muah?.toString() ?? ''}
                                            labelKey1="muah"
                                            labelKey2="muah-long"
                                            onValueUpdated={handleMuahChange}
                                            unit="m²"
                                            decimals={2}
                                        />
                                    )}

                                    <ValueEntry
                                        value={usa?.toString() ?? ''}
                                        labelKey1="usa"
                                        labelKey2="usa-long"
                                        onValueUpdated={handleUsaChange}
                                        unit="m²"
                                        decimals={2}
                                    />
                                </>
                            )}
                        </tbody>
                    </Table>
                </Row>
            </Container>
        </Form>
    );
};
