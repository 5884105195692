import type { InputFile } from '@crb-oa-viewer/data-assistant-building-plan';
import classnames from 'classnames';
import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { BuildingPlanContent } from './BuildingPlanImporterContent';
import { BuildingPlanError, GeneralUploadErrorAddon } from './BuildingPlanImporterError';
import { BuildingPlanInstructions } from './BuildingPlanImporterInstructions';
import { BuildingPlanNavActions } from './BuildingPlanImporterNavActions';
import { BuildingPlanNavState } from './BuildingPlanImporterNavState';
import type { BuildingPlanError as BuildingPlanErrorType, InitialStep, NavigationState } from './types';
import { getInitialStep, getSteps, toByte } from './utils';

export interface BuildingPlanImporterCallbacks {
    onImport: (file: InputFile, ignoreError: () => void) => unknown;
    onError?: (error: BuildingPlanErrorType) => unknown;
}

export interface BuildingPlanImporterConfigProps {
    className?: string;
    maxFileSizeMB?: number;
}

interface UploadOnlyStandardBuildingPlanImporterProps
    extends BuildingPlanImporterConfigProps,
        BuildingPlanImporterCallbacks {
    initialStep?: 'upload';
    onlyUpload: true;
}

interface StandardBuildingPlanImporterProps extends BuildingPlanImporterConfigProps, BuildingPlanImporterCallbacks {
    initialStep?: InitialStep;
    onlyUpload?: false;
}

export type BuildingPlanImporterProps = UploadOnlyStandardBuildingPlanImporterProps | StandardBuildingPlanImporterProps;

const DEFAULT_MAX_SIZE = 20;

export const BuildingPlanImporter: React.FC<BuildingPlanImporterProps> = ({
    className,
    initialStep,
    onlyUpload,
    onImport,
    onError,
    maxFileSizeMB = DEFAULT_MAX_SIZE,
}) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const steps = useMemo(() => getSteps(onlyUpload), [onlyUpload]);
    const initialIndex = useMemo(
        () => steps.findIndex(({ id }) => id === getInitialStep(initialStep, onlyUpload)),
        [steps, initialStep, onlyUpload]
    );

    const [index, setIndex] = useState(Math.max(initialIndex, 0));
    const [error, setError] = useState<BuildingPlanErrorType>();

    useEffect(() => {
        if (error) onError?.(error);
    }, [error, onError]);

    const step = steps[index];

    const isStart = index === 0;
    const isEnd = index === steps.length - 1;

    const previous = useCallback(() => setIndex((s) => s - 1), []);
    const next = useCallback(() => setIndex((s) => s + 1), []);

    const openDialog = useCallback(() => inputRef.current?.click(), []);

    const handleError = useCallback((error) => setError(error), []);

    const navState: NavigationState = {
        step,
        steps,
        isStart,
        isEnd,
    };

    const handleFile = useCallback(
        async (file) => {
            let isErrorIgnored = false;
            const ignoreError = () => {
                isErrorIgnored = true;
            };

            try {
                await onImport(file, ignoreError);
            } catch (error) {
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                if (!isErrorIgnored) {
                    setError({
                        error: 'general-upload-error',
                        errorAddon: <GeneralUploadErrorAddon />,
                    });
                }

                // Reset input
                if (inputRef.current) inputRef.current.value = '';
            }
        },
        [onImport]
    );

    return (
        <div className={classnames('building-plan-importer', className)}>
            <BuildingPlanNavState {...navState} />

            {error && <BuildingPlanError {...error} />}

            <BuildingPlanContent
                {...navState}
                onChange={handleFile}
                onError={handleError}
                openDialog={openDialog}
                maxByteSize={toByte(maxFileSizeMB)}
                ref={inputRef}
            />

            <BuildingPlanInstructions {...navState} />

            <BuildingPlanNavActions {...navState} previous={previous} next={next} openDialog={openDialog} />
        </div>
    );
};
