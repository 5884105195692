import { keyBy, max, min } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { some, Tree } from '../../effects';
import { codeOf } from '../../utils';
import { MenuButtonMenuProps, TextButton } from '../Buttons';
import { Checkbox, RangeInput, SearchSelect, Select, TextInput } from '../FormComponents';
import { createProjectFilter } from './utils';

interface ProjectFilterMenu extends MenuButtonMenuProps {
    projects: ProjectOverview[];
    cantons: StaticCanton[];
    classifications: Tree<StaticBuildingClassification>[];
    filters?: IProjectFilterData;
    onFilterData?: (filters: IProjectFilterData) => void;

    disabledRegulations?: string[];

    minYear?: number;
    maxYear?: number;
}

export interface IProjectFilterData {
    name: string;
    source: string;
    category?: ObjectReference['id'];
    canton?: StaticCanton['cantonCode'];
    yearMin: number;
    yearMax: number;
    regulations: {
        [regulationName: string]: boolean;
    };
}

function createInitialFilter(projects: ProjectOverview[], disabledRegulations: string[] = []) {
    const years = projects
        .map((p) => new Date(p.moveIn).getFullYear())
        .map((value) => (value < 100 ? 2000 + value : value));
    const minYear = min(years) ?? 1900;
    const maxYear = max(years) ?? new Date().getFullYear();

    return {
        name: '',
        source: '',
        category: undefined,
        regulations: disabledRegulations.reduce(
            (acc, reg) => ({
                ...acc,
                [reg]: true,
            }),
            {}
        ),
        yearMin: minYear,
        yearMax: maxYear,
    };
}

const REGULATIONS = ['BKP', 'eBKP-H', 'eBKP-H2020(A)', 'eBKP-H2020(B)', 'eBKP-T(A)', 'eBKP-T(B)'];

function getYear(
    projects: undefined | ProjectOverview[],
    func: (years?: number[]) => number | undefined,
    defaultValue: number
): number {
    const years = projects
        ?.map((p) => new Date(p.moveIn).getFullYear())
        ?.map((value) => (value < 100 ? 2000 + value : value));

    const year = func(years) ?? defaultValue;
    return year && year < 100 ? 2000 + year : year;
}

export const ProjectFilterMenu: React.FC<ProjectFilterMenu> = ({
    projects,
    disabledRegulations,
    filters: suppliedFilters = createInitialFilter(projects, disabledRegulations),
    cantons,
    classifications: buildingClassCategories,
    close,
    onFilterData,
    minYear = getYear(projects, min, 1900),
    maxYear = getYear(projects, max, new Date().getFullYear()),
}) => {
    const [t, i18n] = useTranslation();
    const tt = (key: string) => t(`editor:projects:filter:${key}`);
    const langCode = codeOf(i18n.language);

    const [filters, setFilters] = React.useState<IProjectFilterData>(suppliedFilters);

    const applyFilter = React.useCallback(() => {
        onFilterData?.(filters);
        close();
    }, [filters, onFilterData, close]);

    const deleteFilter = React.useCallback(() => {
        const newFilter = createInitialFilter(projects, disabledRegulations);
        onFilterData?.(newFilter);
        setFilters(newFilter);
    }, [onFilterData, projects, disabledRegulations]);

    const categoryMap = React.useMemo(() => keyBy(buildingClassCategories, (c) => c.id), [buildingClassCategories]);

    const filterProjects = React.useCallback(
        (usedFilters: Partial<IProjectFilterData>) => {
            const usedFilter = createProjectFilter({ ...filters, ...usedFilters }, categoryMap);
            return projects.filter(usedFilter);
        },
        [filters, categoryMap, projects]
    );

    const categories = React.useMemo(() => {
        const filteredProjects = filterProjects({ category: undefined });

        return buildingClassCategories.filter((category) =>
            filteredProjects.some((project) => {
                if (project.buildingClassPartners?.id === category.id) return true;

                return some(category, (i) => i.id === project.buildingClassPartners?.id);
            })
        );
    }, [buildingClassCategories, filterProjects]);

    const possibleCantons = React.useMemo(() => {
        const filteredProjects = filterProjects({ canton: undefined });

        return cantons.filter((canton) => filteredProjects.some((project) => project.cantonCode === canton.cantonCode));
    }, [filterProjects, cantons]);

    const possibleYears = React.useMemo(() => {
        const filteredProjects = filterProjects({
            yearMax: maxYear,
            yearMin: minYear,
        });
        return filteredProjects
            .map((p) => new Date(p.moveIn).getFullYear())
            .map((value) => (value < 100 ? 2000 + value : value));
    }, [filterProjects, maxYear, minYear]);
    const possibleMinYear = React.useMemo(() => Math.max(min(possibleYears) ?? minYear), [possibleYears, minYear]);
    const possibleMaxYear = React.useMemo(() => Math.min(max(possibleYears) ?? maxYear), [possibleYears, maxYear]);

    const possibleRegulations = React.useMemo(() => {
        return REGULATIONS.filter((regulation) => {
            const filteredProjects = filterProjects({
                regulations: { ...filters.regulations, [regulation]: false },
            });

            return filteredProjects.some((p) => p.arithmeticalFlags[regulation]);
        });
    }, [filters.regulations, filterProjects]);

    const changeNameFilter = React.useCallback((name) => setFilters((f) => ({ ...f, name })), []);
    const changeSourceFilter = React.useCallback((source) => setFilters((f) => ({ ...f, source })), []);
    const changeCategory = React.useCallback((category) => setFilters((f) => ({ ...f, category })), []);
    const changeCanton = React.useCallback((canton) => setFilters((f) => ({ ...f, canton })), []);
    const changeYearRange = React.useCallback(({ min: newMin, max: newMax }) => {
        setFilters((f) => ({
            ...f,
            yearMin: Math.min(newMin, newMax),
            yearMax: Math.max(newMin, newMax),
        }));
    }, []);
    const changeRegulation = React.useCallback((regulation: string, value: boolean) => {
        setFilters((f) => ({
            ...f,
            regulations: { ...f.regulations, [regulation]: value },
        }));
    }, []);

    const categoryOptions = React.useMemo(() => {
        const resetOption = { label: '', value: undefined, key: -1 };

        function nameOfCategory(category: StaticBuildingClassification, language: string) {
            return `${category.classificationCode} ${category.description[language]}`;
        }

        const options = categories.map((category) => ({
            title: nameOfCategory(category.parent ? category.parent : category, langCode),
            label: nameOfCategory(category, langCode),
            value: category.id,
            key: category.id,
        }));

        return [resetOption, ...options];
    }, [categories, langCode]);

    const cantonOptions = React.useMemo(() => {
        const resetOption = { label: '', value: undefined, key: -1 };

        const options = possibleCantons.map((canton) => ({
            title: canton.cantonCode,
            label: canton.cantonName,
            value: canton.cantonCode,
            key: canton.cantonCode,
        }));

        return [resetOption, ...options];
    }, [possibleCantons]);

    return (
        <div className="project-filter-menu">
            <h2 data-testid="filter-heading">{tt('title')}</h2>

            <section className="my-3">
                <TextInput
                    label={tt('filters:name')}
                    value={filters.name}
                    onChange={changeNameFilter}
                    testId="name-filter"
                />
                <TextInput label={tt('filters:source')} value={filters.source} onChange={changeSourceFilter} />

                <SearchSelect
                    className="mb-3"
                    label={tt('filters:category')}
                    value={filters.category}
                    onChange={changeCategory}
                    options={categoryOptions}
                />

                <Select
                    className="mb-3"
                    label={tt('filters:canton')}
                    value={filters.canton}
                    onChange={changeCanton}
                    disableScroll={true}
                    options={cantonOptions}
                />

                <RangeInput
                    className="mb-3"
                    label={tt('filters:completionYear')}
                    value={{ min: filters.yearMin, max: filters.yearMax }}
                    onChange={changeYearRange}
                    min={possibleMinYear}
                    max={possibleMaxYear}
                    rangeMin={minYear}
                    rangeMax={maxYear}
                />

                <div>
                    <label>{tt('filters:regulations')}</label>

                    {['BKP', 'eBKP-H', 'eBKP-H2020(A)', 'eBKP-H2020(B)', 'eBKP-T(A)', 'eBKP-T(B)'].map((regulation) => (
                        <RegulationCheckbox
                            regulation={{
                                name: regulation,
                                label: t(`editor:regulation:${regulation}`),
                            }}
                            value={filters.regulations[regulation]}
                            disabled={
                                disabledRegulations?.includes(regulation) || !possibleRegulations.includes(regulation)
                            }
                            changeRegulation={changeRegulation}
                            key={regulation}
                        />
                    ))}
                </div>
            </section>

            <TextButton className="w-100 mt-3" onClick={applyFilter} name={tt('apply')} testId="apply-filter" />

            <TextButton className="w-100 mt-3" onClick={deleteFilter} name={tt('reset')} testId="delete-filter" />
        </div>
    );
};

const RegulationCheckbox: React.FC<{
    regulation: { name: string; label: string };
    value: boolean;
    disabled: boolean;
    changeRegulation: (regulation: string, value: boolean) => void;
}> = ({ regulation, value, disabled, changeRegulation }) => {
    const toggle = React.useCallback(
        () => changeRegulation(regulation.name, !value),
        [regulation.name, value, changeRegulation]
    );

    return (
        <Checkbox
            className="mb-0"
            label={regulation.label}
            value={value}
            disabled={disabled}
            onChange={toggle}
            key={regulation.name}
        />
    );
};
