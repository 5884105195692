import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { TFunction } from 'i18next';
import { identity, noop, uniqBy } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { flattenTree, useDialog, useProject, useProjectId, useSearchParam } from '../../../../effects';
import { TotalCostsCode } from '../../../../enums';
import { PathEstimator } from '../../../../pages/Estimator/PathEstimator';
import { TextButton } from '../../../Buttons';
import { CSVImporter } from '../../../CSV';
import { FileUploader, UploaderComponentProps } from '../../../FileUploader';
import { ForestDiagram } from '../../../ForestDiagram';
import { Overlay } from '../../../Overlay';
import { Spinner } from '../../../Spinner';
import { BaseView } from '../View';
import { Tab } from '../tabs';
import type { ViewProps } from '../types';
import { Cost } from './Cost';
import { CostIndexList } from './CostIndexList';
import { CostCalculator } from './calculators';
import type { CostForest, CostNode, CostTree } from './types';
import {
    ForestChangeFn,
    NodeChangeFn,
    calculateTotalValue,
    changeNode,
    changeNodeDescription,
    changeNodeLocked,
    changeNodeQuantity,
    changeNodeValue,
    changeTotalCost,
    changeTotalForestNode,
    createTotalCostIfNotExists,
    hasProjectChanges,
    importCostsIntoForest,
    propagateLockedChange,
    propagateNodeValueChanges,
    recalculateDependingQuantities,
    shouldBeLocked,
    updateEquivalentQuantities,
    useProjectCostForest,
    useTotalCostForest,
} from './utils';

const getRegulations = (t: TFunction) => [
    { id: 'BKP', name: t('editor:projects:creator:cost-sheet:tabs:BKP') },
    { id: 'eBKP-H', name: t('editor:projects:creator:cost-sheet:tabs:eBKP-H') },
    {
        id: 'eBKP-T(A)',
        name: t('editor:projects:creator:cost-sheet:tabs:eBKP-T(A)'),
    },
    {
        id: 'eBKP-T(B)',
        name: t('editor:projects:creator:cost-sheet:tabs:eBKP-T(B)'),
    },
    {
        id: 'eBKP-H2020(A)',
        name: t('editor:projects:creator:cost-sheet:tabs:eBKP-H2020(A)'),
    },
    {
        id: 'eBKP-H2020(B)',
        name: t('editor:projects:creator:cost-sheet:tabs:eBKP-H2020(B)'),
    },
];

const totalKeyMap: Record<string, keyof TotalProjectCost> = {
    [TotalCostsCode.KoA_INVESTMENT_COSTS]: 'investmentCost',
    [TotalCostsCode.KoE_PRODUCTION_COSTS]: 'productionCost',
    [TotalCostsCode.KoB_CONSTRUCTION_FACILITY_COSTS]: 'constructionFacilityCost',
    [TotalCostsCode.KoG_BUILDING_COSTS]: 'buildingCost',
};

/**
 * Only can open estimator when select eBKP-H2020(A) or eBKP-H2020(B)
 */
const openEstimatorTabs = new Set(['eBKP-H2020(A)', 'eBKP-H2020(B)']);

export const CostView: React.FC<ViewProps> = ({
    project,
    staticData,
    language,
    changeProject,
    t,
    wizardStateExists,
}) => {
    const history = useHistory();
    const Regulations = useMemo<{ id: string; name: string }[]>(() => getRegulations(t), [t]);
    const tabs: Array<Tab> = useMemo(
        () =>
            Object.keys(staticData.regulations)
                .filter((name) => Regulations.some((regulation) => regulation.id === name))
                .map((name) => ({
                    id: name,
                    name: t(`editor:projects:creator:cost-sheet:tabs:${name}`),
                }))
                .sort(
                    (t1, t2) =>
                        Regulations.findIndex((r) => r.id === t1.id) - Regulations.findIndex((r) => r.id === t2.id)
                ),
        [staticData.regulations, Regulations, t]
    );
    const [tab, changeTab] = useSearchParam<string>('tab', tabs[0].id);
    const calculator = useMemo(() => CostCalculator.get(tab), [tab]);

    const [costIndex, setCostIndex] = useState(staticData.costIndices.find((ci) => ci.id === project.costIndex.id));

    const changeCostIndex = useCallback(
        (costIndex) => {
            setCostIndex(costIndex);
            changeProject((p) => ({
                ...p,
                costIndex,
            }));
        },
        [changeProject]
    );

    const regionId =
        costIndex?.greaterRegion.id ||
        project.greaterRegion.id ||
        staticData.greaterRegions.find((region) => region.name.de_DE === 'Schweiz')?.id;
    const regionalCostIndices = useMemo(
        () =>
            staticData.costIndices
                .filter((cIndex) => cIndex.greaterRegion.id === regionId)
                .map((cIndex) => ({
                    ...cIndex,
                    sortDate: new Date(cIndex.date),
                }))
                .sort((i1, i2) => i2.sortDate.getTime() - i1.sortDate.getTime()),
        [staticData.costIndices, regionId]
    );

    const importErrorDialog = useDialog({
        ...t('editor:projects:creator:cost-sheet:import:dialog:error', { returnObjects: true }),
        closeOnOutsideClick: true,
    });

    const importContainsOverridesDialog = useDialog({
        ...t('editor:projects:creator:cost-sheet:import:dialog:overrides', { returnObjects: true }),
        closeOnOutsideClick: true,
    });

    const importNotCorrectDialog = useDialog({
        ...t('editor:projects:creator:cost-sheet:import:dialog:incorrect', { returnObjects: true }),
        closeOnOutsideClick: true,
    });

    const changeCalculator = (type: string) => () => changeTab(type);

    const handleTotalCostForestChange = useCallback(
        (forest: CostForest) => {
            if (forest.length === 0) return;

            const regulationName = forest[0].name;

            changeProject((p) => {
                const totalCosts = createTotalCostIfNotExists(p.totalCosts ?? [], regulationName);

                return {
                    ...p,
                    totalCosts: changeTotalCost(totalCosts, regulationName, (tc) => {
                        return forest.reduce(
                            (acc, tree) => ({
                                ...acc,
                                [totalKeyMap[tree.code]]: tree.cost.value ?? 0,
                            }),
                            tc
                        );
                    }),
                };
            });
        },
        [changeProject]
    );
    const [totalCostForest, setTotalCostForest] = useTotalCostForest(
        project,
        staticData,
        tab,
        handleTotalCostForestChange
    );

    const changeTotalCostForestNode = useCallback(
        (id: Regulation['id'], change: (tree: CostTree) => CostTree) => {
            setTotalCostForest((forest: CostForest): CostForest => changeTotalForestNode(forest, id, change));
        },
        [setTotalCostForest]
    );

    const handleTotalCostValueChange = useCallback(
        ([id]: Regulation['id'][], change) => {
            changeTotalCostForestNode(id, (tree) => {
                const value = change(tree.cost.value ?? 0, tree);

                return {
                    ...tree,
                    cost: {
                        ...tree.cost,
                        value,
                    },
                    locked: shouldBeLocked(value, tree.cost.checksum),
                };
            });
        },
        [changeTotalCostForestNode]
    );

    const handleTotalCostLockedChange = useCallback(
        ([id], change) => {
            changeTotalCostForestNode(id, (tree) => {
                const locked = change(tree.locked, tree);

                return {
                    ...tree,
                    cost: {
                        ...tree.cost,
                        value: (locked ? tree.cost.checksum ?? tree.cost.value : tree.cost.value) ?? 0,
                    },
                    locked,
                };
            });
        },
        [changeTotalCostForestNode]
    );

    const handleProjectCostForestChange = useCallback(
        (forest: CostForest) => {
            const regulations: RegulationWithCost[] = forest.flatMap((tree) => flattenTree(tree));

            const newCosts = regulations.map((n) => ({ ...n.cost }));

            changeProject(({ costs = [], ...p }) => ({
                ...p,
                costs: uniqBy([...newCosts, ...costs], (c) => c.regulation.id).filter(
                    (r) => !!r.value || r.referenceQuantity || Object.values(r.description ?? {}).some((d) => !!d)
                ),
            }));

            setTotalCostForest((totalForest) => {
                return totalForest.map((tree) => {
                    const virtuallyUnlocked = !tree.cost.checksum && !!tree.cost.value;
                    const checksum = calculateTotalValue(calculator, tree, regulations);

                    const locked = tree.locked && (!virtuallyUnlocked || tree.cost.value === checksum);

                    return {
                        ...tree,
                        cost: {
                            ...tree.cost,
                            checksum,
                            value: locked ? checksum : tree.cost.value ?? 0,
                        },
                        locked,
                    };
                });
            });
        },
        [calculator, changeProject, setTotalCostForest]
    );

    const [costForest, setCostForest] = useProjectCostForest(project, staticData, tab, handleProjectCostForestChange);

    const changeCostForestNode = useCallback(
        (
            path,
            change: NodeChangeFn<CostNode>,
            resolve?: NodeChangeFn<CostNode>,
            postChange?: ForestChangeFn<CostNode>
        ) => setCostForest((forest) => changeNode(forest, path, change, resolve, postChange)),
        [setCostForest]
    );

    const changeValue = useCallback(
        (path: Regulation['id'][], change: (value: number, node: CostNode) => number) => {
            return changeCostForestNode(
                path,
                changeNodeValue(change),
                propagateNodeValueChanges,
                recalculateDependingQuantities(path)
            );
        },
        [changeCostForestNode]
    );

    const changeLocked = useCallback(
        (path: Regulation['id'][], change: (locked: boolean, node: CostNode) => boolean) => {
            return changeCostForestNode(
                path,
                changeNodeLocked(change),
                propagateLockedChange,
                recalculateDependingQuantities(path)
            );
        },
        [changeCostForestNode]
    );

    const changeQuantity = useCallback(
        (path: Regulation['id'][], change: (quantity: number, node: CostNode) => number) => {
            return changeCostForestNode(path, changeNodeQuantity(change), identity, updateEquivalentQuantities);
        },
        [changeCostForestNode]
    );

    const changeDescription = useCallback(
        (path: Regulation['id'][], change: (description: TranslationMap, node: CostNode) => TranslationMap) => {
            return changeCostForestNode(path, changeNodeDescription(change));
        },
        [changeCostForestNode]
    );

    const [csv, setCSV] = useState<null | File>(null);
    const readFile = useCallback((fileOrFiles: File | File[]) => {
        const file: File = fileOrFiles instanceof Array ? fileOrFiles[0] : fileOrFiles;
        setCSV(file);
    }, []);

    const [importing, setImporting] = useState(false);
    const importCSVData = useCallback(
        async (data: [string, string, string][]) => {
            setCSV(null);
            setImporting(true);

            const { forest, overrides, corrections } = importCostsIntoForest(costForest, data, 'de_DE');

            if (overrides.length && !(await importContainsOverridesDialog())) {
                return setImporting(false);
            }

            if (corrections.length && !(await importNotCorrectDialog())) {
                return setImporting(false);
            }

            setCostForest(forest);
            setImporting(false);
        },
        [costForest, setCostForest, importNotCorrectDialog, importContainsOverridesDialog]
    );

    const renderFileUploadButton = ({ openFileDialog }: UploaderComponentProps) => (
        <TextButton onClick={openFileDialog} name={t('editor:projects:creator:cost-sheet:import:action')} />
    );

    const closeImporter = useCallback(() => setCSV(null), []);

    const hideQuantity = Regulations[0].id === tab;

    const handleCSVImportError = useCallback(
        (e: unknown) => {
            console.error(e);
            importErrorDialog();
        },
        [importErrorDialog]
    );

    const openBKPtoEBKPHWizard = useCallback(() => {
        history.push(`/editor/project/${project.id}/bkp-to-ebkph`);
    }, [history, project.id]);

    const openEstimationWizard = useCallback(() => {
        const path = generatePath(PathEstimator.EstimationWizard, { projectId: project.id });
        history.push(path);
    }, [history, project.id]);

    const projectId = useProjectId();
    const { data: projectData } = useProject(projectId);
    const hasChanges = hasProjectChanges(projectData, project);

    const showOpenEstimator = openEstimatorTabs.has(tab);

    const shownTotalCosts = totalCostForest.filter((cost) => {
        if (tab === 'BKP') {
            return !['KoE', 'KoB'].includes(cost.code);
        } else {
            return cost.code !== 'KoG';
        }
    });

    return (
        <BaseView
            name="cost"
            className={`tab-${tab.toLowerCase()}`}
            title={t('editor:projects:creator:cost-sheet:title')}
            tabs={tabs}
            onTab={changeCalculator}
        >
            <Row className="justify-content-between">
                {showOpenEstimator ? (
                    <TextButton name={t('create-project:estimation')} onClick={openEstimationWizard} />
                ) : (
                    <TextButton
                        name={t('editor:open-ebkph-wizard')}
                        disabled={!project.arithmeticalFlags.BKP || hasChanges}
                        title={
                            !project.arithmeticalFlags.BKP || hasChanges
                                ? t('editor:arithmetically-incorrect-error-message')
                                : undefined
                        }
                        onClick={openBKPtoEBKPHWizard}
                        testId="open-ebkph-wizard"
                    />
                )}

                <FileUploader
                    accept="text/csv|application/vnd.ms-excel"
                    uploadFile={readFile}
                    Component={renderFileUploadButton}
                    Props={{}}
                />
            </Row>
            <Row className="justify-content-end mt-3">
                <CostIndexList
                    className="w-auto"
                    indices={regionalCostIndices}
                    value={costIndex || regionalCostIndices[0]}
                    onChange={changeCostIndex}
                    t={t}
                />
            </Row>

            {csv && (
                <CSVImporter
                    csv={csv}
                    columns={[{ name: 'Code' }, { name: 'Betrag' }, { name: 'Beschreibung' }]}
                    onData={importCSVData}
                    onError={handleCSVImportError}
                    onClose={closeImporter}
                    noPreview={true}
                    config={{
                        dynamicTyping: false,
                    }}
                />
            )}

            {(csv || importing) && (
                <Overlay className="cost-view-importer-loading-overlay">
                    <div className="vh-100 d-flex  justify-content-center align-items-center">
                        <Spinner size={64} color="#000" />
                    </div>
                </Overlay>
            )}

            {wizardStateExists && (
                <div className="wizard-underway-warning" data-testid="wizard-underway-warning">
                    <FontAwesomeIcon className="wizard-underway-warning__icon" icon={faExclamationTriangle} />
                    <span className="wizard-underway-warning__text">{t('editor:wizard-underway:message')}</span>
                </div>
            )}

            <div className="my-3">
                <ForestDiagram
                    forest={costForest}
                    getId={getCostCode}
                    getName={getCostCode}
                    getValue={getCostValue}
                    maxLevel={2}
                />
            </div>

            <Row className="small-push-bottom">
                <Col xs={12}>
                    <Row className="border-bottom py-2 table-headers">
                        <Col className="col cost-toggle font-weight-bold" xs="24-1" />

                        <Col className="col cost-code font-weight-bold" xs="24-2">
                            {t('editor:projects:creator:cost-sheet:columns:code')}
                        </Col>

                        <Col className="col cost-name font-weight-bold" xs={hideQuantity ? '24-11' : '24-3'}>
                            {t('editor:projects:creator:cost-sheet:columns:name')}
                        </Col>

                        <Col className="col cost-short-name font-weight-bold" hidden={hideQuantity} xs="24-2">
                            {t('editor:projects:creator:cost-sheet:columns:short-name')}
                        </Col>

                        <Col
                            className="col cost-quantity font-weight-bold justify-content-end"
                            hidden={hideQuantity}
                            xs="24-4"
                        >
                            {t('editor:projects:creator:cost-sheet:columns:quantity')}
                        </Col>

                        <Col className="col cost-quantity-unit font-weight-bold" hidden={hideQuantity} xs="24-1" />

                        <Col className="col cost-value font-weight-bold justify-content-end" xs="24-4">
                            {t('editor:projects:creator:cost-sheet:columns:value')}
                        </Col>

                        <Col className="col cost-checksum font-weight-bold pt-0 justify-content-end" xs="24-3">
                            {t('editor:projects:creator:cost-sheet:columns:checksum')}
                        </Col>

                        <Col className="col cost-description font-weight-bold" xs="24-4">
                            {t('editor:projects:creator:cost-sheet:columns:description')}
                        </Col>
                    </Row>

                    <div className="regulation-tree">
                        {shownTotalCosts.map((regulation) => (
                            <Cost
                                regulation={regulation}
                                changeValue={handleTotalCostValueChange}
                                changeQuantity={noop}
                                changeDescription={noop}
                                changeLocked={handleTotalCostLockedChange}
                                language={language}
                                hideQuantity={hideQuantity}
                                total={true}
                                t={t}
                                key={regulation.id}
                            />
                        ))}
                    </div>

                    <div className="regulation-tree">
                        {costForest.map((regulation) => (
                            <Cost
                                regulation={regulation}
                                changeValue={changeValue}
                                changeQuantity={changeQuantity}
                                changeDescription={changeDescription}
                                changeLocked={changeLocked}
                                language={language}
                                hideQuantity={hideQuantity}
                                t={t}
                                key={regulation.code}
                            />
                        ))}
                    </div>
                </Col>
            </Row>
        </BaseView>
    );
};

const getCostCode = (node: CostNode) => node.code;
const getCostValue = (node: CostNode) => node.cost.value;
