import { CancelToken } from 'axios';
import type { TFunction } from 'i18next';
import { identity, isUndefined, keyBy, orderBy, sortBy } from 'lodash';
import XLSX, { WorkBook, WorkSheet, utils } from 'xlsx';
import { TotalCostsCode } from '../enums';

const ALL_LANGUAGES = ['de_DE', 'fr_FR', 'it_IT', 'en_GB'];

export async function exportProject(
    project: Project,
    staticData: StaticData,
    functionalUnitTags: Tag[],
    getProjectById: (id: number) => Promise<Project>,
    t: TFunction,
    lng: string,
    cancelToken: CancelToken
): Promise<void> {
    const book = utils.book_new();

    if (!book.Workbook) book.Workbook = {};
    if (!book.Workbook.Sheets) book.Workbook.Sheets = [];

    appendDatasheetSheet(book, project, staticData, t, lng);
    appendInvolvedPartiesSheet(book, project, staticData, lng);
    appendTagSheet(book, project, lng);
    appendFloorUsageSheet(book, project, staticData, lng);
    appendFunctionalUnitsSheet(book, project, functionalUnitTags, lng);
    await appendLinkedProjectsSheet(book, project, lng, getProjectById);
    appendAreasAndVolumesSheet(book, project, staticData, lng);
    appendBuildingsSheet(book, project, t, lng);
    appendCostsSheets(book, project, staticData, t, lng);
    appendEnergySheet(book, project, staticData, lng);
    appendEnergyParameter(book, project, staticData, lng);
    appendEnergyTree(book, project, staticData, lng);

    if (cancelToken.reason) return;

    XLSX.writeFile(book, `${project.id}.xlsx`);
}

const FIELD_IDS = {
    LANGUAGE: 0,
    ID: 1,
    NAME: 2,
    COST_INDEX: 3,
    CREATOR: 4,
    OWNER: 5,
    ADMIN_STATUS: 6,
    CONSTRUCTION_END: 10,
    CONSTRUCTION_START: 11,
    PLANNING_START: 12,
    COMPETITION_START: 13,
    CONSTRUCTION_DURATION: 14,
    PARAMETERS: {
        PROJECT_TYPE: 20,
        CLIENT: 21,
        FACADE: 22,
        PROJECT_PHASE: 23,
        ROOF_SHAPE: 24,
        CONSTRUCTION_TYPE: 25,
        CONSTRUCTION: 26,
    } as Record<string, number>,
    CLASSIFICATION: {
        CRB: 30,
        PARTNER: 31,
    },
    LOCATION: {
        COUNTRY: 40,
        REGION: 41,
        CANTON: 42,
        MUNICIPALITY: 43,
        CITY: 44,
        STREET: 45,
        STREET_NUMBER: 46,
        ADDRESS_SUPPLEMENT: 47,
        LOCATION: 48,
        COORDINATES: {
            BFS: 50,
            EGID: 51,
            LAT: 52,
            LON: 53,
            GOOGLE_PLUS_CODES: 54,
        },
    },
    MOBILITY: {
        PARKING_SPACES: 60,
        CYCLE_PARKING_SPACES: 61,
        VEHICLE_CHARGING_STATIONS: 62,
        PLOT_RATIO: 63,
        ZONE_ALLOCATION: 64,
        SITE_MASTERPLAN: 65,
        PLOT_RATIO_BONUS: 66,
    },
    DATA_SOURCE: {
        SOURCE: 70,
        CATALOGUE: 71,
        INTERNAL_NUMBER: 72,
        DATA_SOURCE: 73,
        PUBLICATION_DATE: 74,
        ALTERNATIVE_SOURCES: 75,
    },
} as const;

function appendDatasheetSheet(
    book: WorkBook,
    project: Project,
    staticData: StaticData,
    t: TFunction,
    lng: string
): void {
    const header = ['FIELD_ID', 'FIELD_NAME', 'VALUE_ID', 'VALUE'];

    const crbClassification = staticData.buildingClassification.crb.find(
        (partner) => partner.id === project.buildingClassCrb?.id
    );
    const partnerClassification = staticData.buildingClassification.partners.find(
        (partner) => partner.id === project.buildingClassPartners.id
    );

    const region = staticData.greaterRegions.find(({ id }) => id === project.greaterRegion.id);
    const location = project.projectAddress?.location;
    const canton = location?.canton;

    const descriptionTypes = sortBy(staticData.descriptionTypes, 'id');
    const descriptionsMap: Record<string, ProjectDescription | undefined> = keyBy(project.descriptions, 'type.id');

    const descriptions = descriptionTypes.map(({ id, name }) => [
        id,
        getTranslation(name, lng),
        '',
        getTranslation(descriptionsMap[id]?.text, lng),
    ]);

    const costIndex = staticData.costIndices.find(({ id }) => id === project.costIndex.id);

    const data = [
        [FIELD_IDS.LANGUAGE, t('export:language', { lng }), lngMap[lng] ?? lng, lng.toUpperCase()],
        [FIELD_IDS.ID, t('editor:project:parameters:id', { lng }), '', project.id],
        [FIELD_IDS.NAME, t('editor:project:parameters:name', { lng }), '', getTranslation(project.name, lng)],
        [
            FIELD_IDS.COST_INDEX,
            t('editor:projects:creator:cost-sheet:cost-index:level', { lng }),
            costIndex?.id,
            costIndex?.date,
        ],
        [FIELD_IDS.CREATOR, t('editor:project:parameters:creator', { lng }), '', project.creator],
        [FIELD_IDS.OWNER, t('editor:project:parameters:owner', { lng }), '', project.owner],
        [FIELD_IDS.ADMIN_STATUS, t('editor:project:parameters:adminStatus', { lng }), '', project.adminStatus],
        [
            FIELD_IDS.CONSTRUCTION_END,
            t('editor:project:parameters:constructionTime:moveIn', { lng }),
            '',
            project.constructionTime?.moveIn,
        ],
        [
            FIELD_IDS.CONSTRUCTION_END,
            t('editor:project:parameters:constructionTime:constructionStart', { lng }),
            '',
            project.constructionTime?.constructionStart,
        ],
        [
            FIELD_IDS.PLANNING_START,
            t('editor:project:parameters:constructionTime:planningStart', { lng }),
            '',
            project.constructionTime?.planningStart,
        ],
        [
            FIELD_IDS.COMPETITION_START,
            t('editor:project:parameters:constructionTime:competition', { lng }),
            '',
            project.constructionTime?.competition,
        ],
        [
            FIELD_IDS.CONSTRUCTION_DURATION,
            t('editor:project:parameters:constructionTime:duration', { lng }),
            '',
            project.constructionTime?.duration,
        ],
        ...staticData.parameters.map<[] | [number, string, unknown, unknown]>((parameter) => {
            const param = project.parameters?.find(({ typeId }) => typeId === parameter.id);
            if (!param) return [FIELD_IDS.PARAMETERS[parameter.key], getTranslation(parameter.name, lng), '', ''];

            const value = parameter.values.find(({ id }) => id === param.valueId);
            if (!value) return [FIELD_IDS.PARAMETERS[parameter.key], getTranslation(parameter.name, lng), '', ''];

            return [
                FIELD_IDS.PARAMETERS[parameter.key],
                getTranslation(parameter.name, lng),
                value.id,
                getTranslation(value.name, lng),
            ];
        }),
        [
            FIELD_IDS.CLASSIFICATION.CRB,
            t('editor:project:parameters:buildingClassCrb:id', { lng }),
            crbClassification?.id,
            crbClassification
                ? `${crbClassification.classificationCode}: ${getTranslation(crbClassification.description, lng)}`
                : undefined,
        ],
        [
            FIELD_IDS.CLASSIFICATION.PARTNER,
            t('editor:project:parameters:buildingClassPartners:id', { lng }),
            partnerClassification?.id,
            partnerClassification
                ? `${partnerClassification.classificationCode}: ${getTranslation(
                      partnerClassification.description,
                      lng
                  )}`
                : undefined,
        ],
        [
            FIELD_IDS.LOCATION.COUNTRY,
            t('editor:project:parameters:projectAddress:location:country', { lng }),
            '',
            location?.country,
        ],
        [
            FIELD_IDS.LOCATION.REGION,
            t('editor:project:parameters:greaterRegion:id', { lng }),
            region?.id,
            getTranslation(region?.name, lng),
        ],
        [
            FIELD_IDS.LOCATION.CANTON,
            t('editor:project:parameters:projectAddress:location:canton:cantonName', { lng }),
            canton?.cantonCode,
            canton ? `${canton.cantonCode} ${canton.cantonName}` : undefined,
        ],
        [
            FIELD_IDS.LOCATION.MUNICIPALITY,
            t('editor:project:parameters:place:address:municipality', { lng }),
            location?.zipCode,
            location ? `${location.zipCode} ${location.municipality}` : undefined,
        ],
        [
            FIELD_IDS.LOCATION.CITY,
            t('editor:project:parameters:projectAddress:city', { lng }),
            '',
            project.projectAddress?.city,
        ],
        [
            FIELD_IDS.LOCATION.STREET,
            t('editor:project:parameters:projectAddress:streetName', { lng }),
            '',
            project.projectAddress?.streetName,
        ],
        [
            FIELD_IDS.LOCATION.STREET_NUMBER,
            t('editor:project:parameters:projectAddress:streetNumber', { lng }),
            '',
            project.projectAddress?.streetNumber,
        ],
        [
            FIELD_IDS.LOCATION.ADDRESS_SUPPLEMENT,
            t('editor:project:parameters:projectAddress:additional', { lng }),
            '',
            project.projectAddress?.additional,
        ],
        [
            FIELD_IDS.LOCATION.LOCATION,
            t('editor:project:parameters:projectAddress:location:position', { lng }),
            project.projectAddress?.location?.position?.code,
            getTranslation(project.projectAddress?.location?.position?.name, lng),
        ],
        [
            FIELD_IDS.LOCATION.COORDINATES.BFS,
            t('editor:project:parameters:projectAddress:bfsNumber', { lng }),
            '',
            project.projectAddress?.bfsNumber,
        ],
        [
            FIELD_IDS.LOCATION.COORDINATES.EGID,
            t('editor:project:parameters:projectAddress:egidNumber', { lng }),
            '',
            project.projectAddress?.egidNumber,
        ],
        [
            FIELD_IDS.LOCATION.COORDINATES.LAT,
            t('editor:project:parameters:projectAddress:latitude', { lng }),
            '',
            project.projectAddress?.latitude,
        ],
        [
            FIELD_IDS.LOCATION.COORDINATES.LON,
            t('editor:project:parameters:projectAddress:longitude', { lng }),
            '',
            project.projectAddress?.longitude,
        ],
        [
            FIELD_IDS.LOCATION.COORDINATES.GOOGLE_PLUS_CODES,
            t('editor:project:parameters:projectAddress:plusCodes', { lng }),
            '',
            project.projectAddress?.plus_codes,
        ],
        [
            FIELD_IDS.MOBILITY.PARKING_SPACES,
            t('editor:project:parameters:projectSpecificReadings:parkingSpaces', { lng }),
            '',
            project.projectSpecificReadings?.parkingSpaces ?? '',
        ],
        [
            FIELD_IDS.MOBILITY.CYCLE_PARKING_SPACES,
            t('editor:project:parameters:projectSpecificReadings:cycleParkingSpaces', { lng }),
            '',
            project.projectSpecificReadings?.cycleParkingSpaces ?? '',
        ],
        [
            FIELD_IDS.MOBILITY.VEHICLE_CHARGING_STATIONS,
            t('editor:project:parameters:projectSpecificReadings:vehicleChargingStations', { lng }),
            '',
            project.projectSpecificReadings?.vehicleChargingStations,
        ],
        [
            FIELD_IDS.MOBILITY.PLOT_RATIO,
            t('editor:project:parameters:projectSpecificReadings:plotRatio', { lng }),
            '',
            project.projectSpecificReadings?.plotRatio,
        ],
        [
            FIELD_IDS.MOBILITY.ZONE_ALLOCATION,
            t('editor:project:parameters:projectSpecificReadings:zoneAllocation', { lng }),
            '',
            project.projectSpecificReadings?.zoneAllocation,
        ],
        [
            FIELD_IDS.MOBILITY.SITE_MASTERPLAN,
            t('editor:project:parameters:projectSpecificReadings:siteMasterplan', { lng }),
            '',
            !isUndefined(project.projectSpecificReadings?.siteMasterplan)
                ? project.projectSpecificReadings?.siteMasterplan
                    ? 'Ja'
                    : 'Nein'
                : undefined,
        ],
        [
            FIELD_IDS.MOBILITY.PLOT_RATIO_BONUS,
            t('editor:project:parameters:projectSpecificReadings:plotRatioBonus', { lng }),
            '',
            !isUndefined(project.projectSpecificReadings?.plotRatioBonus)
                ? project.projectSpecificReadings?.plotRatioBonus
                    ? 'Ja'
                    : 'Nein'
                : undefined,
        ],
        ...descriptions,
        [
            FIELD_IDS.DATA_SOURCE.SOURCE,
            t('editor:project:parameters:source:source', { lng }),
            '',
            project.source?.source,
        ],
        [
            FIELD_IDS.DATA_SOURCE.CATALOGUE,
            t('editor:project:parameters:source:catalogue', { lng }),
            '',
            project.source?.catalogue,
        ],
        [
            FIELD_IDS.DATA_SOURCE.INTERNAL_NUMBER,
            t('editor:project:parameters:source:internalNumber', { lng }),
            '',
            project.source?.internalNumber,
        ],
        [
            FIELD_IDS.DATA_SOURCE.DATA_SOURCE,
            t('editor:project:parameters:source:dataSource', { lng }),
            '',
            project.source?.dataSource,
        ],
        [
            FIELD_IDS.DATA_SOURCE.PUBLICATION_DATE,
            t('editor:project:parameters:source:publicationDate', { lng }),
            '',
            project.source?.publicationDate,
        ],
        [
            FIELD_IDS.DATA_SOURCE.ALTERNATIVE_SOURCES,
            t('editor:project:parameters:alternativeSources', { lng }),
            '',
            project.alternativeSource,
        ],
    ];

    const sheet = utils.aoa_to_sheet([header, ...data], {});

    utils.book_append_sheet(book, sheet, 'Datenblatt');

    hideRows(sheet, data, ([, , id, value]) => !id && !value);
}

function appendInvolvedPartiesSheet(book: WorkBook, project: Project, staticData: StaticData, lng: string): void {
    const header = ['NAME', 'ROLE_ID', 'ROLE'];
    const data =
        project.members?.map((member) => [
            member.name,
            String(member.role.id),
            getTranslation(staticData.memberRoles.find((role) => role.id === member.role.id)?.name, lng),
        ]) ?? [];

    const sheet = utils.aoa_to_sheet([header, ...data]);

    const sheetName = utils.book_append_sheet(book, sheet, 'Datenblatt_Beteiligte');
    utils.book_set_sheet_visibility(book, sheetName, data.length === 0 ? 1 : 0);
}

function appendTagSheet(book: WorkBook, project: Project, languageCode: string): void {
    const header = ['TAG_ID', 'TAG_NAME'];
    const data = project.tags?.map((tag) => [tag.id, getTranslation(tag.name, languageCode)]) ?? [];

    const sheet = utils.aoa_to_sheet([header, ...data]);

    const sheetName = utils.book_append_sheet(book, sheet, 'Datenblatt_Tags');
    utils.book_set_sheet_visibility(book, sheetName, data.length === 0 ? 1 : 0);
}

function appendFloorUsageSheet(book: WorkBook, project: Project, staticData: StaticData, languageCode: string): void {
    const header = ['TAG_ID', 'TAG_NAME', 'REGULATION_ID', 'REGULATION_CODE', 'REGULATION_NAME', 'AREA'];
    const data = project.usages.map((usage) => {
        const regulation = staticData.regulations.SIA416.find(({ id }) => id === usage.regulation.id);

        return [
            usage.tag.id,
            getTranslation(usage.tag.name, languageCode),
            regulation?.id,
            regulation?.code,
            getTranslation(regulation?.description, languageCode),
            usage.area,
        ];
    });

    const sheet = utils.aoa_to_sheet([header, ...data]);

    const sheetName = utils.book_append_sheet(book, sheet, 'Datenblatt_Nutzungen');
    utils.book_set_sheet_visibility(book, sheetName, data.length === 0 ? 1 : 0);
}

function appendFunctionalUnitsSheet(
    book: WorkBook,
    project: Project,
    tags: FunctionalUnitTag[],
    language: string
): void {
    const header = ['TAG_ID', 'TAG_NAME', 'COUNT'];

    const data = project.functionalUnits
        .map((unit): [Tag | undefined, FunctionalUnitModel] => [tags.find((tag) => tag.id === unit.tag.id), unit])
        .filter((pair): pair is [Tag, FunctionalUnitModel] => !!pair[0])
        .map(([tag, unit]) => [tag.id, getTranslation(tag.name, language), unit.amount]);

    const sheet = utils.aoa_to_sheet([header, ...data]);

    const sheetName = utils.book_append_sheet(book, sheet, 'Datenblatt_FU');
    utils.book_set_sheet_visibility(book, sheetName, data.length === 0 ? 1 : 0);
}

async function appendLinkedProjectsSheet(
    book: WorkBook,
    project: Project,
    lng: string,
    getProjectById: (id: number) => Promise<Project>
): Promise<void> {
    const header = ['PROJECT_ID', 'NAME'];

    const projectIds = orderBy(project.linkedProjects);

    const projects = await Promise.all(projectIds.map(getProjectById));

    const data = projects.map((project) => [project.id, getTranslation(project.name, lng)]);

    const sheet = utils.aoa_to_sheet([header, ...data]);

    const sheetName = utils.book_append_sheet(book, sheet, 'Datenblatt_Verlinkte_Projekte');
    utils.book_set_sheet_visibility(book, sheetName, data.length === 0 ? 1 : 0);
}

function appendAreasAndVolumesSheet(book: WorkBook, project: Project, staticData: StaticData, lng: string): void {
    const header = ['CATALOG', 'CODE', 'ABBRIVATION', 'NAME', 'UNIT', 'VALUE'];

    const regulations = orderBy(
        [...staticData.regulations.Mengen, ...staticData.regulations.SIA116, ...staticData.regulations.SIA416],
        'code'
    );

    const values: Record<string, ProjectMeasurement | undefined> = keyBy(project.measurements, 'regulation.id');

    const data = regulations.map(({ id, name, code, shortName, description, measureUnit }) => {
        const measurement = values[id];
        return [
            name,
            code,
            getTranslation(shortName, lng),
            getTranslation(description, lng),
            measureUnit ?? '',
            measurement?.value,
        ];
    });

    const hasRows = data.some(([, , , , , value]) => !!value);
    const sheet = utils.aoa_to_sheet([header, ...data]);

    if (hasRows) {
        hideRows(sheet, data, ([, , , , , value]) => !value);
    }

    const sheetName = utils.book_append_sheet(book, sheet, 'Flächen_und_Volumen');
    utils.book_set_sheet_visibility(book, sheetName, !hasRows ? 1 : 0);
}

function appendBuildingsSheet(book: WorkBook, project: Project, t: TFunction, lng: string): void {
    const header = ['BUILDING_ID', 'FLOOR', 'TYPE', 'NAME', 'REFERENCE_HEIGHT', 'HEIGHT', 'AREA'];

    const data = project.buildings.flatMap((building) => [
        [building.id, '', 'BUILDING', getTranslation(building.name, lng), building.referenceHeight, '', ''],
        ...building.floors.map((floor) => {
            const floorNumber = (() => {
                switch (floor.floorType) {
                    case 'UNDER_GROUND':
                        return -floor.floorNumber;
                    default:
                        return floor.floorNumber;
                }
            })();

            return [
                building.id,
                `${floorNumber}`,
                'FLOOR',
                t(`editor:project:floor:floorTypes:${floor.floorType}`, { lng }),
                '',
                floor.height,
                floor.area,
            ];
        }),
    ]);

    const sheet = utils.aoa_to_sheet([header, ...data]);

    const sheetName = utils.book_append_sheet(book, sheet, 'Gebäude');
    utils.book_set_sheet_visibility(book, sheetName, data.length === 0 ? 1 : 0);
}

function appendCostsSheets(book: WorkBook, project: Project, staticData: StaticData, t: TFunction, lng: string): void {
    const costs = keyBy(project.costs, 'regulation.id');

    const totalCosts = keyBy(project.totalCosts, 'regulationName');

    appendBKPCostsSheets(book, costs, totalCosts.BKP, staticData.regulations.BKP, t, lng);
    (['eBKP-H', 'eBKP-T(A)', 'eBKP-T(B)', 'eBKP-H2020(A)', 'eBKP-H2020(B)'] as Regulations[]).forEach((regulation) =>
        appendEBKPCostsSheets(
            book,
            regulation,
            costs,
            totalCosts[regulation],
            staticData.regulations[regulation],
            t,
            lng
        )
    );
}

function appendBKPCostsSheets(
    book: WorkBook,
    costs: Record<number, ProjectCost | undefined>,
    totalCosts: TotalProjectCost,
    regulations: StaticRegulation[],
    t: TFunction,
    lng: string
): void {
    const header = ['CODE', 'NAME', 'VALUE', 'DESCRIPTION'];

    regulations = orderBy(regulations, 'code');

    const data = regulations.map((regulation) => {
        const cost = costs[regulation.id];

        return [
            regulation.code,
            getTranslation(regulation.description, lng),
            cost?.value,
            getTranslation(cost?.description, lng),
        ];
    });

    const hasRows = data.some(([, , value, description]) => !!value || !!description);
    const sheet = utils.aoa_to_sheet([header, ...data]);

    if (hasRows) {
        hideRows(sheet, data, ([, , value, description]) => !value && !description);
    }

    const sheetName = utils.book_append_sheet(book, sheet, 'Kennwerte BKP');
    utils.book_set_sheet_visibility(book, sheetName, !hasRows ? 1 : 0);

    const totalSheet = createTotalCostsSheet(totalCosts, ['buildingCost', 'investmentCost'], t, lng);

    const totalSheetName = utils.book_append_sheet(book, totalSheet, `Kennwerte BKP Total`);
    utils.book_set_sheet_visibility(book, totalSheetName, !hasRows ? 1 : 0);
}

function appendEBKPCostsSheets(
    book: WorkBook,
    name: string,
    costs: Record<number, ProjectCost | undefined>,
    totalCosts: TotalProjectCost,
    regulations: StaticRegulation[],
    t: TFunction,
    lng: string
): void {
    const header = ['CODE', 'ABBRIVIATION', 'NAME', 'REFERENCE_AMOUNT', 'REFERENCE_UNIT', 'VALUE', 'DESCRIPTION'];

    regulations = orderBy(regulations, 'code');

    const data = regulations.map((regulation) => {
        const cost = costs[regulation.id];

        return [
            regulation.code,
            getTranslation(regulation.shortName, lng),
            getTranslation(regulation.description, lng),
            cost?.referenceQuantity ?? '',
            regulation.measureUnit ?? '',
            cost?.value ?? '',
            getTranslation(cost?.description, lng),
        ];
    });

    const hasRows = data.some(([, , , , , value, description]) => !!value || !!description);
    const sheet = utils.aoa_to_sheet([header, ...data]);

    if (hasRows) {
        hideRows(sheet, data, ([, , , , , value, description]) => !value && !description);
    }

    const sheetName = utils.book_append_sheet(book, sheet, `Kennwerte ${name}`);
    utils.book_set_sheet_visibility(book, sheetName, !hasRows ? 1 : 0);

    const totalSheet = createTotalCostsSheet(
        totalCosts,
        ['investmentCost', 'constructionFacilityCost', 'productionCost'],
        t,
        lng
    );

    const totalSheetName = utils.book_append_sheet(book, totalSheet, `Kennwerte ${name} Total`);
    utils.book_set_sheet_visibility(book, totalSheetName, !hasRows ? 1 : 0);
}

const TOTAL_COST_MAPPING: Record<Exclude<keyof TotalProjectCost, 'regulationName'>, TotalCostsCode> = {
    buildingCost: TotalCostsCode.KoG_BUILDING_COSTS,
    constructionFacilityCost: TotalCostsCode.KoB_CONSTRUCTION_FACILITY_COSTS,
    investmentCost: TotalCostsCode.KoA_INVESTMENT_COSTS,
    productionCost: TotalCostsCode.KoE_PRODUCTION_COSTS,
} as const;
function createTotalCostsSheet(
    costs: TotalProjectCost | undefined,
    properties: Exclude<keyof TotalProjectCost, 'regulationName'>[],
    t: TFunction,
    lng: string
) {
    const header = ['CODE', 'NAME', 'VALUE'];

    const data = properties
        .map<[Exclude<keyof TotalProjectCost, 'regulationName'>, number | undefined]>((property) => [
            property,
            costs?.[property],
        ])
        .map(([property, value]) => {
            const code = TOTAL_COST_MAPPING[property];

            return [
                code,
                t(`editor:projects:creator:cost-sheet:calculated-regulation:${code.toLowerCase()}`, { lng }),
                value,
            ];
        });

    return utils.aoa_to_sheet([header, ...data]);
}

function appendEnergySheet(book: WorkBook, project: Project, staticData: StaticData, lng: string): void {
    const header = ['ENERGY_ID', 'NAME', 'VALUE'];

    const units = staticData.energyUnits.map((unit) => [unit.id, getTranslation(unit.name, lng)]);

    const values: Record<string, ProjectBuildingEnergy | undefined> = keyBy(
        project.energyInfo.energies,
        'energyUnit.id'
    );

    const data = units.map(([unitId, unitName]) => {
        const energy = values[unitId];

        return [unitId, unitName, energy?.value];
    });

    const hasRows = data.some(([, , value]) => !!value);
    const sheet = utils.aoa_to_sheet([header, ...data]);

    if (hasRows) {
        hideRows(sheet, data, ([, , value]) => !value);
    }

    const sheetName = utils.book_append_sheet(book, sheet, 'Energie');
    utils.book_set_sheet_visibility(book, sheetName, !hasRows ? 1 : 0);
}

function appendEnergyParameter(
    book: WorkBook,
    project: Project,
    { energyParameterTypes }: StaticData,
    lng: string
): void {
    const header = ['PARAMETER_ID', 'NAME', 'VALUE_ID', 'VALUE'];

    const types = orderBy(energyParameterTypes, 'order').map<[EnergyParameterType, string]>((type) => [
        type,
        getTranslation(type.name, lng),
    ]);

    const values: Record<string, ProjectEnergyParameter | undefined> = keyBy(project.energyInfo.parameters, 'type.id');

    const data = types.map(([type, typeName]) => {
        const param = values[type.id];
        if (!param) return [type.id, typeName, ''];

        let id: number | undefined;
        let value: number | string | undefined;
        switch (param.valueType) {
            case 'ENUMERATED': {
                const val = type.values.find(({ id }) => id === param.value.id);
                id = val?.id;
                value = getTranslation(val?.value, lng);
                break;
            }
            case 'NUMERIC':
                value = param.num;
                break;
            case 'TEXT':
                value = param.text;
                break;
        }

        return [type.id, typeName, id, value];
    });

    const hasRows = data.some(([, , id, value]) => !!id || !!value);
    const sheet = utils.aoa_to_sheet([header, ...data]);

    if (hasRows) {
        hideRows(sheet, data, ([, , id, value]) => !id && !value);
    }

    const sheetName = utils.book_append_sheet(book, sheet, 'Energie_Parameter');
    utils.book_set_sheet_visibility(book, sheetName, !hasRows ? 1 : 0);
}

function appendEnergyTree(book: WorkBook, project: Project, { energyTrees }: StaticData, lng: string): void {
    const header = ['ENERGY_NODE_ID', 'NAME', 'kWh/(m²a)', 'kg CO₂/m²a'];

    const trees = orderBy(energyTrees, 'order').flatMap((tree) => {
        return mapHierarchyToList(tree, ({ children }) => orderBy(children, 'order')).map((tree) => [
            tree.id,
            getTranslation(tree.name, lng),
        ]);
    });

    const values: Record<string, ProjectEnergyTreeValue | undefined> = keyBy(
        Object.values(project.energyInfo.treeValues).flatMap(identity),
        'tree.id'
    );

    const data = trees.map(([treeId, treeName]) => {
        const energy = values[treeId];

        return [treeId, treeName, energy?.watt, energy?.co2];
    });

    const hasRows = data.some(([, , watt, co2]) => !!watt || !!co2);
    const sheet = utils.aoa_to_sheet([header, ...data]);

    if (hasRows) {
        hideRows(sheet, data, ([, , watt, co2]) => !watt && !co2);
    }

    const sheetName = utils.book_append_sheet(book, sheet, 'Energie_Baum');
    utils.book_set_sheet_visibility(book, sheetName, !hasRows ? 1 : 0);
}

function mapHierarchyToList<T>(item: T, getChildren: (item: T) => T[]): T[] {
    return [item, ...getChildren(item)];
}

const lngMap: Record<string, undefined | string> = {
    de: 'de_DE',
    fr: 'fr_FR',
    it: 'it_IT',
    en: 'en_GB',
};

const getTranslation = (map: TranslationMap | undefined, lng: string): string => {
    if (!map) return '';
    const lang = lngMap[lng];
    if (!lang) return '';

    if (map[lang]) return map[lang];

    lng = ALL_LANGUAGES.find((lng) => map[lng]) ?? 'de_DE';

    return (map[lng] as string | undefined) ?? '';
};

const hideRow = (sheet: WorkSheet, rowIndex: number) => {
    if (!sheet['!rows']) sheet['!rows'] = [];
    if (!sheet['!rows'][rowIndex]) sheet['!rows'][rowIndex] = {};
    sheet['!rows'][rowIndex].hidden = true;
};

const hideRows = <T>(sheet: WorkSheet, data: T[], predicate: (row: T) => unknown) => {
    for (let i = 0; i < data.length; i++) {
        const hidden = predicate(data[i]);
        if (hidden) hideRow(sheet, i + 1);
    }
};
