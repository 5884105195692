import { TFunction } from 'i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { NumberInput } from '../../../../FormComponents';
import type { MeasurementTree as MeasurementTreeType } from '../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight, faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { sumBy } from 'lodash';

interface Props {
    path: ID[];
    tree: MeasurementTreeType;
    changeMeasurement: (id: number, change: (measurement: ProjectMeasurement) => ProjectMeasurement) => void;
    locks: Record<ID, boolean>;
    changeLocked: (path: ID[], change: (locked: boolean) => boolean) => unknown;
    language: EditorLanguage;
    t: TFunction;
}

export const MeasurementTree: React.FC<Props> = ({
    path,
    tree,
    locks,
    language,
    changeMeasurement,
    changeLocked,
    t,
}) => {
    const [collapsed, setCollapsed] = useState(true);
    const toggleCollapsed = useCallback(() => setCollapsed((c) => !c), []);

    const regulation = tree;
    const measurement = tree.measurement;

    const changeValue = useCallback(
        (value = '') =>
            changeMeasurement(tree.id, (measurement) => ({
                ...measurement,
                value: parseFloat(`${value}`),
            })),
        [changeMeasurement, tree.id]
    );

    const handleLockToggle = useCallback(
        () => changeLocked([...path, regulation.id], (locked) => !locked),
        [changeLocked, path, regulation.id]
    );

    const value = measurement?.value ?? 0;
    const description = regulation.description[language.codes.editor] || Object.values(regulation.description)[0];
    const hasChildren = regulation.children.length > 0;

    const locked = locks[regulation.id];

    const childPath = useMemo(() => [...path, regulation.id], [path, regulation.id]);

    const childValue = useMemo(() => {
        return sumBy(regulation.children, ({ measurement }) => measurement?.value ?? 0);
    }, [regulation.children]);

    const ok = childValue === value;

    const status = useMemo(() => {
        if (childValue === 0) return '';
        if (ok) return 'ok';

        return 'nok';
    }, [childValue, ok]);

    useEffect(() => {
        if (!locked || !hasChildren) return;
        if (ok) return;
        if (!childValue) return;

        changeValue(childValue);
    }, [locked, hasChildren, childValue, ok, changeValue]);

    useEffect(() => {
        if (!locked) return;
        if (!childValue) return;
        if (value === childValue) return;

        changeLocked(childPath, () => false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]); // should only trigger when value changed

    return (
        <>
            <Row className="measurement border-bottom" data-testid="measurement-row">
                <Col xs="1" className="d-flex align-items-center" onClick={toggleCollapsed}>
                    {hasChildren && collapsed && <FontAwesomeIcon icon={faChevronRight} />}
                    {hasChildren && !collapsed && <FontAwesomeIcon icon={faChevronDown} />}
                </Col>
                <Col xs={2} className="d-flex align-items-center">
                    {regulation.code}
                </Col>
                <Col xs={4} className="px-0 d-flex align-items-center">
                    {regulation.shortName[language.codes.editor]}&nbsp;
                    {`${description} ${regulation.measureUnit}`}
                </Col>
                <Col xs={5} className="position-relative px-0 mt-1">
                    <NumberInput
                        label={t('editor:projects:creator:measurement-sheet:label')}
                        decimals={2}
                        value={`${value}`}
                        onChange={changeValue}
                    />

                    {hasChildren && (
                        <div className={classNames('measurement-value-lock')} onClick={handleLockToggle}>
                            {locked && <FontAwesomeIcon icon={faLock} />}
                            {!locked && <FontAwesomeIcon icon={faUnlock} />}
                        </div>
                    )}

                    {hasChildren && <div className="status-bubble" data-status={status} />}
                </Col>
            </Row>

            {!collapsed &&
                tree.children.map((child) => (
                    <MeasurementTree
                        path={childPath}
                        tree={child}
                        changeMeasurement={changeMeasurement}
                        locks={locks}
                        changeLocked={changeLocked}
                        t={t}
                        language={language}
                        key={child.id}
                    />
                ))}
        </>
    );
};
