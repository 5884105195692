import React from 'react';
import { NavigationItem } from './NavigationItem';

interface Props {
    items: Array<TNavigationItem>;
}

export const Navigation: React.FC<Props> = ({ items }) => (
    <nav className="navigation">
        {items.map((item) => (
            <NavigationItem item={item} key={item.name} />
        ))}
    </nav>
);
