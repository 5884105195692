import papaparser from 'papaparse';
import React from 'react';
import { Table } from 'reactstrap';

interface Props {
    headers: string[];
    data: any[];
    config: papaparser.ParseConfig;
}

export const CSVPreview: React.FC<Props> = ({ headers, data }) => {
    if (!data) {
        return null;
    }
    const values: any[][] = data.slice(1);

    return (
        <div className="csv-preview">
            {/* <div>
                <Select
                    label="Delimiter"
                    options={[

                    ]}
                    value={}
                    onChange={}
                />
            </div> */}

            <Table responsive={true}>
                <thead>
                    <tr>
                        {headers.map((header, index) => (
                            <th key={index}>{header}</th>
                        ))}
                    </tr>
                </thead>

                <tbody>
                    {values.map((value, index) => (
                        <tr key={index}>
                            {value.map((v, jndex) => (
                                <td key={jndex}>{v}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};
