export * from './Calculators';
export * from './types';

/**
 * Map for mapping total cost to an id. Required for having the same behaviour in the cost view as normal costs.
 *
 * Regulations from the backend will have at least 5 digits, so no conflict there.
 */
export enum CalculatedRegulationID {
    KoA = 0,
    KoE = 1,
    KoB = 2,
    KoG = 3,
}
