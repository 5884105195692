import { Dictionary, keyBy } from 'lodash';

export function haveCostsChanged(currentCosts: ProjectCost[], costData: ProjectCost[]): boolean {
    const currentCostMap: Dictionary<ProjectCost | undefined> = keyBy(currentCosts, ({ regulation }) => regulation.id);
    const costDataMap: Dictionary<ProjectCost | undefined> = keyBy(costData, ({ regulation }) => regulation.id);

    const costIDs = new Set([...Object.keys(currentCostMap), ...Object.keys(costDataMap)]);

    for (const costId of costIDs) {
        const cost = currentCostMap[costId];
        const costData = costDataMap[costId];

        if (!cost || !costData) {
            if (!cost && costData && !costIsEmpty(costData)) return true;
            if (cost && !costData && !costIsEmpty(cost)) return true;
            continue;
        }

        if ((cost.value ?? 0) !== (costData.value ?? 0)) return true;
        if ((cost.referenceQuantity ?? 0) !== (costData.referenceQuantity ?? 0)) return true;

        if (hasTranslationChanged(cost.description ?? {}, costData.description ?? {})) return true;
    }

    return false;
}

export function hasTranslationChanged(
    translation: TranslationMap | undefined,
    translationData: TranslationMap | undefined
): boolean {
    const cdEntries = Object.entries(translation ?? {}).filter(([, d]) => !!d);
    const costDescriptions: Dictionary<string | undefined> = Object.fromEntries(cdEntries);

    const cddEntries = Object.entries(translationData ?? {}).filter(([, d]) => !!d);
    const costDataDescriptions: Dictionary<string | undefined> = Object.fromEntries(cddEntries);

    const langs = new Set([...Object.keys(costDescriptions), ...Object.keys(costDataDescriptions)]);

    for (const lang of langs) {
        if ((costDescriptions[lang] ?? '') !== (costDataDescriptions[lang] ?? '')) return true;
    }

    return false;
}

export function costIsEmpty(c: ProjectCost): boolean {
    const valueEmpty = (c.value ?? 0) === 0;
    const quantityEmpty = (c.referenceQuantity ?? 0) === 0;
    const descriptionEmpty = Object.values(c.description ?? {}).every((d) => d === '');

    return valueEmpty && quantityEmpty && descriptionEmpty;
}

export function haveBuildingsChanged(currentBuildings: Building[], buildingsData: Building[]): boolean {
    if (currentBuildings.length !== buildingsData.length) return true;

    const cBuildingMap: Dictionary<Building | undefined> = keyBy(currentBuildings, (building) => building.id ?? '');
    const buildingDataMap: Dictionary<Building | undefined> = keyBy(buildingsData, (building) => building.id ?? '');

    const buildingsIDs = new Set([...Object.keys(cBuildingMap), ...Object.keys(buildingDataMap)]);

    for (const buildingId of buildingsIDs) {
        const cBuilding = cBuildingMap[buildingId];
        const buildingData = buildingDataMap[buildingId];

        if (!cBuilding || !buildingData) return true;

        if (hasTranslationChanged(cBuilding.name, buildingData.name)) return true;
        if ((cBuilding.referenceHeight ?? 0) !== (buildingData.referenceHeight ?? 0)) return true;

        if (haveFloorsChanged(cBuilding.floors ?? [], buildingData.floors ?? [])) return true;
    }

    return false;
}

export function haveFloorsChanged(currentFloors: Floor[], floorData: Floor[]): boolean {
    const cFloorMap: Dictionary<Floor | undefined> = keyBy(currentFloors, (floor) => floor.id ?? '');
    const floorDataMap: Dictionary<Floor | undefined> = keyBy(floorData, (floor) => floor.id ?? '');

    const floorIDs = new Set([...Object.keys(cFloorMap), ...Object.keys(floorDataMap)]);

    for (const floorId of floorIDs) {
        const cFloor = cFloorMap[floorId];
        const floorData = floorDataMap[floorId];

        if (!cFloor || !floorData) return true;

        if (cFloor.area !== floorData.area) return true;
        if (cFloor.height !== floorData.height) return true;
        if (cFloor.floorType !== floorData.floorType) return true;
        if (cFloor.floorNumber !== floorData.floorNumber) return true;
    }

    return false;
}

export function hasUsageAreaChanged(
    currentUsageArea: ProjectMeasurement | undefined,
    usageAreaData: ProjectMeasurement | undefined
): boolean {
    if (currentUsageArea && usageAreaData) return currentUsageArea.value !== usageAreaData.value;
    if (currentUsageArea) return (currentUsageArea.value ?? 0) !== 0;
    if (usageAreaData) return (usageAreaData.value ?? 0) !== 0;

    return false;
}
