import React from 'react';

interface SliderStatePositionProps {
    active?: boolean;
}

export const SliderStatePosition: React.FC<SliderStatePositionProps> = ({ active }) => (
    <div className="slider-state-position" data-active={active}>
        <svg viewBox="0 0 32 32" width="2em" height="2em">
            <circle cx={16} cy={16} r={8} stroke="#000" strokeWidth={2} fill="none" />
        </svg>
    </div>
);
