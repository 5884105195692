import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { i18n } from '../../i18n';

export class CustomerCareService {
    protected http: AxiosInstance;

    constructor() {
        this.http = axios.create({
            baseURL: '/api',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });
    }

    public async submitFeedback(formValues: AnyObject): Promise<AxiosResponse> {
        const values = {
            ...formValues,
        };
        delete values.recaptchaCode;

        try {
            const res = await this.http.post('/feedback', values, {
                headers: {
                    'captcha-response': formValues.recaptchaCode,
                    'Accept-Language': i18n.language,
                },
            });

            return res;
        } catch (e) {
            return e.response;
        }
    }

    public async submitCustomerServiceRequest(formValues: AnyObject): Promise<AxiosResponse> {
        const values = {
            ...formValues,
        };
        delete values.recaptchaCode;

        try {
            const res = await this.http.post('/customerService', values, {
                headers: {
                    'captcha-response': formValues.recaptchaCode,
                    'Accept-Language': i18n.language,
                },
            });

            return res;
        } catch (e) {
            return e.response;
        }
    }
}
