interface Value {
    [path: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

interface NestedValues {
    [name: string]: NestedValues | any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export function nest(values: Value = {}, initialValue: NestedValues = {}): NestedValues {
    return Object.entries(values).reduce((acc, [path, value]) => {
        const [name, ...restPath] = path.split('.');

        let pair;
        if (restPath && restPath.length > 0) {
            pair = {
                ...(acc[name] || {}),
                ...nest({ [restPath.join('.')]: value }, acc[name] || {}),
            };
        } else if (typeof value === 'object' && !(value instanceof Array)) {
            pair = { ...acc[name], ...value };
        } else {
            pair = value;
        }

        return { ...acc, [name]: pair };
    }, initialValue);
}
