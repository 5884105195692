import React, { useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useAuthentication } from '../../contexts';
import { useProject, useProjectLicenceCreate, useProjectLicenceDelete, useProjectLicenceEdit } from '../../effects';
import { useProjectLicences } from '../../effects/queries/use-project-licences';
import { ActionsEnum } from '../../license/actions';
import { ProjectShareOverlay } from './ProjectShareOverlay';

interface ProjectShareProps {
    isOpen?: boolean;
    projectId?: number;
    project?: Project | ProjectOverview;
    onExit: () => unknown;
    Button: React.ComponentType<{ disabled: boolean }>;
}

export const ProjectShare: React.FC<ProjectShareProps> = ({ isOpen, projectId, project, onExit, Button }) => {
    const authentication = useAuthentication();
    const userId = authentication.user?.username;

    const isAdmin = authentication.user?.actions.has(ActionsEnum.VIEW_ALL_PROJECTS);

    const isCreator = !!project?.creator && project.creator === userId;
    const allowedToEdit = isAdmin || isCreator;

    const { data: projectDetailsData } = useProject(projectId ?? -1, {
        enabled: !isProjectDetails(project) && allowedToEdit && !!projectId,
    });

    const { data: licences } = useProjectLicences(projectId || -1, { enabled: !!projectId });

    const { mutateAsync: createLicence } = useProjectLicenceCreate(projectId ?? 0);
    const { mutateAsync: deleteLicence } = useProjectLicenceDelete(projectId ?? 0);
    const { mutateAsync: editLicence } = useProjectLicenceEdit(projectId ?? 0);

    const handleSave = useCallback(
        async (info: {
            newLicences: ProjectLicence[];
            changedLicences: ProjectLicence[];
            deletedLicences: ProjectLicence[];
        }) => {
            for (const deletedLicence of info.deletedLicences) await deleteLicence(deletedLicence);
            for (const changedLicence of info.changedLicences) await editLicence(changedLicence);
            for (const newLicence of info.newLicences) await createLicence(newLicence);
        },
        [deleteLicence, editLicence, createLicence]
    );

    if (!allowedToEdit) return null;

    const projectDetails = isProjectDetails(project) ? project : projectDetailsData;
    const isDisabled = !userId || !allowedToEdit;

    return (
        <div className="project-share">
            <Button disabled={isDisabled} />

            {isOpen &&
                projectDetails &&
                userId &&
                !isDisabled &&
                licences &&
                ReactDOM.createPortal(
                    <ProjectShareOverlay
                        project={projectDetails}
                        licences={licences}
                        userId={userId}
                        onClose={onExit}
                        onSave={handleSave}
                    />,
                    document.body
                )}
        </div>
    );
};

function isProjectDetails(project: undefined | ProjectOverview | Project): project is Project {
    if (!project) return false;
    return !!(project as Project & ProjectOverview).projectLicences;
}
