import type { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Table } from 'reactstrap';
import { useAuthentication } from '../../contexts';
import { usePagination, useSearchParam } from '../../effects';
import { SortDirection } from '../../enums';
import { ActionsEnum } from '../../license/actions';
import { codeOf } from '../../utils';
import { LinkButton } from '../Buttons';
import { Select } from '../FormComponents';
import { Spinner } from '../Spinner';
import { ProjectTableHeader } from './ProjectTableHeader';
import { ProjectTableItem } from './ProjectTableItem';
import { ElaboratedProject } from './types';

const PAGE_SIZE = 100;

type ContentProps = {
    elaboratedProjects: ElaboratedProject[];
    selection?: ID[];
    onSelection?: (projectIds: Project['id'][]) => void;
    isLoading: boolean;
};

export const ProjectTableContent: React.FC<ContentProps> = ({
    elaboratedProjects,
    selection = [],
    isLoading,
    onSelection,
}) => {
    const auth = useAuthentication();
    const { t } = useTranslation();
    const [page, changePage] = useSearchParam<string>('page', '0');
    const [sort, setSort] = React.useState<undefined | string>(undefined);

    const toggleOne = React.useCallback(
        (id: ID) => {
            const newSet = new Set(selection);

            if (newSet.has(id)) newSet.delete(id);
            else newSet.add(id);

            onSelection?.(Array.from(newSet));
        },
        [onSelection, selection]
    );

    const toggleAll = React.useCallback(() => {
        const allIds = new Set((elaboratedProjects || []).map(({ id }) => id));
        const allChecked = elaboratedProjects.every((project) => selection.includes(project.id));

        if (allChecked) {
            onSelection?.([]);
        } else {
            onSelection?.(Array.from(allIds));
        }
    }, [selection, onSelection, elaboratedProjects]);

    const sortedProjects = React.useMemo(() => sortProjects(sort, elaboratedProjects), [sort, elaboratedProjects]);
    const pagination = usePagination(sortedProjects, {
        size: PAGE_SIZE,
        page: parseInt(page, 10) - 1,
        changePage: (p: number) => changePage(`${p + 1}`),
    });
    const createChangePage = (p: number) => pagination.changePage(p - 1);

    if (isLoading) {
        return (
            <>
                <Table className="gutter-top w-100" responsive={true}>
                    <ProjectTableHeader
                        projects={elaboratedProjects}
                        selection={selection}
                        sort={sort}
                        onSort={setSort}
                        onToggleAll={toggleAll}
                    />
                </Table>
                <Row className="justify-content-center mb-3">
                    <Col xs="auto">
                        <Spinner size={32} color="#000" />
                    </Col>
                </Row>
            </>
        );
    }

    const canViewProjects = auth.user?.actions.has(ActionsEnum.VIEW_PROJECTS);
    if (!canViewProjects) {
        return (
            <>
                <Table className="gutter-top w-100" responsive={true}>
                    <ProjectTableHeader
                        projects={[]}
                        selection={selection}
                        sort={undefined}
                        onSort={undefined}
                        onToggleAll={undefined}
                    />
                </Table>
                <Row className="justify-content-center mb-3">
                    <Col xs="auto">
                        <p className="text-center">{t('editor:projects:list:empty-list')}</p>
                        <p className="px-5">{t('editor:projects:list:no-access-to-projects')}</p>
                    </Col>
                </Row>
            </>
        );
    }

    if (elaboratedProjects.length === 0) {
        return (
            <>
                <Table className="gutter-top w-100" responsive={true}>
                    <ProjectTableHeader
                        projects={elaboratedProjects}
                        selection={selection}
                        sort={sort}
                        onSort={setSort}
                        onToggleAll={toggleAll}
                    />
                </Table>
                <Row className="justify-content-center mb-3">
                    <Col xs="auto">{t('editor:projects:list:empty-list')}</Col>
                </Row>
            </>
        );
    }

    return (
        <>
            <Table className="gutter-top w-100" responsive={true}>
                <ProjectTableHeader
                    projects={elaboratedProjects}
                    selection={selection}
                    sort={sort}
                    onSort={setSort}
                    onToggleAll={toggleAll}
                />
                <tbody>
                    {pagination.page.items.map((project) => (
                        <ProjectTableItem
                            project={project}
                            selected={selection.includes(project.id)}
                            onSelectionToggle={toggleOne}
                            key={project.id}
                        />
                    ))}
                </tbody>
            </Table>
            <Row className="justify-content-end">
                <Col xs={4} className="d-flex">
                    <LinkButton
                        name={t('common:pagination:previous')}
                        onClick={pagination.previous}
                        disabled={pagination.page.number === 0}
                    />
                    <Pagination
                        className="px-3"
                        page={pagination.page.number + 1}
                        maxPage={pagination.info.maxPage}
                        changePage={createChangePage}
                        t={t}
                    />
                    <LinkButton
                        name={t('common:pagination:next')}
                        onClick={pagination.next}
                        disabled={pagination.info.maxPage === pagination.page.number}
                    />
                </Col>
            </Row>
        </>
    );
};

interface Props {
    projects?: Array<ProjectOverview>;
    staticData?: StaticData | null;
    selection?: ID[];
    onSelection?: (projectIds: Project['id'][]) => void;
}
export const ProjectTable: React.FC<Props> = ({ projects, staticData, selection = [], onSelection }) => {
    const [t, i18n] = useTranslation();
    const languageCode = codeOf(i18n.language);

    const elaboratedProjects: ElaboratedProject[] = React.useMemo(() => {
        return mapProjectsToElaborateProjects({ projects, languageCode, t, staticData });
    }, [languageCode, projects, staticData, t]);

    const isLoading = !projects || !staticData;
    return (
        <div className="project-table">
            <ProjectTableContent
                elaboratedProjects={elaboratedProjects}
                selection={selection}
                isLoading={isLoading}
                onSelection={onSelection}
            />
        </div>
    );
};

const Pagination: React.FC<{
    className?: string;
    page: number;
    maxPage: number;
    changePage: (page: number) => unknown;
    t: TFunction;
}> = ({ className, page, maxPage, changePage, t }) => {
    const [selectActive, setSelectActive] = React.useState(false);

    const activeSelect = () => setSelectActive(true);
    const deactivateSelect = () => setSelectActive(false);

    const setPage = (newPage: number) => {
        changePage(newPage || 1);
        deactivateSelect();
    };

    return (
        <div onClick={activeSelect} className={className}>
            {!selectActive && (
                <>
                    {t('editor:projects:list:navigation:page')}&nbsp;
                    <b>{page}</b>&nbsp;
                    {t('editor:projects:list:navigation:of-page', {
                        max: maxPage,
                    })}
                </>
            )}

            {selectActive && (
                <Select
                    label={t('common:pagination:page')}
                    className="project-list__page-selector"
                    value={page}
                    onChange={setPage}
                    options={Array.from({ length: maxPage }).map((_, i) => ({
                        label: `${i + 1}`,
                        value: i + 1,
                    }))}
                />
            )}
        </div>
    );
};

/**
 * Takes a sorting order and a list of ElaboratedProject and returns them in the desired order.
 *
 * @param sort - A string indicating the property to sort on and the order "key:order" - "name:asc"
 * @param projects
 * @returns
 */
function sortProjects(sort?: string, projects: ElaboratedProject[] = []): ElaboratedProject[] {
    if (!sort) {
        return projects;
    }

    const [sortId, sortDirection] = sort.split(':') as [keyof typeof projects[0], 'asc' | 'desc' | undefined];
    const ascending = sortDirection === SortDirection.Ascending;

    return projects.sort((a, b) => {
        const aVal = a[sortId];
        const bVal = b[sortId];

        if (aVal === null) {
            return 1;
        } else if (bVal === null) {
            return -1;
        }

        if (typeof aVal === 'string' && typeof bVal === 'string') {
            return bVal.localeCompare(aVal as string) * (ascending ? -1 : 1);
        }

        if (typeof aVal === 'number' && typeof bVal === 'number') {
            return Math.min(1, Math.max(-1, bVal - aVal)) * (ascending ? -1 : 1);
        }

        if (typeof aVal === 'object' && typeof bVal === 'object') {
            if (aVal instanceof Date && bVal instanceof Date) {
                return (bVal.getTime() - aVal.getTime()) * (ascending ? -1 : 1);
            }

            if (isObjectReference(aVal) && isObjectReference(bVal)) {
                return (bVal.id - aVal.id) * (ascending ? -1 : 1);
            }
        }

        return 0;
    });
}
function isObjectReference(o: unknown): o is ObjectReference<AnyObject> {
    return o != null && typeof o === 'object' && (o as { id?: ID }).id != null;
}

function mapProjectsToElaborateProjects({
    projects,
    languageCode,
    t,
    staticData,
}: {
    projects?: ProjectOverview[];
    languageCode: string;
    t: TFunction;
    staticData?: StaticData | null;
}): ElaboratedProject[] {
    if (!projects) return [];

    return projects.map((project) => {
        const buildingClassPartners = staticData?.buildingClassification.partners.find((classification) => {
            return classification.id === project.buildingClassPartners?.id;
        });
        const parent = buildingClassPartners
            ? staticData?.buildingClassification.partners.find((elem) => elem.id === buildingClassPartners.parent?.id)
            : null;
        return {
            ...project,
            id: project.id,
            name: translateField(project.name, languageCode),
            canton: project.cantonCode,
            year: project.moveIn ? new Date(project.moveIn) : null,
            classification: buildingClassPartners
                ? ({
                      ...buildingClassPartners,
                      parent,
                  } as StaticBuildingClassification)
                : null,
            projectPhase: translateField(project.phase ?? {}, languageCode),
            status: t(`editor:projects:details:published:${!!project.userPublished}`),
            thumbnail: project.thumbnail,
        };
    });
}
function translateField(field: TranslationMap = {}, lang: string) {
    return field[lang] || Object.values(field)[0] || null;
}
