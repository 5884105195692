import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { IconButton } from '../Buttons';

export const ScrollupButton: React.FC = () => {
    const handleClick = useCallback(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <IconButton className="scrollup-button" type="button" onClick={handleClick}>
            <FontAwesomeIcon icon={faArrowUp} />
        </IconButton>
    );
};
