import React, { useCallback } from 'react';
import { useHistory } from 'react-router';

import { AuthenticationProvider, CustomerCareProvider, ResourcesProvider } from './contexts';
import { AuthenticationService, CustomerCareService, ResourcesService, TableauService } from './services';

import { Routes } from './Routes';

import { TableauProvider } from './contexts/TableauContext';
import { RoutesEnum } from './enums/paths';

import './styles';

interface Props {
    authService: AuthenticationService;
    tableauService: TableauService;
    resourcesService: ResourcesService;
    customerCareService: CustomerCareService;
}

export const Application: React.FC<Props> = ({
    authService,
    tableauService,
    resourcesService,
    customerCareService: customerCareSErvice,
}) => {
    const history = useHistory();

    const handleLogout = useCallback(async () => {
        tableauService.clearToken();
        history.push(RoutesEnum.BASE);
    }, [history, tableauService]);

    return (
        <AuthenticationProvider authenticationService={authService} onLogout={handleLogout}>
            <TableauProvider tableauService={tableauService}>
                <ResourcesProvider resourcesService={resourcesService}>
                    <CustomerCareProvider customerCareService={customerCareSErvice}>
                        <Routes />
                    </CustomerCareProvider>
                </ResourcesProvider>
            </TableauProvider>
        </AuthenticationProvider>
    );
};
