import classnames from 'classnames';
import { noop } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { codeOf } from '../../utils';
import { ElaboratedProject } from './types';

export interface ProjectTableItemProps {
    project: ElaboratedProject & { disabled?: boolean };
    selected?: boolean;
    hideThumbnail?: boolean;
    onSelectionToggle: (projectId: Project['id']) => void;
}

const FALLBACK_THUMBNAIL = '/api/content/default_thumbnail.png';

export const ProjectTableItem: React.FC<ProjectTableItemProps> = ({
    project,
    selected,
    hideThumbnail,
    onSelectionToggle,
}) => {
    const [, i18n] = useTranslation();

    const languageCode = codeOf(i18n.language);

    const handleClick = React.useCallback(
        () => void (!project.disabled && onSelectionToggle(project.id)),
        [project.id, project.disabled, onSelectionToggle]
    );

    const setDefaultImage = React.useCallback((ev) => {
        ev.preventDefault();

        const target: HTMLImageElement = ev.target;
        target.src = FALLBACK_THUMBNAIL;
    }, []);

    return (
        <tr
            className={classnames('project-list-item', {
                selected,
                disabled: project.disabled,
            })}
            onClick={handleClick}
            data-testid="project-list-item"
        >
            <td>
                <div className="checkbox">
                    <input type="checkbox" checked={selected} disabled={project.disabled} onChange={noop} />
                    <span className="checkbox-checkmark" />
                </div>
            </td>

            {!hideThumbnail && (
                <td>
                    <img
                        alt="thumbnail"
                        className="project-list-item__thumbnail"
                        src={project.thumbnail ? `/api/project-media/${project.id}` : FALLBACK_THUMBNAIL}
                        onError={setDefaultImage}
                    />
                </td>
            )}
            <td>{project.id}</td>
            <td>{project.name}</td>
            <td>{project.canton}</td>
            <td className="text-center">{project.year?.getFullYear() ?? '-'}</td>
            <td>
                {project.classification?.parent && (
                    <div className="project-list-item__werkkategorie-title">
                        {`${project.classification.parent.classificationCode} ${translateField(
                            project.classification.parent.description,
                            languageCode
                        )}`}
                    </div>
                )}
                {project.classification && (
                    <div>
                        {`${project.classification.classificationCode} ${translateField(
                            project.classification.description,
                            languageCode
                        )}`}
                    </div>
                )}
            </td>
            <td>{project.projectPhase}</td>
            <td>{project.status}</td>
        </tr>
    );
};

function translateField(field: TranslationMap = {}, lang: string) {
    return field[lang] || Object.values(field)[0] || null;
}
