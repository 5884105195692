import { faBolt, faIndustry } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { keyBy, orderBy } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { TextButton } from '../../../Buttons';
import { ForestDiagram } from '../../../ForestDiagram';
import { BaseView } from '../View';
import { ViewProps } from '../types';
import { Energy } from './Energy';
import { EnergyParameter } from './EnergyParameter';
import { EnergyTree as EnergyTreeComponent } from './EnergyTree/EnergyTree';

const NO_ENERGIES: ProjectBuildingEnergy[] = [];
const NO_ENERGY_TREE_VALUES: Record<string, ProjectEnergyTreeValue> = {};

export const EnergyView: React.FC<ViewProps> = ({ project, staticData, language, changeProject, t }) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const energies = project.energyInfo?.energies ?? NO_ENERGIES;

    const changeEnergy = useCallback(
        (editedEnergy: ProjectBuildingEnergy) => {
            changeProject((p) => {
                const exists = p.energyInfo.energies.find(
                    (energy) => energy.energyUnit.id === editedEnergy.energyUnit.id
                );

                if (exists) {
                    return {
                        ...p,
                        energyInfo: {
                            ...p.energyInfo,
                            energies: p.energyInfo.energies.map((energy) =>
                                energy.energyUnit.id === editedEnergy.energyUnit.id ? editedEnergy : energy
                            ),
                        },
                    };
                } else {
                    return {
                        ...p,
                        energyInfo: {
                            ...p.energyInfo,
                            energies: [...p.energyInfo.energies, editedEnergy],
                        },
                    };
                }
            });
        },
        [changeProject]
    );

    const units = useMemo(() => {
        return staticData.energyUnits.sort((eu1, eu2) =>
            (eu1.name[language.codes.editor] || Object.values(eu1.name)[0]).localeCompare(
                eu2.name[language.codes.editor] || Object.values(eu2.name)[0]
            )
        );
    }, [staticData.energyUnits, language.codes.editor]);

    const [e1, e2] = useMemo(
        () => [
            units.slice(0, Math.ceil(units.length / 2)).map((unit) => {
                const energy = energies.find(({ energyUnit }) => energyUnit.id === unit.id);

                return { unit, energy };
            }),
            units.slice(Math.ceil(units.length / 2), units.length).map((unit) => {
                const energy = energies.find(({ energyUnit }) => energyUnit.id === unit.id);

                return { unit, energy };
            }),
        ],
        [units, energies]
    );

    const values = useMemo(() => {
        const entries = Object.entries(project.energyInfo.treeValues).map<
            [string, undefined | Record<string, ProjectEnergyTreeValue | undefined>]
        >(([key, values]) => [key, keyBy(values, 'tree.id')]);

        return Object.fromEntries(entries);
    }, [project.energyInfo.treeValues]);

    const handleEnergyTreeValueChange = useCallback(
        (
            type: string,
            tree: EnergyTree,
            update: (
                val: ProjectEnergyTreeValue,
                values: Record<string, ProjectEnergyTreeValue | undefined>
            ) => ProjectEnergyTreeValue
        ) => {
            const treeId = tree.id;
            changeProject((p) => {
                const values: Record<number, ProjectEnergyTreeValue | undefined> = keyBy(
                    p.energyInfo.treeValues[type],
                    'tree.id'
                );

                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                values[treeId] = update(
                    values[treeId] ?? {
                        tree,
                        co2: 0,
                        watt: 0,
                    },
                    values
                );

                return {
                    ...p,
                    energyInfo: {
                        ...p.energyInfo,
                        treeValues: {
                            ...p.energyInfo.treeValues,
                            [type]: Object.values(values).filter((value): value is ProjectEnergyTreeValue => !!value),
                        },
                    },
                };
            });
        },
        [changeProject]
    );

    const parameterValuePairs = useMemo(() => {
        return orderBy(staticData.energyParameterTypes, 'order', 'asc').map((type) => {
            const value = project.energyInfo.parameters.find((value) => value.type.id === type.id);

            return {
                type,
                value: value || createProjectEnergyParameterForType(type),
            };
        });
    }, [project.energyInfo.parameters, staticData.energyParameterTypes]);

    const handleEnergyParameterChange = useCallback(
        (type: EnergyParameterType, update: (value: ProjectEnergyParameter) => ProjectEnergyParameter) => {
            changeProject((p) => {
                let exists = false;
                const parameters = p.energyInfo.parameters.map((param) => {
                    if (param.type.id !== type.id) return param;

                    exists = true;

                    return update(param);
                });

                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                if (!exists) parameters.push(update(createProjectEnergyParameterForType(type)));

                return {
                    ...p,
                    energyInfo: { ...p.energyInfo, parameters },
                };
            });
        },
        [changeProject]
    );

    const [locks, setLocks] = useLocks(staticData.energyTrees, project.energyInfo.treeValues);
    const toggleLock = useCallback(
        (id, key: 'watt' | 'co2', set?: boolean) => {
            setLocks((locks) => ({
                ...locks,
                [id]: { ...locks[id], [key]: set !== undefined ? set : !locks[id][key] },
            }));
        },
        [setLocks]
    );

    const { hasValues, hasWattValues, hasCo2Values } = useMemo(() => {
        const hasWattValues = Object.values(values).some(
            (map) => map && Object.values(map).some((value) => !!value?.watt)
        );
        const hasCo2Values = Object.values(values).some(
            (map) => map && Object.values(map).some((value) => !!value?.co2)
        );
        const hasValues = hasWattValues || hasCo2Values;

        return { hasWattValues, hasCo2Values, hasValues };
    }, [values]);

    const [metric, setMetric] = useState<'watt' | 'co2'>('watt');
    const forcedMetric = hasCo2Values && metric === 'co2' ? 'co2' : 'watt';
    const toggleMetric = useCallback(() => setMetric((m) => (m === 'watt' ? 'co2' : 'watt')), []);
    const getEnergyId = useCallback((tree: EnergyTree) => tree.id, []);
    const getEnergyText = useCallback((tree: EnergyTree) => tree.name[language.codes.editor], [language]);
    const getEnergyValue = useCallback(
        (tree: EnergyTree) => {
            const energy: ProjectEnergyTreeValue | undefined = values[tree.type]?.[tree.id];

            return energy?.[forcedMetric];
        },
        [values, forcedMetric]
    );

    return (
        <BaseView name="energy" title={t('editor:projects:creator:energy-sheet:title')}>
            <Row className="small-push-bottom">
                <Col xs={12} lg={6}>
                    <Container>
                        {e1.map(({ unit, energy }) => (
                            <Energy
                                energy={energy}
                                unit={unit}
                                language={language}
                                changeEnergy={changeEnergy}
                                key={unit.id}
                            />
                        ))}
                    </Container>
                </Col>
                <Col xs={12} lg={6}>
                    <Container>
                        {e2.map(({ unit, energy }) => (
                            <Energy
                                energy={energy}
                                unit={unit}
                                language={language}
                                changeEnergy={changeEnergy}
                                key={unit.id}
                            />
                        ))}
                    </Container>
                </Col>
            </Row>
            <Row className="small-push-bottom">
                <Col xs={12}>
                    <Container>
                        <Row>
                            <Col>
                                <h3>Energie Parameter</h3>
                            </Col>
                        </Row>
                    </Container>
                </Col>
                <Col className="px-0" xs={12}>
                    <Container>
                        {parameterValuePairs.map(({ type, value }) => (
                            <EnergyParameter
                                type={type}
                                value={value}
                                onChange={handleEnergyParameterChange}
                                key={type.id}
                            />
                        ))}
                    </Container>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <Container>
                        <Row>
                            <Col>
                                <h3>Energie Werte</h3>
                            </Col>
                        </Row>
                    </Container>
                </Col>

                <Col xs={12} className="my-3 position-relative">
                    {hasValues && (
                        <TextButton
                            name={(
                                <>
                                    {forcedMetric === 'watt' && <FontAwesomeIcon icon={faBolt} />}
                                    {forcedMetric === 'co2' && <FontAwesomeIcon icon={faIndustry} />}
                                </>
                              )}
                            className="energy-tree-metric-toggle py-1 px-2"
                            onClick={toggleMetric}
                            disabled={hasCo2Values !== hasWattValues}
                        />
                    )}
                    <ForestDiagram
                        forest={staticData.energyTrees}
                        getId={getEnergyId}
                        getName={getEnergyText}
                        getValue={getEnergyValue}
                    />
                </Col>
                <Col className="px-0" xs={12}>
                    <Container>
                        {staticData.energyTrees.map((tree) => (
                            <EnergyTreeComponent
                                tree={tree}
                                locks={locks}
                                toggleLock={toggleLock}
                                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                                values={values[tree.type] ?? NO_ENERGY_TREE_VALUES}
                                onChange={handleEnergyTreeValueChange}
                                key={tree.id}
                            />
                        ))}
                    </Container>
                </Col>
            </Row>
        </BaseView>
    );
};

function createProjectEnergyParameterForType(type: EnergyParameterType): ProjectEnergyParameter {
    switch (type.type) {
        case 'ENUMERATED':
            return {
                id: -1,
                type,
                valueType: 'ENUMERATED',
                value: { id: -1 },
            };
        case 'NUMERIC':
            return {
                id: -1,
                type,
                valueType: 'NUMERIC',
                num: 0,
            };
        case 'TEXT':
            return {
                id: -1,
                type,
                valueType: 'TEXT',
                text: '',
            };
    }
}

function useLocks(trees: EnergyTree[], values: Record<string, ProjectEnergyTreeValue[]>) {
    return useState<Record<ID, { watt: boolean; co2: boolean }>>(() => getLocks({}, trees, values));
}

function getLocks(
    locks: Record<ID, { watt: boolean; co2: boolean }>,
    trees: EnergyTree[],
    values: Record<string, ProjectEnergyTreeValue[]>
): Record<ID, { watt: boolean; co2: boolean }> {
    return trees.reduce((acc, tree) => {
        getLockForTree(acc, tree, values[tree.type]);

        return acc;
    }, locks);
}

function getLockForTree(
    locks: Record<ID, { watt: boolean; co2: boolean }>,
    tree: EnergyTree,
    values: undefined | ProjectEnergyTreeValue[]
): { watt: number; co2: number } {
    const { watt, co2 } = tree.children.reduce(
        (acc, tree) => {
            const total = getLockForTree(locks, tree, values);

            return { watt: acc.watt + total.watt, co2: acc.co2 + total.co2 };
        },
        { watt: 0, co2: 0 }
    );

    const value = values?.find((value) => value.tree.id === tree.id);

    locks[tree.id] = {
        watt: (value?.watt ?? 0) === watt,
        co2: (value?.co2 ?? 0) === co2,
    };

    return { watt, co2 };
}
