import { UseMutationResult, useMutation, useQueryClient } from 'react-query';

export function useProjectTagMutation<A, R>(
    mutFn: (arg: A) => Promise<R>
): UseMutationResult<unknown, unknown, A, unknown> {
    const queryClient = useQueryClient();

    return useMutation(mutFn, {
        onSuccess: () => {
            queryClient.invalidateQueries(['project-tags']);
        },
    });
}
