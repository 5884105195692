export class ValidationExecutor {
    private reports: Report[] = [];

    public execute(validator: Validator, value: unknown): boolean {
        this.reports = [];
        const reportingValidator = validator(this.report);

        return reportingValidator(value);
    }

    public getReports(): Report[] {
        const sortDefinition: ReportSeverity[] = ['error', 'warning', 'info'];

        this.reports = this.reports.sort(
            (a, b) => sortDefinition.indexOf(b.severity) - sortDefinition.indexOf(a.severity)
        );

        return this.reports;
    }

    public getReport(): Report | null {
        return this.getReports()[0] || null;
    }

    get hasReports(): boolean {
        return this.reports.length > 0;
    }

    private report = (message: string, severity: ReportSeverity = 'error') => {
        this.reports = [...(this.reports || []), { message, severity }];
    };
}
