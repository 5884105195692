/**
 * Enum of the top categories of the BKP-H cost categories
 */
export enum BPKH_Categories {
    A_SITE = 'A',
    B_ENABLING_WORKS = 'B',
    C_BUILDING_STRUCTURE = 'C',
    D_BUILDING_SERVICES = 'D',
    E_EXTERNAL_WALL_CLADDING_TO_BUILDINGS = 'E',
    F_BUILDING_ROOFING = 'F',
    G_BUILDING_FIT_OUT = 'G',
    H_USE_SPECIFIC_BUILDING_FACILITIES = 'H',
    I_EXTERNAL_WORKS_FOR_BUILDING = 'I',
    J_BUILDING_FURNISHINGS = 'J',
    V_DESIGN_COSTS = 'V',
    W_INCIDENTAL_COSTS_FOR_CONSTRUCTION = 'W',
    Y_CONTINGENCIES_INCREASED_COSTS = 'Y',
    Z_VALUE_ADDED_TAX = 'Z',
}
