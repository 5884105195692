import React from 'react';
import { ProjectEditorNavigationItem } from './ProjectEditorNavigationItem';
import { EditorView } from './ProjectEditorNavigationView';

interface Props {
    activePage?: EditorView;
    pages: Page[];
    changeView: (view: EditorView) => unknown;
}

export interface Page {
    name: string;
    page: EditorView;
}

export const ProjectEditorNavigation: React.FC<Props> = ({ activePage, pages, changeView }) => (
    <div className="project-editor-navigation">
        {pages.map(({ name, page }) => (
            <ProjectEditorNavigationItem
                name={name}
                view={page}
                changeView={changeView}
                active={activePage === page}
                key={page}
            />
        ))}
    </div>
);
