import type { TOptions } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { BuildingPlanError as BuildingPlanErrorType } from './types';
import { tKey } from './utils';

export const BuildingPlanError: React.FC<BuildingPlanErrorType> = ({ error, errorParams, errorAddon, floating }) => {
    const [t] = useTranslation();

    return (
        <div
            className={['building-plan-importer__error', floating ? 'building-plan-importer__error--floating' : '']
                .join(' ')
                .trim()}
        >
            {t(tKey(error), errorParams as TOptions)}

            {errorAddon && (
                <>
                    &nbsp;
                    {errorAddon}
                </>
            )}
        </div>
    );
};

export const GeneralUploadErrorAddon: React.FC = () => (
    <a className="building-plan-importer__error-addon" href="mailto:werk-material@crb.ch">
        werk-material@crb.ch
    </a>
);
