import React, { useCallback, forwardRef } from 'react';
import type { InputActions, InputCallbacks, NavigationState } from './types';
import { useDialog } from '../../effects';
import { useTranslation } from 'react-i18next';
import { AllowInputFileType } from '../../enums';

interface BuildingPlanContentProps extends InputActions, NavigationState, InputCallbacks {
    maxByteSize: number;
}

function getFileFormat(file: File) {
    return file.name.split('.')[1].toLowerCase();
}

const dxfFile: AllowInputFileType = '.dxf';
const ifcFile: AllowInputFileType = '.ifc';

export const BuildingPlanContent = forwardRef<HTMLInputElement, BuildingPlanContentProps>(function BuildingPlanContent(
    { step, isEnd, maxByteSize, openDialog, onChange, onError },
    ref
) {
    const [t] = useTranslation();

    const wrongFileEndingDialog = useDialog(
        t('editor:input-file-import:incorrect-input-file-type:dialog', { returnObjects: true })
    );

    const handleImport = useCallback(
        ({ target }: React.ChangeEvent<HTMLInputElement>) => {
            const [newFile] = target.files ?? [];

            if (newFile.size > maxByteSize) {
                return onError({
                    error: 'file-too-large-upload-error',
                    errorParams: { param: `${maxByteSize / 1024 / 1024} MB` },
                });
            }

            onError(null);

            const reader = new FileReader();

            const handleLoad = async () => {
                try {
                    const isFileTypeRight = newFile.name.endsWith(dxfFile) || newFile.name.endsWith(ifcFile);

                    if (!isFileTypeRight) {
                        const result = await wrongFileEndingDialog();

                        if (!result) {
                            target.value = '';

                            return void openDialog();
                        }
                    }

                    onChange({
                        src: reader.result as string,
                        name: newFile.name,
                        extension: getFileFormat(newFile),
                    });
                } catch (error) {
                    console.error(error);
                }
            };

            reader.readAsDataURL(newFile);
            reader.onload = () => void handleLoad();
            reader.onerror = () => onError({ error: 'reading-input-file-error' });
        },
        [maxByteSize, onError, onChange, wrongFileEndingDialog, openDialog]
    );

    const handleClick = useCallback(() => {
        if (isEnd) openDialog();
    }, [isEnd, openDialog]);

    return (
        <div className="building-plan-importer__content">
            <img
                className="building-plan-importer__img"
                src={step.image}
                onClick={handleClick}
                alt="Import Building Plan Instructions"
            />

            <input type="file" accept={`${dxfFile}, ${ifcFile}`} onChange={handleImport} ref={ref} disabled={!isEnd} />
        </div>
    );
});
