import { Calculator } from './types';

export class EBKPTCalculator implements Calculator {
    /**
     * KoA Anlagekosten
     */
    public getInvestmentCosts(regulations: Array<RegulationWithCost>): number {
        return regulations
            .filter((regulation) => !regulation.parent)
            .reduce((sum, regulation) => sum + (regulation.cost.value ?? 0), 0);
    }

    /**
     * KoE Erstellungskosten
     */
    public getCreationCosts(regulations: Array<RegulationWithCost>): number {
        return (
            regulations.reduce((acc, regulation) => {
                switch (`${regulation.code}`) {
                    case 'L':
                    case 'M':
                    case 'N':
                    case 'O':
                    case 'P':
                    case 'Q':
                    case 'R':
                    case 'S':
                    case 'T':
                    case 'V':
                    case 'W':
                        return acc + (regulation.cost.value ?? 0) * 100;
                    default:
                        return acc;
                }
            }, 0) / 100
        );
    }

    /**
     * KoB Bauwerkskosten
     */
    public getConstructionCosts(regulations: Array<RegulationWithCost>): number {
        return (
            regulations.reduce((acc, regulation) => {
                switch (`${regulation.code}`) {
                    case 'M':
                    case 'N':
                    case 'O':
                    case 'P':
                    case 'Q':
                    case 'R':
                    case 'S':
                    case 'T':
                        return acc + (regulation.cost.value ?? 0) * 100;
                    default:
                        return acc;
                }
            }, 0) / 100
        );
    }

    /**
     * KoG Gebäudekosten
     */
    public getBuildingCosts(): number {
        return 0;
    }
}
