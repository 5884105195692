import type { TFunction } from 'i18next';
import React, { useCallback, useMemo, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { useDialog, useProjectDuplicate, useProjectVisibilityToggle, useProjectsDelete } from '../../effects';
import { RoutesEnum } from '../../enums/paths';
import { useProjectTransferDialog } from '../../forms';
import { ActionsEnum } from '../../license/actions';
import type { UserInfo } from '../../types';
import { TextButton } from '../Buttons';
import { useDialogPortal } from '../Dialog';
import { PageHeader } from '../PageHeader';
import { ProjectImporter } from '../ProjectImporter';
import { ProjectActions } from './ProjectActions';
import { ProjectTable } from './ProjectTable';
import { ProjectElektrosmogExporter, ProjectExcelExporter } from '../ProjectExporter';

interface Props {
    projects?: ProjectOverview[];
    staticData?: StaticData;
    user: UserInfo;
    t: TFunction;
    onExitLocation?: (location: { pathname: string; search: string }) => unknown;
}

type ActionDisplay = {
    documentNewProject: boolean;
    estimateNewProject: boolean;
};
function getActionDisplay(user: UserInfo): ActionDisplay {
    const display = {
        documentNewProject: false,
        estimateNewProject: true,
    };

    if (user.actions.has(ActionsEnum.VIEW_PROJECTS)) {
        display.documentNewProject = true;
    }

    return display;
}

export const ProjectList: React.FC<Props> = ({ projects, staticData, user, t, onExitLocation }) => {
    const history = useHistory();

    const [filter, setFilter] = useState<(project: ProjectOverview) => boolean>(() => () => true);
    const [selection, setSelection] = useState<ID[]>([]);
    const [exportedProjects, setExportedProjects] = useState<{
        type: 'excel' | 'elektrosmog';
        projects: number[];
    }>();

    const { mutate: duplicateProject } = useProjectDuplicate();
    const { mutate: deleteProjects } = useProjectsDelete();
    const { mutate: toggleProjectsVisibility } = useProjectVisibilityToggle();

    const executeAfterConfirm = useDialog({
        title: t('editor:confirm-deletion:title'),
        description: t('editor:confirm-deletion:description'),
        confirm: t('editor:confirm-deletion:confirm'),
        cancel: t('editor:confirm-deletion:cancel'),
        closeOnOutsideClick: true,
    });
    const transferProject = useProjectTransferDialog();

    const abortExport = useCallback(() => setExportedProjects(undefined), []);
    const closeExport = useCallback(() => abortExport(), [abortExport]);

    const selectedProjects = useMemo(
        () => (projects || []).filter(({ id }) => selection.includes(id)),
        [projects, selection]
    );
    const filteredProjects = useMemo(() => projects?.filter(filter), [projects, filter]);

    const changeSelection = useCallback((newSelection: number[]) => {
        setSelection(newSelection);
    }, []);

    const changeFilter = useCallback((filt) => setFilter(() => filt), []);

    const actionDisplay = getActionDisplay(user);

    const openCreateProjectForm = useCallback(() => {
        const path = generatePath(RoutesEnum.EDITOR_CREATE, { type: 'project' });

        history.push(path);
    }, [history]);

    const [openExcelProjectImporter, portal, closeExcelProjectImporter] = useDialogPortal({
        title: t('import:title'),
        body: <ProjectImporter onClose={() => closeExcelProjectImporter()} />,
        footer: null,
    });

    const openCreateEstimationForm = useCallback(() => {
        const path = generatePath(RoutesEnum.EDITOR_CREATE, { type: 'estimation' });

        history.push(path);
    }, [history]);

    const openProject = useCallback(
        (projectId: ID) => {
            onExitLocation?.({ pathname: history.location.pathname, search: history.location.search });

            const path = generatePath(RoutesEnum.PROJECT_PAGE, { projectId });
            history.push(path);
        },
        [history, onExitLocation]
    );

    const handleProjectDuplicate = useCallback(
        (projectId: ID) =>
            duplicateProject(projectId, {
                onSuccess: openProject,
            }),
        [duplicateProject, openProject]
    );

    const handleProjectsDelete = useCallback(
        async (projectsToDelete: ProjectOverview[]) => {
            const confirmed = await executeAfterConfirm();

            const projectIds = projectsToDelete.map(({ id }) => id);

            const resetProjectSelection = () => {
                setSelection([]);
            };

            if (confirmed) {
                deleteProjects(projectIds, {
                    onSettled: resetProjectSelection,
                });
            }
        },
        [executeAfterConfirm, deleteProjects]
    );

    const handleProjectVisibilityToggle = useCallback(
        async (projects: ProjectOverview[]) => {
            const payload: ProjectVisibilityUpdatePayload = {
                projects: projects.map((p) => ({
                    projectId: p.id,
                    userPublished: !p.userPublished,
                })),
            };

            toggleProjectsVisibility(payload);
        },
        [toggleProjectsVisibility]
    );

    const handleProjectTransfer = useCallback(
        async (project: ProjectOverview[]) => transferProject(project),
        [transferProject]
    );

    const handleProjectExport = useCallback(
        async (projects: ProjectOverview[], type) =>
            setExportedProjects({
                type,
                projects: projects.map(({ id }) => id),
            }),
        []
    );

    return (
        <div className="project-list">
            <PageHeader title={t('editor:projects:list:title')} />

            {actionDisplay.documentNewProject ? (
                <TextButton
                    name={t('editor:projects:list:actions:document-new-project')}
                    onClick={openCreateProjectForm}
                    testId="document-new-project"
                />
            ) : null}
            {user.actions.has(ActionsEnum.VIEW_ALL_PROJECTS) && actionDisplay.documentNewProject ? (
                <TextButton
                    name={t('editor:projects:list:actions:import-new-project')}
                    onClick={openExcelProjectImporter}
                    testId="import-new-project"
                    className="ml-2"
                />
            ) : null}
            {portal}
            {actionDisplay.estimateNewProject ? (
                <TextButton
                    name={t('editor:projects:list:actions:estimate-new-project')}
                    onClick={openCreateEstimationForm}
                    testId="estimate-new-project"
                    className="ml-2"
                />
            ) : null}

            <hr className="mt-3" />

            <ProjectActions
                className="mt-3 d-flex justify-content-end align-items-end"
                user={user}
                projects={projects}
                staticData={staticData}
                selectedProjects={selectedProjects}
                onFilter={changeFilter}
                copyProject={handleProjectDuplicate}
                editProject={openProject}
                deleteProject={handleProjectsDelete}
                toggleVisibility={handleProjectVisibilityToggle}
                transferProjects={handleProjectTransfer}
                exportProjects={handleProjectExport}
                t={t}
            />

            <ProjectTable
                projects={filteredProjects}
                staticData={staticData}
                selection={selection}
                onSelection={changeSelection}
            />

            {exportedProjects?.type === 'excel' && exportedProjects.projects.length && (
                <ProjectExcelExporter
                    projects={exportedProjects.projects}
                    onCancel={abortExport}
                    onClose={closeExport}
                />
            )}

            {exportedProjects?.type === 'elektrosmog' && exportedProjects.projects.length && (
                <ProjectElektrosmogExporter
                    projects={exportedProjects.projects}
                    onCancel={abortExport}
                    onClose={closeExport}
                />
            )}
        </div>
    );
};
