import React, { useCallback, useEffect, useState } from 'react';
import { useId } from '../../../../effects';
import { TextInput } from '../../TextInput';
import { SelectBase } from '../SelectBase';
import { useRegulationSelectorOptions } from './use-regulation-selector-options';
import { useRegulationSelectorValue } from './use-regulation-selector-value';
import { toString } from './utils';

interface Props {
    label: string;
    regulation?: ObjectReference<StaticRegulation>;
    regulations: StaticRegulation[];
    onChange: (regulation: StaticRegulation) => void;
    language: string;
    autoFocus?: boolean;
    testId?: string;
    defaultRegulation?: StaticRegulation;
}

export const RegulationSelector: React.FC<Props> = ({
    label,
    regulation,
    regulations,
    language,
    onChange,
    autoFocus,
    defaultRegulation,
    ...props
}) => {
    const id = useId();
    const [value, , valueName] = useRegulationSelectorValue(regulation, regulations, language);
    const [text, setText] = useState(valueName);

    useEffect(() => {
        if (!value && defaultRegulation) {
            onChange(defaultRegulation);
            setText(toString(defaultRegulation, language));
        }
    }, [value, defaultRegulation, onChange, language]);

    const optionValue = valueName !== text ? null : value;
    const options = useRegulationSelectorOptions(regulations, optionValue, text, language);

    const [focused, setFocused] = useState(false);
    const handleFocus = useCallback(() => setFocused(true), []);
    const handleBlur = useCallback(() => setFocused(false), []);

    const changeText = useCallback((value: string) => {
        setText(value);
        if (!value) return;
    }, []);

    const changeRegulation = useCallback(
        (value?: StaticRegulation | null) => {
            if (!value) return;

            setText(toString(value, language));
            onChange(value);
        },
        [language, onChange]
    );

    useEffect(() => {
        if (!focused && value) {
            setText(toString(value, language));
        }
    }, [focused, value, language]);

    return (
        <SelectBase
            {...props}
            className="form__regulation-select"
            label={label}
            value={value}
            onChange={changeRegulation}
            options={options}
            focused={focused}
            multiple={false}
            filled={focused || !!text || !!value?.id}
            onFocus={handleFocus}
            onBlur={handleBlur}
            labelTarget={id}
        >
            <TextInput
                id={id}
                value={text}
                onChange={changeText}
                noDefaultClassName={true}
                autoFocus={autoFocus && !value}
                onFocus={handleFocus}
            />
        </SelectBase>
    );
};
