import { faChevronDown, faChevronRight, faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import type { TFunction } from 'i18next';
import React, { useCallback, useMemo, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { every, Tree } from '../../../../effects';
import { formatValueToLocale } from '../../../../utils/format';
import { NumberInput, TextArea } from '../../../FormComponents';
import { isCostDependingQuantity } from './quantity-calculations';
import type { CostNode } from './types';
import { isStaticRegulation } from './utils';
interface CostProps {
    regulation: Tree<CostNode>;
    language: EditorLanguage;
    hideQuantity?: boolean;
    total?: boolean;
    changeValue: (path: Regulation['id'][], change: (value: number, node: CostNode) => number) => unknown;
    changeQuantity: (path: Regulation['id'][], change: (quantity: number, node: CostNode) => number) => unknown;
    changeLocked: (path: Regulation['id'][], change: (locked: boolean, node: CostNode) => boolean) => unknown;
    changeDescription: (
        path: Regulation['id'][],
        change: (description: TranslationMap, node: CostNode) => TranslationMap
    ) => unknown;
    t: TFunction;
}

export const Cost: React.FC<CostProps> = ({
    regulation,
    language,
    hideQuantity,
    total,
    changeValue,
    changeQuantity,
    changeLocked,
    changeDescription,
    t,
}) => {
    const { cost } = regulation;
    const { value, checksum = 0, referenceQuantity = 0, description } = cost;

    const [collapsed, setCollapsed] = useState(true);
    const toggle = () => setCollapsed((c) => !c);

    const handleLockToggle = useCallback(
        () => changeLocked([regulation.id], (locked) => !locked),
        [changeLocked, regulation.id]
    );

    const handleQuantityChange = useCallback(
        (quantity: string) => {
            if (total) return;
            changeQuantity([regulation.id], () => parseFloat(quantity || '0'));
        },
        [changeQuantity, regulation.id, total]
    );

    const handleValueChange = useCallback(
        (newValue = '') => {
            changeValue([regulation.id], (_, { cost }) => (newValue ? parseFloat(newValue) || 0 : cost.checksum ?? 0));
        },
        [changeValue, regulation.id]
    );

    const handleDescriptionChange = useCallback(
        (newDescription: string) => {
            if (total) return;

            changeDescription([regulation.id], (description) => ({
                ...description,
                [language.codes.documentation]: newDescription,
            }));
        },
        [changeDescription, language.codes.documentation, regulation.id, total]
    );

    const quantityEditable = isStaticRegulation(regulation) ? !total && !isCostDependingQuantity(regulation) : false;

    const hasChildren = !!regulation.children.length;

    const status = useMemo(() => {
        const arithmeticallyCorrect = isArithmeticallyCorrect(value, checksum);

        if (!arithmeticallyCorrect) return 'nok';

        const correctTree = every(regulation, ({ cost }) => isArithmeticallyCorrect(cost.value, cost.checksum));

        if (!correctTree) return 'warn';

        if (!checksum) return '';

        return 'ok';
    }, [regulation, value, checksum]);

    const handleChildValueChange = useCallback(
        (path, change) => changeValue([regulation.id, ...path], change),
        [regulation.id, changeValue]
    );

    const handleChildQuantityChange = useCallback(
        (path, change) => changeQuantity([regulation.id, ...path], change),
        [regulation.id, changeQuantity]
    );

    const handleChildLockedChange = useCallback(
        (path, change) => changeLocked([regulation.id, ...path], change),
        [regulation.id, changeLocked]
    );

    const handleChildDescriptionChange = useCallback(
        (path, change) => changeDescription([regulation.id, ...path], change),
        [regulation.id, changeDescription]
    );

    return (
        <>
            <Row
                className={classnames('border-bottom', 'cost', {
                    'py-1': !total,
                    'py-2 cost--inactive': total,
                    'cost--quantity-hidden': hideQuantity,
                })}
                data-testid="cost"
            >
                <Col className="col cost-toggle" xs="24-1" onClick={toggle}>
                    {hasChildren && <FontAwesomeIcon icon={collapsed ? faChevronRight : faChevronDown} />}
                </Col>
                <Col className="col cost-code" xs="24-2">
                    {regulation.code}
                </Col>
                <Col className="col cost-name" xs={hideQuantity ? '24-10' : '24-3'}>
                    {getTranslations(regulation.description, language)}
                </Col>
                <Col className="col cost-short-name" xs="24-2" hidden={hideQuantity}>
                    {isStaticRegulation(regulation) && getTranslations(regulation.shortName, language)}
                </Col>
                <Col
                    className={classnames('col cost-quantity', {
                        'pt-3': !total && !quantityEditable,
                    })}
                    xs="24-4"
                    hidden={hideQuantity}
                >
                    {hideQuantity || total ? null : quantityEditable ? (
                        <NumberInput
                            wrapperClassName="mb-0"
                            decimals={2}
                            lazy={true}
                            value={`${referenceQuantity}`}
                            label={t('editor:projects:creator:cost-sheet:rows:quantity')}
                            onChange={handleQuantityChange}
                            testId="cost-quantity"
                        />
                    ) : (
                        <span
                            className={classnames('pt-3 ml-auto', {
                                'pr-3': !total,
                            })}
                        >
                            {formatValueToLocale(referenceQuantity, 2)}
                        </span>
                    )}
                </Col>
                <Col className="col cost-quantity-unit pl-0 pt-3" xs="24-1" hidden={hideQuantity}>
                    {isStaticRegulation(regulation) && regulation.measureUnit}
                </Col>
                <Col className={classnames('col cost-value p-0')} xs="2">
                    <NumberInput
                        wrapperClassName="mb-0"
                        decimals={2}
                        lazy={true}
                        value={`${value ?? 0}`}
                        label={t('editor:projects:creator:cost-sheet:rows:value-in-chf')}
                        onChange={handleValueChange}
                        testId="cost-in-chf"
                    />

                    {(hasChildren || total) && (
                        <div className={classnames('cost-value-lock', { 'pt-3': total })} onClick={handleLockToggle}>
                            {regulation.locked && <FontAwesomeIcon icon={faLock} />}
                            {!regulation.locked && <FontAwesomeIcon icon={faUnlock} />}
                        </div>
                    )}
                </Col>
                <Col className="col cost-checksum-check" xs="24-1">
                    {(hasChildren || total) && <div className="status-bubble" data-status={status} />}
                </Col>
                <Col className="col cost-checksum justify-content-end pt-3 px-0" xs="24-2" data-testid="checksum">
                    {(hasChildren || total) && formatValueToLocale(checksum, 2)}
                </Col>
                <Col className="col cost-description" xs="24-4">
                    {total ? (
                        `${description?.[language.codes.documentation] ?? ''}`
                    ) : (
                        <TextArea
                            wrapperClassName="mb-0"
                            value={`${description?.[language.codes.documentation] ?? ''}`}
                            label={t('editor:projects:creator:cost-sheet:rows:description')}
                            onChange={handleDescriptionChange}
                            autoResize={true}
                        />
                    )}
                </Col>
            </Row>
            {!collapsed &&
                regulation.children.map((child) => (
                    <Cost
                        regulation={child}
                        changeValue={handleChildValueChange}
                        changeQuantity={handleChildQuantityChange}
                        changeLocked={handleChildLockedChange}
                        changeDescription={handleChildDescriptionChange}
                        total={false}
                        language={language}
                        hideQuantity={hideQuantity}
                        t={t}
                        key={child.code}
                    />
                ))}
        </>
    );
};

function isArithmeticallyCorrect(value = 0, checksum = 0): boolean {
    if (checksum === 0) return true;
    return Math.abs(value - checksum) < 0.001;
}

function getTranslations(translationMap: Record<string, string | undefined>, language: EditorLanguage): string {
    const lang = language.codes.editor;

    return translationMap[lang] ?? translationMap.de_DE ?? Object.values(translationMap).find((t) => !!t) ?? '';
}
