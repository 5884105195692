import { Calculator } from './types';

export const creationCostsCategories: Set<string> = new Set(['B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'V', 'W']);
export const constructionCostsCategories: Set<string> = new Set(['C', 'D', 'E', 'F', 'G']);

function calculateGivenCategories(regulations: Array<RegulationWithCost>, categories: Set<string>) {
    return (
        regulations.reduce((acc, regulation) => {
            if (categories.has(regulation.code)) {
                return acc + (regulation.cost.value ?? 0) * 100;
            }
            return acc;
        }, 0) / 100
    );
}

export class EBKPHCalculator implements Calculator {
    /**
     * KoA Anlagekosten
     */
    public getInvestmentCosts(regulations: Array<RegulationWithCost>): number {
        return regulations
            .filter((regulation) => !regulation.parent)
            .reduce((sum, regulation) => sum + (regulation.cost.value ?? 0), 0);
    }

    /**
     * KoE Erstellungskosten
     */
    public getCreationCosts(regulations: Array<RegulationWithCost>): number {
        return calculateGivenCategories(regulations, creationCostsCategories);
    }

    /**
     * KoB Bauwerkskosten
     */
    public getConstructionCosts(regulations: Array<RegulationWithCost>): number {
        return calculateGivenCategories(regulations, constructionCostsCategories);
    }

    /**
     * KoG Gebäudekosten
     */
    public getBuildingCosts(): number {
        return 0;
    }
}
