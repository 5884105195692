import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import { VolumeEvaluationQuantities, VolumeEvaluationQuantitiesKey } from '../../../types';
import { ResultsCalculationQuantitiesItem } from './ResultsCalculationQuantitiesItem';
import { QuantitiesKey, QuantitiesKeyIFC } from '@crb-oa-viewer/data-assistant-building-plan';
import { TFunction } from 'i18next';
import { resolveTranslation } from '../../../utils';
import { MeasureUnit } from '../../../enums';

interface ResultsCalculationQuantitiesViewProps {
    quantities?: VolumeEvaluationQuantities;
    siaRegulations?: StaticRegulation[];
}

const tPrefix = 'editor:bkp-to-ebkph-wizard:volume-evaluation:results:';

export const ResultsCalculationQuantitiesView: React.FC<ResultsCalculationQuantitiesViewProps> = ({
    quantities = {},
    siaRegulations = [],
}) => {
    const [t, i18n] = useTranslation();

    const measurements = getTranslatedMeasurements(quantities, siaRegulations, t, i18n.language);

    return (
        <div className="bg-light-green py-1 my-sm-5">
            <Container>
                <div className="my-3">
                    <div className="drawer-headers">
                        <div className="text-left drawer-header-comparison ml-3">{t(`${tPrefix}measurement-rule`)}</div>
                        <div className="text-center drawer-header-quantity-amount">{t(`${tPrefix}short-name`)}</div>
                        <div className="text-right drawer-header-quantity-amount mr-3">{t(`${tPrefix}value`)}</div>
                    </div>
                    <div className="pt-2">
                        {measurements.map(({ shortName, measurementRule, measureUnit }) => (
                            <ResultsCalculationQuantitiesItem
                                key={shortName}
                                measurementRule={measurementRule}
                                shortName={shortName}
                                measureUnit={measureUnit}
                                value={quantities[shortName as VolumeEvaluationQuantitiesKey] ?? 0}
                            />
                        ))}
                    </div>
                </div>
            </Container>
        </div>
    );
};

type ShortName = QuantitiesKey | QuantitiesKeyIFC;

function getTranslatedMeasurements(
    quantities: Partial<Record<ShortName, number>>,
    siaRegulations: StaticRegulation[],
    t: TFunction,
    language: string
) {
    const siaRegulationsMap = createShortNameToRegulationMap(siaRegulations);

    const validQuantityNames = getValidQuantityShortNames(quantities);

    const result = validQuantityNames.map((shortName) => {
        const regulation = siaRegulationsMap.get(shortName);

        const measurementRule = getMeasurementRule(shortName, regulation, language, t);

        const measureUnit = getMeasureUnit(shortName, regulation);

        return { shortName, measurementRule, measureUnit };
    });

    return result;
}

/**
 * Map of quantity short name to SIA regulation
 */
function createShortNameToRegulationMap(siaRegulations: StaticRegulation[]) {
    const map: Map<ShortName, StaticRegulation> = new Map();

    for (const regulation of siaRegulations) {
        const name = regulation.shortName.de_DE as ShortName;
        map.set(name, regulation);
    }

    return map;
}

/**
 * Return short names of quantity whose value is not O
 */
function getValidQuantityShortNames(quantities: Partial<Record<ShortName, number>>) {
    const validQuantities = Object.entries(quantities)
        .filter(([, value]) => value !== 0)
        .map(([shortName]) => shortName)
        .sort() as ShortName[];

    return validQuantities;
}

// TODO: WMO4-209 remove 'X', 'MUAHV' from local translation once static data updated
type TranslationKey = QuantitiesKey | Partial<QuantitiesKeyIFC>;
/**
 * Short names of eBKP-H related regulations & 'X', 'MUAHV'
 *
 * 'X', 'MUAHV' are SIA regulation Rules, but don't have translations from static data.
 */
const TRANSLATION_KEYS: Array<TranslationKey> = [
    'USA',
    'MUAH',
    'SA',
    'FA',
    'BV',
    'FGA',
    'BRA',
    'ESA',
    'FGMA',
    'X',
    'MUAHV',
];

/**
 * Description of measurement
 *
 * For eBKP-H related regulations, get translation through i18n.
 *
 * For SIA regulations, get translation from static data
 */
function getMeasurementRule(
    shortName: ShortName,
    regulation: StaticRegulation | undefined,
    language: string,
    t: TFunction
) {
    if (TRANSLATION_KEYS.includes(shortName)) {
        return t(`${tPrefix}${shortName.toLowerCase()}`);
    }
    if (regulation) {
        // TODO: WMO4-209 remove fallback to 'de_DE' once translation in EN is added in static data
        return resolveTranslation(regulation.description, language) || regulation.description['de_DE'];
    }

    return '';
}

/**
 * Unit of measurement
 *
 * For eBKP-H related regulations, only 'BV' is cubic
 *
 * For SIA regulations, get unit from static data
 */
function getMeasureUnit(shortName: ShortName, regulation: StaticRegulation | undefined) {
    // TODO: WMO4-209 remove 'MUAHV'
    if (shortName === 'BV' || shortName === 'MUAHV') {
        return MeasureUnit.CUBIC_METER;
    }

    if (regulation) {
        return regulation.measureUnit as string;
    }

    return MeasureUnit.SQUARE_METER;
}
