import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isFunction } from 'lodash';
import React, { CSSProperties, DragEvent, FC, ReactNode, useCallback, useState } from 'react';
import { Drag, useDrag } from './DraggableList';

interface DraggableItemProps {
    index: number;
    transition?: { duration: number; ease?: string };
    disableDrag?: boolean;
    hideDragIcon?: boolean;
    onDragEnd?: (oldIndex: number, newIndex: number) => Promise<unknown>; // Will remove the drag and the translation will be undone
    children: ((currentIndex: number) => React.ReactNode) | React.ReactNode;

    onClick: (index: number) => void;
}

const isBetween = (num: number, start: number, end: number) =>
    (start <= num && num <= end) || (end <= num && num <= start);
const limit = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);

const getNewIndex = (index: number, drag: Drag | null) => {
    if (drag) {
        if (index === drag.start.index) {
            return drag.current;
        } else if (isBetween(index, drag.start.index, drag.current)) {
            return index + limit(drag.start.index - index, -1, 1);
        }
    }

    return index;
};

const getTranslate = (index: number, drag: Drag | null) => {
    if (drag) {
        if (index === drag.start.index) {
            return drag.dragWidth;
        } else if (isBetween(index, drag.start.index, drag.current)) {
            return drag.start.element.clientHeight * limit(drag.start.index - index, -1, 1);
        }
    }

    return 0;
};

export const DraggableItem: FC<DraggableItemProps> = ({
    index,
    transition = { duration: 100 },
    disableDrag,
    hideDragIcon,
    onDragEnd,
    onClick,
    children,
}) => {
    const [drag, { startDrag, handleDrag, handleDrop }] = useDrag();

    const [transitioning, setTransitioning] = useState(false);

    const currentIndex = getNewIndex(index, drag);

    const handleDragStart = ({ target, dataTransfer }: DragEvent<HTMLDivElement>) => {
        if (!disableDrag) {
            const transparentImage = new Image();
            const transparentImageData =
                'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';
            transparentImage.setAttribute('src', transparentImageData);

            dataTransfer.setDragImage(transparentImage, 0, 0);

            startDrag(index, target as HTMLElement);
        }
    };

    const handleDragOver = useCallback(
        (e: DragEvent<HTMLDivElement>) => {
            e.preventDefault();
            if (!disableDrag) {
                if (drag && currentIndex !== drag.current && !transitioning) {
                    setTransitioning(true);
                    handleDrag(currentIndex, e.target as HTMLElement);
                }
            }
        },
        [currentIndex, drag, transitioning, disableDrag, handleDrag]
    );

    const handleTransitionEnd = () => {
        setTransitioning(false);
    };

    const drop = useCallback(
        async (e: DragEvent<HTMLDivElement>) => {
            e.preventDefault();
            if (onDragEnd && drag) {
                await onDragEnd(index, drag.current);
            }

            handleDrop();
        },
        [drag, handleDrop, index, onDragEnd]
    );

    const raiseClick = () => {
        onClick(index);
    };

    const translate = getTranslate(index, drag);
    const transform = `translateY(${translate || 0}px)`;

    const style: CSSProperties = {
        transition: drag ? `transform ${transition.duration}ms ${transition.ease || 'linear'}` : undefined,
        transform,
        zIndex: drag ? (drag.start.index === index ? 101 : 100) : 'auto',
        touchAction: 'none',
    };

    return (
        <div
            className="draggable-item"
            draggable={!disableDrag}
            data-hide-icon={hideDragIcon}
            data-index={index}
            data-new-index={currentIndex}
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDragEnd={drop}
            style={style}
            onClick={raiseClick}
            onTransitionEnd={handleTransitionEnd}
            data-testid="draggable-item"
        >
            {isFunction(children) ? (children as (currentIndex: number) => ReactNode)(currentIndex) : children}

            <FontAwesomeIcon className="drag-icon" icon={faBars} />
        </div>
    );
};
