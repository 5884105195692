import { faPlus, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'i18next';
import React, { useCallback, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { TextInput } from '../FormComponents';

interface Props {
    language: EditorLanguage;
    onSave: (tag: TagCreate) => unknown;
    t: TFunction;
}

const EMPTY_TAG: TagCreate = {
    name: { de_DE: '', fr_FR: '', it_IT: '', en_GB: '' },
};

export const TagEditorCreateRow: React.FC<Props> = ({ language, onSave, t }) => {
    const [adding, setAdding] = useState(false);

    const [tag, setTag] = useState<TagCreate>(EMPTY_TAG);

    const hasName = Object.values(tag.name).some((val) => !!val);

    const handleAdd = useCallback(() => setAdding(true), []);
    const handleDiscard = useCallback(() => {
        setAdding(false);
        setTag(EMPTY_TAG);
    }, []);

    const handleTextChange = useCallback(
        (name: string, lng: string) =>
            setTag((t) => ({
                ...t,
                name: { ...t.name, [lng]: name },
            })),
        []
    );

    const handleTextChangeDE = useCallback((name) => handleTextChange(name, 'de_DE'), [handleTextChange]);
    const handleTextChangeFR = useCallback((name) => handleTextChange(name, 'fr_FR'), [handleTextChange]);
    const handleTextChangeIT = useCallback((name) => handleTextChange(name, 'it_IT'), [handleTextChange]);
    const handleTextChangeEN = useCallback((name) => handleTextChange(name, 'en_GB'), [handleTextChange]);

    const handleSave = useCallback(async () => {
        await onSave(tag);
        setAdding(false);
        setTag(EMPTY_TAG);
    }, [onSave, tag]);

    if (!adding) {
        return (
            <Row className="mb-3">
                <Col role="button" xs="auto" onClick={handleAdd}>
                    <FontAwesomeIcon role="button" icon={faPlus} onClick={handleAdd} />
                </Col>
                <Col>
                    <span>{t('editor:tagEditor:addTag')}</span>
                </Col>
            </Row>
        );
    }

    return (
        <React.Fragment>
            <Row className="data-view__project-tag py-1 mt-2">
                <Col>{tag.name[language.codes.editor]}</Col>
                {hasName && (
                    <Col xs="auto" role="button" onClick={handleSave}>
                        <FontAwesomeIcon icon={faSave} />
                    </Col>
                )}
                <Col xs="auto" role="button" onClick={handleDiscard}>
                    <FontAwesomeIcon icon={faTimes} />
                </Col>
            </Row>
            <Row className="data-view__project-tag border-bottom py-1 mb-3">
                <Col className="px-1">
                    <TextInput label={t('languages:de')} value={tag.name.de_DE} onChange={handleTextChangeDE} />
                </Col>
                <Col className="px-1">
                    <TextInput label={t('languages:fr')} value={tag.name.fr_FR} onChange={handleTextChangeFR} />
                </Col>
                <Col className="px-1">
                    <TextInput label={t('languages:it')} value={tag.name.it_IT} onChange={handleTextChangeIT} />
                </Col>
                <Col className="px-1">
                    <TextInput label={t('languages:en')} value={tag.name.en_GB} onChange={handleTextChangeEN} />
                </Col>
            </Row>
        </React.Fragment>
    );
};
