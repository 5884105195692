import React, { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { Form, FormField, Select, TextInput } from '../../components';
import { config } from '../../config';

interface Props {
    onSubmit: (data: AnyObject) => void;
    isSubmitting: boolean;
}

export const CustomerServiceForm: React.FC<Props> = ({ onSubmit, isSubmitting }) => {
    const [t, i18n] = useTranslation();

    const [valid, setValidity] = useState(false);
    const [captcha, setCaptcha] = useState<string | null>(null);

    const recaptchaRef = useRef<ReCAPTCHA>(null);

    const submit = async (vals: NestedFormValues) => {
        onSubmit({
            ...vals,
            recaptchaCode: captcha,
        });
    };

    const onCaptchaVerified = (captchaResponse: string | null) => {
        setCaptcha(captchaResponse);
    };

    const canSubmit = (): boolean => {
        return captcha !== null;
    };

    const handleChange = (_newValues: NestedFormValues, newValidity: boolean) => {
        setValidity(newValidity);
    };

    useEffect(() => {
        setCaptcha(null);
    }, [i18n.language]);

    return (
        <Form onChange={handleChange} onSubmit={submit}>
            <Row className="small-push-bottom">
                <Col xs="12">
                    <FormField
                        name="salutation"
                        Component={Select}
                        Props={{
                            id: 'input-salutation',
                            label: t('customerService:salutation'),
                            options: [
                                {
                                    label: t('salutation:male'),
                                    value: 'Mr',
                                },
                                {
                                    label: t('salutation:female'),
                                    value: 'Ms',
                                },
                            ],
                            required: true,
                            className: 'my-3',
                        }}
                    />
                </Col>
                <Col xs="12" lg="6">
                    <FormField
                        name="firstName"
                        Component={TextInput}
                        Props={{
                            id: 'input-firstname',
                            type: 'text',
                            label: t('customerService:firstname'),
                            autoComplete: 'firstname',
                            required: true,
                        }}
                    />
                </Col>
                <Col xs="12" lg="6">
                    <FormField
                        name="lastName"
                        Component={TextInput}
                        Props={{
                            id: 'input-lastname',
                            type: 'text',
                            label: t('customerService:lastname'),
                            autoComplete: 'lastname',
                            required: true,
                        }}
                    />
                </Col>
                <Col xs="12">
                    <FormField
                        name="email"
                        Component={TextInput}
                        Props={{
                            id: 'input-email',
                            type: 'text',
                            label: t('customerService:email'),
                            autoComplete: 'email',
                            required: true,
                            gutterTop: true,
                        }}
                    />
                </Col>
                <Col xs="12">
                    <FormField
                        name="text"
                        Component={TextInput}
                        Props={{
                            id: 'input-text',
                            type: 'text',
                            label: t('customerService:text'),
                            required: true,
                        }}
                    />

                    {!valid && isSubmitting && (
                        <div className="form__input form__error">
                            <div>The form is incomplete or invalid</div>
                        </div>
                    )}

                    <div className="mb-3">
                        <ReCAPTCHA
                            key={i18n.language}
                            ref={recaptchaRef}
                            sitekey={config.captcha.siteKey}
                            onChange={onCaptchaVerified}
                            hl={i18n.language}
                        />
                    </div>

                    {isSubmitting ? (
                        <div style={{ height: '50px' }}>{t('common:processing-request')}...</div>
                    ) : (
                        <button type="submit" className="button" disabled={!canSubmit()}>
                            {t('customerService:submit-customer-service-request')}
                        </button>
                    )}
                </Col>
            </Row>
        </Form>
    );
};

export default CustomerServiceForm;
