import type { AxiosError } from 'axios';
import { useQuery, UseQueryResult, UseQueryOptions } from 'react-query';
import { editorService } from '../../services';
import { WizardState } from '../../types';

export function useWizardState(
    id: ID,
    options?: Omit<UseQueryOptions<unknown, AxiosError, WizardState>, 'queryKey' | 'queryFn'>
): UseQueryResult<WizardState, AxiosError> {
    return useQuery(['wizard-state', id], () => editorService.getWizardState(id), options);
}
