import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import germanTranslations from './languages/de';
import englishTranslations from './languages/en';
import frenchTranslations from './languages/fr';
import italianTranslations from './languages/it';

i18n.use(initReactI18next).init({
    fallbackLng: 'en',
    whitelist: ['en', 'de', 'fr', 'it'],
    defaultNS: 'translation',
    resources: {
        en: englishTranslations,
        de: germanTranslations,
        fr: frenchTranslations,
        it: italianTranslations,
    },
    debug: process.env.NODE_ENV === 'development',

    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },

    react: {
        wait: true,
    },
});

export default i18n;
