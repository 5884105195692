import { BKPCalculator } from './bkp.calculator';
import { EBKPHCalculator } from './ebkp-h.calculator';
import { EBKPTCalculator } from './ebkp-t.calculator';
import type { Calculator } from './types';

export class CostCalculator implements Calculator {
    public getInvestmentCosts(_costs: Array<RegulationWithCost>): number {
        return 0;
    }

    public getCreationCosts(_costs: Array<RegulationWithCost>): number {
        return 0;
    }

    public getConstructionCosts(_costs: Array<RegulationWithCost>): number {
        return 0;
    }

    public getBuildingCosts(_costs: Array<RegulationWithCost>): number {
        return 0;
    }

    public static get(type = 'BKP'): Calculator {
        switch (type) {
            case 'BKP':
                return new BKPCalculator();
            case 'eBKP-H':
            case 'eBKP-H2020(A)':
            case 'eBKP-H2020(B)':
                return new EBKPHCalculator();
            case 'eBKP-T':
            case 'eBKP-T(A)':
            case 'eBKP-T(B)':
                return new EBKPTCalculator();
            default:
                return new CostCalculator();
        }
    }
}
