import { generatePath } from 'react-router-dom';
import { RoutesEnum } from '../enums/paths';

/**
 * Builds an URL to an AWS S3 Bucket of werkmaterial.
 *
 * @param page The name of the page
 * @param dataName The name of the stored data
 * @example getAWSBucketSrc('Glossar', 'Glossar_DE.htm') // '/s3/werkmaterial_WEB/Glossar/Glossar_DE.htm'
 */
export function getAWSBucketSrc(page: string, dataName: string): string {
    return generatePath(RoutesEnum.AWS_BUCKET_SRC, { page, dataName });
}
