import React from 'react';

interface DraggableListProps extends React.PropsWithChildren<AnyObject> {
    className?: string;
    ref?: React.RefObject<HTMLDivElement>;
}

interface Context {
    drag: Drag | null;
    startDrag: (index: number, element: HTMLElement) => void;
    handleDrag: (index: number, element: HTMLElement) => void;
    handleDrop: () => void;
}

export interface Drag {
    start: {
        index: number;
        element: HTMLElement;
    };
    current: number;
    previous: number;

    dragWidth: number;
}

interface DraggableActions {
    startDrag: (index: number, element: HTMLElement) => void;
    handleDrag: (index: number, element: HTMLElement) => void;
    handleDrop: () => void;
}

const Context = React.createContext<null | Context>(null);

export const DraggableList = React.forwardRef<HTMLDivElement, DraggableListProps>(function DraggableList(
    { className, children },
    ref
) {
    const [drag, setDrag] = React.useState<null | Drag>(null);

    const startDrag = (index: number, element: HTMLElement) => {
        setDrag(() => ({
            start: {
                index,
                element,
            },
            current: index,
            previous: index,
            dragWidth: 0,
        }));
    };

    const handleDrag = (index: number, element: HTMLElement) => {
        setDrag((d) => {
            const current = index;
            const previous = d ? (d.current !== current ? d.current : d.previous) : index;

            if (!d || d.current === index) {
                return d;
            }

            return {
                ...d,
                previous,
                current,
                dragWidth: d.dragWidth + element.clientHeight * (index > d.current ? 1 : -1),
            };
        });
    };

    const drop = () => {
        setDrag(null);
    };

    return (
        <Context.Provider
            value={{
                drag,
                startDrag,
                handleDrag,
                handleDrop: drop,
            }}
        >
            <div className={['draggable-list', className].join(' ')} data-drag={!!drag} ref={ref}>
                {children}
            </div>
        </Context.Provider>
    );
});

export function useDrag(): [Drag | null, DraggableActions] {
    const context = React.useContext(Context);

    if (!context) {
        throw new Error();
    }

    const { drag, ...actions } = context;

    return [drag, { ...actions }];
}
