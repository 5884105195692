import { InitialStep, StepIDs, Step } from './types';

export const STEPS: Step[] = [
    createStep(StepIDs.PROCEDURE),
    createStep(StepIDs.CAD),
    createStep(StepIDs.INPUTFILE),
    createStep(StepIDs.UPLOAD),
];

function createStep(step: StepIDs): Step {
    const stepName = step.toLowerCase();

    return {
        image: createImageURL(`3d-${stepName}.svg`),
        id: step,
    };
}
/**
 * return translation key of building-plan-importer
 * @example tKey('upload') // editor:bkp-to-ebkph-wizard:volume-evaluation:building-plan-importer:upload
 */
export function tKey(key: string): string {
    return `editor:bkp-to-ebkph-wizard:volume-evaluation:building-plan-importer:${key}`;
}

export function getInitialStep(initialStep?: InitialStep, onlyUpload?: boolean): StepIDs {
    if (initialStep === 'upload' || onlyUpload) return StepIDs.UPLOAD;

    return StepIDs.PROCEDURE;
}

export function getSteps(onlyUpload?: boolean): Step[] {
    if (onlyUpload) return [STEPS[3]];

    return STEPS;
}

export function toByte(mb: number): number {
    return mb * 1024 * 1024;
}

export function getImage(step: StepIDs): string {
    switch (step) {
        case StepIDs.PROCEDURE:
            return createImageURL('3d-procedure.svg');
        case StepIDs.CAD:
            return createImageURL('3d-cad.svg');
        case StepIDs.INPUTFILE:
            return createImageURL('3d-inputfile.svg');
        case StepIDs.UPLOAD:
            return createImageURL('3d-upload.svg');
        default:
            throw new Error('IllegalArgument');
    }
}

export function createImageURL(file: string): string {
    return `${process.env.PUBLIC_URL}/images/grafiken/${file}`;
}
