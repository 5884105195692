import React from 'react';
import { Col, Row } from 'reactstrap';

interface Props {
    title: string;
    lead?: string;
    noGutter?: boolean;
    noPush?: boolean;
}

export const PageHeader: React.FC<Props> = ({ title, lead, noGutter, noPush }) => (
    <Row className={[!noGutter && 'gutter-top', !noPush && 'small-push-bottom'].filter((c) => !!c).join(' ')}>
        <Col xs="12" lg="10">
            <h1>{title}</h1>
            {lead && <p className="lead">{lead}</p>}
        </Col>
    </Row>
);
