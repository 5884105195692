import type { AxiosError } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { editorService } from '../../services';

export function useProjects(): UseQueryResult<ProjectOverview[], AxiosError> {
    return useQuery(['projects', 'private'], () => editorService.getProjects());
}

export function useReferenceProjectsQuery(): UseQueryResult<ProjectOverview[], AxiosError> {
    return useQuery(['projects', 'public'], () => editorService.getReferenceProjects(), { retry: 2 });
}
