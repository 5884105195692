import type { InputFile } from '@crb-oa-viewer/data-assistant-building-plan';

export type InitialStep = 'intro' | 'upload';

export enum StepIDs {
    PROCEDURE = 'procedure',
    CAD = 'cad',
    INPUTFILE = 'inputfile',
    UPLOAD = 'upload',
}

export interface Step {
    image: string;
    id: StepIDs;
}

export interface NavigationState {
    step: Step;
    steps: Step[];
    isStart: boolean;
    isEnd: boolean;
}

export interface NavigationActions {
    previous: () => unknown;
    next: () => unknown;
}

export interface InputActions {
    openDialog: () => unknown;
}

export interface InputCallbacks {
    onChange: (file: InputFile) => unknown;
    onError: (error: BuildingPlanError | null) => unknown;
}

export interface BuildingPlanError {
    error: string;
    errorParams?: unknown;
    errorAddon?: React.ReactNode;
    floating?: boolean;
}
