import { uniqBy, sortBy } from 'lodash';
import React from 'react';

export function useCantons(staticData?: StaticData | null): StaticCanton[] {
    /**
     * Filters all distinct cantons and orders them with the cantonCode in alphabetical order
     */
    return React.useMemo(() => {
        if (!staticData) {
            return [];
        }

        const cs = staticData.locations.map(({ canton }) => canton);
        const nonNullCs = cs.filter((c): c is StaticCanton => !!c);

        return sortBy(
            uniqBy(nonNullCs, ({ cantonCode }) => cantonCode),
            ({ cantonCode }) => cantonCode
        );
    }, [staticData]);
}
