import classnames from 'classnames';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Tab, Tabs } from './tabs';

interface Props {
    name: string;
    title: string;
    tabs?: undefined;
    className?: string;
}

interface PropsWithTabs extends Omit<Props, 'tabs'> {
    tabs: Tab[];
    onTab?: (tab: string) => void;
}

export const BaseView: React.FC<Props | PropsWithTabs> = (props) => (
    <Container
        className={classnames('project-editor-view', 'small-push-bottom', `${props.name}-view`, props.className)}
    >
        <Row className="mt-3 mb-5">
            <Col xs={12}>
                <h2>{props.title}</h2>
            </Col>
        </Row>

        {props.tabs && props.tabs.length > 0 && <Tabs tabs={props.tabs} onTab={props.onTab} />}

        {props.children}
    </Container>
);
