import React from 'react';

export function useDictionary<I, K extends string | number>(
    values: I[] = [],
    map: (item: I, index: number) => K,
    deps: unknown[] = []
): Record<K, I | undefined> {
    return React.useMemo(() => {
        return values.reduce(
            (acc, value, index) => ({
                ...acc,
                [map(value, index)]: value,
            }),
            {} as Record<K, I>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values, ...deps]);
}
