import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'i18next';
import React, { useCallback } from 'react';
import { Col, Row } from 'reactstrap';
import { Member } from './Member';

interface Props {
    project: Project;
    roles: ProjectMemberRole[];
    language: EditorLanguage;
    changeProject: (update: (project: Project) => Project) => void;
    t: TFunction;
}

export const Members: React.FC<Props> = ({ project, roles, language, changeProject, t }) => {
    const rolesMap = React.useMemo(
        () =>
            roles.reduce<{ [id: string]: ProjectMemberRole }>((m, r) => {
                m[r.id.toString()] = r;

                return m;
            }, {}),
        [roles]
    );

    const members = React.useMemo(() => {
        return (project.members || [])
            .map((member, index) => ({ ...member, index }))
            .sort((m1, m2) => {
                if (!m1.role.id) {
                    return 1;
                }

                if (!m2.role.id) {
                    return -1;
                }

                const role1 = rolesMap[m1.role.id].name;
                const role2 = rolesMap[m2.role.id].name;

                const roleName1 = role1[language.codes.editor] || role1.de_DE || Object.values(role1 || {})[0];
                const roleName2 = role2[language.codes.editor] || role2.de_DE || Object.values(role2 || {})[0];

                return roleName1.localeCompare(roleName2);
            });
    }, [project.members, language, rolesMap]);

    const changeMember = useCallback(
        (changedMember: ProjectMember, index: number) => {
            changeProject((p) => ({
                ...p,
                members: (p.members || []).map((member, i) => {
                    if (index === i) {
                        return changedMember;
                    }

                    return member;
                }),
            }));
        },
        [changeProject]
    );

    const deleteMember = useCallback(
        (index: number) => {
            changeProject((p) => ({
                ...p,
                members: (p.members || []).filter((_, i) => i !== index),
            }));
        },
        [changeProject]
    );

    const addMember = useCallback(() => {
        changeProject((p) => ({
            ...p,
            members: [
                ...(p.members ?? []),
                {
                    name: '',
                    role: {
                        id: undefined,
                    },
                } as unknown as ProjectMember,
            ],
        }));
    }, [changeProject]);

    const initialRole = React.useMemo(() => roles.find((r) => r.name.de_DE === 'Architekt') || roles[0], [roles]);

    return (
        <Row className="small-push-bottom">
            <Col xs={12}>
                <h3 className="mb-3">{t('editor:projects:creator:data-sheet:members')}</h3>
                {members.map((member) => (
                    <Member
                        member={member}
                        role={member.role.id != null ? rolesMap[member.role.id] : undefined}
                        initialRole={initialRole}
                        roles={roles}
                        language={language}
                        index={member.index}
                        onChange={changeMember}
                        onDelete={deleteMember}
                        t={t}
                        key={member.index}
                    />
                ))}
            </Col>
            <Col xs={12} className="mt-3 project-editor__action" onClick={addMember} data-testid="add-participant">
                {`${t('editor:project:parameters:participants:add-participant')} `}
                <FontAwesomeIcon icon={faPlus} />
            </Col>
        </Row>
    );
};
