import { Contour, OrthographicHighlight } from '@crb-oa-viewer/data-assistant-building-plan';
import classnames from 'classnames';
import { noop } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Form, Row, Table } from 'reactstrap';
import { codeOf } from '../../../utils';
import type { BuildingPlanContext } from '../types';

interface Props {
    className?: string;
    contours: Contour[];
    hovering?: string;
    onHover: (action: OrthographicHighlight) => void;
    onSelectionToggle?: (event: OrthographicHighlight) => void;
    onComplete?: () => void;
    selection?: string[];
    context?: BuildingPlanContext;
}

const NO_SELECTION: string[] = [];

export const ContourSelectionForm: React.FC<Props> = ({
    className,
    contours,
    hovering,
    onHover,
    onSelectionToggle = noop,
    onComplete,
    selection = NO_SELECTION,
    context,
}) => {
    const { t, i18n } = useTranslation();
    const languageCode = codeOf(i18n.language);
    const tt = (key: string, options?: AnyObject) =>
        t(`editor:bkp-to-ebkph-wizard:volume-evaluation:terrain-control-form:${key}`, options);
    const tfloor = (key: string) => t(`editor:project:floor:floorTypes:${key}`);
    const floorName = `${context?.floor.floorNumber ? context.floor.floorNumber : ''} ${tfloor(
        context?.floor.floorType as string
    )}`;

    const handleSelection = React.useCallback(
        (id: string) => {
            const highlight = {
                itemId: id,
                itemType: 'contour',
                eventType: 'select',
            };

            onSelectionToggle(highlight);
        },
        [onSelectionToggle]
    );

    const handleHover = (id: string) =>
        onHover({
            itemId: id,
            itemType: 'contour',
            eventType: 'hover',
        });

    const handleSubmit = React.useCallback(() => onComplete?.(), [onComplete]);
    const sortedContours = React.useMemo(() => [...contours].sort((a, b) => b.area - a.area), [contours]);

    return (
        <Form className={classnames('contour-selection-form container', className)} onSubmit={handleSubmit}>
            <Container>
                <Row className="very-small-push-bottom">
                    <Col xs={12}>
                        <h3>
                            {tt(context ? 'floor-title' : 'terrain-title', {
                                buildingName: context?.building.name[languageCode],
                                floorName,
                            })}
                        </h3>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <p>{tt(context ? 'floor-description' : 'terrain-description')}</p>
                    </Col>
                </Row>

                <Row>
                    <Table className="gutter-top w-100" responsive={true}>
                        <thead>
                            <tr>
                                <th className="border-top-0" style={{ width: 42 }} />
                                <th className="border-top-0 w-75">{tt('name')}</th>
                                <th className="border-top-0 text-right" style={{ width: 150 }}>
                                    {tt('area')}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedContours.map((contour, i) => (
                                <ContourEntry
                                    className={i === sortedContours.length - 1 ? 'border-bottom' : ''}
                                    contour={contour}
                                    selected={selection.includes(contour.id)}
                                    hovering={!!hovering && hovering === contour.id}
                                    onHover={handleHover}
                                    onSelect={handleSelection}
                                    key={contour.id}
                                />
                            ))}
                        </tbody>
                    </Table>
                </Row>
            </Container>
        </Form>
    );
};

interface ContourEntryProps {
    className?: string;
    contour: Contour & { name?: string };
    selected?: boolean;
    hovering?: boolean;
    onHover?: (id: string) => void;
    onSelect?: (id: string) => void;
}

const ContourEntry: React.FC<ContourEntryProps> = ({ className, contour, selected, hovering, onHover, onSelect }) => {
    const hover = React.useCallback(() => onHover?.(contour.id), [contour.id, onHover]);
    const [t] = useTranslation();
    const tt = (key: string, options?: AnyObject) =>
        t(`editor:bkp-to-ebkph-wizard:volume-evaluation:terrain-control-form:${key}`, options);

    const select = React.useCallback(() => onSelect?.(contour.id), [contour.id, onSelect]);
    const contourName = contour.id != null ? `${tt('contour')} ${contour.id}` : 'Missing Name';

    return (
        <tr className={classnames('hoverable', className)} onMouseEnter={hover} onClick={select} data-hover={hovering}>
            <td>
                <div className="checkbox">
                    <input type="checkbox" readOnly={true} checked={selected} />
                    <span className="checkbox-checkmark" />
                </div>
            </td>
            <td>{contourName}</td>
            <td className="text-right" data-testid="contour-area">
                {contour.area.toFixed(0)} m&sup2;
            </td>
        </tr>
    );
};
