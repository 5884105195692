import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'i18next';
import React, { useCallback } from 'react';
import { Col, Row } from 'reactstrap';
import { NumberInput, TagSelector } from '../../../../../FormComponents';

interface Props {
    index: number;
    functionalUnit: FunctionalUnitModel;
    tags: FunctionalUnitTag[];
    onChange: (update: (unit: FunctionalUnitModel) => FunctionalUnitModel, index: number) => void;
    onDelete: (index: number) => void;
    t: TFunction;
    language: EditorLanguage;
}

export const FunctionalUnit: React.FC<Props> = ({
    index,
    functionalUnit,
    tags,
    onChange: changeFunctionalUnit,
    onDelete,
    t,
    language,
}) => {
    const changeTag = useCallback(
        (tag: FunctionalUnitTag) => {
            changeFunctionalUnit((fu) => ({ ...fu, tag }), index);
        },
        [changeFunctionalUnit, index]
    );

    const changeAmount = useCallback(
        (amount: string) => {
            changeFunctionalUnit((fu) => ({ ...fu, amount: parseInt(amount) }), index);
        },
        [changeFunctionalUnit, index]
    );

    const raiseDelete = () => {
        onDelete(index);
    };
    return (
        <Row className="data-view__functional-unit border-bottom py-0">
            <Col className="pt-1">
                <TagSelector
                    tag={functionalUnit.tag}
                    tags={tags}
                    onChange={changeTag}
                    label={t('editor:project:parameters:functionalUnits:functionalUnit')}
                    language={language.codes.editor}
                    t={t}
                />
                {/* <TextInput
                    label={t('editor:project:parameters:functionalUnits:functionalUnit')}
                    value={functionalUnit.functionalUnit || ''}
                    onChange={changeName}
                    lazy={true}
                    required={true}
                    testId="functional-unit-name"
                /> */}
            </Col>
            <Col className="pt-1">
                <NumberInput
                    label={t('editor:project:parameters:functionalUnits:amount')}
                    value={`${functionalUnit.amount || ''}`}
                    decimals={0}
                    onChange={changeAmount}
                    lazy={true}
                    required={true}
                    testId="functional-unit-amount"
                />
            </Col>
            <Col
                xs={3}
                lg={2}
                className="project-editor__action"
                onClick={raiseDelete}
                data-testid="delete-functional-unit"
            >
                {`${t('editor:project:parameters:functionalUnits:deleteFunctionalUnit')} `}
                <FontAwesomeIcon icon={faTimes} />
            </Col>
        </Row>
    );
};
