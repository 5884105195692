import React from 'react';

interface Page<I> {
    number: number;
    items: I[];
}

interface Pagination<I> {
    page: Page<I>;
    changePage: (page: number) => void;
    next: () => void;
    previous: () => void;
    info: {
        maxPage: number;
    };
}

interface PaginationOptions {
    size?: number;
    page?: number;
    changePage?: (page: number) => unknown;
}

const DEFAULT_SIZE = 25;

export function usePagination<I>(items: I[], options: PaginationOptions = { size: 25, page: 0 }): Pagination<I> {
    const [pageNumber, setPage] = React.useState(options.page || 0);

    const size = options.size || DEFAULT_SIZE;

    const changePage = React.useCallback(
        (newPage: number) => {
            setPage(newPage);
            options.changePage?.(newPage);
        },
        [options]
    );

    const next = () => {
        const newPage = Math.min(pageNumber + 1, Math.max(0, Math.floor(items.length / size)));
        changePage(newPage);
    };

    const previous = () => {
        const newPage = Math.max(0, pageNumber - 1);
        changePage(newPage);
    };

    const info = {
        maxPage: Math.ceil(items.length / size) || (options.page ?? 0) + 1,
    };

    React.useEffect(() => {
        if ((options.page || 0) >= info.maxPage) {
            changePage(info.maxPage - 1);
        } else if ((options.page || 0) < 0) {
            changePage(0);
        }
    }, [options.page, info.maxPage]); // eslint-disable-line react-hooks/exhaustive-deps

    const page: Page<I> = {
        number: pageNumber,
        items: items.filter((_, index) => index >= pageNumber * size && index < (pageNumber + 1) * size),
    };

    React.useEffect(() => {
        if (pageNumber >= info.maxPage) changePage(info.maxPage - 1);
    }, [pageNumber, info.maxPage, changePage]);

    return {
        page,
        changePage,
        next,
        previous,
        info,
    };
}
