import { emailValidator } from './email.validator';
import { lengthValidator } from './length.validator';
import { ValidationExecutor } from './ValidationExecutor';

export const Validators = {
    Email: emailValidator,
    Length: lengthValidator,

    use:
        (...validators: Array<ReturnType<Validator>>): Validator =>
        (report: ReportFunction) => {
            const reportingValidators = validators.map((validator) => validator(report));

            return (value: unknown) => reportingValidators.forEach((validator) => validator(value));
        },
    execute<Value>(validator: Validator<Value>, value: Value): Report | null {
        const executor = new ValidationExecutor();

        executor.execute(validator, value);

        return executor.getReport();
    },
};
