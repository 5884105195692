import React from 'react';
import { formatValueToLocale } from '../../../utils';
import { CostValue } from '../../CostValue';

interface MeasurementValueItemProps {
    measurementRule: string;
    shortName: string;
    measureUnit: string;
    value: number;
}

export const ResultsCalculationQuantitiesItem: React.FC<MeasurementValueItemProps> = ({
    measurementRule,
    shortName,
    measureUnit,
    value,
}) => {
    return (
        <div className="wizard-tree-row p-3 mt-2 mb-2" data-testid="calculation-result-item">
            <span className="drawer-header-comparison">{measurementRule}</span>
            <span className="regulation-short-name drawer-header-quantity-amount mr-sm-0">{shortName}</span>
            <div className="regulation-value">
                <CostValue
                    className="regulation-value__item drawer-header-quantity-amount mr-sm-0"
                    label={measureUnit}
                    value={formatValueToLocale(value, 2)}
                    locked={true}
                />
            </div>
        </div>
    );
};
