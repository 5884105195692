import React from 'react';
import { Row } from 'reactstrap';
import { useSearchParam } from '../../../effects';
import { TextButton } from '../../Buttons';

export interface Tab {
    id: string;
    name: string;
}

interface TabsProps {
    tabs: Tab[];
    onTab?: (tab: string) => void;
}

const getTab = (tabs: Tab[], tabId?: string) => {
    return tabs.find((tab) => tab.id === tabId)?.id || null;
};

export const Tabs: React.FC<TabsProps> = ({ tabs, onTab }) => {
    const [tabParam, setTabParam] = useSearchParam('tab', tabs.length > 0 ? tabs[0].id : undefined);
    const tab = getTab(tabs, tabParam);

    const changeTab = (newTab: string) => () => {
        setTabParam(newTab);
        if (onTab) onTab(newTab);
    };

    return (
        <Row className="tabs">
            {tabs.map(({ id, name }) => (
                <TextButton className="mr-3 mb-3" onClick={changeTab(id)} name={name} active={id === tab} key={id} />
            ))}
        </Row>
    );
};
