import { faSort } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ElaboratedProject } from './types';
import { SortDirection } from '../../enums';

export interface ProjectTableHeaderProps {
    projects?: Array<ElaboratedProject> | null;
    sort?: string;
    selection: ID[];
    hideThumbnail?: boolean;
    onToggleAll?: () => void;
    onSort?: (sort: string) => void;
}

export const ProjectTableHeader: React.FC<ProjectTableHeaderProps> = ({
    projects,
    sort,
    selection,
    hideThumbnail,
    onToggleAll,
    onSort,
}) => {
    const [t] = useTranslation();

    const sortProject = (id: string) => () => {
        const [sortId, sortDirection] = sort ? sort.split(':') : [];

        const newSortDirection =
            sortId === id && sortDirection === SortDirection.Ascending
                ? SortDirection.Descending
                : SortDirection.Ascending;

        onSort?.(`${id}:${newSortDirection}`);
    };

    const PROJECT_LIST_COLUMN_HEADERS = [
        { id: 'id', name: 'editor:projects:list:project-id', sortable: true },
        {
            id: 'name',
            name: 'editor:projects:list:project-name',
            sortable: true,
        },
        {
            id: 'canton',
            name: 'editor:projects:list:project-canton',
            sortable: true,
        },
        {
            id: 'year',
            name: 'editor:projects:list:project-year',
            sortable: true,
        },
        {
            id: 'classification',
            name: 'editor:projects:list:project-type-partners',
            sortable: true,
        },
        {
            id: 'projectPhase',
            name: 'editor:projects:list:project-phase',
            sortable: true,
        },
        {
            id: 'status',
            name: 'editor:projects:list:project-status',
            sortable: true,
        },
    ];

    const allChecked = React.useMemo(() => {
        if (selection.length === 0) return false;

        return projects?.every((project) => selection.includes(project.id));
    }, [projects, selection]);

    const disableCheckAll = !onToggleAll || projects?.length === 0;

    return (
        <thead className="project-table-header">
            <tr>
                <th>
                    <div className="checkbox">
                        <input
                            type="checkbox"
                            checked={allChecked}
                            onChange={onToggleAll}
                            data-testid="check-all"
                            disabled={disableCheckAll}
                        />
                        <span className="checkbox-checkmark" onClick={onToggleAll} />
                    </div>
                </th>
                {!hideThumbnail && <th />}
                {PROJECT_LIST_COLUMN_HEADERS.map((col) => (
                    <th
                        className={`project-list-header ${col.sortable ? 'project-list-header--sortable' : ''}`}
                        onClick={col.sortable ? sortProject(col.id) : undefined}
                        key={col.id}
                    >
                        <div className="project-list-header__content">
                            <div className="project-list-header__text">{t(col.name)}</div>
                            <FontAwesomeIcon className="project-list-header__sort-icon" icon={faSort} />
                        </div>
                    </th>
                ))}
            </tr>
        </thead>
    );
};
