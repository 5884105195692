import type { AxiosError } from 'axios';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { editorService } from '../../services';

export function useProjectCreate(): UseMutationResult<ID, AxiosError, CreateProjectPayload> {
    const queryClient = useQueryClient();

    return useMutation(
        (payload: CreateProjectPayload) => {
            return editorService.createProject({
                ...payload,
                constructionTime: { moveIn: new Date() },
                source: { publicationDate: new Date() },
            });
        },
        {
            onSuccess: () => void queryClient.invalidateQueries(['projects']),
        }
    );
}

export function useProjectDuplicate(): UseMutationResult<ID, AxiosError, ID> {
    const queryClient = useQueryClient();

    return useMutation((id) => editorService.duplicateProject(id), {
        onSuccess: () => void queryClient.invalidateQueries(['projects']),
    });
}

export function useProjectDelete(): UseMutationResult<void, AxiosError, ID> {
    const queryClient = useQueryClient();

    return useMutation((id) => editorService.deleteProject(id), {
        onMutate: (id) =>
            queryClient.setQueriesData<ProjectOverview[] | undefined>(['projects'], (projects) =>
                projects?.filter((p) => p.id !== id)
            ),
        onSettled: () => void queryClient.invalidateQueries(['projects']),
    });
}

export function useProjectsDelete(): UseMutationResult<ID[], AxiosError, ID[]> {
    const queryClient = useQueryClient();

    return useMutation((ids) => editorService.deleteProjects(ids), {
        onMutate: (ids) => {
            queryClient.setQueriesData<ProjectOverview[] | undefined>(['projects'], (projects) =>
                projects?.filter((p) => !ids.includes(p.id))
            );
        },
        onSettled: () => queryClient.invalidateQueries(['projects']),
    });
}

export function useProjectVisibilityToggle(): UseMutationResult<void, AxiosError, ProjectVisibilityUpdatePayload> {
    const queryClient = useQueryClient();

    return useMutation((update) => editorService.updateProjectsVisibility(update), {
        onMutate: (payload) => {
            queryClient.setQueriesData<ProjectOverview[] | undefined>(['projects'], (projects) =>
                projects?.map((p) => {
                    if (payload.projects.some(({ projectId }) => projectId === p.id)) {
                        return { ...p, userPublished: !p.userPublished };
                    }

                    return p;
                })
            );
        },
        onSettled: () => void queryClient.invalidateQueries(['projects']),
    });
}
