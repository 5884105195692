import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isMobile } from '../../../utils';
import { BaseTextInput, DefaultTextInputProps } from './BaseTextInput';
import { validate } from 'uuid';
import classnames from 'classnames';

export interface UUIDInputProps
    extends Omit<FormFieldComponentProps<string, DefaultTextInputProps>, 'type' | 'onChange'> {
    onChange: (value: string, valid: boolean) => unknown;
}

export const UUIDInput: React.FC<UUIDInputProps> = ({ value = '', onChange, lazy, ...props }) => {
    const type = 'text';

    const [focused, setFocused] = useState(false);
    const [text, setText] = useState(parseUUIDv4(value || ''));

    useEffect(() => {
        if (!focused) {
            setText(parseUUIDv4(value));
        }
    }, [value, focused]);

    const focus = useCallback(() => setFocused(true), []);

    const onChangeHandler = useMemo(() => {
        if (!lazy) return (value: string) => onChange(value, validate(value));
        return debounce((value: string) => onChange(value, validate(value)), 300);
    }, [lazy, onChange]);

    const change = useCallback(
        (newValue = '') => {
            const uuid = parseUUIDv4(newValue);
            setText(uuid);
            onChangeHandler(uuid);
        },
        [onChangeHandler]
    );

    const handleChange = useCallback(
        ({ target }: React.ChangeEvent<HTMLInputElement>) => change(target.value),
        [change]
    );

    const handlePaste = useCallback(
        (e: React.ClipboardEvent<HTMLElement>) => {
            e.preventDefault();
            change(`${e.clipboardData.getData('Text')}`);
        },
        [change]
    );

    const handleBlur = useCallback(() => setFocused(false), []);

    return (
        <BaseTextInput
            {...props}
            className={classnames(props.className, 'uuid-input')}
            value={text}
            onChange={handleChange}
            onBlur={handleBlur}
            onPaste={handlePaste}
            onFocus={focus}
            type={isMobile() ? type : 'text'}
        />
    );
};

const parseUUIDv4 = (value: string): string => {
    value = value.replaceAll(/[^0-9a-f]/gi, '');

    const parts = [
        value.substring(0, 8),
        value.length >= 8 ? value.substring(8, 12) : '',
        value.length >= 12 ? value.substring(12, 16) : '',
        value.length >= 16 ? value.substring(16, 20) : '',
        value.length >= 20 ? value.substring(20, 32) : '',
    ].filter((s) => s.length);

    return parts.join('-');
};
