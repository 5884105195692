import type { AxiosError } from 'axios';
import { MutationFunction, useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { editorService } from '../../services';
import { useStaticData } from '../queries';

interface MutationContext {
    oldProjects?: ProjectOverview[];
}

export function useWizardStateComplete(): UseMutationResult<
    Project,
    AxiosError | Error,
    Project,
    MutationContext | undefined
> {
    const queryClient = useQueryClient();
    const { data: staticData } = useStaticData();

    /**
     * Saves the project to the backend.
     *
     * Also deletes the wizard state if it exists
     */
    const saveProject: MutationFunction<Project, Project> = async (project: Project) => {
        const costIndex = staticData?.costIndices.find((ci) => ci.id === project.costIndex.id);

        if (!costIndex) throw new Error('Cost index not provided');

        return editorService.completeWizardState({
            ...project,
            costIndexDate: costIndex.date,
            greaterRegion: costIndex.greaterRegion,
        });
    };

    return useMutation<Project, AxiosError | Error, Project, MutationContext | undefined>(saveProject, {
        onSuccess: (project) => void queryClient.setQueryData(['project', project.id], project),
        onSettled: async (_d, _e, project) => {
            await Promise.all([
                queryClient.invalidateQueries(['project', project.id]),
                queryClient.invalidateQueries(['wizard-state', project.id]),
                queryClient.resetQueries(['project', project.id, 'inputfile'], { inactive: true }),
                queryClient.invalidateQueries('projects', { inactive: true }),
            ]);
        },
    });
}
