import classnames from 'classnames';
import React, { useCallback, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { config } from '../../config';
import { useParentInViewport } from '../../hooks';
import { LanguageSelectorButton } from './LanguageSelectorButton';
import { OutOfViewLanguageSelector } from './OutOfViewLanguageSelector';

interface Props {
    className?: string;
}

export const LanguageSelector: React.FC<Props> = ({ className }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [, i18n] = useTranslation();

    const parentInView = useParentInViewport(ref);

    const { language } = i18n;
    const { languages } = config;

    const changeLanguage = useCallback((lang: string) => i18n.changeLanguage(lang), [i18n]);

    const inViewChildren = useMemo(() => {
        return languages.languages.map(({ id, name }) => (
            <LanguageSelectorButton
                isActive={id === language}
                label={name}
                onClick={() => changeLanguage(id)}
                key={id}
            />
        ));
    }, [languages, language, changeLanguage]);

    const mount = useMemo(() => document.querySelector('#root'), []);
    const selectedIndex = useMemo(
        () =>
            Math.max(
                languages.languages.findIndex(({ id }) => id === language),
                0
            ),
        [languages, language]
    );
    const outOfViewChildren = useMemo(() => {
        if (!mount) return null;

        return createPortal(
            <OutOfViewLanguageSelector selectedIndex={selectedIndex}>{inViewChildren}</OutOfViewLanguageSelector>,
            mount
        );
    }, [mount, selectedIndex, inViewChildren]);

    return (
        <div className={classnames(className, 'lang-changer', 'lang-changer__in-view')} ref={ref}>
            {inViewChildren}
            {!parentInView && outOfViewChildren}
        </div>
    );
};
