import type { FeedbackMessage } from '@crb-oa-viewer/data-assistant-building-plan';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import { NumberInput } from '../../FormComponents';
import { FeedbackMessagesView } from './FeedbackMessagesView';

interface Props {
    onScaleFactorChange: (scaleFactor: number) => void;
    scaleFactor?: number;
    defaultScaleFactor?: number;
    onComplete: () => void;
    messages?: FeedbackMessage[];
}

export const ImportControlForm: React.FC<Props> = ({
    onScaleFactorChange,
    scaleFactor,
    defaultScaleFactor = 1.0,
    onComplete,
    messages,
}) => {
    const [t] = useTranslation();
    const tt = (key: string, options?: AnyObject) =>
        t(`editor:bkp-to-ebkph-wizard:volume-evaluation:import-control-form:${key}`, options);

    useEffect(() => {
        if (!scaleFactor && defaultScaleFactor) onScaleFactorChange(defaultScaleFactor);
    }, [scaleFactor, defaultScaleFactor, onScaleFactorChange]);

    const changeScaling = React.useCallback(
        (newScaleFactorString: string) => {
            const newScaleFactor = parseFloat(newScaleFactorString);
            onScaleFactorChange(
                isNaN(newScaleFactor) ? defaultScaleFactor : newScaleFactor <= 0 ? defaultScaleFactor : newScaleFactor
            );
        },
        [onScaleFactorChange, defaultScaleFactor]
    );

    return (
        <Container className="import-control-form" onSubmit={onComplete} tag="form">
            <Row className="very-small-push-bottom">
                <Col xs={12}>
                    <h3>{tt('title')}</h3>
                </Col>
            </Row>
            <Row className="very-small-push-bottom">
                <Col xs={12}>
                    <p>{tt('description-1')}</p>

                    <p>{tt('description-2')}</p>
                </Col>
            </Row>
            <Row className="border-bottom">
                <Col xs="4" className="d-flex align-items-center">
                    {tt('scale')}
                </Col>
                <Col>
                    <NumberInput
                        wrapperClassName="mb-0"
                        label={tt('scale')}
                        value={`${scaleFactor ?? defaultScaleFactor}`}
                        numberDefaultValue={defaultScaleFactor}
                        onChange={changeScaling}
                        decimals={2}
                        required={true}
                        lazy={true}
                        autoFocus={true}
                    />
                </Col>
                <Col xs="auto" className="d-flex align-items-center">
                    <FontAwesomeIcon icon={faEye} />
                </Col>
            </Row>

            {messages?.length && (
                <Row>
                    <Col>
                        <FeedbackMessagesView messages={messages} />
                    </Col>
                </Row>
            )}
        </Container>
    );
};
