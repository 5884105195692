import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import type { NavigationState } from './types';
import { StepIDs } from './types';
import { tKey } from './utils';
import { Link } from 'react-router-dom';
import { RoutesEnum } from '../../enums/paths';

export const BuildingPlanInstructions: React.FC<NavigationState> = ({ step }) => {
    const [t] = useTranslation();

    /**
     * Upload step only has one title and description for both ifc and dxf file
     */
    if (step.id === StepIDs.UPLOAD) {
        return (
            <div className="building-plan-importer__instructions">
                <h3>{t(tKey(`${step.id}:title`))}</h3>
                <p>{t(tKey(`${step.id}:description`))}</p>
            </div>
        );
    }

    return (
        <div className="building-plan-importer__instructions">
            <h3>
                <Trans i18nKey={tKey(`${step.id}:ifc:title`)}>
                    This is a (<Link to={RoutesEnum.GLOSSARY}>link</Link>
                    ).
                </Trans>
            </h3>
            <p>{t(tKey(`${step.id}:ifc:description`))}</p>

            <h3>{t(tKey(`${step.id}:dxf:title`))}</h3>
            <p>{t(tKey(`${step.id}:dxf:description`))}</p>
        </div>
    );
};
