import React from 'react';
import ReactDOM from 'react-dom';

interface ToastProps {
    onClose?: () => void;
    title?: string;
    description?: string;
}

const Toast: React.FC<ToastProps> = ({ onClose, title, description }) => {
    return (
        <div className="toast__content" onClick={onClose}>
            <div className="toast__header">{title}</div>
            <div className="toast__body">{description}</div>
        </div>
    );
};

const CONTAINER_ID = 'toast-container';

function createToastContainer() {
    const el = document.createElement('div');
    el.id = CONTAINER_ID;

    document.body.appendChild(el);

    return el;
}

export function useToast(): (title: string, message: string) => Promise<void> {
    const container = React.useMemo(() => document.getElementById(CONTAINER_ID) ?? createToastContainer(), []);

    return React.useCallback(
        (title, message) => {
            return new Promise<void>((resolve) => {
                const close = () => {
                    ReactDOM.unmountComponentAtNode(container);
                    container.innerHTML = '';
                    resolve();
                };

                const timeout = setTimeout(close, 5000);
                const closeDialog = () => {
                    clearTimeout(timeout);
                    close();
                };

                ReactDOM.render(<Toast title={title} description={message} onClose={closeDialog} />, container);
            });
        },
        [container]
    );
}
